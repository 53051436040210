import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { JiraConfig } from './jira-integration.models';

@Injectable({ providedIn: 'root' })
export class JiraIntegrationService {
    constructor(private http: HttpClient) {}

    /**
     * deletes jira configuration on jira integration disabled
     * @param instanceId
     */
    disableJiraIntegration(instanceId) {
        return this.http.delete(
            `${environment.apiEndPoint}api/v1/integrations/jira/${instanceId}`,
            {
                headers: {
                    'url-id': instanceId
                }
            }
        );
    }

    /**
     * persist jira config on server
     * @param configToUpdate configuration we want to update
     * @param currentConfig current configuration to persis the fields that
     * haven't updated
     * @param instanceId instance of the configuration
     */
    saveJiraConfig(
        configToUpdate: Partial<JiraConfig>,
        currentConfig: JiraConfig,
        instanceId
    ): Observable<{ config: JiraConfig }> {
        const newConfig: JiraConfig = { ...currentConfig, ...configToUpdate };
        const { id, ...attributes } = newConfig;
        if (id === null || id === undefined) {
            return this.http
                .post(
                    `${environment.apiEndPoint}api/v1/integrations/jira`,
                    {
                        data: {
                            type: 'jira',
                            attributes
                        }
                    },
                    {
                        headers: {
                            'url-id': instanceId + ''
                        }
                    }
                )
                .pipe(
                    map(() => {
                        return { config: { ...newConfig, id: instanceId } };
                    }),
                    catchError(err => {
                        console.error(err);
                        throw err;
                    })
                );
        } else {
            return this.http
                .put(
                    `${environment.apiEndPoint}api/v1/integrations/jira/${instanceId}`,
                    {
                        data: {
                            type: 'jira',
                            attributes
                        }
                    },
                    {
                        headers: {
                            'url-id': instanceId + ''
                        }
                    }
                )
                .pipe(
                    map(() => {
                        return { config: newConfig };
                    }),
                    catchError(err => {
                        console.error(err);
                        throw err;
                    })
                );
        }
    }

    /**
     * fetch possible applications
     * @param instanceId
     */
    fetchApplications(
        instanceId
    ): Observable<{ applications: { label: string; value: string }[] }> {
        return this.http
            .get(
                `${environment.apiEndPoint}api/v2/open-issue-filter?` +
                    `filter[instance_id]=${instanceId}&` +
                    'filter[selected_field]=application&' +
                    'filter[issue_status]=OPEN'
            )
            .pipe(
                map((res: any) => {
                    return {
                        applications: res.data.map(el => ({
                            label: el.attributes.label,
                            value: el.attributes.value
                        }))
                    };
                })
            );
    }

    /**
     * fetch instance jira integration configuration
     * @param instanceId
     */
    fetchJiraIntegrationInfo(instanceId): Observable<{ config: JiraConfig }> {
        return this.http
            .get(
                `${environment.apiEndPoint}api/v1/integrations/jira/${instanceId}`
            )
            .pipe(
                map((res: { data: { attributes: JiraConfig } }) => {
                    return { config: res.data.attributes };
                }),
                catchError(err => {
                    if (err.status === 404)
                        return of({
                            config: {
                                id: null,
                                email: null,
                                token: null,
                                subdomain: null,
                                project: null,
                                severity: null,
                                impactArea: null,
                                application: null,
                                selfClose: null,
                                enabled: null,
                                initialState: null,
                                finalState: null,
                                initialStatus: null,
                                finalStatus: null,
                                issueType: null,
                                assignee: null
                            }
                        });
                    console.error(err);
                    throw err;
                })
            );
    }

    /***
     * Fetch Jira Projects
     * @param email email to create a basic authentication header for the request
     * @param token token to create a basic authentication header for the request
     * @param subdomain subdomain to create a basic authentication header for the request
     */
    fetchProjects(
        email: string,
        token: string,
        subdomain: string
    ): Observable<any> {
        return this.http.post(
            `${environment.apiEndPoint}proxy/v1/integrations/jira`,
            {
                method: 'GET',
                url: `https://${subdomain}.atlassian.net/rest/api/3/project?expand=issueTypes`,
                headers: {
                    Authorization: 'Basic ' + btoa(email + ':' + token)
                }
            }
        );
    }

    /**
     * Fetch a project issue from jira API
     * @param email email to create a basic authentication header for the request
     * @param token token to create a basic authentication header for the request
     * @param subdomain subdomain to create a basic authentication header for the request
     * @param projectId project id to retrieve the information from
     * @param issueTypeId issue type id to retrieve the issue of this type
     */
    fetchProjectIssue(
        email: string,
        token: string,
        subdomain: string,
        projectId: string,
        issueTypeId: string
    ) {
        return this.http
            .post(`${environment.apiEndPoint}proxy/v1/integrations/jira`, {
                method: 'GET',
                url: `https://${subdomain}.atlassian.net/rest/api/3/search?maxResults=1&jql=project=${projectId} AND issuetype=${issueTypeId}`,
                headers: {
                    Authorization: 'Basic ' + btoa(email + ':' + token)
                }
            })
            .pipe(
                catchError(error => {
                    console.error(error);
                    return of({
                        issues: []
                    });
                })
            );
    }

    /**
     * Fetch issue transitions given an specific issue (by issue id)
     * @param email email to create a basic authentication header for the request
     * @param token token to create a basic authentication header for the request
     * @param subdomain subdomain to create a basic authentication header for the request
     * @param issueId issue id to retrieve the information from
     */
    fetchIssueTransitions(
        email: string,
        token: string,
        subdomain: string,
        issueId: string
    ) {
        return this.http
            .post(`${environment.apiEndPoint}proxy/v1/integrations/jira`, {
                method: 'GET',
                url: `https://${subdomain}.atlassian.net/rest/api/3/issue/${issueId}/transitions`,
                headers: {
                    Authorization: 'Basic ' + btoa(email + ':' + token)
                }
            })
            .pipe(
                map((res: any) => {
                    if (res.transitions) {
                        res.transitions = res.transitions.filter(
                            tx => tx.isGlobal
                        );
                    }
                    return res;
                })
            );
    }

    /**
     * Fetch project workflow given a project id
     * @param email email to create a basic authentication header for the request
     * @param token token to create a basic authentication header for the request
     * @param subdomain subdomain to create a basic authentication header for the request
     * @param projectId project id to retrieve the information from
     */
    fetchProjectWorkflowSchema(email, token, subdomain, projectId) {
        return this.http.post(
            `${environment.apiEndPoint}proxy/v1/integrations/jira`,
            {
                method: 'GET',
                url: `https://${subdomain}.atlassian.net/rest/api/3/workflowscheme/project?projectId=${projectId}`,
                headers: {
                    Authorization: 'Basic ' + btoa(email + ':' + token)
                }
            }
        );
    }

    /**
     * Fetch workflow transitions given a workflow name
     * @param email email to create a basic authentication header for the request
     * @param token token to create a basic authentication header for the request
     * @param subdomain subdomain to create a basic authentication header for the request
     * @param workflowName workflow name to retrieve the information from
     */
    fetchWorkflowTransitions(email, token, subdomain, workflowName) {
        return this.http.post(
            `${environment.apiEndPoint}proxy/v1/integrations/jira`,
            {
                method: 'GET',
                url: `https://${subdomain}.atlassian.net/rest/api/3/workflow/search?workflowName=${workflowName}&expand=transitions`,
                headers: {
                    Authorization: 'Basic ' + btoa(email + ':' + token)
                }
            }
        );
    }

    /**
     * Fetch jira integrations activity logs for the specified instance
     * @param instanceId instance to get the logs from
     * @param tableFilter pagination parameters. pageIndex and pageSize
     */
    fetchActivityLog(
        instanceId: string | number,
        tableFilter: { pageIndex: number; pageSize: number } = {
            pageIndex: 0,
            pageSize: 10
        }
    ) {
        const { pageSize, pageIndex } = tableFilter;
        return this.http.get(
            `${environment.apiEndPoint}api/v1/integrations/jira/activityLog?` +
                `page[size]=${pageSize}&page[number]=${pageIndex + 1}`,
            {
                headers: {
                    'url-id': instanceId + ''
                }
            }
        );
    }

    /**
     * retrieve the users from a project
     * @param email email to create a basic authentication header for the request
     * @param token token to create a basic authentication header for the request
     * @param subdomain subdomain to create a basic authentication header for the request
     * @param projectId project id to retrieve the information from
     * @param query to fetch some specific user
     */
    fetchUsers(
        email: string,
        token: string,
        subdomain: string,
        projectId: string | number,
        query: string = null
    ) {
        let url = `https://${subdomain}.atlassian.net/rest/api/3/user/assignable/search?project=${projectId}`;
        if (query) {
            url += '&query=' + query;
        }
        return this.http.post(
            `${environment.apiEndPoint}proxy/v1/integrations/jira`,
            {
                method: 'GET',
                url,
                headers: {
                    Authorization: 'Basic ' + btoa(email + ':' + token)
                }
            }
        );
    }
}
