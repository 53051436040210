import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../../../environments/environment';

@Component({
    selector: 'qcbi-activity',
    templateUrl: './activity.component.html'
})
export class ActivityComponent implements OnInit {
    @Input() issueId;
    @Input() instanceId;

    activity$: Observable<any>;

    columnNames = {
        Date: 'created-on',
        Type: 'activity-type',
        User: 'created-by'
    };

    constructor(private http: HttpClient) {}

    ngOnInit() {
        this.activity$ = this.http
            .get(
                `${environment.apiEndPoint}api/v2/issue-activity?` +
                    `filter[issue_id]=${this.issueId}` +
                    `&filter[instance_id]=${this.instanceId}` +
                    `&sort=-created_on`
            )
            .pipe(
                first(),
                map((res: any) => {
                    return res.data.map(el => {
                        return {
                            ...el.attributes
                        };
                    });
                })
            );
    }
}
