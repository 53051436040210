import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ColorService } from '../../../core/services/color.service';

/**
 * readonly tags is a component responsible for the tags display
 */
@Component({
    selector: 'qcbi-readonly-tags',
    templateUrl: 'readonly-tags.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadonlyTagsComponent {
    @Input() tags = ['tag1adfasd', 'tag2'];

    constructor(public colorService: ColorService) {}
}
