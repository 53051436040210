import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated } from './selectors';
import { map } from 'rxjs/operators';
import { qcAuthActions } from './actions';

@Injectable()
export class QCAuthGuard implements CanActivate {
    constructor(private store: Store<any>) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.store.select(selectIsAuthenticated).pipe(
            map((guardStatus: boolean) => {
                if (!guardStatus) {
                    this.store.dispatch(qcAuthActions.loginRedirection());
                }
                return guardStatus;
            })
        );
    }
}
