import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { stateSelectors as dmNewSelectors } from '../../../../../../core/state/widgets/large/debt-manager-new/dm-new.selectors';
import { first } from 'rxjs/operators';
import { bulkActions } from '../../../../../../core/state/widgets/large/debt-manager-new/bulk.actions';
import { TagsActions } from '../../../../../../core/state/tags/tags.actions';
import { AddTagActionService } from '../actions/add-tag-action.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActionsService } from '../../../../../tables/services/actions.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/core.state';

@Component({
    selector: 'qcbi-tag-menu-component',
    templateUrl: 'tag-menu.component.html'
})
export class TagMenuComponent implements OnInit, AfterViewInit {
    @ViewChild('tagsMenuAnchor', { static: false }) tagsMenuAnchor;
    @ViewChild(MatMenuTrigger, { static: false }) tagsMenu: MatMenuTrigger;

    constructor(
        public addTagActionService: AddTagActionService,
        private actionsService: ActionsService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.actionsService.registerAction(this.addTagActionService);
    }

    ngAfterViewInit() {
        this.addTagActionService.registerVisualElements(
            this.tagsMenuAnchor,
            this.tagsMenu
        );
    }

    handleCloseMenu() {
        if (
            this.addTagActionService.unassignTagsIds.length ||
            this.addTagActionService.assignTagsIds.length
        ) {
            this.store
                .select(dmNewSelectors.filter)
                .pipe(first())
                .subscribe(filters => {
                    this.store.dispatch(
                        bulkActions.bulkTags({
                            assignTagsIds: [
                                ...this.addTagActionService.assignTagsIds
                            ],
                            unassignTagsIds: [
                                ...this.addTagActionService.unassignTagsIds
                            ],
                            issuesIds: this.addTagActionService.items,
                            allSelected: this.addTagActionService
                                .tagsAllSelected,
                            filters
                        })
                    );
                    this.store.dispatch(TagsActions.fetchTags());
                    this.addTagActionService.unassignTagsIds = [];
                    this.addTagActionService.assignTagsIds = [];
                });
        }
    }

    handleSelectionChange(event: { value: any; selected: boolean }) {
        if (event.selected) {
            // if was already assigned, keep it that way
            if (
                this.addTagActionService.unassignTagsIds.find(
                    tagId => tagId === event.value
                )
            ) {
                this.addTagActionService.unassignTagsIds = this.addTagActionService.unassignTagsIds.filter(
                    tagId => tagId !== event.value
                );
                if (
                    this.addTagActionService.tagsAndItems.hasOwnProperty(
                        event.value
                    )
                ) {
                    this.addTagActionService.assignTagsIds.push(event.value);
                }
            } else {
                // otherwise, assign it
                this.addTagActionService.assignTagsIds.push(event.value);
            }
        } else {
            // if was already unassigned, keep it that way
            if (
                this.addTagActionService.assignTagsIds.find(
                    tagId => tagId === event.value
                )
            ) {
                this.addTagActionService.assignTagsIds = this.addTagActionService.assignTagsIds.filter(
                    tagId => tagId !== event.value
                );
            } else {
                // otherwise, unassign it
                this.addTagActionService.unassignTagsIds.push(event.value);
            }
        }
    }
}
