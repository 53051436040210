import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { IssueModel } from '../../../../../../../models/issue.model';
import { AppState } from '../../../../../../../core/core.state';
import { Store } from '@ngrx/store';
import { selectIssueOwnerAvailable } from '../../../../../../../core/new-features/new-features.selectors';
import { Observable } from 'rxjs';

/**
 * issue main container is responsible from displaying the main issue info
 */
@Component({
    selector: 'qcbi-issue-main-info-container',
    templateUrl: 'issue-main-info-container.component.html',
    styleUrls: ['issue-main-info-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IssueMainInfoContainerComponent implements OnInit {
    @Input() item: IssueModel;
    @Input() displayInOneColumn = false;

    issueOwnerAvailable$: Observable<boolean>;

    constructor(private store: Store<AppState>) {}

    ngOnInit() {
        this.issueOwnerAvailable$ = this.store.select(
            selectIssueOwnerAvailable
        );
    }
}
