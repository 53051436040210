import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    template: `
        <h1 style="text-align: center; font-weight: bold;">
            Application hotkeys
        </h1>
        <table>
            <tbody>
                <tr *ngFor="let keys of hotkeys">
                    <td>{{ keys[1] }}</td>
                    <td class="text-right">
                        <kbd>{{ keys[0] }}</kbd>
                    </td>
                </tr>
            </tbody>
        </table>
    `
})
export class HotkeysDialogComponent {
    hotkeys = Array.from(this.data);

    constructor(@Inject(MAT_DIALOG_DATA) public data) {}
}
