import {
    AfterViewInit,
    ChangeDetectorRef,
    ContentChild,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../core/core.state';
import { NotificationService } from '../core/notifications/notification.service';
import { ResizeEvent } from 'angular-resizable-element';
import {
    getRestFilterName,
    mapFilterPath
} from '../utils/widget-state-creator';
import { DownloadFormattedFileActions } from '../core/state/download-formatted-file/download-formatted-file.actions';
import { downloadInProgressSelector } from '../core/state/download-formatted-file/download-formatted-file.selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { selectCurrentUser } from '../core/auth-lib';
import { ActivityTrackingActions } from '../core/state/activity-tracking/activity-tracking.actions';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SortDirection } from '@angular/material/sort/sort-direction';
import { ActionDispatchedEvent } from '../components/tables/services/action.models';
import { ColorService } from '../core/services/color.service';

export interface TablePageEvent {
    sort: string;
    direction: SortDirection;
    pageIndex: number;
    pageSize: number;
}

export type TableAction = {
    id: string;
    label: string;
    tooltip?: (allSelected: boolean, elementsLength: number) => string;
    disabled?: (allSelected: boolean, elementsLength: number) => boolean;
};

export type FilterByEvent = {
    column: { filter: string; key: string };
    value: string;
};

@Directive({
    selector: '[detailsRef]'
})
export class DetailsRefDirective {
    constructor(public elemRef: ElementRef) {}
}

@Directive()
export abstract class Table implements AfterViewInit, OnInit, OnDestroy {
    @Input() initialConfigValues: any;
    @Input() extraData: any;
    @Input() actions: TableAction[] = [];
    @Input() noDataMsg = 'No data available';
    @Input() defaultSortingColumn: string;
    @Input() defaultSortingDirection: 'asc' | 'desc';
    @Input() columnNames: {};
    @Input() disabledSortingColumns: string[] = [];
    @Input() downloadType = 'none';
    @Input() downloadFilters;
    @Input() isExpandable = false;
    @Input() isSelectable = false;
    @Input() selected: { key: string; value: any } = null;
    @Input() configuration: string;
    @Input() hideColumnsByDefault: string[];
    @Input() tableContextualFilters = {};
    @Input()
    set data(value: any) {
        if (value === null || value === undefined) return;
        this.clearSelection();
        if (
            this.columnNames !== null &&
            this.columnNames !== undefined &&
            this.displayedColumns.length === 0
        ) {
            if (
                this.actions.length > 0 &&
                !this.columnNames.hasOwnProperty('Element_Id')
            ) {
                this.columnNames = {
                    Element_Id: 'id',
                    ...this.columnNames
                };
            }

            this.displayedColumns = this.parseToDisplayedColumns(
                this.columnNames
            );
            this.originalDisplayedColumns = [...this.displayedColumns];
        }
        if (value.length > 0) value = this.parseToDataSource(value);
        this.dataSource = new MatTableDataSource<any>(value);
        this.hasTableData =
            this.dataSource &&
            this.dataSource.filteredData &&
            this.dataSource.filteredData.length > 0;
    }

    @Output() rowClick = new EventEmitter<{ data: any }>();
    @Output() actionDispatched = new EventEmitter<ActionDispatchedEvent>();
    @Output() filterBy = new EventEmitter<FilterByEvent>();
    @Output() configChanged = new EventEmitter<object>();

    displayedColumns: string[] = [];
    originalDisplayedColumns: string[] = [];
    dataSource = new MatTableDataSource<any>([]);
    selection = new SelectionModel<any>(true, []);
    expandedElement: any;
    hasHyperlink = false;
    hasTableData = true;
    hasDocLink = false;
    hasVersionLink = false;
    downloadLoadingXls = false;
    downloadLoadingSarif = false;
    exportXlsId;

    allSelected = false;

    unsubscribe = new Subject();

    selectedFilter;
    selectedFilterValue;

    timeFormat;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('link', { static: true }) link: TemplateRef<any>;
    @ViewChild('hyperlink', { static: true }) hyperlink: TemplateRef<any>;
    @ViewChild('general', { static: true }) general: TemplateRef<any>;
    @ViewChild('empty', { static: true }) empty: TemplateRef<any>;
    @ViewChild('checkbox', { static: true }) checkbox: TemplateRef<any>;
    @ViewChild('percentage', { static: true }) percentage: TemplateRef<any>;
    @ViewChild('user', { static: true }) user: TemplateRef<any>;
    @ViewChild('qualityGate', { static: true }) qualityGate: TemplateRef<any>;
    @ViewChild('releaseManagementStatus', { static: true })
    releaseManagementStatus: TemplateRef<any>;
    @ViewChild('peerReviewStatus', { static: true })
    peerReviewStatus: TemplateRef<any>;
    @ViewChild('issuesRelated', { static: true }) issuesRelated: TemplateRef<
        any
    >;
    @ViewChild('action', { static: true }) action: TemplateRef<any>;

    @ViewChild('linesCopied', { static: true }) linesCopied: TemplateRef<any>;
    @ViewChild('writeOff', { static: true }) writeOff: TemplateRef<any>;
    @ViewChild('labels', { static: true }) labels: TemplateRef<any>;
    @ViewChild('teams', { static: true }) teams: TemplateRef<any>;
    @ViewChild('editableLabels', { static: true }) editableLabels: TemplateRef<
        any
    >;
    @ViewChild('dateWithoutTime', { static: true })
    dateWithoutTime: TemplateRef<any>;

    @ViewChild(MatMenuTrigger, { static: false }) contextMenu: MatMenuTrigger;
    @ViewChild('menuAnchor', { static: false }) menuAnchor;

    @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;

    protected constructor(
        public store: Store<AppState>,
        public notificationService: NotificationService,
        public ref: ChangeDetectorRef,
        public colorService: ColorService
    ) {}

    ngOnInit() {
        this.store
            .select(downloadInProgressSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
                this.checkWhichXlsIsDownLoading(
                    res.hasOwnProperty(this.downloadType + '_xls')
                );
                this.downloadLoadingSarif = res.hasOwnProperty(
                    this.downloadType + '_sarif'
                );
                this.ref.detectChanges();
            });
        this.store
            .select(selectCurrentUser)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(user => {
                this.timeFormat = user.timeFormat;
            });
    }

    ngAfterViewInit() {
        this.sort.sortChange
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.sortHandler());
        this.paginator.page
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.pageHandler());
        this.onInit();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    checkWhichXlsIsDownLoading(downloadingXls) {
        if (downloadingXls && this.configuration.includes('code-monitor')) {
            // if it is code monitor we take into account which of the xls is
            if (this.configuration.substr(0, 19) === this.exportXlsId) {
                this.downloadLoadingXls = true;
            }
            this.exportXlsId = undefined;
        } else {
            this.downloadLoadingXls = downloadingXls;
        }
    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.data);
    }

    onContextMenu(event: MouseEvent, displayedColumn, element) {
        event.preventDefault();
        if (!this.tableContextualFilters.hasOwnProperty(displayedColumn))
            return;
        this.menuAnchor.nativeElement.style.top = event.clientY - 8 + 'px';
        this.menuAnchor.nativeElement.style.left = event.clientX - 8 + 'px';
        this.selectedFilter = displayedColumn;
        this.selectedFilterValue = element[displayedColumn];
        this.contextMenu.openMenu();
    }

    closeMenu() {
        this.contextMenu.closeMenu();
    }

    applyFilter() {
        this.filterBy.emit({
            column: this.tableContextualFilters[this.selectedFilter],
            value: this.selectedFilterValue
        });
    }

    actionClickedHandler(
        actionId: string,
        selectedItems: any[],
        event: MouseEvent
    ) {
        this.actionDispatched.emit({
            actionId,
            selectedItems,
            allSelected: this.allSelected,
            event
        });
    }

    manageInlineTag(selectedItems, event) {
        event.stopPropagation();
        const addTagId = this.actions.find(a => a.id.includes('add-tag'));
        if (addTagId)
            this.actionClickedHandler(addTagId.id, selectedItems, event);
    }

    columnsConfigurationChangedHandler(columns: string[]): void {
        this.displayedColumns = columns.filter(column =>
            this.originalDisplayedColumns.includes(column)
        );
    }

    configChangedHandler(config) {
        this.configChanged.emit(config);
    }

    async downloadIssues(format) {
        if (this.downloadFilters['exportXlsId']) {
            this.exportXlsId = this.downloadFilters['exportXlsId'];
        }
        const filterKeys = Object.keys(this.downloadFilters).filter(
            item => item !== 'exportXlsId'
        );
        let filters = filterKeys.reduce((result, key) => {
            const name =
                this.downloadType === 'governance' ||
                this.downloadType === 'live-check-scans' ||
                this.downloadType === 'live-check-issues-by-scan' ||
                this.downloadType === 'scanned-update-sets' ||
                this.downloadType === 'application-scans' ||
                this.downloadType === 'feature-branch-scans' ||
                this.downloadType === 'developer-stats'
                    ? key
                    : getRestFilterName(key);
            if (
                name !== 'sort' &&
                name !== 'top' &&
                this.downloadFilters[key] !== '' &&
                this.downloadFilters[key] !== undefined &&
                (!Array.isArray(this.downloadFilters[key]) ||
                    this.downloadFilters[key].length > 0)
            )
                result.push({
                    key:
                        this.downloadType === 'governance' ||
                        this.downloadType === 'live-check-scans' ||
                        this.downloadType === 'live-check-issues-by-scan' ||
                        this.downloadType === 'scanned-update-sets' ||
                        this.downloadType === 'application-scans' ||
                        this.downloadType === 'feature-branch-scans' ||
                        this.downloadType === 'developer-stats'
                            ? mapFilterPath(name)
                            : `filter[${mapFilterPath(name)}]`,
                    value: Array.isArray(this.downloadFilters[key])
                        ? this.downloadFilters[key].join(',')
                        : this.downloadFilters[key]
                });
            return result;
        }, []);
        if (this.downloadType === 'developer-stats') {
            const reformattedFilters = [
                { key: 'page_number', value: 1 },
                { key: 'page_size', value: 9999999 }
            ];
            filters.forEach(item => {
                if (typeof item.value === 'boolean') {
                    reformattedFilters.push({
                        key: item.key,
                        value: item.value
                    });
                } else {
                    const keys = item.key.split(',');
                    const values = item.value.split(',');
                    keys.forEach(key => {
                        values.forEach(value => {
                            reformattedFilters.push({ key, value });
                        });
                    });
                }
            });
            filters = reformattedFilters;
        }
        if (this.downloadType === 'ootb-affected-by-upgrade') {
            filters.push(
                { key: 'page[number]', value: 1 },
                { key: 'page[size]', value: 9999999 }
            );
        }

        this.store.dispatch(
            DownloadFormattedFileActions.download({
                id: this.downloadType,
                queryParams: filters,
                format
            })
        );
    }

    findCol(col: object) {
        return this.dataSource.filteredData.find(element => element === col);
    }

    formatArray(col: any[]): string {
        return col.join(',\n');
    }

    getCellElementInfo(element: object, displayedColumn: string) {
        if (this.isArray(element, displayedColumn))
            return this.formatArray(element[displayedColumn]);
        return element[displayedColumn];
    }

    getDetailsTemplate(): TemplateRef<any> {
        return this.template;
    }

    getTableParams(): TablePageEvent {
        const { active, direction } = this.sort;
        const { pageIndex, pageSize } = this.paginator;
        let sort = '';
        if (active) {
            const sortKey = Object.keys(this.columnNames).find(
                key => key.replace(/_/g, ' ') === active
            );
            sort = this.columnNames[sortKey].replace(/-/g, '_');
        }
        return {
            sort,
            direction,
            pageIndex,
            pageSize
        };
    }

    getTemplate(element: object, displayedColumn: string): TemplateRef<any> {
        if (displayedColumn === 'Select') return this.checkbox;
        if (this.isExpirationDate(element, displayedColumn))
            return this.general;
        if (displayedColumn === 'Action') return this.action;
        if (this.isDateWithoutTime(element, displayedColumn))
            return this.dateWithoutTime;
        if (!this.hasData(element, displayedColumn)) return this.empty;
        if (this.isLink(element, displayedColumn)) return this.link;
        if (this.isHyperLink(element, displayedColumn)) return this.hyperlink;
        if (this.isDocLink(displayedColumn)) return this.hyperlink;
        if (this.isVersionLink(displayedColumn)) return this.hyperlink;
        if (this.isUser(element, displayedColumn)) return this.user;
        if (this.isQualityGate(element, displayedColumn))
            return this.qualityGate;
        if (this.isReleaseManagementStatus(element, displayedColumn))
            return this.releaseManagementStatus;
        if (this.isIssuesRelated(displayedColumn)) return this.issuesRelated;
        if (this.isLinesCopied(displayedColumn)) return this.linesCopied;
        if (displayedColumn === 'Quality Of Cloud') return this.percentage; // TODO: change it to make it generic. decouple feature
        if (this.isPercentage(element, displayedColumn)) return this.percentage; // TODO: change it to make it generic. decouple feature
        if (this.isWriteOffScan(element, displayedColumn)) return this.writeOff;
        if (this.isLabel(element, displayedColumn)) return this.labels;
        if (this.isTeam(element, displayedColumn)) return this.teams;
        if (this.isEditableLabel(element, displayedColumn))
            return this.editableLabels;
        if (this.isPeerReviewStatus(element, displayedColumn))
            return this.peerReviewStatus;
        return this.general;
    }

    goToExternalLink(link, col) {
        window.open(link);
        this.store.dispatch(
            ActivityTrackingActions.notifyDocumentationAccess({
                issueId: col['IssueId']
            })
        );
    }

    handleRowClick(data: any) {
        if (this.rowClick.observers.length > 0) this.rowClick.emit({ data });
    }

    hasData(col: object, colName: string): boolean {
        return colName.toUpperCase() === 'APPLICATION' ||
            colName.toUpperCase() === 'CE NAME' ||
            colName.toUpperCase() === 'DEVELOPER' ||
            colName.toUpperCase() === 'PACKAGE_NAME'
            ? col[colName] !== undefined && col[colName] !== null
            : col[colName] !== 'N/A' &&
                  col[colName] !== undefined &&
                  col[colName] !== null;
    }

    hasValidLink(col: object, colName: string) {
        const val = this.findCol(col);
        return !(
            (colName.toUpperCase() === 'ISSUE TYPE' &&
                val &&
                val.IssueLink &&
                val.IssueLink.toUpperCase() === 'N/A') ||
            (colName.toUpperCase() === 'VERSION' &&
                val &&
                val.versionlink &&
                val.versionlink.toUpperCase() === 'N/A') ||
            (colName.toUpperCase() === 'CE NAME' &&
                val &&
                val.hyperlink &&
                val.hyperlink.toUpperCase() === 'N/A')
        );
    }

    isANumber(val: any) {
        if (typeof val === 'string' && val.trim() === '') return false;
        if (typeof val === 'string' && val.startsWith('00')) return false;
        return val !== null && val !== undefined && !isNaN(val);
    }

    isArray(col: object, colName: string): boolean {
        return Array.isArray(col[colName]);
    }

    isDisabled(displayedColumn: string): boolean {
        return (
            displayedColumn === 'Select' ||
            this.disabledSortingColumns.indexOf(displayedColumn) !== -1
        );
    }

    isDocLink(colName: string) {
        return colName.toUpperCase() === 'ISSUE TYPE' && this.hasDocLink;
    }

    isHyperLink(col: object, colName: string): boolean {
        return (
            (colName.toUpperCase() === 'CE NAME' ||
                colName.toUpperCase() === 'CONFIGURATION ELEMENT NAME') &&
            this.hasHyperlink
        );
    }

    isIssuesRelated(colName: string) {
        if (colName.toUpperCase() === 'ISSUES RELATED') {
            return true;
        }
    }

    isLinesCopied(colName: string) {
        if (colName.toUpperCase() === 'LINES COPIED') {
            return true;
        }
    }

    isLink(col: object, colName: string): boolean {
        return (
            colName.toUpperCase() === 'LINK' || colName.toUpperCase() === 'DOC'
        );
    }

    isPercentage(col: object, colName: string): boolean {
        return (
            colName.toUpperCase() === 'QUALITY OF CLOUD' ||
            colName.toUpperCase() === 'OOTB' ||
            colName.toUpperCase() === 'TECHNICAL DEBT VARIATION FIRST SCAN' ||
            colName.toUpperCase() ===
                'TECHNICAL DEBT VARIATION PREVIOUS SCAN' ||
            colName.toUpperCase() === 'OOTB VARIATION FIRST SCAN' ||
            colName.toUpperCase() === 'OOTB VARIATION PREVIOUS SCAN' ||
            colName.toUpperCase() === 'OOTB MODIFICATION '
        );
    }

    isQualityGate(col: object, colName: string) {
        if (colName.toUpperCase() === 'QUALITY GATE') {
            return true;
        }
    }

    isReleaseManagementStatus(col: object, colName: string) {
        return (
            colName.toUpperCase() === 'STATUS' &&
            col['QgSkip'] === true &&
            col[colName] === 'Complete'
        );
    }

    isPeerReviewStatus(col: object, colName: string) {
        if (colName.toUpperCase() === 'PEER REVIEW STATUS') {
            return true;
        }
    }

    isUser(col: object, colName: string) {
        if (colName.toUpperCase() === 'USER') {
            return true;
        }
    }

    isVersionLink(colName: string) {
        return colName.toUpperCase() === 'VERSION' && this.hasVersionLink;
    }

    isWriteOffScan(col: object, colName: string) {
        return colName.toUpperCase() === 'WRITE OFF';
    }

    isTeam(col: object, colName: string) {
        return colName.toUpperCase() === 'TEAMS';
    }

    isLabel(col: object, colName: string) {
        return (
            colName.toUpperCase() === 'TAGS' &&
            !this.actions.some(
                a =>
                    a.id === 'add-tag' ||
                    a.id === 'add-tag-pending' ||
                    a.id === 'add-tag-closed'
            )
        );
    }

    isEditableLabel(col: object, colName: string) {
        return (
            colName.toUpperCase() === 'TAGS' &&
            this.actions.some(
                a =>
                    a.id === 'add-tag' ||
                    a.id === 'add-tag-pending' ||
                    a.id === 'add-tag-closed'
            )
        );
    }

    isExpirationDate(element, colName) {
        return (
            colName.toUpperCase() === 'EXPIRATION DATE' ||
            colName.toUpperCase() === 'WRITE OFF EXPIRATION DATE'
        );
    }

    isDateWithoutTime(element, colName) {
        return colName.toUpperCase() === 'APPROVE/REJECT DATE';
    }

    onInit(): void {}

    onResizeEnd(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + 'px';
            const columnElts = document.getElementsByClassName(
                'mat-column-' + columnName.replaceAll(' ', '-')
            );
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLElement;
                currentEl.style.width = cssValue;
                currentEl.style.minWidth = cssValue;
                currentEl.style.maxWidth = cssValue;
            }
        }
    }

    getActionIsString(value: string | { label: string; value: string }[]) {
        return typeof value === 'string';
    }

    openHyperlink(col: object, colName: string) {
        const val = this.findCol(col);
        if (
            colName.toUpperCase() === 'ISSUE TYPE' &&
            val &&
            val.IssueLink &&
            val.IssueLink.toUpperCase() !== 'N/A'
        ) {
            window.open(val.IssueLink);
        } else if (
            colName.toUpperCase() === 'VERSION' &&
            val &&
            val.versionlink &&
            val.versionlink.toUpperCase() !== 'N/A'
        ) {
            window.open(val.linkToVersion);
        } else if (
            val &&
            val.hyperlink &&
            val.hyperlink.toUpperCase() !== 'N/A'
        ) {
            window.open(val.hyperlink);
            if (col['CeId']) {
                this.store.dispatch(
                    ActivityTrackingActions.notifyLinkAccess({
                        elementId: col['CeId'],
                        elementType: 'CE'
                    })
                );
            } else {
                this.store.dispatch(
                    ActivityTrackingActions.notifyLinkAccess({
                        elementId: col['IssueId'],
                        elementType: 'ISSUE'
                    })
                );
            }
        }
    }

    pageHandler(): void {}

    parseToDataSource(items: any) {
        return items.reduce((result, item) => {
            const column = {};
            Object.entries(this.columnNames).forEach(
                ([columnKey, elementKey]: any) => {
                    if (columnKey === 'Written_Off' || columnKey === 'Active') {
                        column[columnKey.replace(/_/g, ' ')] = item[elementKey]
                            ? 'Yes'
                            : 'No';
                    } else if (
                        columnKey.toUpperCase() === 'APPLICATION' ||
                        columnKey.toUpperCase() === 'PACKAGE_NAME'
                    ) {
                        column[columnKey.replace(/_/g, ' ')] =
                            item[elementKey] &&
                            item[elementKey].toUpperCase() === 'N/A'
                                ? 'Default'
                                : item[elementKey];
                    } else if (columnKey.toUpperCase() === 'SEVERITY') {
                        column[columnKey.replace(/_/g, ' ')] = item[
                            elementKey
                        ].toUpperCase();
                    } else if (columnKey.toUpperCase() === 'WRITE_OFF') {
                        column[columnKey.replace(/_/g, ' ')] =
                            item[elementKey] !== 'APPROVED' ? 'No' : 'Yes';
                    } else {
                        column[columnKey.replace(/_/g, ' ')] = item[elementKey];
                    }
                }
            );
            result.push(column);
            return result;
        }, []);
    }

    parseToDisplayedColumns(items: any) {
        if (items) {
            let cols = this.removeColumns(Object.keys(items));
            cols = cols.map(element => {
                return element.replace(/_/g, ' ');
            });
            return this.actions.length > 0 ? ['Select', ...cols] : cols;
        }
        return [];
    }

    removeColumns(cols): Array<string> {
        const elementsToRemove = [];

        cols.forEach(element => {
            if (
                element.toLowerCase() === 'hyperlink' ||
                element.toLowerCase() === 'updatesetscanid'
            ) {
                this.hasHyperlink = true;
                elementsToRemove.push(element);
            } else if (element.toLowerCase() === 'issuelink') {
                this.hasDocLink = true;
                elementsToRemove.push(element);
            } else if (element.toLowerCase() === 'versionlink') {
                this.hasVersionLink = true;
                elementsToRemove.push(element);
            } else if (element.toLowerCase() === 'sysid') {
                elementsToRemove.push(element);
            } else if (element.toLowerCase() === 'instanceid') {
                elementsToRemove.push(element);
            } else if (
                element.toLowerCase() === 'write-off-snapshot-request' ||
                element.toLowerCase() === 'write-off-snapshot-date' ||
                element.toLowerCase() === 'write-off-snapshot-developer' ||
                element.toLowerCase() === 'write-off-snapshot-status-detail' ||
                element.toLowerCase() === 'write-off-snapshot-reason' ||
                element.toLowerCase() === 'duplicate-block-id' ||
                element.toLowerCase() === 'ce-link' ||
                element.toLowerCase() === 'num-issues-on-duplicated-block' ||
                element.toLowerCase() === 'code-duplication-id' ||
                element.toLowerCase() === 'peer-review-approver' ||
                element.toLowerCase() === 'peer-review-requester' ||
                element.toLowerCase() === 'peer-review-request-description' ||
                element.toLowerCase() === 'peer-review-write-off-id'
            ) {
                elementsToRemove.push(element);
            }
        });

        if (elementsToRemove.length > 0) {
            elementsToRemove.forEach(element => {
                const index = cols.findIndex(column => {
                    return column === element ? element : null;
                });
                cols.splice(index, 1);
            });
        }

        return cols;
    }

    selectAllElements() {
        this.allSelected = true;
        this.selection.select(...this.dataSource.data);
    }

    clearSelection() {
        this.allSelected = false;
        this.selection.clear();
    }

    showTooltip(col: object, colName: string) {
        if (colName.toLowerCase() === 'quality gate status') {
            return col['Quality Gate Condition'];
        }
        return col[colName];
    }

    sortHandler(): void {}

    toggleExpandedRow(row): void {
        this.expandedElement = this.expandedElement === row ? null : row;
    }

    getColor(tagName: string) {
        return this.colorService.getColor(tagName);
    }

    pickTextColorBasedOnBgColorSimple(label) {
        return this.colorService.pickTextColorBasedOnBgColorSimple(label);
    }
}
