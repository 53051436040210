import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterToParamsParserService } from './filter-to-params-parser.service';
import { Observable } from 'rxjs';
import {
    PagerOptions,
    SortOptions
} from '../../../../components/tables/server.component';

export type OtherOptions = {
    instanceId: number | string;
    tableOptions: PagerOptions & SortOptions;
    timeFilter: { begin: string; end: string };
};

@Injectable({
    providedIn: 'root'
})
export abstract class LargeWidgetService<T> {
    protected constructor(
        protected http: HttpClient,
        protected parserService: FilterToParamsParserService
    ) {}

    abstract load(
        filter: any,
        otherOptions: Partial<OtherOptions>
    ): Observable<T | Partial<T>>;
}
