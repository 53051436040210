<div class="container position-relative">
    <div class="row justify-content-center position-relative">
        <img alt="" src="./assets/images/logoInverted.png" />
        <button tabindex="-1"
            mat-icon-button
            class="position-absolute close"
            (click)="close()"
        >
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="row m-4 justify-content-center position-relative">
        <div class="mt-4">
            <h4>{{ "help.title" | translate }}</h4>
        </div>
    </div>

    <div class="row m-4 justify-content-center position-relative">
        <div class="col-md-3 p-3">
            <div matRipple class="rounded">
                <a href="mailto:help@qualityclouds.com">
                    <img
                        class="main"
                        src="/assets/images/help/contact_mail.svg"
                        alt=""
                /></a>
                <a class="text-center m-2" href="mailto:help@qualityclouds.com">
                    <h6>{{ "help.contact" | translate }}</h6>
                </a>
            </div>
        </div>
        <div class="col-md-3 p-3">
            <div matRipple class="rounded">
                <a
                    href="https://qualityclouds.com/documentation/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        class="main"
                        src="/assets/images/help/menu_book.svg"
                        alt=""
                /></a>
                <a
                    class="text-center m-2"
                    href="https://qualityclouds.com/documentation/"
                    target="_blank"
                >
                    <h6>{{ "help.docu" | translate }}</h6>
                </a>
            </div>
        </div>
        <div class="col-md-3 p-3">
            <div matRipple class="rounded">
                <a
                    href="https://qualityclouds.com/documentation/whats-new/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        class="main"
                        src="/assets/images/help/contact_notes.svg"
                        alt=""
                /></a>
                <a
                    class="text-center m-2"
                    href="https://qualityclouds.com/documentation/whats-new/"
                    target="_blank"
                >
                    <h6>{{ "help.release" | translate }}</h6>
                </a>
            </div>
        </div>
    </div>
    <div class="row m-4 justify-content-center position-relative">
        <div class="col-12">
            <ul>
                <li>
                    <a class="p-0" mat-stroked-button (click)="showTermsDialog()" style="cursor: pointer;"><i
                            class="link-icon flaticon-edit"></i>{{ "help.termsAndConditions" | translate }}</a>
                </li>
                <li>
                    <a mat-stroked-button
                        href="https://www.qualityclouds.com/about"
                        target="_blank"
                        class="p-0"
                        rel="noopener noreferrer"
                        ><mat-icon class="link-icon">info_outline</mat-icon>{{ "help.about" | translate }}</a
                    >
                </li>
            </ul>
        </div>
    </div>
    <div class="version text-secondary-500 text-xsm">version : <a href="https://qualityclouds.com/documentation/whats-new/" target="_blank">{{version}}</a></div>
</div>
