import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromNewFeatures from './new-features.reducer';
import { NewFeaturesEffects } from './new-features.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            'newFeatures',
            fromNewFeatures.newFeaturesReducer
        ),
        EffectsModule.forFeature([NewFeaturesEffects])
    ]
})
export class NewFeaturesModule {}
