<div class="drawer-backdrop" (click)="closeDrawer()" *ngIf="drawerService.opened$ | async">
</div>
<div
    class="drawer"
    [attr.aria-hidden]="!(drawerService.opened$ | async)"
    [ngClass]='{ open: drawerService.opened$ | async }'
    [ngStyle]="{
      width:  'min(max(' + drawerService.minWidthInPixels + 'px, 66vw), 100vw)'
    }"
>
    <div class='drawer-header'>
        <button
            tabindex="-1"
            mat-icon-button
            class="close-icon"
            (click)="closeDrawer()"
            aria-label="close drawer"
        >
            <mat-icon>close</mat-icon>
        </button>
        <div class='drawer-header-title'>{{ drawerService.title }}</div>
    </div>
    <ng-template [cdkPortalOutlet]="drawerService.componentPortal" ></ng-template>
</div>
