import { IWidget } from '../core/state/models/widgets';

export const detailSuccessState = (state: any, { data, key }) => {
    return {
        ...state,
        data: {
            ...state.data,
            details: {
                ...state.data.details,
                [key]: data
            }
        },
        loading: false,
        isLoaded: true
    };
};

export const modalSuccessState = (state: any, { data, key }) => {
    return {
        ...state,
        data: {
            ...state.data,
            modal: {
                ...state.data.modal,
                [key]: data
            }
        },
        loading: false,
        isLoaded: true
    };
};

export const onlyModalSuccessState = (state: any, { data, tableFilter }) => {
    return {
        ...state,
        data: {
            ...state.data,
            modal: {
                ...state.data.modal,
                data,
                tableFilter,
                loading: false
            }
        }
    };
};

export const successStateWithoutTableFilter = (
    state: IWidget<any>,
    { data }
) => {
    let newTableFilter = state.tableFilter;
    const { tableFilter, ...newData } = data;
    if (tableFilter) {
        newTableFilter = tableFilter;
    }
    return {
        ...state,
        loading: false,
        isLoaded: true,
        data: newData,
        tableFilter: newTableFilter
    };
};
