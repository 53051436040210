import {
    Action,
    createAction,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
    Store
} from '@ngrx/store';
import { StateFeatures, Widgets } from '../../../models/widgets';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WidgetService } from '../../../../services/widget.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState } from '../../../../core.state';
import { WidgetEffects } from '../../widget.effects';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import {
    baseFilterSelector,
    instanceSelector,
    selectedDateRangeSelector
} from '../../../general/general.selectors';
import { GeneralActions } from '../../../general/general.actions';
import { getYYYYMMDDfromDate } from '../../../../../utils/general';
import { IDebtManagerFixedLarge } from '../../../models/widgets/large/debt-manager/dm-closed-fixed.model';
import { forkJoin } from 'rxjs';

type State = typeof Widgets.Large.DebtManager.DMCF;
enum StateConfig {
    Name = 'Widget - Debt Manager - Closed fixed - Debt Manager Large',
    Key = 'dm_lw_dmcf'
}

// First storage state object
const initialState: State = {
    isLoaded: false,
    loading: false,
    error: '',
    filter: {
        severity: [1, 2, 3],
        issue_status: ['CLOSED']
    },
    data: {
        table0: [],
        kpis0: {
            issues: '-',
            td: '-'
        },
        tableFilter: [],
        baseFilters: {},
        filters: []
    },
    cascadeFilters: [],
    tableFilter: [
        {
            pageIndex: 0,
            pageSize: 10,
            total: undefined,
            sort: undefined,
            direction: undefined
        }
    ]
};

/*
 *  Actions express unique events that happen throughout your application
 */
const stateActions = {
    load: createAction(
        `[${StateConfig.Name}] Load`,
        props<{ filter: any; tableFilter: any }>()
    ),
    error: createAction(
        `[${StateConfig.Name}] Load error`,
        props<{ error: string }>()
    ),
    success: createAction(
        `[${StateConfig.Name}] Load success`,
        props<{ data: IDebtManagerFixedLarge }>()
    ),
    reset: createAction(`[${StateConfig.Name}] Reset`),
    resetWithCurrentFilter: createAction(
        `[${StateConfig.Name}] Reset with current filter`,
        props<{ filter }>()
    ),
    saveCascadeFilters: createAction(
        `[${StateConfig.Name}] Save cascade filters`,
        props<{ data: any }>()
    )
};

/*
 * Reducer functions handle these transitions by determining which actions to
 * handle based on the action's type
 */
const reducers = createReducer(
    initialState,
    on(stateActions.load, (state, { filter }) => ({
        ...state,
        loading: true,
        error: '',
        filter
    })),
    on(stateActions.error, (state, { error }) => ({
        ...state,
        loading: false,
        error,
        isLoaded: true
    })),
    on(stateActions.success, (state, { data }) => {
        const { tableFilter, ...newData } = data;
        const newTableFilter = [...tableFilter];
        return {
            ...state,
            loading: false,
            isLoaded: true,
            data: newData,
            tableFilter: newTableFilter
        };
    }),
    on(stateActions.reset, () => ({
        ...initialState
    })),
    on(stateActions.resetWithCurrentFilter, (state, { filter }) => {
        const tableFilter = state.tableFilter;
        return {
            ...initialState,
            tableFilter,
            filter
        };
    }),
    on(stateActions.saveCascadeFilters, (state, { data }) => ({
        ...state,
        cascadeFilters: data
    }))
);

export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}

/*
 * Selectors are pure functions used for obtaining slices of store state.
 */
const stateSelector = createFeatureSelector<AppState, State>(StateConfig.Key);
const stateSelectors = {
    isLoaded: createSelector(stateSelector, (state: State) => state.isLoaded),
    loading: createSelector(stateSelector, (state: State) => state.loading),
    data: createSelector(stateSelector, (state: State) => state.data),
    error: createSelector(stateSelector, (state: State) => state.error),
    filter: createSelector(stateSelector, (state: State) => state.filter),
    cascadeFilters: createSelector(
        stateSelector,
        (state: State) => state.cascadeFilters
    ),
    tableFilter: createSelector(
        stateSelector,
        (state: State) => state.tableFilter
    )
};

/*
 * Effects use streams to provide new sources of actions to reduce state
 * based on external interactions such as network requests, web socket messages
 * and time-based events.
 */
@Injectable()
export class StateEffects extends WidgetEffects<any> {
    constructor(
        public actions$: Actions,
        public service: WidgetService,
        public store: Store<AppState>,
        public router: Router
    ) {
        super(
            actions$,
            service,
            store,
            stateActions,
            {
                cascadeFilters: [
                    'application',
                    'area',
                    'severity',
                    'tag',
                    'issue_type',
                    'ce_type',
                    'ce_name',
                    'write_off_expiration_date',
                    'delta_issues_long_id'
                    // 'issue_created_by'
                ],
                cascadeFiltersContext: 'debt-manager',
                loadLarge: {
                    key: 'dmcf-lw'
                }
            },
            stateSelector,
            undefined,
            router
        );
    }

    @Effect()
    _load = this.loadLarge;

    @Effect()
    _cascadeFilters = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(this.store.select(instanceSelector)),
        withLatestFrom(this.store.select(baseFilterSelector)),
        withLatestFrom(this.store.select(selectedDateRangeSelector)),
        withLatestFrom(this.store.select(this.stateSelector)),
        switchMap(
            ([[[[{ filter }, instance], baseFilters], dateRange], store]) => {
                let defaultInitialDate;
                if (dateRange === null) {
                    defaultInitialDate = this.getInitialDate();
                }
                let cascadeFilters = [...this.params.cascadeFilters];
                if (+instance.serviceId === 3) {
                    cascadeFilters = cascadeFilters.filter(
                        f => f !== 'application'
                    );
                }

                if (!store.isLoaded) {
                    return forkJoin([
                        this.service.newCascadeFilters(
                            cascadeFilters,
                            { issue_status: ['CLOSED'] },
                            instance.id,
                            null,
                            this.params.cascadeFiltersContext,
                            {
                                begin: getYYYYMMDDfromDate(
                                    dateRange
                                        ? dateRange.begin
                                        : defaultInitialDate.begin
                                ),
                                end: getYYYYMMDDfromDate(
                                    dateRange
                                        ? dateRange.end
                                        : defaultInitialDate.end
                                )
                            }
                        ),
                        this.service.newCascadeFilters(
                            cascadeFilters,
                            filter,
                            instance.id,
                            null,
                            this.params.cascadeFiltersContext,
                            {
                                begin: getYYYYMMDDfromDate(
                                    dateRange
                                        ? dateRange.begin
                                        : defaultInitialDate.begin
                                ),
                                end: getYYYYMMDDfromDate(
                                    dateRange
                                        ? dateRange.end
                                        : defaultInitialDate.end
                                )
                            }
                        )
                    ]).pipe(
                        switchMap((data: any[]) => {
                            const newBaseFilters = {};
                            let newFilterElements;
                            data[0].map(itemFilter => {
                                const newFilter = { ...itemFilter };
                                newBaseFilters[newFilter.filterName] = {
                                    context: this.params.cascadeFiltersContext,
                                    filterValues: newFilter.filterElements
                                };
                                return newFilter;
                            });
                            const newData = data[1].map(itemFilter => {
                                const newFilter = { ...itemFilter };
                                newFilterElements =
                                    newBaseFilters[newFilter.filterName]
                                        .filterValues;
                                const aux = newFilterElements.reduce(
                                    (result: any[], itemFilterElement) => {
                                        const newFilterElement = {
                                            ...itemFilterElement
                                        };
                                        if (
                                            newFilter.filterElements.find(
                                                el =>
                                                    el.value ===
                                                        itemFilterElement.value &&
                                                    el.label ===
                                                        itemFilterElement.label
                                            )
                                        ) {
                                            result[0].push(newFilterElement);
                                        } else {
                                            newFilterElement.disabled = true;
                                            result[1].push(newFilterElement);
                                        }
                                        return result;
                                    },
                                    [[], []]
                                );
                                newFilter.filterElements = aux[0].concat(
                                    aux[1]
                                );
                                return newFilter;
                            });
                            return [
                                this.actions.saveCascadeFilters({
                                    data: newData
                                }),
                                GeneralActions.saveBaseFilters({
                                    baseFilters: newBaseFilters
                                })
                            ];
                        })
                    );
                } else {
                    return this.service
                        .newCascadeFilters(
                            cascadeFilters,
                            filter,
                            instance.id,
                            null,
                            this.params.cascadeFiltersContext,
                            {
                                begin: getYYYYMMDDfromDate(
                                    dateRange
                                        ? dateRange.begin
                                        : defaultInitialDate.begin
                                ),
                                end: getYYYYMMDDfromDate(
                                    dateRange
                                        ? dateRange.end
                                        : defaultInitialDate.end
                                )
                            }
                        )
                        .pipe(
                            switchMap((data: any[]) => {
                                const newData = data.map(itemFilter => {
                                    const newFilter = { ...itemFilter };
                                    let newFilterElements;
                                    newFilterElements =
                                        baseFilters[newFilter.filterName]
                                            .filterValues;
                                    const aux = newFilterElements.reduce(
                                        (result: any[], itemFilterElement) => {
                                            const newFilterElement = {
                                                ...itemFilterElement
                                            };
                                            if (
                                                newFilter.filterElements.find(
                                                    el =>
                                                        el.value ===
                                                            itemFilterElement.value &&
                                                        el.label ===
                                                            itemFilterElement.label
                                                )
                                            ) {
                                                result[0].push(
                                                    newFilterElement
                                                );
                                            } else {
                                                newFilterElement.disabled = true;
                                                result[1].push(
                                                    newFilterElement
                                                );
                                            }
                                            return result;
                                        },
                                        [[], []]
                                    );
                                    newFilter.filterElements = aux[0].concat(
                                        aux[1]
                                    );
                                    return newFilter;
                                });
                                return [
                                    this.actions.saveCascadeFilters({
                                        data: newData
                                    })
                                ];
                            })
                        );
                }
            }
        )
    );
    @Effect()
    _instanceReset = this.instanceReset;
    @Effect()
    _dateChangedReset = this.dateChangedReset;
}

export const stateFeatures: StateFeatures = {
    config: StateConfig,
    actions: stateActions,
    selectors: stateSelectors
};
