<div class="main-wrapper">
	<div class="filters-wrapper">
		<qcbi-filter
			*ngFor="let visible of selectedFilters$ | async"
			[id]="visible.id"
			[options]="visible.options"
			[values]="visible.values"
			[disabled]="visible.id === 'issue_status' && !canShowStatus"
			(filterChanged)="handleNewSelectedOptions($event, visible.id)"
		></qcbi-filter>
	</div>
	<div class="button-wrapper">
		<button
			mat-button
			[matMenuTriggerFor]="menu"
			matTooltip="Manage Filters"
            aria-label="Manage Filters"
		>
			<mat-icon class="material-icons-outlined">filter_alt</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<button
				*ngFor="let availableFilter of filtersList$ | async"
				(click)="$event.stopPropagation();"
				mat-menu-item
                [attr.aria-label]="availableFilter.label | serviceDependentLabels"
			>
				<mat-checkbox
					color="primary"
					[checked]="availableFilter.checked"
					(change)="handleAvailableFilterChange($event, availableFilter.id)"
				>
					<div class="capitalize-first-letter">{{ availableFilter.label | serviceDependentLabels }}</div>
				</mat-checkbox>
			</button>
		</mat-menu>
	</div>
	<div class="button-wrapper">
		<button
			mat-button
			aria-label="clear filters"
			(click)="handleResetFiltersClick()"
		>
			<mat-icon
				class="fa-gray"
				svgIcon="filter-variant-remove"
				matTooltip="Clear filters"
			></mat-icon>
		</button>
	</div>
</div>