import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';
import {
    dashboardSelector,
    instanceSelector
} from '../../core/state/general/general.selectors';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'qcbi-nothing-found',
    templateUrl: './nothing-found.component.html',
    styleUrls: ['./nothing-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NothingFoundComponent implements OnInit {
    instance;
    dashboard;

    constructor(public store: Store<AppState>, public router: Router) {}

    ngOnInit() {
        this.store
            .select(dashboardSelector)
            .pipe(first())
            .subscribe(dashboard => {
                this.dashboard = dashboard;
                this.navigateToDashboard();
            });
        this.store
            .select(instanceSelector)
            .pipe(first())
            .subscribe(instance => {
                this.instance = instance;
                this.navigateToDashboard();
            });
    }

    navigateToDashboard() {
        if (this.instance && this.dashboard) {
            const servicePaths = ['servicenow', 'salesforce', 'office365'];
            this.router.navigate([
                '/' + servicePaths[this.instance.serviceId - 1],
                this.instance.id,
                this.dashboard.url
            ]);
        }
    }
}
// use routeLink (no window reload)
