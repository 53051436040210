import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'qcbi-dashboard-dialog',
    template: `
        <div class="content">
            <div class="title">
                {{ data.name }}
            </div>
            <div class="description">
                {{ data.description }}
            </div>
            <div class="link">
                The access to this dashboard is restricted. Contact us for more
                information. More information on this dashboard available
                <a
                    [href]="
                        data['help-url'] ||
                        'https://docs.qualityclouds.com/qcd/views-in-servicenow-31721500.html'
                    "
                    target="_blank"
                    style="color: blue;"
                    >here</a
                >
            </div>
        </div>
        <div class="actions">
            <button
                mat-raised-button
                color="primary"
                (click)="requestDashboardInfo()"
            >
                Contact us
            </button>
            <button mat-raised-button (click)="close()">Close</button>
        </div>
    `,
    styles: [
        `
            .content {
                min-width: 300px;
            }

            .actions {
                display: flex;
                justify-content: space-between;
                margin-top: 16px;
            }

            .title {
                font-size: 24px;
                margin-bottom: 16px;
            }

            .link {
                margin-top: 16px;
            }
        `
    ]
})
export class DashboardDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<DashboardDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    close() {
        this.dialogRef.close();
    }

    requestDashboardInfo() {
        window.open('https://www.qualityclouds.com/contact/', '_blank');
        this.dialogRef.close();
    }
}
