import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDateTimePipe } from './user-date-time.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [UserDateTimePipe],
    exports: [UserDateTimePipe]
})
export class UserDateTimeModule {}
