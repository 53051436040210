import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'qcbi-num-code-input',
    templateUrl: 'num-code-input.component.html',
    styleUrls: ['qcbi-num-code-input.component.scss']
})
export class NumCodeInputComponent implements ControlValueAccessor {
    @Input() set disabled(value: boolean) {
        this.isDisabled = value;
    }

    @Output() change = new EventEmitter();

    code = [null, null, null, null, null, null];
    isDisabled;

    onTouched = () => {};
    onChange = (_: any) => {};

    /**
     * handler for when a numeric input has changed
     * @param event value of the input
     * @param el next element to focus
     */
    valueChanged(event, el) {
        if (event !== null) el.focus();
        this.checkAndPropagateChanges();
    }

    /**
     * check if it has to emit an onChange value
     */
    checkAndPropagateChanges() {
        if (this.code.every(e => e !== null && e !== undefined)) {
            this.change.emit(this.code.join(''));
            this.onChange(this.code.join(''));
        }
    }

    /**
     * handler for a text paste to fill all the input fields
     * @param event clipboard event to get pasted data
     */
    pasteHandler(event: ClipboardEvent) {
        event.preventDefault();
        const data = event.clipboardData.getData('Text').split('');
        (event.target as HTMLInputElement).value = null;
        for (let i = 0; i < 6; ++i) {
            if (data[i] !== null && data[i] !== undefined) {
                this.code[i] = +data[i];
            }
        }
        this.checkAndPropagateChanges();
    }

    /**
     * On change register for control value accessor interface
     * @param fn function to assign
     */
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * On touch register for control value accessor interface
     * @param fn
     */
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * set disabled state for control value accessor interface. sets disabled state
     * @param isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    /**
     * writes value on the input. for the control value accessor interface
     * @param val
     */
    writeValue(val: string): void {
        if (val.length !== 6) console.warn('length do not match');
        const data = val.split('');
        for (let i = 0; i < 6; ++i) {
            if (data[i] !== null && data[i] !== undefined) {
                this.code[i] = +data[i];
            }
        }
    }
}
