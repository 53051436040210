import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    NotificationsUpdate,
    Notification,
    NotificationsDetail
} from './notifications.models';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    constructor(private http: HttpClient) {}

    getLatestNotificationsToDate(
        toDate: string,
        count = 10
    ): Observable<NotificationsUpdate> {
        const queryParams = [
            'markAsRead=false',
            'filter[include_read_notifications]=true',
            `page[size]=${count}`,
            'page[number]=1',
            'sort=is_read,-sent_on'
        ];
        if (toDate) queryParams.push(`filter[sent_on_gt]=${toDate}`);
        return this.http
            .get(
                `${environment.apiUrl}/v2/notification?` + queryParams.join('&')
            )
            .pipe(
                map((data: any) => {
                    const result: NotificationsUpdate = {
                        notifications: data.data.map(n => {
                            const notification: Notification = {
                                id: n.id,
                                customerId: n.attributes['customer-id'],
                                message: n.attributes.message,
                                instanceId: n.attributes['instance-id'],
                                isRead: n.attributes['is-read'],
                                date: n.attributes['sent-on']
                            };
                            return notification;
                        }),
                        unreadNotifications: data.meta.total_user_notifications
                            ? data.meta.total_user_notifications -
                              data.meta.total_read_notifications
                            : 0,
                        lastUpdate: Date.now(),
                        totalItems: data.meta.total_user_notifications || 0
                    };
                    return result;
                })
            );
    }

    getNextPageNotifications(
        lastDate: string,
        count = 10
    ): Observable<NotificationsUpdate> {
        const queryParams = [
            'markAsRead=false',
            'filter[include_read_notifications]=true',
            `filter[sent_on_lt]=${lastDate}`,
            `page[size]=${count}`,
            'page[number]=1',
            'sort=is_read,-sent_on'
        ];
        return this.http
            .get(
                `${environment.apiUrl}/v2/notification?` + queryParams.join('&')
            )
            .pipe(
                map((data: any) => {
                    const result: NotificationsUpdate = {
                        notifications: data.data.map(n => {
                            const notification: Notification = {
                                id: n.id,
                                customerId: n.attributes['customer-id'],
                                message: n.attributes.message,
                                instanceId: n.attributes['instance-id'],
                                isRead: n.attributes['is-read'],
                                date: n.attributes['sent-on']
                            };
                            return notification;
                        }),
                        unreadNotifications: data.meta.total_user_notifications
                            ? data.meta.total_user_notifications -
                              data.meta.total_read_notifications
                            : 0,
                        lastUpdate: Date.now(),
                        totalItems: data.meta.total_user_notifications || 0
                    };
                    return result;
                })
            );
    }

    getUnreadNotifications(lastDate: string): Observable<NotificationsDetail> {
        const queryParams = [
            'markAsRead=false',
            'filter[is_read]=false',
            `page[size]=10`,
            'page[number]=1',
            'sort=is_read,-sent_on'
        ];
        if (lastDate) queryParams.push(`filter[sent_on_lt]=${lastDate}`);
        return this.http
            .get(
                `${environment.apiUrl}/v2/notification?` + queryParams.join('&')
            )
            .pipe(
                map((data: any) => {
                    const result: NotificationsDetail = {
                        notifications: data.data.map(n => {
                            const notification: Notification = {
                                id: n.id,
                                customerId: n.attributes['customer-id'],
                                message: n.attributes.message,
                                instanceId: n.attributes['instance-id'],
                                isRead: n.attributes['is-read'],
                                date: n.attributes['sent-on']
                            };
                            return notification;
                        }),
                        total: data.meta.total_resources
                    };
                    return result;
                })
            );
    }

    getReadNotifications(lastDate: string): Observable<NotificationsDetail> {
        const queryParams = [
            'markAsRead=false',
            'filter[is_read]=true',
            `page[size]=10`,
            'page[number]=1',
            'sort=is_read,-sent_on'
        ];
        if (lastDate) queryParams.push(`filter[sent_on_lt]=${lastDate}`);
        return this.http
            .get(
                `${environment.apiUrl}/v2/notification?` + queryParams.join('&')
            )
            .pipe(
                map((data: any) => {
                    const result: NotificationsDetail = {
                        notifications: data.data.map(n => {
                            const notification: Notification = {
                                id: n.id,
                                customerId: n.attributes['customer-id'],
                                message: n.attributes.message,
                                instanceId: n.attributes['instance-id'],
                                isRead: n.attributes['is-read'],
                                date: n.attributes['sent-on']
                            };
                            return notification;
                        }),
                        total: data.meta.total_resources
                    };
                    return result;
                })
            );
    }

    markNotificationAsRead(notificationId: number): Observable<any> {
        return this.http.patch(
            `${environment.apiUrl}/v2/notification/${notificationId}`,
            {
                data: {
                    type: 'notification',
                    id: notificationId + '',
                    attributes: {
                        'is-read': true
                    }
                }
            }
        );
    }

    markAllNotificationsAsRead(): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}/v2/read-all-notifications`,
            null
        );
    }
}
