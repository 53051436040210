import { createReducer, on } from '@ngrx/store';
import { NewFeaturesActions } from './new-features.actions';
import { Feature } from './new-features.model';

export interface State {
    newFeatures: Feature[];
}

export const initialState: State = {
    newFeatures: []
};

export const newFeaturesReducer = createReducer(
    initialState,
    on(NewFeaturesActions.newFeaturesFetched, (state: State, { features }) => {
        return {
            ...state,
            newFeatures: features
        };
    })
);
