<header class="header flex items-center h-18 px-6 bg-white z-10 shadow responsive-container-wrapper">
    <div
      class="flex"
      *ngIf="
        !(initialLoading | async) &&
        !(instanceLoading | async) &&
        !(scanLoading | async) &&
        !(isEmptyView | async) &&
        router.url !== '/notifications'"
    >
        <div
          class="flex flex-col justify-center pr-8 responsive-container"
          *ngIf="!(onAllClouds$ | async) && (instance | async) != null"
        >
            <div class="text-xs text-primary-600 whitespace-no-wrap">
                {{ (instance | async)['service-name'] }} ({{ (instance | async).environment }})
            </div>
            <div class="text-xl truncated-text" [matTooltip]="(instance | async)['description']">
                {{ (instance | async).description }}
            </div>
        </div>
        <div class="flex flex-col justify-center pr-8" *ngIf="(onAllClouds$ | async)">
            <div class="text-xl">
                All Clouds
            </div>
        </div>
        <div
            class="flex pl-2 pr-4 border-l border-gray-200 text-xs responsive-container"
            *ngIf="!(onAllClouds$ | async) && (instance | async) != null"
        >
          <div class="flex flex-col justify-center pr-2">
            <button mat-icon-button aria-label="star instance" (click)="toggleStarredInstance()">
              <mat-icon
                [matTooltip]="starredInstance ?
                  'remove instance from starred instances list' :
                  'add instance to starred instances list'"
                [ngStyle]="{ color: starredInstance ? '#EFD500' : '#000' }"
              >
                {{ starredInstance ? 'star' : 'star_outline' }}
              </mat-icon>
            </button>
          </div>
          <div class="flex flex-col justify-center instance-info" style="width: calc(100% - 48px);">
              <ng-container *ngIf="(instance | async)['serviceId'] === 1">
                <div class="flex gap-8 flex-wrap" *ngIf="(scan | async) !== null">
                  <div class="text-secondary-500">Version:</div>
                  <div>{{ (scan | async).version || '-' }}</div>
                  <div class="text-secondary-500">Patch:</div>
                  <div>{{ (scan | async)['patch'] || '-' }}</div>
                </div>
                <div class="flex gap-8">
                  <div class="text-secondary-500">URL:</div>
                  <div class="url-container">
                    <a
                      class="link-url-top"
                      *ngIf="(instance | async).url"
                      [href]="(instance | async).url"
                      target="_blank"
                    >{{ (instance | async).url }}</a
                    >
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="(instance | async).target === 'git' && (instance | async)['serviceId'] === 2"
              >
                <div class="flex gap-8">
                  <div class="text-secondary-500 whitespace-no-wrap">Git URL:</div>
                  <div class="url-container">
                    <a
                      *ngIf="(instance | async).url"
                      [href]="(instance | async).url"
                      target="_blank"
                      [matTooltip]="(instance | async).url"
                    >{{ (instance | async).url  }}</a
                    >
                  </div>
                </div>
                <div class="flex gap-8">
                  <div class="text-secondary-500">Environment:</div>
                  <div [matTooltip]="(instance | async)['environment'] || '-'">
                    {{ (instance | async).environment || '-' }}
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="(instance | async).target === 'org' && (instance | async)['serviceId'] === 2"
              >
                <div class="flex gap-8">
                  <div class="text-secondary-500 whitespace-no-wrap">Org ID:</div>
                  <div class="truncated-text" [matTooltip]="(instance | async)['org-id'] || '-'">
                    {{ (instance | async)['org-id'] || '-' }}
                  </div>
                  <ng-container *ngIf="(scan | async) !== null">
                    <div class="text-secondary-500">Edition:</div>
                    <div class="truncated-text" [matTooltip]="(scan | async)['edition']">
                      {{ (scan | async)['edition'] }}
                    </div>
                  </ng-container>
                </div>
                <div class="flex gap-8">
                  <div class="text-secondary-500 whitespace-no-wrap">Org URL:</div>
                  <div class="url-container" style="width: calc(100% - 48px);">
                    <a
                      *ngIf="(instance | async).url"
                      [href]="(instance | async).url"
                      target="_blank"
                      class="truncated-text"
                      style="width: 100%"
                    >{{ (instance | async).url }}</a
                    >
                  </div>
                </div>
                <div class="flex">
                  <div class="mr-2 text-secondary-500">Environment:</div>
                  <div class="truncated-text" [matTooltip]="(instance | async)['environment'] || '-'">
                    {{ (instance | async).environment || '-' }}
                  </div>
                </div>
              </ng-container>
          </div>
        </div>
        <div
            class="flex flex-col justify-center px-8 pt-3 border-l border-gray-200 responsive-container"
            *ngIf="(layoutDashboards$ | async) as layoutDashboards;"
        >
            <mat-form-field *ngIf="layoutDashboards.length > 0" class="view">
                <mat-label id="view">View</mat-label>
                <mat-select
                    #dashboardSelect
                    disableOptionCentering
                    aria-labelledby="view"
                    [value]="(dashboard | async) === null ? '' : (dashboard | async).id"
                    (selectionChange)="handleViewChange($event)"
                >
                  <ng-container *ngIf="!onEmulation">
                    <mat-option
                      *ngFor="
                            let dashboard of (layoutDashboards$ | async)
                            "
                      [disabled]="!dashboard.active || !dashboard['is-available-for-user']"
                      [value]="dashboard.id"
                    >
                      <div class='flex'>
                        <div>{{ dashboard.name }}</div>
                      </div>
                    </mat-option>
                    <mat-option
                      *ngFor="
                            let dashboard of (layoutUnavailableDashboards$ | async)"
                      [disabled]="true"
                      [value]="dashboard.id"
                    >
                      <div class='flex'>
                        <mat-icon style="margin-top: 14px; margin-left: 2px; margin-right: 2px; height: 19px; font-size: 19px;">lock</mat-icon>
                        <div>{{ dashboard.name }}</div>
                        <div
                          class='more-info'
                          (click)="dashboardSelect.close(); openDashboardInfo(dashboard)"
                        >
                          +info
                        </div>
                      </div>
                    </mat-option>
                  </ng-container>
                  <ng-container *ngIf="onEmulation">
                    <mat-option
                      *ngFor="
                            let dashboard of (layoutDashboards$ | async)"
                      [disabled]="!dashboard.active || !dashboard['is-available-for-user']"
                      [value]="dashboard.id"
                    >
                      <div class='flex'>
                        <div>{{ dashboard.name }}</div>
                      </div>
                    </mat-option>
                    <mat-option
                      *ngFor="
                            let dashboard of (layoutUnavailableDashboards$ | async)"
                      style="color: red !important;"
                      class="view-red"
                      [value]="dashboard.id"
                    >
                      <div class='flex'>
                        <div>{{ dashboard.name }}</div>
                      </div>
                    </mat-option>
                  </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <nav class="ml-auto flex items-center responsive-container">
        <qcbi-notifications
          (notificationClicked)="notificationClickedHandler($event)"
        ></qcbi-notifications>
        <button
            *ngIf="isEmulating$ | async"
            mat-icon-button
            (click)="handleEndEmulation()"
            color="primary"
            matTooltip="End emulation"
        >
            <mat-icon>power_settings_new</mat-icon>
        </button>
        <button
            type="button"
            class="text-xsm profile-button userInfo"
            mat-button
            color="dark"
            *ngIf="!(initialLoading | async)"
            [matMenuTriggerFor]="profileMenu"
        >
            <div class="avatar">
                <app-avatar [user]="user | async" [size]="30"></app-avatar>
                <div class="avatar-text">
                  {{ (user | async).username | uppercase }}
                </div>
                <br />
                {{
                    (isEmulating$ | async)
                        ? 'Emulated by ' + (authUser | async).username
                        : ''
                }}
            </div>
        </button>
        <mat-menu #profileMenu="matMenu" class="profile-menu" cdkTrapFocus>
            <div class="profile-header p-4" *ngIf="!(initialLoading | async)">
                <span class="avatar">
                    <app-avatar [user]="user | async" [size]="30"></app-avatar>
                    <div class="avatarPopup">
                        <h1>
                            {{ (user | async).firstName + ' '+ (user | async).lastName }}
                        </h1>
                        <h1>
                            {{
                                (user | async).customerName
                            }}
                        </h1>
                        <h3>
                            {{
                                (user | async).email
                            }}
                        </h3>
                        <h3>
                            {{
                                (user | async).roleName
                            }}
                        </h3>
                    </div>
                </span>
            </div>
            <div class="profile-body p-4">
                <ul>
                    <li class="menu-li">
                        <button
                          type="button"
                          class="link link-text secondary"
                          (click)="goToChangePassword()"
                          href="#"
                          role="button"
                          cdkFocusInitial
                          *ngIf="!isSso"
                        >
                            <em class="secondary las la-key margin-r1"></em>
                            Change Password
                        </button>
                    </li>
                    <li class="menu-li" *ngIf="!isSso && (user | async).customerHasMfa">
                        <qcbi-two-factor-authentication></qcbi-two-factor-authentication>
                    </li>
                    <li class="menu-li secondary">
                        Last logged:
                        {{  (user | async).lastLogged | userDateTime : 'dateTime' : '-'}}
                    </li>
                </ul>
                <div class="modal-options">
                    <button
                      *ngIf="!(isEmulating$ | async)"
                      type="button"
                      role="button"
                      aria-label="logout"
                      mat-stroked-button
                      color="primary"
                      (click)="handleLogout()"
                      class="margin-r1 rounded-button"
                    >
                        Logout
                    </button>
                </div>
            </div>
        </mat-menu>
    </nav>
</header>
