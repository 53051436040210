import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mapToAttribute'
})
export class MapToAttributePipe implements PipeTransform {
    transform(array: any[], attribute: string): any {
        if (!array) return;
        return array.map(el => el[attribute]);
    }
}
