import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { BannerComponent } from './banner.component';

@NgModule({
    imports: [CommonModule, MatIconModule],
    declarations: [BannerComponent],
    exports: [BannerComponent]
})
export class BannerModule {}
