import { NotificationsState } from './notifications.models';
import { Action, createReducer, on } from '@ngrx/store';
import { NotificationsActions } from './notifications.actions';

export const initialState: NotificationsState = {
    notifications: [],
    lastUpdate: 0,
    totalItems: 0,
    unreadNotifications: 0,
    loading: false,
    detailedUnreadNotifications: [],
    detailedUnreadTotal: 0,
    detailedUnreadLoading: false,
    detailedReadNotifications: [],
    detailedReadTotal: 0,
    detailedReadLoading: false
};

const reducer = createReducer(
    initialState,
    on(
        NotificationsActions.setNotifications,
        (
            state,
            { notifications, lastUpdate, unreadNotifications, totalItems }
        ) => {
            return {
                ...state,
                loading: false,
                notifications: [...notifications, ...state.notifications],
                lastUpdate,
                totalItems: Math.max(state.totalItems, totalItems),
                unreadNotifications: Math.max(
                    state.unreadNotifications,
                    unreadNotifications
                )
            };
        }
    ),
    on(
        NotificationsActions.appendNotifications,
        (state, { notifications, lastUpdate, totalItems }) => {
            return {
                ...state,
                loading: false,
                notifications: [...state.notifications, ...notifications],
                lastUpdate,
                totalItems
            };
        }
    ),
    on(NotificationsActions.markAllNotificationsAsRead, state => {
        return {
            ...state,
            notifications: state.notifications.map(n => ({
                ...n,
                isRead: true
            })),
            unreadNotifications: 0
        };
    }),
    on(
        NotificationsActions.markNotificationAsRead,
        (state, { notificationId }) => {
            return {
                ...state,
                notifications: state.notifications.map(n => ({
                    ...n,
                    isRead: n.isRead || n.id === notificationId
                })),
                unreadNotifications: state.unreadNotifications - 1
            };
        }
    ),
    on(NotificationsActions.getNextPage, state => ({
        ...state,
        loading: true
    })),
    on(NotificationsActions.getUnreadDetail, (state, { reset }) => ({
        ...state,
        detailedUnreadLoading: true,
        detailedUnreadNotifications: reset
            ? []
            : state.detailedUnreadNotifications
    })),
    on(
        NotificationsActions.setUnreadDetail,
        (state, { notifications, total }) => {
            return {
                ...state,
                detailedUnreadNotifications: [
                    ...state.detailedUnreadNotifications,
                    ...notifications
                ],
                detailedUnreadTotal: total,
                detailedUnreadLoading: false
            };
        }
    ),
    on(NotificationsActions.getReadDetail, (state, { reset }) => ({
        ...state,
        detailedReadLoading: true,
        detailedReadNotifications: reset ? [] : state.detailedReadNotifications
    })),
    on(
        NotificationsActions.setReadDetail,
        (state, { notifications, total }) => {
            return {
                ...state,
                detailedReadNotifications: [
                    ...state.detailedReadNotifications,
                    ...notifications
                ],
                detailedReadTotal: total,
                detailedReadLoading: false
            };
        }
    )
);

export function notificationsReducer(
    state: NotificationsState,
    action: Action
): NotificationsState {
    return reducer(state, action);
}
