import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe, DatePipe } from '@angular/common';
import moment from 'moment';

@Pipe({
    name: 'table'
})
export class TablePipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe, private datePipe: DatePipe) {}

    transform(value: any, timeFormat: string, decimal: any, date: any): any {
        try {
            if (
                value !== null &&
                !isNaN(value) &&
                typeof value !== 'boolean' &&
                (typeof value !== 'string' || !value.startsWith('00'))
            )
                return this.decimalPipe.transform(value, ...(decimal || []));
            else if (moment(value, moment.ISO_8601, true).isValid()) {
                const d = new Date(Date.parse(value));
                const offsetInMilliseconds = d.getTimezoneOffset() * 60000;
                const adjustedDate = new Date(
                    d.getTime() - offsetInMilliseconds
                );
                return this.datePipe.transform(
                    adjustedDate,
                    ...(date
                        ? date
                        : timeFormat === undefined ||
                          timeFormat === null ||
                          timeFormat === 'EU'
                        ? ['dd/MM/yyyy HH:mm', '+0000']
                        : ['MM/dd/yyyy HH:mm', '+0000'])
                );
            }
            return value;
        } catch (error) {
            console.error('table pipe error:', error);
            return value || '';
        }
    }
}
