import { TagsState } from './tags.models';
import { Action, createReducer, on } from '@ngrx/store';
import { TagsActions } from './tags.actions';

export const initialState: TagsState = {
    isLoaded: false,
    tags: []
};

const reducer = createReducer(
    initialState,
    on(TagsActions.fetchTagsSuccess, (state, { tags }) => ({
        ...state,
        isLoaded: true,
        tags
    }))
);

export function tagsReducer(
    state: TagsState | undefined,
    action: Action
): TagsState {
    return reducer(state, action);
}
