import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectableTeam } from '../../../../../models/selectable-team.model';

@Component({
    selector: 'qcbi-selectable-item',
    templateUrl: 'selectable-item.component.html',
    styleUrls: ['selectable-item.component.scss']
})
export class SelectableItemComponent {
    @Input() team: SelectableTeam;
    @Output() selectedChange: EventEmitter<boolean> = new EventEmitter();

    toggle(checked: boolean): void {
        this.selectedChange.next(checked);
    }
}
