import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NotificationsService } from './notifications.service';
import { NotificationsActions } from './notifications.actions';
import { first, map, switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Injectable()
export class NotificationsEffects {
    constructor(
        private actions$: Actions,
        private service: NotificationsService
    ) {}

    @Effect()
    fetchNotifications = this.actions$.pipe(
        ofType(NotificationsActions.fetchNotifications),
        switchMap(({ date }) =>
            this.service.getLatestNotificationsToDate(date).pipe(
                map(
                    ({
                        notifications,
                        lastUpdate,
                        unreadNotifications,
                        totalItems
                    }) => {
                        return NotificationsActions.setNotifications({
                            notifications,
                            lastUpdate,
                            unreadNotifications,
                            totalItems
                        });
                    }
                )
            )
        )
    );

    @Effect({ dispatch: false })
    markNotificationAsRead = this.actions$.pipe(
        ofType(NotificationsActions.markNotificationAsRead),
        map((...params) => {
            this.service
                .markNotificationAsRead(+params[0]['notificationId'])
                .pipe(first())
                .subscribe(() => {});
        })
    );

    @Effect({ dispatch: false })
    markAllNotificationsAsRead = this.actions$.pipe(
        ofType(NotificationsActions.markAllNotificationsAsRead),
        map(() => {
            this.service
                .markAllNotificationsAsRead()
                .pipe(first())
                .subscribe(() => {});
        })
    );

    @Effect()
    getNextPage = this.actions$.pipe(
        ofType(NotificationsActions.getNextPage),
        switchMap(({ lastNotificationDate, firstNotificationDate }) => {
            return forkJoin([
                this.service.getNextPageNotifications(lastNotificationDate),
                this.service.getLatestNotificationsToDate(firstNotificationDate)
            ]).pipe(
                switchMap(([nextPageNotifications, latestNotifications]) => {
                    return [
                        NotificationsActions.appendNotifications({
                            notifications: nextPageNotifications.notifications,
                            lastUpdate: nextPageNotifications.lastUpdate,
                            unreadNotifications: Math.max(
                                nextPageNotifications.unreadNotifications,
                                latestNotifications.unreadNotifications
                            ),
                            totalItems: Math.max(
                                nextPageNotifications.totalItems,
                                latestNotifications.totalItems
                            )
                        }),
                        NotificationsActions.setNotifications({
                            notifications: latestNotifications.notifications,
                            lastUpdate: latestNotifications.lastUpdate,
                            unreadNotifications: Math.max(
                                latestNotifications.unreadNotifications,
                                nextPageNotifications.unreadNotifications
                            ),
                            totalItems: Math.max(
                                latestNotifications.totalItems,
                                nextPageNotifications.totalItems
                            )
                        })
                    ];
                })
            );
        })
    );

    @Effect()
    getUnreadDetail = this.actions$.pipe(
        ofType(NotificationsActions.getUnreadDetail),
        switchMap(({ date }) => {
            return this.service.getUnreadNotifications(date).pipe(
                map(detail => {
                    return NotificationsActions.setUnreadDetail(detail);
                })
            );
        })
    );

    @Effect()
    getReadDetail = this.actions$.pipe(
        ofType(NotificationsActions.getReadDetail),
        switchMap(({ date }) => {
            return this.service.getReadNotifications(date).pipe(
                map(detail => {
                    return NotificationsActions.setReadDetail(detail);
                })
            );
        })
    );
}
