import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'qcbi-label-value',
    template: `
        <div class="flex pb-2">
            <div class="label">{{ label }}:</div>
            <div class="value type">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styles: [
        `
            .label {
                color: #3a3b3f;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: -0.05px;
                line-height: 20px;
                width: 112px;
                min-width: 112px;
            }

            .value {
                margin-right: 16px;
                color: #3a3b3f;
                font-size: 14px;
                letter-spacing: -0.05px;
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelValueComponent {
    @Input() label;
}
