import { createAction, props } from '@ngrx/store';
import { ActivityLog, JiraConfig } from './jira-integration.models';

const JIRA_INTEGRATION_STATE_NAME = 'Jira Integration';

export const JiraIntegrationActions = {
    fetchJiraConfig: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] fetch jira config`
    ),
    setJiraConfig: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] set jira config`,
        props<{
            config: JiraConfig;
        }>()
    ),
    enableJiraIntegration: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] enable Jira Integration`
    ),
    disableJiraIntegration: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] disable Jira Integration`
    ),
    connect: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] connect`,
        props<{
            email: string;
            token: string;
            subdomain: string;
        }>()
    ),
    connectionSuccess: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] connection success`,
        props<{ config: JiraConfig }>()
    ),
    connectionError: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] connection error`,
        props<{
            error: any;
        }>()
    ),
    disconnect: createAction(`[${JIRA_INTEGRATION_STATE_NAME}] disconnect`),
    disconnectionSuccess: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] disconnection success`,
        props<{
            config: JiraConfig;
        }>()
    ),
    disconnectionError: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] disconnection error`,
        props<{
            error: any;
        }>()
    ),
    fetchProjects: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] fetch projects`,
        props<{
            email: string;
            token: string;
            subdomain: string;
        }>()
    ),
    fetchProjectsSuccess: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] fetch projects success`,
        props<{
            projects: { label: string; value: string }[];
        }>()
    ),
    fetchProjectsError: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] fetch projects error`,
        props<{
            error: any;
        }>()
    ),
    saveConfig: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] save config`,
        props<{
            severity: any[];
            impactArea: any[];
            application: any[];
        }>()
    ),
    saveConfigSuccess: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] save config success`
    ),
    saveConfigError: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] save config error`,
        props<{
            error: any;
        }>()
    ),
    saveProjectAndStates: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] save project and states`,
        props<{
            project;
            issueType;
            initialState;
            finalState;
            initialStatus;
            finalStatus;
            assignee;
        }>()
    ),
    saveProjectAndStatesSuccess: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] save project and states success`
    ),
    saveProjectAndStatesError: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] save project and states error`,
        props<{
            error: any;
        }>()
    ),
    fetchApplications: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] fetch applications`
    ),
    setApplications: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] set applications`,
        props<{ applications: any[] }>()
    ),
    fetchApplicationsError: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] fetch applications error`
    ),
    fetchTransitions: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] fetch transitions`,
        props<{ projectId: string; issueTypeId: string }>()
    ),
    setTransitions: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] set transitions`,
        props<{ transitions: { label: string; value: string }[] }>()
    ),
    setTransitionsError: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] set transitions error`,
        props<{ error: string }>()
    ),
    getActivityLog: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] get activity log`,
        props<{ tableFilter?: any }>()
    ),
    setActivityLog: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] set activity log`,
        props<{ data: ActivityLog[]; meta: object }>()
    ),
    fetchUsers: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] fetch users`,
        props<{
            projectId: string;
            query?: string;
        }>()
    ),
    fetchUsersSuccess: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] fetch users success`,
        props<{
            users: { label: string; value: string }[];
        }>()
    ),
    fetchUsersError: createAction(
        `[${JIRA_INTEGRATION_STATE_NAME}] fetch users error`,
        props<{
            error: any;
        }>()
    )
};
