import {
    Action,
    createAction,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
    Store
} from '@ngrx/store';
import { StateFeatures, Widgets } from '../../../models/widgets';
import { Injectable } from '@angular/core';
import { WidgetService } from '../../../../services/widget.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState } from '../../../../core.state';
import { WidgetEffects } from '../../widget.effects';
import { ITotalLicensesSmall } from '../../../models/widgets/small/cross-org-licensing/tl.model';
import { selectedDateRangeSelector } from '../../../general/general.selectors';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

type State = typeof Widgets.Small.CrossOrgLicensing.TL;
enum StateConfig {
    Name = 'Widget - Cross Org Licensing - Overview - Total Licenses small',
    Key = 'col_sw_tl'
}
// First storage state object
const initialState: State = {
    isLoaded: false,
    loading: false,
    error: '',
    filter: {},
    data: {
        value: 0,
        usedLicenses: 0,
        trend: []
    }
};

/*
 *  Actions express unique events that happen throughout your application
 */
const stateActions = {
    load: createAction(`[${StateConfig.Name}] Load`, props<{ filter: any }>()),
    error: createAction(
        `[${StateConfig.Name}] Load error`,
        props<{ error: string }>()
    ),
    success: createAction(
        `[${StateConfig.Name}] Load success`,
        props<{ data: ITotalLicensesSmall }>()
    ),
    reset: createAction(`[${StateConfig.Name}] Reset`)
};

/*
 * Reducer functions handle these transitions by determining which actions to
 * handle based on the action's type
 */
const reducers = createReducer(
    initialState,
    on(stateActions.load, (state, { filter }) => ({
        ...state,
        loading: true,
        error: '',
        filter
    })),
    on(stateActions.error, (state, { error }) => ({
        ...state,
        loading: false,
        error,
        isLoaded: true
    })),
    on(stateActions.success, (state, { data }) => ({
        ...state,
        loading: false,
        isLoaded: true,
        data
    })),
    on(stateActions.reset, () => ({
        ...initialState
    }))
);
export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}

/*
 * Selectors are pure functions used for obtaining slices of store state.
 */
const stateSelector = createFeatureSelector<AppState, State>(StateConfig.Key);
const stateSelectors = {
    isLoaded: createSelector(stateSelector, (state: State) => state.isLoaded),
    loading: createSelector(stateSelector, (state: State) => state.loading),
    data: createSelector(stateSelector, (state: State) => state.data),
    error: createSelector(stateSelector, (state: State) => state.error),
    filter: createSelector(stateSelector, (state: State) => state.filter)
};

/*
 * Effects use streams to provide new sources of actions to reduce state
 * based on external interactions such as network requests, web socket messages
 * and time-based events.
 */
@Injectable()
export class StateEffects extends WidgetEffects<ITotalLicensesSmall> {
    constructor(
        public actions$: Actions,
        public service: WidgetService,
        public store: Store<AppState>
    ) {
        super(
            actions$,
            service,
            store,
            stateActions,
            {
                load: {
                    path: 'licenses-compact-cross-org'
                }
            },
            stateSelector
        );
    }
    @Effect()
    _load = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(this.store.select(selectedDateRangeSelector)),
        switchMap(() => {
            return this.service
                .load(
                    this.params.load.path,
                    null,
                    undefined,
                    undefined,
                    undefined
                )
                .pipe(
                    map((data: ITotalLicensesSmall) =>
                        this.actions.success({ data })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(this.actions.error({ error: error.message }))
                    )
                );
        })
    );

    @Effect()
    _dateChangedReset = this.dateChangedReset;
    @Effect()
    _instanceReset = this.instanceReset;
}

export const stateFeatures: StateFeatures = {
    config: StateConfig,
    actions: stateActions,
    selectors: stateSelectors
};
