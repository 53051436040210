import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ColorService {
    constructor() {}

    pickTextColorBasedOnBgColorSimple(text: string) {
        const bgColor = this.getColor(text);
        const lightColor = '#fff';
        const darkColor = '#000';
        const color =
            bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
        const r = parseInt(color.substring(0, 2), 16); // hexToR
        const g = parseInt(color.substring(2, 4), 16); // hexToG
        const b = parseInt(color.substring(4, 6), 16); // hexToB
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
    }

    getColor(text: string): string {
        if (text === '') return '#aaa';
        return this.intToRGB(this.hashCode(text.trim()));
    }

    private hashCode(str: string) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            // tslint:disable-next-line:no-bitwise
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    private intToRGB(num: number) {
        // tslint:disable-next-line:no-bitwise
        const c = (num & 0x00ffffff).toString(16).toUpperCase();
        return '#' + '00000'.substring(0, 6 - c.length) + c;
    }
}
