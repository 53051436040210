<form #myForm="ngForm" (submit)="submitForm(myForm.value)" novalidate>
  <section carrousel>
    <input id="slide-0" name="carrousel" type="radio" value="legacy" [ngModel]="smartTag" />
    <div class="slide">
      <label for="slide-2" class="back">
        <mat-icon>chevron_left</mat-icon>
      </label>
      <div class="slide-content">
          <div class="smart-tagging-label legacy">legacy</div>
      </div>
      <label for="slide-1" class="forward">
        <mat-icon>chevron_right</mat-icon>
      </label>
    </div>

    <input id="slide-1" name="carrousel" type="radio" value="minor" [ngModel]="smartTag"/>
    <div class="slide">
      <label for="slide-0" class="back">
        <mat-icon>chevron_left</mat-icon>
      </label>
      <div class="slide-content">
        <div class="smart-tagging-label minor">minor</div>
      </div>
      <label for="slide-2" class="forward">
        <mat-icon>chevron_right</mat-icon>
      </label>
    </div>

    <input id="slide-2" name="carrousel" type="radio" value="blocker" [ngModel]="smartTag" />
    <div class="slide">
      <label for="slide-1" class="back">
        <mat-icon>chevron_left</mat-icon>
      </label>
      <div class="slide-content">
        <div class="smart-tagging-label blocker">blocker</div>
      </div>
      <label for="slide-0" class="forward">
        <mat-icon>chevron_right</mat-icon>
      </label>
    </div>
  </section>
  <div class="actions flex justify-center gap-8">
    <button
	    mat-raised-button
        cdkFocusInitial
	    class="w-full"
	    color="primary"
	    type="submit"
    >
	    Change
    </button>
    <button
	    mat-button
	    type="button"
	    class="w-full"
	    (click)="close()"
    >
	    Cancel
    </button>
  </div>
</form>
