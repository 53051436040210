import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
    generateOldParams,
    generateParams,
    generateTableParams,
    generateTableParams_API_BI,
    getEPfromPath,
    parseAttributesWriteOffScan,
    parseTableMeta,
    parseTableMetaBI_API,
    removeFilterByPath
} from '../../utils/widget-state-creator';
import { map, takeUntil } from 'rxjs/operators';
import { IIssueBreakdownSmall } from '../state/models/widgets/small/best-practices/ib.model';
import { LinesOfCodeLarge } from '../state/models/widgets/large/namespaces/loc.model';
import { IssuesLarge } from '../state/models/widgets/large/namespaces/iss.model';
import { IInventoryLarge } from '../state/models/widgets/large/team/inv.model';
import { getYYYYMMDDfromDate } from '../../utils/general';
import { IActiveUsersLarge } from '../state/models/widgets/large/user-adoption/au.model';
import { ITotalLoginsLarge } from '../state/models/widgets/large/user-adoption/tl.model';
import { ChartData } from '../../models/ChartData';
import { Store } from '@ngrx/store';
import { AppState } from '../core.state';
import { selectCurrentUser } from '../auth-lib';

@Injectable()
export class WidgetService implements OnDestroy {
    private latestUserTimeValue: string;
    private unsubscribe$ = new Subject();

    constructor(private http: HttpClient, private store: Store<AppState>) {
        this.store
            .select(selectCurrentUser)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(user => {
                this.latestUserTimeValue = user?.timeFormat;
            });
    }

    static formatLabel(val) {
        val.label = val.label
            .toLowerCase()
            .replace(val.label.charAt(0), val.label.charAt(0).toUpperCase());
        return val;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    load(
        path: string,
        id: number,
        filter = {},
        tableFilter: any = null,
        timeFilters: any = null,
        mainFilter: string = null,
        includeBaseline: boolean = null,
        includeTablesAndDict: boolean = null
    ): Observable<any> {
        const tableParams =
            tableFilter !== null ? generateTableParams_API_BI(tableFilter) : '';
        const queryParams = generateParams(filter);
        const url = `${environment.apiEndPoint}widgets/v1/${path}${
            id !== null ? '/' + id : ''
        }${queryParams}${
            queryParams !== ''
                ? tableParams
                : tableFilter
                ? '?' + tableParams
                : ''
        }${
            timeFilters
                ? (queryParams || tableParams ? '&' : '?') +
                  'from=' +
                  timeFilters.begin +
                  '&to=' +
                  timeFilters.end
                : ''
        }${
            mainFilter
                ? (queryParams || tableParams ? '&' : '?') +
                  ('condition=' + mainFilter)
                : ''
        }${
            includeBaseline === true
                ? (queryParams || tableParams || timeFilters || mainFilter
                      ? '&'
                      : '?') + 'includeBaseline=true'
                : includeBaseline === false
                ? (queryParams || tableParams || timeFilters || mainFilter
                      ? '&'
                      : '?') + 'includeBaseline=false'
                : ''
        }${
            includeTablesAndDict === true
                ? (queryParams ||
                  tableParams ||
                  timeFilters ||
                  mainFilter ||
                  includeBaseline
                      ? '&'
                      : '?') + 'includeTablesAndDict=true'
                : includeTablesAndDict === false
                ? (queryParams ||
                  tableParams ||
                  timeFilters ||
                  mainFilter ||
                  includeBaseline
                      ? '&'
                      : '?') + 'includeTablesAndDict=false'
                : ''
        }
        `;
        return this.http.get(url);
    }

    apiLoad(path, id): Observable<any> {
        const url = `${environment.apiEndPoint}api/widgets/${path}/${id}`;
        return this.http.get(url);
    }

    cascadeFilters(
        scanId,
        loadList: (
            | 'developers'
            | 'createdby'
            | 'updatedby'
            | 'areas'
            | 'best-practices'
            | 'severities'
            | 'namespaces'
            | 'cmNamespaces'
            | 'sources'
            | 'cetypes'
            | 'cetypesnames'
            | 'orgs'
            | 'licensesCustomer'
            | 'cetypesnamesisootb'
            | 'auditelement'
            | 'auditapplication'
            | 'dateUsers'
            | 'dateTeams'
            | 'dateCeTypes'
            | 'dateCeNames'
            | 'dateIssuesCeTypes'
            | 'dateIssuesDevelopers'
            | 'dateIssuesTeams'
            | 'scansFilter'
            | 'typeOfChange'
            | 'ootbModifications'
            | 'auditupdatedby'
            | 'updatedByUgr'
            | 'cetypesnamesugr'
            | 'dateUpdateSetQgStatus'
            | 'dateAppInventoryQgStatus'
            | 'dateUpdateSetStatus'
            | 'dateUpdateSetName'
            | 'dateAppInventoryStatus'
            | 'dateUpdateSetReleaseType'
            | 'dateFeatureBranchQgStatus'
            | 'dateFeatureBranchStatus'
            | 'dateFeatureBranchReleaseType'
            | 'dateFeatureBranchName'
            | 'dateScansCeTypes'
            | 'dateScansDevelopers'
            | 'dateScansQualityGatesResults'
            | 'CInamespaces'
        )[],
        filter,
        show,
        dateRange,
        instance,
        providerId = null
    ) {
        const observables = loadList.reduce((all, path) => {
            const key = path === 'best-practices' ? 'bestPractices' : path;
            all[key] = this.cascadeFilter(
                path,
                scanId,
                filter,
                show,
                dateRange,
                instance,
                providerId
            );
            return all;
        }, {});
        return forkJoin(observables);
    }

    cascadeFilter(
        path,
        scanId,
        filter,
        show,
        dateRange,
        instance,
        providerId
    ): Observable<any> {
        path = path.toLowerCase();
        const url = environment.apiEndPoint;
        const cascadeFilter = removeFilterByPath(path, filter);
        const params = generateParams(cascadeFilter);
        return this.http
            .get(
                `${url}widgets/v1/${getEPfromPath(path)}${
                    !show &&
                    path !== 'scansfilter' &&
                    path !== 'orgs' &&
                    path !== 'licensescustomer'
                        ? '/' + scanId
                        : instance !== null
                        ? '/' + instance
                        : ''
                }${params}${
                    show && dateRange
                        ? `${
                              params === '' ? '?' : '&'
                          }from=${getYYYYMMDDfromDate(
                              dateRange.begin
                          )}&to=${getYYYYMMDDfromDate(dateRange.end)}`
                        : ''
                }${
                    (instance === null || instance === undefined) &&
                    providerId !== null
                        ? '&providerId=' + providerId
                        : ''
                }${
                    path === 'typeofchange' // path === 'ootbmodifications' ||
                        ? params === ''
                            ? '?onlyChanges=true'
                            : '&onlyChanges=true'
                        : ''
                }${
                    path === 'updatedbyugr' || path === 'cetypesnamesugr'
                        ? params === ''
                            ? '?isAffectedUgr=true'
                            : '&isAffectedUgr=true'
                        : ''
                }${
                    path === 'auditupdatedby' ||
                    path === 'cetypesnamesisootb' ||
                    path === 'auditapplication'
                        ? params === ''
                            ? '?isOotb=true'
                            : '&isOotb=true'
                        : ''
                }${
                    path === 'cmnamespaces'
                        ? params === ''
                            ? '?codeMonitor=true'
                            : '&codeMonitor=true'
                        : ''
                }${path === 'orgs' ? '?providerId=2' : ''}`
            )
            .pipe(
                map((data: any) => {
                    if (
                        path === 'cetypesnamesugr' ||
                        path === 'cetypesnamesisootb' ||
                        path === 'licensescustomer'
                    )
                        return data.data.map(
                            (item: { label: string; value: string }) => {
                                const newItem = { ...item };
                                newItem.value = item.label;
                                return newItem;
                            }
                        );
                    if (
                        data.data.length > 0 &&
                        data.data[0].hasOwnProperty('data')
                    )
                        return data.data[0].data;
                    return data.data;
                })
            );
    }

    loadDetails(
        params: {
            key: string;
        },
        state: {
            instanceId: number;
            serviceId?: number;
            scanId?: number;
            scanLongId?: string;
            filter?: any;
            tableFilter?: any;
            timeFilter?: any;
            includeTablesAndDict?: boolean;
        }
    ) {
        const {
            scanId,
            filter,
            tableFilter,
            timeFilter,
            serviceId,
            includeTablesAndDict
        } = state;
        switch (params.key) {
            case 'ov-usage':
                const { instanceId, ...copyFilter } = filter;
                if (tableFilter) {
                    tableFilter.sort = 'livecheckIssues.scanDate';
                    tableFilter.direction = 'desc';
                }
                return this.load(
                    'live-check-issues-by-ce',
                    instanceId ? instanceId : null,
                    copyFilter,
                    tableFilter || {
                        sort: 'livecheckIssues.scanDate',
                        direction: 'desc',
                        pageIndex: 0,
                        pageSize: 10
                    },
                    timeFilter
                ).pipe(
                    map((data: any) => {
                        return {
                            data: data.data[0].data,
                            pagination: parseTableMetaBI_API(data.meta)
                        };
                    })
                );
            case 'ov-ed':
                return this.livecheckIssuesTable(filter, tableFilter).pipe(
                    map((data: any) => {
                        const meta = data.meta;
                        return {
                            data: data.data[0].data.map(res => {
                                return {
                                    ...res
                                };
                            }),
                            meta: parseTableMetaBI_API(meta)
                        };
                    })
                );
            case 'am-am':
                const {
                    AMmainScanId,
                    AMceType,
                    AMactive,
                    AMcmnamespaces0,
                    AMIncludeWritteOffs
                } = filter;
                const { AMmainCEConfig } = tableFilter;
                return forkJoin([
                    this.configurationElementsTable(
                        AMmainScanId,
                        AMceType,
                        AMmainCEConfig,
                        serviceId === 1 ? AMactive : undefined,
                        AMcmnamespaces0,
                        undefined,
                        undefined,
                        AMIncludeWritteOffs
                    )
                ]).pipe(
                    map(responses => {
                        return {
                            mainCE: {
                                data: responses[0].data.map(
                                    ({ id, attributes }) => ({
                                        id,
                                        ...attributes
                                    })
                                ),
                                meta: parseTableMeta(responses[0].meta)
                            }
                        };
                    })
                );
            case 'c-noc':
                return this.catalogDetailTable(
                    scanId,
                    filter.catalogName,
                    tableFilter
                );
            case 'c-cc':
                return this.catalogItemDetailTable(
                    filter.scanId,
                    filter.catalogName,
                    filter.categoryName,
                    tableFilter
                );
            case 'dc-dc':
                return this.duplicatedCodeDetail(scanId, filter).pipe(
                    map((data: any) => {
                        return {
                            data: data.data.map(({ id, attributes }) => ({
                                id,
                                ...attributes
                            })),
                            meta: parseTableMetaBI_API(data.meta)
                        };
                    })
                );
            case 'a-at':
                return this.load(
                    'alert-metrics-over-time',
                    state.instanceId,
                    { alert_template_id: filter.alertTemplateId },
                    undefined,
                    timeFilter
                );
            case 'rm-lw':
                const { updateSetScanId, ...appliedFilters } = filter;
                const requestFilters = { ...appliedFilters };
                if (
                    appliedFilters['cesWithIssues'] === undefined ||
                    appliedFilters['cesWithIssues'] === null
                ) {
                    appliedFilters['cesWithIssues'] = true;
                    requestFilters['cesWithIssues'] = true;
                } else if (appliedFilters['cesWithIssues'] === false) {
                    delete requestFilters['cesWithIssues'];
                }
                return forkJoin([
                    this.load(
                        'update-set-inventory',
                        updateSetScanId,
                        requestFilters,
                        tableFilter
                    ),
                    this.updateSetApplicationsFilter(updateSetScanId),
                    this.updateSetApplicationsFilter(
                        updateSetScanId,
                        appliedFilters
                    ),
                    this.updateSetUpdatedByFilter(updateSetScanId),
                    this.updateSetUpdatedByFilter(
                        updateSetScanId,
                        appliedFilters
                    )
                ]).pipe(
                    map((data: any) => {
                        return {
                            data: data[0].data[0].data
                                ? data[0].data[0].data.map(el => {
                                      const result = { ...el };
                                      result.isOotb = result.isOotb
                                          ? 'Yes'
                                          : 'No';
                                      return result;
                                  })
                                : [],
                            meta: parseTableMetaBI_API(data[0].meta),
                            updateSetScanId,
                            filters: {
                                namespaces: data[1].data.map(el => {
                                    for (
                                        let i = 0;
                                        i < data[2].data.length;
                                        i++
                                    ) {
                                        if (
                                            data[2].data[i].label === el.label
                                        ) {
                                            data[2].data.splice(i, 1);
                                            return el;
                                        }
                                    }
                                    return {
                                        ...el,
                                        disabled: true
                                    };
                                }),
                                developers: data[3].data.map(el => {
                                    for (
                                        let i = 0;
                                        i < data[4].data.length;
                                        i++
                                    ) {
                                        if (
                                            data[4].data[i].label === el.label
                                        ) {
                                            data[4].data.splice(i, 1);
                                            return el;
                                        }
                                    }
                                    return {
                                        ...el,
                                        disabled: true
                                    };
                                }),
                                cesWithIssues: false
                            },
                            filter: appliedFilters
                        };
                    })
                );
            case 'rm-appl-lw':
                const {
                    RMApplicationsScanId,
                    ...appliedRMApplicationsFilters
                } = filter;
                const requestRMApplicationsFilters = {
                    ...appliedRMApplicationsFilters
                };
                if (
                    appliedRMApplicationsFilters['cesWithIssues'] ===
                        undefined ||
                    appliedRMApplicationsFilters['cesWithIssues'] === null
                ) {
                    appliedRMApplicationsFilters['cesWithIssues'] = true;
                    requestRMApplicationsFilters['cesWithIssues'] = true;
                } else if (
                    appliedRMApplicationsFilters['cesWithIssues'] === false
                ) {
                    delete requestRMApplicationsFilters['cesWithIssues'];
                }
                return forkJoin([
                    this.load(
                        'application-inventory',
                        RMApplicationsScanId,
                        requestRMApplicationsFilters,
                        tableFilter
                    ),
                    this.RMApplUpdatedByFilter(RMApplicationsScanId),
                    this.RMApplUpdatedByFilter(
                        RMApplicationsScanId,
                        appliedRMApplicationsFilters
                    )
                ]).pipe(
                    map((data: any) => {
                        return {
                            data: data[0].data[0].data
                                ? data[0].data[0].data.map(el => {
                                      const result = { ...el };
                                      result.isOotb = result.isOotb
                                          ? 'Yes'
                                          : 'No';
                                      return result;
                                  })
                                : [],
                            meta: parseTableMetaBI_API(data[0].meta),
                            RMApplicationsScanId,
                            filters: {
                                developers: data[1].data.map(el => {
                                    for (
                                        let i = 0;
                                        i < data[2].data.length;
                                        i++
                                    ) {
                                        if (
                                            data[2].data[i].label === el.label
                                        ) {
                                            data[2].data.splice(i, 1);
                                            return el;
                                        }
                                    }
                                    return {
                                        ...el,
                                        disabled: true
                                    };
                                }),
                                cesWithIssues: false
                            },
                            filter: appliedRMApplicationsFilters
                        };
                    })
                );
            case 'fb-lw':
                const {
                    featureBranchScanId,
                    ...appliedFeatureBranchFilters
                } = filter;
                const requestFeatureBranchFilters = {
                    ...appliedFeatureBranchFilters
                };
                if (
                    appliedFeatureBranchFilters['cesWithIssues'] ===
                        undefined ||
                    appliedFeatureBranchFilters['cesWithIssues'] === null
                ) {
                    appliedFeatureBranchFilters['cesWithIssues'] = true;
                    requestFeatureBranchFilters['cesWithIssues'] = true;
                } else if (
                    appliedFeatureBranchFilters['cesWithIssues'] === false
                ) {
                    delete requestFeatureBranchFilters['cesWithIssues'];
                }
                return forkJoin([
                    this.load(
                        'feature-branch-inventory',
                        featureBranchScanId,
                        requestFeatureBranchFilters,
                        tableFilter
                    ),
                    this.featureBranchUpdatedByFilter(featureBranchScanId),
                    this.featureBranchUpdatedByFilter(
                        featureBranchScanId,
                        appliedFeatureBranchFilters
                    )
                ]).pipe(
                    map((data: any) => {
                        return {
                            data: data[0].data[0].data
                                ? data[0].data[0].data
                                : [],
                            meta: parseTableMetaBI_API(data[0].meta),
                            featureBranchScanId,
                            filters: {
                                developers: data[1].data.map(el => {
                                    for (
                                        let i = 0;
                                        i < data[2].data.length;
                                        i++
                                    ) {
                                        if (
                                            data[2].data[i].label === el.label
                                        ) {
                                            data[2].data.splice(i, 1);
                                            return el;
                                        }
                                    }
                                    return {
                                        ...el,
                                        disabled: true
                                    };
                                }),
                                cesWithIssues: false
                            },
                            filter: appliedFeatureBranchFilters
                        };
                    })
                );
            case 'up-ceu':
                if (filter.version)
                    return forkJoin([
                        this.CEsConflicted(
                            scanId,
                            filter,
                            true,
                            tableFilter,
                            includeTablesAndDict
                        ),
                        this.CEsConflicted(
                            scanId,
                            filter,
                            false,
                            tableFilter,
                            includeTablesAndDict
                        )
                    ]).pipe(
                        map((data: any) => {
                            return {
                                codeChange: data[0].data.map(
                                    ({ id, attributes }) => ({
                                        id,
                                        ...attributes
                                    })
                                ),
                                codeChangeMeta: parseTableMeta(data[0].meta),
                                notCodeChange: data[1].data.map(
                                    ({ id, attributes }) => ({
                                        id,
                                        ...attributes
                                    })
                                ),
                                notCodeChangeMeta: parseTableMeta(data[1].meta)
                            };
                        })
                    );
                else
                    return forkJoin([
                        this.ootbElementSourceCode(filter.ceId),
                        filter.showCode
                            ? this.ootbElementOriginalSourceCode(
                                  filter.sysId,
                                  state.instanceId
                              )
                            : new Observable(observer => {
                                  observer.next({
                                      data: {
                                          version: {
                                              code_fields: []
                                          }
                                      }
                                  });
                                  observer.complete();
                              }),
                        this.ceDependencyMap(
                            filter.ceId,
                            {},
                            tableFilter || {
                                sort: '',
                                direction: '',
                                pageIndex: 0,
                                pageSize: 10
                            }
                        )
                    ]).pipe(
                        map((data: any) => {
                            return {
                                sources:
                                    data[0].data.attributes[
                                        'source-code-split'
                                    ],
                                originalSources: data[1].data.version.code_fields.reduce(
                                    (result, code_field) => {
                                        result[code_field] =
                                            data[1].data.version[code_field];
                                        return result;
                                    },
                                    {}
                                ),
                                data: data[2].data.map(
                                    ({ id, attributes }) => ({
                                        id,
                                        ...attributes
                                    })
                                ),
                                meta: parseTableMeta(data[2].meta)
                            };
                        })
                    );
            case 'col-tl':
                return this.load(
                    'active-licenses-per-profile',
                    null,
                    filter,
                    tableFilter
                ).pipe(
                    map((data: any) => {
                        return {
                            data: data.data[0].data,
                            meta: parseTableMetaBI_API(data.meta)
                        };
                    })
                );
            default:
                return null;
        }
    }

    formatTime(yyyymmdd: string): string | null {
        if (yyyymmdd === null) return null;
        return (
            yyyymmdd.substring(0, 4) +
            '-' +
            yyyymmdd.substring(4, 6) +
            '-' +
            yyyymmdd.substring(6, 8)
        );
    }

    writeOff(
        instanceId,
        filter,
        timeFilter,
        tableFilter = null
    ): Observable<any> {
        const queryParams = this.getQueryParamsFromFilter(
            filter,
            '',
            'write-off'
        );
        const begin = timeFilter.begin
            ? this.formatTime(timeFilter.begin)
            : null;
        const end = timeFilter.end ? this.formatTime(timeFilter.end) : null;
        const url =
            `${environment.apiEndPoint}api/v2/write-off-snapshot?` +
            'filter[instance_id]=' +
            instanceId +
            (begin ? '&filter[request_date_gte]=' + begin : '') +
            (end ? '&filter[request_date_lte]=' + end : '') +
            (queryParams === '' ? '' : '&' + queryParams) +
            generateTableParams(tableFilter);
        return this.http.get(url).pipe(
            map((res: any) => {
                return {
                    table: res.data.map((wo: any) => ({
                        id: wo.id,
                        ...wo.attributes
                    })),
                    tableFilter: parseTableMetaBI_API(res.meta)
                };
            })
        );
    }

    getTableData(
        instanceId: string,
        tableFilter: any,
        queryParams: any,
        timeFilter: { begin: string; end: string },
        tableStatusFilter: string,
        index: number
    ) {
        return this.http.get(
            `${environment.apiEndPoint}api/v2/open-issue?` +
                `filter[instance_id]=${instanceId}` +
                '&' +
                tableStatusFilter +
                (timeFilter.begin
                    ? `&filter[${
                          tableStatusFilter === 'filter[issue_status]=CLOSED'
                              ? 'issue_closed_date_gte'
                              : 'issue_open_date_gte'
                      }]=${this.formatTime(timeFilter.begin)}`
                    : '') +
                (timeFilter.end
                    ? `&filter[${
                          tableStatusFilter === 'filter[issue_status]=CLOSED'
                              ? 'issue_closed_date_lte'
                              : 'issue_open_date_lte'
                      }]=${this.formatTime(timeFilter.end)}`
                    : '') +
                `${generateTableParams(tableFilter[index])}` +
                `${queryParams !== '' ? '&' + queryParams : ''}`
        );
    }

    getKpis(
        instanceId: string,
        queryParams: any,
        timeFilter: { begin: string; end: string },
        tableStatusFilter: string
    ) {
        return this.http.get(
            `${environment.apiEndPoint}api/v2/open-issues-kpis/${instanceId}?` +
                (timeFilter.begin
                    ? `&filter[${
                          tableStatusFilter === 'filter[issue_status]=CLOSED'
                              ? 'issue_closed_date_gte'
                              : 'issue_open_date_gte'
                      }]=${this.formatTime(timeFilter.begin)}`
                    : '') +
                (timeFilter.end
                    ? `&filter[${
                          tableStatusFilter === 'filter[issue_status]=CLOSED'
                              ? 'issue_closed_date_lte'
                              : 'issue_open_date_lte'
                      }]=${this.formatTime(timeFilter.end)}`
                    : '') +
                '&' +
                tableStatusFilter +
                `${queryParams !== '' ? '&' + queryParams : ''}`
        );
    }

    getDebtManagerData(
        instanceId: string,
        tableFilter: any,
        queryParams: any,
        timeFilter: { begin: string; end: string },
        firstTableFilter: string,
        secondTableFilter: string,
        commonTableFilter: string
    ): Observable<any> {
        return forkJoin([
            this.getTableData(
                instanceId,
                tableFilter,
                queryParams,
                timeFilter,
                firstTableFilter,
                0
            ),
            secondTableFilter
                ? this.getTableData(
                      instanceId,
                      tableFilter,
                      queryParams,
                      timeFilter,
                      secondTableFilter,
                      1
                  )
                : of({ data: [], meta: {} }),
            commonTableFilter
                ? this.getTableData(
                      instanceId,
                      tableFilter,
                      queryParams,
                      timeFilter,
                      commonTableFilter,
                      2
                  )
                : of({ data: [], meta: {} }),
            this.getKpis(instanceId, queryParams, timeFilter, firstTableFilter),
            secondTableFilter
                ? this.getKpis(
                      instanceId,
                      queryParams,
                      timeFilter,
                      secondTableFilter
                  )
                : of({ data: { attributes: {} } })
        ]).pipe(
            map((res: any) => {
                return {
                    table0: res[0].data.map(el => ({
                        id: el.id,
                        ...el.attributes,
                        'write-off-expiration-date-time': el.attributes[
                            'write-off-expiration-date'
                        ]
                            ? this.getFormattedExpirationDate(
                                  el.attributes['write-off-expiration-date'],
                                  el.attributes['write-off-expiration-time']
                              )
                            : 'Never'
                    })),
                    table1: res[1].data.map(el => ({
                        id: el.id,
                        ...el.attributes,
                        'write-off-expiration-date-time': el.attributes[
                            'write-off-expiration-date'
                        ]
                            ? this.getFormattedExpirationDate(
                                  el.attributes['write-off-expiration-date'],
                                  el.attributes['write-off-expiration-time']
                              )
                            : 'Never'
                    })),
                    tableShared: res[2].data.map(el => ({
                        id: el.id,
                        ...el.attributes,
                        'write-off-expiration-date-time': el.attributes[
                            'write-off-expiration-date'
                        ]
                            ? this.getFormattedExpirationDate(
                                  el.attributes['write-off-expiration-date'],
                                  el.attributes['write-off-expiration-time']
                              )
                            : 'Never'
                    })),
                    kpis0: {
                        issues: res[3].data.attributes['issues'],
                        td: res[3].data.attributes['technical-debt']
                    },
                    kpis1: {
                        issues: res[4].data.attributes['issues'],
                        td: res[4].data.attributes['technical-debt']
                    },
                    tableFilter: [
                        {
                            ...tableFilter[0],
                            ...parseTableMetaBI_API(res[0].meta)
                        },
                        {
                            ...tableFilter[1],
                            ...parseTableMetaBI_API(res[1].meta)
                        },
                        {
                            ...tableFilter[2],
                            ...parseTableMetaBI_API(res[2].meta)
                        }
                    ]
                };
            })
        );
    }

    debtManager(
        instanceId,
        filter,
        timeFilter,
        tableFilter = null,
        firstTableFilter,
        secondTableFilter,
        commonTableFilter
    ): Observable<any> {
        const { issue_status, write_off_status, ...f } = filter;
        if (f.application && Array.isArray(f.application)) {
            f.application = f.application.map(el => {
                if (el.includes('&')) {
                    return el.replace(/&/g, '%26');
                } else {
                    return el;
                }
            });
        }
        if (
            firstTableFilter === 'filter[issue_status]=OPEN' &&
            write_off_status
        ) {
            f['write_off_status'] = write_off_status;
        }
        const queryParams = this.getQueryParamsFromFilter(
            f,
            '',
            'debt-manager'
        );

        return this.getDebtManagerData(
            instanceId,
            tableFilter,
            queryParams,
            timeFilter,
            firstTableFilter,
            secondTableFilter,
            commonTableFilter
        );
    }

    getQueryParamsFromFilter(filter, exclude, context): string {
        const keys = Object.keys(filter);
        return keys
            .reduce((result, key) => {
                if (key !== exclude && filter[key] && filter[key].length > 0) {
                    if (
                        context === 'write-off' ||
                        context === 'debt-manager' ||
                        context === 'code-duplication'
                    ) {
                        if (key === 'write_off_expiration_date') {
                            result.push(
                                `filter[expiration_date_gte]=${this.formatDateFilter(
                                    filter[key][0].begin
                                )}`
                            );
                            result.push(
                                `filter[expiration_date_lte]=${this.formatDateFilter(
                                    filter[key][0].end
                                )}`
                            );
                        } else if (
                            !filter[key].every(f => f.trim && f.trim() === '')
                        )
                            result.push(
                                `filter[${this.getFilterFromKey(
                                    key,
                                    context
                                )}]=${filter[key].join(',')}`
                            );
                    } else
                        filter[key].map(val => {
                            result.push(
                                `${this.getFilterFromKey(key, context)}=${val}`
                            );
                        });
                }
                return result;
            }, [])
            .join('&');
    }

    getFilterFromKey(key: string, context: string): string {
        const contextKey = context ? `${context}_${key}` : key;
        switch (contextKey) {
            case 'write-off_severity':
            case 'debt-manager_severity':
                return 'severity_id';
            case 'debt-manager_reason':
                return 'reason_name_id';
            case 'debt-manager_area':
                return 'area_id';
            case 'write-off_issue_type':
            case 'debt-manager_issue_type':
                return 'best_practice_name_id';
            case 'severity':
                return 'severityId';
            case 'areas':
                return 'areaId';
            case 'namespaces':
                return 'namespace';
            case 'best-practices':
                return 'bestPracticeId';
            case 'cetypes':
                return 'ceTypeId';
            case 'debt-manager_tag':
                return 'debt_manager_tag_id';
            default:
                return key;
        }
    }

    updateSetApplicationsFilter(
        scanId: string,
        filter: any = {}
    ): Observable<any> {
        const url =
            environment.apiEndPoint +
            'widgets/v1/update-set-applications/' +
            scanId +
            '?' +
            (filter.isOotb !== undefined ? '&isOotb=' + filter.isOotb : '') +
            (filter.cesWithIssues === true
                ? '&cesWithIssues=' + filter.cesWithIssues
                : '') +
            (filter.developer && filter.developer.length > 0
                ? '&' +
                  filter.developer.map(val => `updatedBy=${val}`).join('&')
                : '');
        return this.http.get(url);
    }

    updateSetUpdatedByFilter(
        scanId: string,
        filter: any = {}
    ): Observable<any> {
        const url =
            environment.apiEndPoint +
            'widgets/v1/update-set-developers/' +
            scanId +
            '?' +
            (filter.isOotb !== undefined ? '&isOotb=' + filter.isOotb : '') +
            (filter.cesWithIssues === true
                ? '&cesWithIssues=' + filter.cesWithIssues
                : '') +
            (filter.namespace && filter.namespace.length > 0
                ? '&' +
                  filter.namespace.map(val => `application=${val}`).join('&')
                : '');
        return this.http.get(url);
    }

    RMApplUpdatedByFilter(scanId: string, filter: any = {}): Observable<any> {
        const url =
            environment.apiEndPoint +
            'widgets/v1/application-inventory-developers/' +
            scanId +
            '?' +
            (filter.isOotb !== undefined ? '&isOotb=' + filter.isOotb : '') +
            (filter.cesWithIssues === true
                ? '&cesWithIssues=' + filter.cesWithIssues
                : '') +
            (filter.namespace && filter.namespace.length > 0
                ? '&' +
                  filter.namespace.map(val => `application=${val}`).join('&')
                : '');
        return this.http.get(url);
    }

    featureBranchUpdatedByFilter(
        scanId: string,
        filter: any = {}
    ): Observable<any> {
        const url =
            environment.apiEndPoint +
            'widgets/v1/feature-branch-inventory-developers/' +
            scanId +
            '?' +
            (filter.cesWithIssues === true
                ? '&cesWithIssues=' + filter.cesWithIssues
                : '');
        return this.http.get(url);
    }

    loadModal(
        params: {
            key: string;
        },
        state: {
            instanceId: number;
            serviceId?: number;
            scanId?: number;
            scanLongId?: string;
            filter?: any;
            tableFilter?: any;
            timeFilter?: any;
        }
    ) {
        const { filter, tableFilter } = state;
        switch (params.key) {
            case 'fb-lw-iss':
                return this.load(
                    'feature-branch-issues',
                    filter.featureBranchScanId,
                    {
                        affectedElementName: filter.ceName,
                        ceType: filter.ceType
                    },
                    tableFilter
                ).pipe(
                    map((data: any) => {
                        return {
                            data: data.data[0].data,
                            pagination: parseTableMetaBI_API(data.meta)
                        };
                    })
                );
            case 'rm-lw-iss':
                return this.load(
                    'update-set-issues',
                    filter.updateSetScanId,
                    {
                        affectedElementSysId: filter.sysId
                    },
                    tableFilter
                ).pipe(
                    map((data: any) => {
                        return {
                            data: data.data[0].data,
                            pagination: parseTableMetaBI_API(data.meta)
                        };
                    })
                );
            case 'rm-appl-lw-iss':
                return this.load(
                    'application-scan-issues',
                    filter.RMApplicationsScanId,
                    {
                        affectedElementSysid: filter.sysId
                    },
                    tableFilter
                ).pipe(
                    map((data: any) => {
                        return {
                            data: data.data[0].data,
                            pagination: parseTableMetaBI_API(data.meta)
                        };
                    })
                );
            case 'ov-usage-lw-iss':
                const UsagetableParams = generateTableParams_API_BI(
                    tableFilter
                );
                const Usageurl = `${environment.apiDevEndPoint}widgets/v1/live-check-issues-history-by-scan/${filter.scanLongId}?${UsagetableParams}`;
                return this.http.get(Usageurl).pipe(
                    map((data: any) => {
                        const meta = data.meta;
                        return {
                            data: data.data[0].data.map(res => {
                                return {
                                    ...res
                                };
                            }),
                            meta: parseTableMetaBI_API(meta)
                        };
                    })
                );
            default:
                return;
        }
    }

    CEsConflicted(
        scanId,
        filter,
        isCodeChange,
        tableFilter,
        includeTablesAndDict
    ): Observable<any> {
        const url =
            `${environment.apiEndPoint}api/v2/ootb-affected-by-upgrade?filter[scan_id]=${scanId}` +
            `&filter[include_tables_and_dict]=${includeTablesAndDict}&filter[to_version]=${filter.version}&filter[is_code_change]=${isCodeChange}`;
        if (tableFilter) {
            return this.http.get(url + generateTableParams(tableFilter));
        }
        return this.http.get(url);
    }

    ootbElementSourceCode(ceId: number): Observable<any> {
        const url = `${environment.apiEndPoint}api/v2/ootb-element-source-code/${ceId}`;
        return this.http.get(url);
    }

    ootbElementOriginalSourceCode(
        sysId: number,
        instanceId: number
    ): Observable<any> {
        const url = `${environment.apiEndPoint}api/v1/snow/ootb/common/source-code?instanceUrlId=${instanceId}&updateVersionSysId=${sysId}`;
        return this.http.get(url, {
            headers: { 'Content-Type': 'application/json' }
        });
    }

    ceDependencyMap(
        ceId: number,
        filter: any,
        tableFilter: any
    ): Observable<any> {
        const filtersParams = generateOldParams(filter);
        const tableParams = generateTableParams(tableFilter);
        const url =
            `${environment.apiEndPoint}api/v2/ce-dependency-map?filter[ootb_affected_ce_id]=${ceId}` +
            `?${filtersParams}${tableParams}`;
        return this.http.get(url);
    }

    catalogDetailTable(
        scanId: number,
        catalogName: string,
        tableFilter: any
    ): Observable<any> {
        return this.load(
            'catalog-items-breakdown-details',
            scanId,
            { catalogName },
            tableFilter
        ).pipe(
            map((data: any) => {
                return {
                    data: data.data[0].data,
                    pagination: parseTableMetaBI_API(data.meta)
                };
            })
        );
    }

    catalogNumberOfCatalogs(scanId: number, tableFilter: any): Observable<any> {
        return forkJoin([
            this.load('catalogs', scanId),
            this.load('catalog-items-breakdown', scanId, undefined, tableFilter)
        ]).pipe(
            map((data: any) => {
                return {
                    data: data[1].data[0].data,
                    chart: data[0].data,
                    tableFilter: parseTableMetaBI_API(data[1].meta)
                };
            })
        );
    }

    catalogItems(scanId: number, tableFilter: any): Observable<any> {
        return forkJoin([
            this.load('catalog-items', scanId),
            this.load('catalog-items-designer', scanId), //
            this.load('catalog-items-by-type', scanId, undefined, tableFilter),
            this.catalogItemsRestApi(scanId, tableFilter)
        ]).pipe(
            map((data: any) => {
                return {
                    chart0: data[0].data,
                    chart1: data[1].data,
                    chart2: data[2].data,
                    data: data[3].data.map(({ id, attributes }) => ({
                        id,
                        ...attributes
                    })),
                    tableFilter: parseTableMeta(data[3].meta)
                };
            })
        );
    }

    catalogItemsRestApi(scanId: number, tableFilter: any): Observable<any> {
        const tableParams = generateTableParams(tableFilter);
        const url = `${environment.apiEndPoint}api/v2/catalog-item?filter[scan_id]=${scanId}${tableParams}`;
        return this.http.get(url);
    }

    catalogCategories(scanId: number, tableFilter: any): Observable<any> {
        return forkJoin([
            this.load('catalog-categories', scanId),
            this.getCatalogCategoryTable(scanId, tableFilter)
        ]).pipe(
            map((data: any) => {
                return {
                    data: data[1].data.map(({ id, attributes }) => ({
                        id,
                        ...attributes
                    })),
                    chart: data[0].data,
                    tableFilter: parseTableMeta(data[1].meta)
                };
            })
        );
    }

    getCatalogCategoryTable(scanId, tableFilter): Observable<any> {
        const tableParams = generateTableParams(tableFilter);
        const url = `${environment.apiEndPoint}api/v2/catalog-category?filter[scan_id]=${scanId}${tableParams}`;
        return this.http.get(url);
    }

    integrationsData(
        scanId: number,
        scanLongId: string,
        tableFilter: any
    ): Observable<any> {
        return forkJoin([
            this.load('integrations-by-created-on', scanId),
            this.configurationElementsTable(
                scanLongId,
                'REST Message,Integration Plugin,SOAP Message',
                tableFilter,
                undefined,
                undefined
            )
        ]).pipe(
            map((data: any[]) => {
                return {
                    data: data[0].data,
                    table: data[1].data.map(({ id, attributes }) => ({
                        id,
                        ...attributes
                    })),
                    tableFilter: parseTableMeta(data[1].meta)
                };
            })
        );
    }

    configurationElementsTable(
        scanId: any,
        ceType: string,
        tableFilter: any,
        active: any,
        cmnamespaces: any[],
        onlyDiff?: any,
        scanToCompare?: any,
        includeWritteOffs?: boolean
    ): Observable<any> {
        const tableParams = generateTableParams(tableFilter);
        let url = `${environment.apiEndPoint}api/v2/auditable-element?filter[scan_uuid]=${scanId}&filter[ce_type]=${ceType}${tableParams}`;
        if (active !== undefined) url = url + `&filter[active]=${active}`;
        if (includeWritteOffs !== undefined)
            url = url + `&filter[include_write_offs]=${includeWritteOffs}`;
        if (
            cmnamespaces !== undefined &&
            cmnamespaces !== null &&
            cmnamespaces.length > 0
        )
            url = url + `&filter[namespace]=${cmnamespaces.join(',')}`;
        if (onlyDiff)
            url =
                url +
                `&filter[show_differences]=${onlyDiff}&filter[compare_scan_uuid]=${scanToCompare}`;
        return this.http.get(url);
    }

    secondaryInfo(
        serviceId: number,
        scanId: number,
        filter: any
    ): Observable<any> {
        if (serviceId === 1) {
            const f = { ...filter };
            if (f.hasOwnProperty('active')) {
                f['activeFilter'] = f['active'];
                delete f['active'];
            }
            return this.load('code-monitor-snow', scanId, f);
        } else return this.load('code-monitor-sf', scanId, filter);
    }

    livecheckIssuesTable(filter: any, tableFilter = null): Observable<any> {
        const tableParams = generateTableParams_API_BI(tableFilter);
        const url = `${environment.apiDevEndPoint}widgets/v1/live-check-issues-history-by-scan/${filter.scanId}?${tableParams}`;
        return this.http.get(url);
    }

    loadLarge(
        params: {
            key: string;
        },
        state: {
            instanceId: number;
            filter: any;
            tableFilter?: any;
            serviceId?: number;
            scanId?: number;
            scanLongId?: string;
            timeFilter?: any;
            customerId?: any;
            providerId?: any;
            mainFilter?: string;
            includeBaseline?: boolean;
            scan?: any;
            includeTablesAndDict?: boolean;
        }
    ): Observable<any> {
        const {
            instanceId,
            filter,
            scanId,
            serviceId,
            scanLongId,
            tableFilter,
            timeFilter,
            customerId,
            providerId,
            includeBaseline,
            scan,
            includeTablesAndDict
        } = state;
        switch (params.key) {
            case 'gov-sf':
                return this.governanceSummary(
                    customerId,
                    tableFilter,
                    2,
                    filter
                );
            case 'gov-sn':
                return this.governanceSummary(
                    customerId,
                    tableFilter,
                    1,
                    filter
                );
            case 'gov-sf-sm':
                return this.load('usage-compact-sf', instanceId);
            case 'gov-sn-sm':
                return this.load('usage-compact-sf', instanceId);
            case 'alerts-compact':
                return this.load('alerts-compact', filter.opScan);

            // SMALL WIDGETS QUALITY IN USE - USAGE TAB

            case 'us-ua-sm':
                return this.apiLoad('user-adoption-compact', instanceId);
            case 'us-li-sm':
                return this.apiLoad('licenses-compact', instanceId);
            case 'us-gls-sm':
                return this.apiLoad(
                    'governor-limit-storage-compact',
                    instanceId
                );
            case 'us-glac-sm':
                return this.apiLoad('governor-limit-api-compact', instanceId);

            // LARGE WIDGETS QULITY IN USE - USAGE

            case 'us-ua-lg':
                return this.apiLoad('user-adoption', instanceId);
            case 'us-li-lg':
                return this.apiLoad('licenses', instanceId);
            case 'us-gls-lg':
                return this.apiLoad('governor-limit-storage', instanceId);
            case 'us-glac-lg':
                return this.apiLoad('governor-limit-api', instanceId);

            // LIVECHECK

            case 'ov-usage':
                return this.livecheckUsage(
                    serviceId,
                    instanceId,
                    scanId,
                    scanLongId,
                    filter,
                    tableFilter,
                    timeFilter
                );
            case 'ov-ed':
                return this.earlyDetection(
                    serviceId,
                    instanceId,
                    scanId,
                    scanLongId,
                    filter,
                    tableFilter,
                    timeFilter
                );

            // UPGRADEABILITY

            case 'up-ootb':
                return this.outOfTheBox(
                    serviceId,
                    instanceId,
                    scanId,
                    scanLongId,
                    filter,
                    tableFilter,
                    includeTablesAndDict
                );
            case 'up-ceu':
                return this.ceAffectedByUpgrade(
                    serviceId,
                    instanceId,
                    scanId,
                    filter,
                    tableFilter,
                    includeTablesAndDict
                );
            case 'up-ootb-inventory':
                return this.ootbInventory(scan, filter);

            case 'ia-ia':
                return this.impactAnalysis(instanceId, filter, timeFilter);

            case 'up-ootb-inventory-node':
                return this.ootbInventoryNode(filter);

            case 'ia-ia-node':
                return this.impactAnalysisNode(instanceId, filter, timeFilter);

            // ASSET MANAGER

            case 'am-am':
                const AMactiveFilter = { ...filter };
                if (
                    (serviceId !== 1 &&
                        AMactiveFilter.hasOwnProperty('active')) ||
                    (AMactiveFilter.hasOwnProperty('active') &&
                        AMactiveFilter['active'] === undefined)
                ) {
                    delete AMactiveFilter['active'];
                }
                return forkJoin([
                    this.load('code-monitor-ces-by-type', scanId, {
                        ...AMactiveFilter,
                        providerId
                    }),
                    this.secondaryInfo(
                        serviceId,
                        scanId,
                        AMactiveFilter['active'] !== undefined
                            ? { active: AMactiveFilter['active'] }
                            : {}
                    )
                ]).pipe(
                    map(data => {
                        return {
                            table:
                                data[0] && data[0].data.length === 1
                                    ? data[0].data[0].data
                                    : [],
                            secondaryInfo: data[1]
                        };
                    })
                );

            // PROFILING

            case 'p-apps':
                return this.profilingApplications(
                    serviceId,
                    instanceId,
                    scanId,
                    scanLongId,
                    filter,
                    tableFilter
                );
            case 'p-ct':
                return this.customTables(serviceId, instanceId, scanId);
            case 'p-iss':
                return this.profilingIssues(
                    serviceId,
                    instanceId,
                    scanId,
                    scanLongId,
                    filter,
                    tableFilter
                );
            case 'p-locs':
                return this.profilingLOCS(
                    serviceId,
                    instanceId,
                    scanId,
                    scanLongId,
                    filter
                );

            case 'p-nlocs':
                return this.profilingNLOCS(instanceId, filter);
            case 'p_nce':
                return this.newConfigurationElements(instanceId, filter);
            case 'p-warn':
                return this.profilingWarnings(scanId, filter);
            case 'p-cctir':
                return this.profilingCCTIR(instanceId);

            // MOST CONFIGURABLE AREAS

            case 'mca':
                return this.mostConfiguredAreas(scanId);

            // USER ADOPTION

            case 'ua-au':
                return this.activeUsersData(instanceId, timeFilter);
            case 'ua-tl':
                return this.userTotalLoginsData(instanceId, timeFilter);
            case 'uasf-au':
                return this.activeUsersSFData(instanceId, timeFilter);
            case 'uasf-tl':
                return this.totalLoginsSFData(instanceId, timeFilter);

            // CATALOG

            case 'c-noc':
                return this.catalogNumberOfCatalogs(scanId, tableFilter);
            case 'c-cc':
                return this.catalogCategories(scanId, tableFilter);
            case 'c-ci':
                return this.catalogItems(scanId, tableFilter);
            case 'c-cv':
                return this.catalogVariables(scanId, tableFilter);
            case 'c-issues':
                return this.catalogIssuesAndWarnings(
                    scanLongId,
                    filter,
                    tableFilter
                );

            // LICENSING

            case 'li-li':
                return this.licensingLicenses(instanceId, timeFilter);
            case 'li-gls':
                return this.licensingGLS(instanceId, timeFilter);
            case 'li-glac':
                return this.licensingGLAC(instanceId, filter, timeFilter);

            // CROSS-ORG LICENSING
            case 'col-tl':
                return this.crossOrglicensing(filter, tableFilter);
            // PERFORMANCE
            case 'pf-cst':
                return this.performanceCSTData(scanId, filter, tableFilter);
            case 'pf-cst-filtered':
                return this.performanceCSTDataFiltered(scanId, filter);
            case 'pf-sst':
                return this.performanceSSTData(scanId, filter, tableFilter);
            case 'pf-pi':
                return this.performancePIData(
                    serviceId,
                    scanId,
                    scanLongId,
                    filter,
                    tableFilter
                );

            // CUSTOMIZATION VS CONFIGURATION

            case 'cvsc-cr':
                return this.customizarionVsConfiguration(
                    scanId,
                    scanLongId,
                    filter,
                    tableFilter,
                    serviceId
                );

            // INTEGRATIONS

            case 'int':
                return this.integrationsData(scanId, scanLongId, tableFilter);

            // ALERTS
            case 'a-ai':
                return this.alertsData(instanceId, timeFilter, tableFilter);
            case 'a-at':
                return this.alertsTriggeredData(
                    instanceId,
                    timeFilter,
                    tableFilter
                );

            // DUPLICATED CODE
            case 'dc-dc':
                return this.duplicatedCode(instanceId, scanId, tableFilter);

            // RELEASE MANAGEMENT
            case 'rm-lw':
                return this.releaseManagement(
                    instanceId,
                    filter,
                    tableFilter,
                    timeFilter
                );

            case 'rm-appl-lw':
                return this.releaseManagementApps(
                    instanceId,
                    filter,
                    tableFilter,
                    timeFilter
                );

            case 'wo-lw':
                return this.writeOff(
                    instanceId,
                    filter,
                    timeFilter,
                    tableFilter
                );

            case 'fb-lw':
                return this.featureBranch(
                    instanceId,
                    filter,
                    tableFilter,
                    timeFilter
                );

            case 'dm-lw':
                return this.debtManager(
                    instanceId,
                    filter,
                    timeFilter,
                    tableFilter,
                    'filter[issue_status]=OPEN',
                    undefined,
                    undefined
                );
            case 'dmcwo-lw':
                return this.debtManager(
                    instanceId,
                    filter,
                    timeFilter,
                    tableFilter,
                    'filter[write_off_status]=REQUESTED',
                    'filter[write_off_status]=APPROVED',
                    'filter[write_off_status]=REQUESTED,APPROVED'
                );
            case 'dmcf-lw':
                return this.debtManager(
                    instanceId,
                    filter,
                    timeFilter,
                    tableFilter,
                    'filter[issue_status]=CLOSED',
                    undefined,
                    undefined
                );
        }
    }

    releaseManagement(
        instanceId,
        filter,
        tableFilter,
        timeFilter
    ): Observable<any> {
        return forkJoin([
            this.load(
                'scanned-update-sets',
                instanceId,
                filter,
                tableFilter,
                timeFilter
            )
        ]).pipe(
            map(data => ({
                table: data[0].data[0].data.map(updateSet => {
                    updateSet.peerReview = updateSet.peerReview || 'PENDING';
                    const d = updateSet.updatesetCreationDate
                        ? new Date(updateSet.updatesetCreationDate)
                        : new Date();
                    updateSet.age = updateSet.age
                        ? updateSet.age + ' days'
                        : Math.floor(
                              (Date.now() - d.getTime()) / (1000 * 3600 * 24)
                          ) + ' days';
                    updateSet.source =
                        updateSet.isFromApp === true ? 'app' : 'portal';
                    return updateSet;
                }),
                tableFilter: parseTableMetaBI_API(data[0].meta)
            }))
        );
    }

    releaseManagementApps(
        instanceId,
        filter,
        tableFilter,
        timeFilter
    ): Observable<any> {
        return forkJoin([
            this.load(
                'application-scans',
                instanceId,
                filter,
                tableFilter,
                timeFilter
            )
        ]).pipe(
            map(data => ({
                table: data[0].data[0].data.map(updateSet => {
                    updateSet.peerReview = updateSet.peerReview || 'PENDING';
                    const d = updateSet.updatesetCreationDate
                        ? new Date(updateSet.updatesetCreationDate)
                        : new Date();
                    updateSet.age = updateSet.age
                        ? updateSet.age + ' days'
                        : Math.floor(
                              (Date.now() - d.getTime()) / (1000 * 3600 * 24)
                          ) + ' days';
                    return updateSet;
                }),
                tableFilter: parseTableMetaBI_API(data[0].meta)
            }))
        );
    }

    featureBranch(
        instanceId,
        filter,
        tableFilter,
        timeFilter
    ): Observable<any> {
        return forkJoin([
            this.load(
                'feature-branch-scans',
                instanceId,
                filter,
                tableFilter,
                timeFilter
            )
        ]).pipe(
            map(data => ({
                table: data[0].data[0].data.map(updateSet => {
                    updateSet.peerReview = updateSet.peerReview || 'PENDING';
                    const d = updateSet.updatesetCreationDate
                        ? new Date(updateSet.updatesetCreationDate)
                        : new Date();
                    updateSet.age = updateSet.age
                        ? updateSet.age + ' days'
                        : Math.floor(
                              (Date.now() - d.getTime()) / (1000 * 3600 * 24)
                          ) + ' days';
                    updateSet.source =
                        updateSet.source === '' ||
                        updateSet.source === 'N/A' ||
                        updateSet.source === undefined ||
                        updateSet.source === null
                            ? 'portal'
                            : updateSet.source;
                    return updateSet;
                }),
                tableFilter: parseTableMetaBI_API(data[0].meta)
            }))
        );
    }

    activeUsersSFData(instanceId: number, timeFilters: any): Observable<any> {
        return forkJoin([
            this.load(
                'user-adoption-compact',
                instanceId,
                undefined,
                undefined,
                timeFilters
            )
        ]).pipe(
            map((data: any[]) => ({
                trend0: data[0].trend as ChartData[]
            }))
        );
    }

    totalLoginsSFData(instanceId: number, timeFilters: any): Observable<any> {
        return forkJoin([
            this.load(
                'logins-over-time',
                instanceId,
                undefined,
                undefined,
                timeFilters
            ),
            this.load(
                'logins-by-dimension-over-time',
                instanceId,
                { dimension: 'Department' },
                undefined,
                timeFilters
            ),
            this.load(
                'logins-by-dimension-over-time',
                instanceId,
                { dimension: 'Type' },
                undefined,
                timeFilters
            )
        ]).pipe(
            map((data: any[]) => ({
                trend0: data[0] as ChartData[],
                trend1: data[1].data as ChartData[],
                trend2: data[2].data as ChartData[]
            }))
        );
    }

    duplicatedCodeDetail(scanId, filter) {
        let filterCopy = { ...filter, scanId };

        if (filterCopy && filterCopy['blockId'] !== undefined) {
            filterCopy['duplicate_block_id'] = filterCopy['blockId'];
            delete filterCopy['blockId'];
        }

        filterCopy = generateOldParams(filterCopy);

        const url = `${environment.apiEndPoint}api/v2/duplicate-code?${filterCopy}`;
        return this.http.get(url);
    }

    alertsTriggeredData(
        instanceId: number,
        timeFilters: any,
        tableFilter: any
    ): Observable<any> {
        return forkJoin([
            this.load(
                'alerts-triggered-over-time',
                instanceId,
                undefined,
                undefined,
                timeFilters
            ),
            this.getATGridData(instanceId, tableFilter, timeFilters),
            this.getAIGridData(instanceId, undefined)
        ]).pipe(
            map((data: any[]) => ({
                trend: data[0].data,
                data: data[1].data,
                tableFilter: data[1].tableFilter,
                alertsList: data[2].data
            }))
        );
    }

    getATGridData(
        instanceId: number,
        tableFilter: any,
        timeFilters: any
    ): Observable<any> {
        const tableParams = tableFilter ? generateTableParams(tableFilter) : '';
        const url =
            `${environment.apiEndPoint}api/v2/alert-event` +
            `?filter[created_on_from]=${timeFilters.begin}&filter[created_on_to]=${timeFilters.end}` +
            `&filter[instance_id]=${instanceId}${tableParams}`;
        return this.http.get(url).pipe(
            map((data: any) => ({
                data: data.data.map(({ id, attributes }) => ({
                    id,
                    ...attributes.extra,
                    ...attributes
                })),
                tableFilter: parseTableMeta(data.meta)
            }))
        );
    }

    alertsData(
        instanceId: number,
        timeFilters: any,
        tableFilter: any
    ): Observable<any> {
        return forkJoin([
            this.load(
                'alerts-implemented-over-time',
                instanceId,
                undefined,
                undefined,
                timeFilters
            ),
            this.getAIGridData(instanceId, tableFilter)
        ]).pipe(
            map((data: any[]) => ({
                trend: data[0].data,
                data: data[1].data,
                tableFilter: data[1].tableFilter
            }))
        );
    }

    getAIGridData(instanceId: number, tableFilter: any): Observable<any> {
        const tableParams = tableFilter ? generateTableParams(tableFilter) : '';
        const url = `${environment.apiEndPoint}api/v2/alert?filter[instance_id]=${instanceId}${tableParams}`;
        return this.http.get(url).pipe(
            map((data: any) => ({
                data: data.data.map(({ id, attributes }) => ({
                    id,
                    ...attributes.extra,
                    ...attributes
                })),
                tableFilter: parseTableMeta(data.meta)
            }))
        );
    }

    performanceCSTData(
        scanId: number,
        filter: any,
        tableFilter: any
    ): Observable<any> {
        return forkJoin([
            this.load('slow-transactions', scanId, {
                txSlider: 5,
                type: 'client',
                ...filter
            }),
            this.load('browsers-used-client-tx', scanId, filter),
            this.load('tx-global-ratio', scanId),
            this.load('tx-response-time-by-type', scanId),
            this.load('tx-affected-users', scanId),
            this.getSlowTxList(scanId, tableFilter)
        ]).pipe(
            map((data: any) => {
                data[0].data.push(this.calculateTrendLine(data[0].data[0]));
                return {
                    trend: data[0].data,
                    chart0: data[1].data,
                    chart1: data[2].data,
                    chart2: data[3].data,
                    chart3: data[4].data,
                    table: data[5].data,
                    tableFilter: data[5].tableFilter
                };
            })
        );
    }

    performanceCSTDataFiltered(scanId: number, filter: any): Observable<any> {
        return forkJoin([
            this.load('browsers-used-client-tx', scanId, filter)
        ]).pipe(
            map((data: any) => {
                return {
                    chart0: data[0].data
                };
            })
        );
    }

    calculateTrendLine(chart) {
        let a, b, c, d, e, slope, yIntercept;
        let xSum = 0,
            ySum = 0,
            xySum = 0,
            xSquare = 0;
        const dpsLength = chart.data.length;

        for (let i = 0; i < dpsLength; i++) {
            const date = new Date(Date.parse(chart.data[i].label));
            const zuludate = new Date(
                date.getTime() + date.getTimezoneOffset() * 60000
            );

            xySum += zuludate.getTime() * chart.data[i].value;
            xSum += zuludate.getTime();
            ySum += chart.data[i].value;
            xSquare += Math.pow(zuludate.getTime(), 2);
        }

        a = xySum * dpsLength;
        b = xSum * ySum;
        c = dpsLength * xSquare;

        d = Math.pow(xSum, 2);
        slope = (a - b) / (c - d);
        e = slope * xSum;
        yIntercept = (ySum - e) / dpsLength;

        const data = [];

        for (let i = 0; i < dpsLength; i++) {
            const date = new Date(Date.parse(chart.data[i].label));
            const zuludate = new Date(
                date.getTime() + date.getTimezoneOffset() * 60000
            );

            const point = this.getTrendLinePoint(
                0,
                chart,
                zuludate.getTime(),
                slope,
                yIntercept
            );

            data.push(point);
        }

        return {
            data: data,
            name: 'Trend over time'
        };
    }

    getTrendLinePoint(index, chart, x, slope, intercept) {
        return {
            label: chart.data[index].label,
            value: slope * x + intercept
        };
    }

    getSlowTxList(
        scanId: number,
        tableFilter: any,
        client = true
    ): Observable<any> {
        const tableParams = generateTableParams(tableFilter);
        const url = `${environment.apiEndPoint}api/v2/transaction?filter[scan_id]=${scanId}&filter[client_transaction]=${client}${tableParams}`;
        return this.http.get(url).pipe(
            map((data: any) => ({
                data: data.data.map(({ id, attributes }) => ({
                    id,
                    ...attributes
                })),
                tableFilter: parseTableMeta(data.meta)
            }))
        );
    }

    performanceSSTData(
        scanId: number,
        filter: any,
        tableFilter: any
    ): Observable<any> {
        return forkJoin([
            this.load('slow-transactions', scanId, {
                txSlider: 5,
                type: 'server',
                ...filter
            }),
            this.load('tx-response-time-by-type', scanId, {
                clientServerType: 'server'
            }),
            this.getSlowTxList(scanId, tableFilter, false)
        ]).pipe(
            map((data: any) => {
                data[0].data.push(this.calculateTrendLine(data[0].data[0]));
                return {
                    trend: data[0].data,
                    pie: data[1].data,
                    table: data[2].data,
                    tableFilter: data[2].tableFilter
                };
            })
        );
    }

    bulkChangeOwner(
        ids,
        owner,
        allSelected,
        filters,
        instanceId
    ): Observable<any> {
        const data = {
            instanceId,
            owner
        };
        const url = `${environment.apiEndPoint}api/v2/bulk-owner`;
        return this.manageBulkCall({
            allSelected,
            data,
            ids,
            filters,
            url
        });
    }

    customizarionVsConfiguration(
        scanId: number,
        scanLongId: string,
        filter: any,
        tableFilter: any,
        serviceId: number
    ): Observable<any> {
        const { createdOnRange, updatedOnRange, ...restFilter } = filter;
        if (createdOnRange) {
            restFilter['created_on_from'] = getYYYYMMDDfromDate(
                createdOnRange.begin
            );
            restFilter['created_on_to'] = getYYYYMMDDfromDate(
                createdOnRange.end
            );
        }
        if (updatedOnRange) {
            restFilter['updated_on_from'] = getYYYYMMDDfromDate(
                updatedOnRange.begin
            );
            restFilter['updated_on_to'] = getYYYYMMDDfromDate(
                updatedOnRange.end
            );
        }
        restFilter['onlyChanges'] = true;
        return forkJoin([
            this.load('elements-by-change-type', scanId),
            this.load('lines-code-by-change-type', scanId),
            this.auditableElementsTable(
                scanLongId,
                restFilter,
                tableFilter,
                undefined
            )
        ]).pipe(
            map((data: any) => {
                const chart1 = data[1].data.map(item =>
                    this.addExtraInfo(item, serviceId)
                );
                chart1[0].data = chart1[0].data.filter(item => {
                    if (+serviceId === 2) return item.label !== 'CONFIGURATION';
                    else return true;
                });
                return {
                    chart0: data[0].data.map(item =>
                        this.addExtraInfo(item, serviceId)
                    ),
                    chart1,
                    data: data[2].data.map(({ id, attributes }) => ({
                        id,
                        ...attributes
                    })),
                    tableFilter: parseTableMeta(data[2].meta)
                };
            })
        );
    }

    licensingGLS(instanceId: number, timeFilters: any): Observable<any> {
        return forkJoin([
            this.load(
                'governor-limit-storage',
                instanceId,
                { type: 'file' },
                undefined,
                timeFilters
            ),
            this.load(
                'governor-limit-storage',
                instanceId,
                { type: 'data' },
                undefined,
                timeFilters
            ),
            this.load(
                'governor-limit-storage-department',
                instanceId,
                undefined,
                undefined,
                timeFilters
            )
        ]).pipe(
            map((data: any[]) => ({
                trend1: data[0] as ChartData[],
                trend2: data[1] as ChartData[],
                bar: data[2] as ChartData[]
            }))
        );
    }

    crossOrglicensing(filter, tableFilter) {
        const copyFilter = { ...filter };
        if (!copyFilter['see_aggregated']) {
            // Remove License and org filters if the toggle is not selected in order to load data for the bar chart breakdowns
        }
        return forkJoin([
            this.load(
                'licenses-cross-org',
                null,
                { ...filter, providerId: 2 },
                undefined,
                undefined
            ),
            this.load(
                'total-licenses-per-org',
                null,
                { ...filter, providerId: 2 },
                undefined,
                undefined
            ),
            this.load(
                'total-licenses-per-org-table',
                null,
                { ...filter, providerId: 2 },
                tableFilter,
                undefined
            )
        ]).pipe(
            map((data: any[]) => {
                return {
                    chart0: data[copyFilter['see_aggregated'] ? 0 : 1].data.map(
                        serie => {
                            return {
                                name: serie.name,
                                data: serie.data.map(item => ({
                                    label: item.label,
                                    value: item.value === 0 ? 0.1 : item.value
                                }))
                            };
                        }
                    ),
                    data: data[2].data[0].data,
                    tableFilter: parseTableMetaBI_API(data[2].meta)
                };
            })
        );
    }

    licensingGLAC(
        instanceId: number,
        filter: any,
        timeFilters: any
    ): Observable<any> {
        if (filter && filter['api_calls_available']) {
            return forkJoin([
                this.load(
                    'governor-limit-api',
                    instanceId,
                    undefined,
                    undefined,
                    timeFilters
                )
            ]).pipe(
                map((data: any[]) => ({
                    trend: data[0] as ChartData[]
                }))
            );
        } else {
            return forkJoin([
                this.load(
                    'governor-limit-api',
                    instanceId,
                    undefined,
                    undefined,
                    timeFilters
                )
            ]).pipe(
                map((data: any[]) => ({
                    trend: [data[0][0]] as ChartData[]
                }))
            );
        }
    }

    licensingLicenses(instanceId: number, timeFilters: any): Observable<any> {
        return forkJoin([
            this.load(
                'licenses-over-time',
                instanceId,
                undefined,
                undefined,
                timeFilters
            ),
            this.load(
                'licenses',
                instanceId,
                { state: 'total' },
                undefined,
                timeFilters
            ),
            this.load(
                'licenses',
                instanceId,
                { state: 'inactive' },
                undefined,
                timeFilters
            ),
            this.load(
                'licenses',
                instanceId,
                { state: 'frozen' },
                undefined,
                timeFilters
            ),
            this.load(
                'used-licenses-over-time',
                instanceId,
                undefined,
                undefined,
                timeFilters
            )
        ]).pipe(
            map((data: any[]) => {
                const pie1 = data[2].data;
                const pie2 = data[3].data;
                if (
                    pie1.length > 0 &&
                    pie1[0].data.length > 0 &&
                    pie1[0].data.every(el => +el.value === 0)
                ) {
                    pie1[0].data = [];
                }
                if (
                    pie2.length > 0 &&
                    pie2[0].data.length > 0 &&
                    pie2[0].data.every(el => +el.value === 0)
                ) {
                    pie2[0].data = [];
                }
                return {
                    trend: data[0].data as ChartData[],
                    pie0: data[1].data as ChartData[],
                    pie1: pie1 as ChartData[],
                    pie2: pie2 as ChartData[],
                    bar: data[4].data as ChartData[]
                };
            })
        );
    }

    addExtraInfo(item: any, serviceId: number) {
        item.data = item.data.map((value: { label: string; value: any }) => {
            const newValue = { ...value };
            switch (newValue.label.trim()) {
                case 'CONFIGURATION':
                    newValue['extra'] =
                        'Changes to the behavior of the system which are less likely to introduce technical debt. ' +
                        'Any change which does not involve lines of code is considered Configuration.';
                    break;
                case 'CUSTOMISATION NOT OOTB':
                    newValue.label =
                        serviceId === 1
                            ? 'CUSTOMIZATION - EXCLUDING OOTB MODIFICATIONS'
                            : 'CUSTOMIZATION';
                    newValue['extra'] =
                        'Material modifications to the out of the box (OOTB) behavior of the system, ' +
                        'which have been implemented without directly modifying an OOTB element. ' +
                        'These can introduce technical debt, but do not have a direct impact on upgradeability.';
                    break;
                case 'CUSTOMISATION OOTB':
                    newValue.label =
                        serviceId === 1
                            ? 'CUSTOMIZATION - OOTB MODIFICATIONS'
                            : 'CUSTOMIZATION';
                    newValue['extra'] =
                        'Material modifications to the out of the box (OOTB) behavior of the system, ' +
                        'which have been implemented by a direct modification of an OOTB element. ' +
                        'These directly impact upgradeability.';
                    break;
                case 'EXTENSION':
                    newValue['extra'] =
                        'Extensions to the platform (purchased or developed) that have no impact on upgradeability.';
                    break;
                default:
                    console.error('missing info for type ' + newValue.label);
                    break;
            }
            return newValue;
        });
        return item;
    }

    catalogVariables(scanId, tableFilter): Observable<any> {
        const tableParams = generateTableParams(tableFilter);
        const url =
            `${environment.apiEndPoint}api/v2/duplicate-variable?` +
            `filter[scan_id]=${scanId}&${tableParams}`;
        return this.http.get(url).pipe(
            map((data: any) => ({
                data: data.data.map(({ id, attributes }) => ({
                    id,
                    ...attributes
                })),
                tableFilter: parseTableMeta(data.meta)
            }))
        );
    }

    catalogIssuesAndWarnings(scanId, filter, tableFilter): Observable<any> {
        const tableParams = generateTableParams(tableFilter);
        const filtersParams = generateOldParams(filter);
        const url =
            `${environment.apiEndPoint}api/v2/issue?filter[scan]=${scanId}` +
            `&${filtersParams}&${tableParams}`;
        return this.http.get(url).pipe(
            map((data: any) => ({
                data: data.data.map(({ id, attributes }) => ({
                    id,
                    ...attributes
                })),
                tableFilter: parseTableMeta(data.meta)
            }))
        );
    }

    activeUsersData(
        instanceId: number,
        timeFilters: any
    ): Observable<IActiveUsersLarge> {
        return forkJoin([
            this.load(
                'active-users-per-role',
                instanceId,
                undefined,
                undefined,
                timeFilters
            ),
            this.load(
                'active-users-over-time',
                instanceId,
                undefined,
                undefined,
                timeFilters
            )
        ]).pipe(
            map((data: any[]) => ({
                data: data[0].data as ChartData[],
                trend: data[1].data as ChartData[]
            }))
        );
    }

    userTotalLoginsData(
        instanceId = null,
        timeFilters = null
    ): Observable<ITotalLoginsLarge> {
        return forkJoin([
            this.load(
                'logins-over-time',
                instanceId,
                undefined,
                undefined,
                timeFilters
            ),
            this.load(
                'logins-by-department-over-time',
                instanceId,
                undefined,
                undefined,
                timeFilters
            )
        ]).pipe(
            map((data: any[]) => ({
                data: data[1].data,
                trend: data[0]
            }))
        );
    }

    profilingWarnings(scanId, filter): Observable<any> {
        const copyFilter = { ...filter };
        copyFilter['severityId'] = 4;
        return forkJoin([
            this.load('issues-by-group-type', scanId, copyFilter),
            this.load('issues-by-scan', scanId, copyFilter)
        ]).pipe(
            map(data => ({
                chart0: data[0].data,
                ...data[1].data.reduce(
                    (result, value, key) => ({
                        ...result,
                        ['chart' + (key + 1)]: [this.formatValueLabels(value)]
                    }),
                    {}
                )
            }))
        );
    }

    profilingLOCS(
        serviceId: number,
        instanceId: number,
        scanId: number,
        scanLongId: string,
        filter: any
    ): Observable<any> {
        return forkJoin([this.load('lines-code', instanceId, filter)]).pipe(
            map(data => ({
                trend: data[0].data
            }))
        );
    }

    profilingIssues(
        serviceId: number,
        instanceId: number,
        scanId: number,
        scanLongId: string,
        filter: any,
        tableFilter: any
    ): Observable<any> {
        return forkJoin([
            this.load('issues-by-group-type', scanId, filter),
            this.load('issues-by-scan', scanId, filter),
            this.issuesTable(serviceId, scanLongId, filter, tableFilter)
            // PONER AQUÍ LOAD DE LA TABLA DE ISSUES
        ]).pipe(
            map(data => ({
                chart0: data[0].data,
                ...data[1].data.reduce(
                    (result, value, key) => ({
                        ...result,
                        ['chart' + (key + 1)]: [this.formatValueLabels(value)]
                    }),
                    {}
                ),
                table: data[2].data.map(({ id, attributes }) => {
                    const attr = parseAttributesWriteOffScan(attributes);
                    return {
                        id,
                        ...attr
                    };
                }),
                tableFilter: parseTableMeta(data[2].meta)
            }))
        );
    }

    newConfigurationElements(instanceId, filter: any): Observable<any> {
        return this.load('new-config-elem-over-time', instanceId, filter).pipe(
            map((data: any) => {
                return {
                    data: data.data
                };
            })
        );
    }

    profilingApplications(
        serviceId: number,
        instanceId: number,
        scanId: number,
        scanLongId: string,
        filter: any,
        tableFilter: any
    ): Observable<any> {
        return forkJoin([
            this.load('num-ce-by-namespace', scanId, filter),
            this.load('num-ce-by-namespace', scanId, filter, tableFilter)
        ]).pipe(
            map(data => ({
                data: data[0].data,
                table: data[1].data.map(res => ({
                    data: res.data.map(item => ({
                        application: item.label,
                        sumNumConfElements: item.value,
                        scanId
                    }))
                })),
                tableFilter: parseTableMetaBI_API(data[1].meta)
            }))
        );
    }

    customTables(
        serviceId: number,
        instanceId: number,
        scanId: number
    ): Observable<any> {
        return forkJoin([this.load('custom-tables-by-scope', scanId)]).pipe(
            map(data => ({
                pie: data[0].data
            }))
        );
    }

    OOTBTable(
        serviceId: number,
        instanceId: number,
        scanId: number,
        scanLongId: string,
        filter: any,
        tableFilter: any,
        includeTablesAndDict: boolean
    ): Observable<any> {
        const filtersParams = generateOldParams(filter);
        const tableParams = generateTableParams(tableFilter);
        const includeTablesAndDictNumber = includeTablesAndDict ? 1 : 0;
        const url =
            `${environment.apiEndPoint}api/v2/auditable-element?filter[is_ootb_modification]=1` +
            `&filter[scan_uuid]=${scanLongId}` +
            `&filter[include_tables_and_dict]=${includeTablesAndDictNumber}${filtersParams}&${tableParams}`;
        return this.http.get(url);
    }

    ootbInventoryNode(filter: any): Observable<any> {
        const releaseId = filter.version
            ? filter.version.toLowerCase()
            : 'paris';
        const call =
            filter.ceIdentifier && filter.ceIdentifier !== ''
                ? this.http.get(
                      environment.apiEndPoint +
                          'api/v1/snow/ootb/common/expanded-dependencies-map?' +
                          `releaseId=${releaseId}` +
                          `&ceType=${filter.ceType}&` +
                          `ceIdentifier=${filter.ceIdentifier}` +
                          `&dependentType=${filter.typeId}` +
                          `&page=${filter.pageNumber + 1}` +
                          '&size=10',
                      {
                          headers: { 'Content-Type': 'application/json' }
                      }
                  )
                : this.http.get(
                      environment.apiEndPoint +
                          'api/v1/snow/ootb/common/ootb-inventory?' +
                          `releaseId=${releaseId}` +
                          `&ceType=${filter.typeId}&` +
                          `&page=${filter.pageNumber + 1}` +
                          '&size=10',
                      {
                          headers: { 'Content-Type': 'application/json' }
                      }
                  );
        return forkJoin([call]).pipe(
            map((data: any) => {
                return {
                    node: data[0].data[0].data.map(el => {
                        return {
                            value: {
                                'Configuration Elements': el.name,
                                'Configuration Elements Total': '',
                                'Last Update': el.updated_on,
                                'Created On': el.created_on
                            },
                            sys_id: el.sys_id,
                            sourceCode: el.lines_number > 0,
                            children: []
                        };
                    }),
                    meta: parseTableMetaBI_API(data[0].meta)
                };
            })
        );
    }

    impactAnalysisNode(
        instanceId,
        filter: any,
        timeFilter: any
    ): Observable<any> {
        const call =
            filter.ceIdentifier && filter.ceIdentifier !== ''
                ? this.http.get(
                      environment.apiEndPoint +
                          'impact-analysis/v1/expanded-dependencies-map?' +
                          `instanceId=${instanceId}` +
                          (timeFilter.begin
                              ? `&originDate=${this.formatTime(
                                    timeFilter.begin
                                )}`
                              : '') +
                          (filter.ceTypeName
                              ? `&ceNameFilter=${filter.ceTypeName}`
                              : '') +
                          `&ceType=${filter.dependencyCeType}&` +
                          `ceIdentifier=${filter.ceIdentifier}` +
                          `&dependentType=${filter.typeId}` +
                          `&page=${filter.pageNumber + 1}` +
                          '&size=10',
                      {
                          headers: { 'Content-Type': 'application/json' }
                      }
                  )
                : this.http.get(
                      environment.apiEndPoint +
                          'impact-analysis/v1/inventory?' +
                          `instanceId=${instanceId}` +
                          (timeFilter.begin
                              ? `&originDate=${this.formatTime(
                                    timeFilter.begin
                                )}`
                              : '') +
                          (filter.ceTypeName
                              ? `&ceNameFilter=${filter.ceTypeName}`
                              : '') +
                          `&ceType=${filter.typeId}&` +
                          `&page=${filter.pageNumber + 1}` +
                          '&size=10',
                      {
                          headers: { 'Content-Type': 'application/json' }
                      }
                  );
        return forkJoin([call]).pipe(
            map((data: any) => {
                return {
                    node: data[0].data[0].data.map(el => {
                        return {
                            value: {
                                'Configuration Elements': el.name,
                                'Configuration Elements Total': '',
                                'Last Update': this.dateToFormat_dd_mm_yyyy_hh_ss(
                                    el.updated_on
                                ),
                                'Created On': this.dateToFormat_dd_mm_yyyy_hh_ss(
                                    el.created_on
                                )
                            },
                            sys_id: el.sys_id,
                            link: el.link,
                            children: []
                        };
                    }),
                    meta: parseTableMetaBI_API(data[0].meta)
                };
            })
        );
    }

    dateToFormat_dd_mm_yyyy_hh_ss(value: string): string {
        if (value === null || value === undefined) return '';
        const date = new Date(value);
        return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
            .getDate()
            .toString()
            .padStart(2, '0')}/${date
            .getFullYear()
            .toString()
            .padStart(4, '0')} ${date
            .getHours()
            .toString()
            .padStart(2, '0')}:${date
            .getMinutes()
            .toString()
            .padStart(2, '0')}:${date
            .getSeconds()
            .toString()
            .padStart(2, '0')}`;
    }

    ootbInventory(scan: any, filter: any): Observable<any> {
        const releaseId = scan.version ? scan.version.toLowerCase() : 'paris';
        return forkJoin([
            this.http.get(
                environment.apiEndPoint +
                    'api/v1/snow/ootb/common/dependencies-statistics?' +
                    `releaseId=${releaseId}` +
                    (filter.ceType ? `&ceType=${filter.ceType}` : '') +
                    (filter.ceIdentifier !== '' && filter.ceIdentifier
                        ? `&ceIdentifier=${filter.ceIdentifier}`
                        : ''),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            ),
            this.http.get(
                environment.apiEndPoint +
                    'api/v1/snow/ootb/common/supported-dependencies-types',
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
        ]).pipe(
            map((data: any) => {
                return {
                    tree: data[0].data.map((el, index) => {
                        return {
                            value: {
                                'Configuration Elements': el.categoryName,
                                'Configuration Elements Total': el.total,
                                'Last Update': '',
                                'Created On': ''
                            },
                            index: [index],
                            children: el.data
                                ? el.data.map((child, childIndex) => {
                                      return {
                                          value: {
                                              'Configuration Elements':
                                                  child.type +
                                                  `(${child.total})`,
                                              typeId: child.typeId,
                                              total: child.total,
                                              'Configuration Elements Total':
                                                  '',
                                              'Last Update': '',
                                              'Created On': '',
                                              value: []
                                          },
                                          index: [index, childIndex],
                                          page: 0,
                                          children: [
                                              {
                                                  value: {
                                                      'Configuration Elements':
                                                          'loading...',
                                                      'Configuration Elements Total':
                                                          '',
                                                      'Last Update': '',
                                                      'Created On': ''
                                                  },
                                                  loadingNode: true,
                                                  children: []
                                              }
                                          ]
                                      };
                                  })
                                : []
                        };
                    }),
                    dependenciesTypes: data[1].data
                };
            })
        );
    }

    impactAnalysis(
        instanceId: any,
        filter: any,
        timeFilter: any
    ): Observable<any> {
        return forkJoin([
            this.http.get(
                environment.apiEndPoint +
                    'impact-analysis/v1/dependencies-statistics?' +
                    (timeFilter.begin
                        ? `originDate=${this.formatTime(timeFilter.begin)}`
                        : '') +
                    `&instanceId=${instanceId}` +
                    (filter.dependencyCeType
                        ? `&ceType=${filter.dependencyCeType}`
                        : '') +
                    (filter.ceTypeName
                        ? `&ceNameFilter=${filter.ceTypeName}`
                        : '') +
                    (filter.ceType &&
                    Array.isArray(filter.ceType) &&
                    filter.ceType.length
                        ? `&ceTypeFilter=${filter.ceType.join(',')}`
                        : '') +
                    (filter.ceIdentifier !== '' && filter.ceIdentifier
                        ? `&ceIdentifier=${filter.ceIdentifier}`
                        : ''),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            ),
            this.http.get(
                environment.apiEndPoint +
                    'impact-analysis/v1/supported-dependencies-types',
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            ),
            this.http.get(
                environment.apiEndPoint + 'impact-analysis/v1/supported-types',
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
        ]).pipe(
            map((data: any) => {
                return {
                    tree: data[0].data.map((el, index) => {
                        return {
                            value: {
                                'Configuration Elements': el.categoryName,
                                'Configuration Elements Total': el.total,
                                'Last Update': '',
                                'Created On': ''
                            },
                            index: [index],
                            children: el.data
                                ? el.data.map((child, childIndex) => {
                                      return {
                                          value: {
                                              'Configuration Elements':
                                                  child.type +
                                                  `(${child.total})`,
                                              typeId: child.typeId,
                                              total: child.total,
                                              'Configuration Elements Total':
                                                  '',
                                              'Last Update': '',
                                              'Created On': '',
                                              value: []
                                          },
                                          index: [index, childIndex],
                                          page: 0,
                                          children: [
                                              {
                                                  value: {
                                                      'Configuration Elements':
                                                          'loading...',
                                                      'Configuration Elements Total':
                                                          '',
                                                      'Last Update': '',
                                                      'Created On': ''
                                                  },
                                                  loadingNode: true,
                                                  children: []
                                              }
                                          ]
                                      };
                                  })
                                : []
                        };
                    }),
                    dependenciesTypes: data[1].data,
                    types: data[2].data
                };
            })
        );
    }

    outOfTheBox(
        serviceId: number,
        instanceId: number,
        scanId: number,
        scanLongId: string,
        filter: any,
        tableFilter: any,
        includeTablesAndDict: boolean
    ): Observable<any> {
        const {
            active,
            namespace,
            auditUpdatedBy,
            ceTypeId,
            cetypesnamesisootb,
            auditApplication
        } = filter;
        const trendFilter = {};
        const piesFilter = {};
        const gridFilter = {};
        if (active !== undefined && active !== '') {
            trendFilter['active'] = active;
            gridFilter['active'] = active;
            piesFilter['active'] = active;
        }
        if (namespace !== undefined) {
            piesFilter['namespace'] = namespace;
        }

        if (auditApplication !== undefined) {
            piesFilter['auditApplication'] = auditApplication;
            gridFilter['auditApplication'] = auditApplication;
        }

        if (auditUpdatedBy !== undefined) {
            piesFilter['auditUpdatedBy'] = auditUpdatedBy;
            gridFilter['auditUpdatedBy'] = auditUpdatedBy;
        }
        if (ceTypeId !== undefined) {
            piesFilter['ceTypeId'] = ceTypeId;
        }
        if (cetypesnamesisootb !== undefined) {
            piesFilter['cetypesnamesisootb'] = cetypesnamesisootb;
            gridFilter['cetypesnamesisootb'] = cetypesnamesisootb;
        }

        return forkJoin([
            this.load(
                'ootb-modifications-over-time',
                instanceId,
                trendFilter,
                undefined,
                undefined,
                undefined,
                undefined,
                includeTablesAndDict
            ),
            this.load(
                'ootb-modifications-by-developer',
                scanId,
                piesFilter,
                undefined,
                undefined,
                undefined,
                undefined,
                includeTablesAndDict
            ),
            this.load(
                'ootb-modifications-by-ce-type',
                scanId,
                piesFilter,
                undefined,
                undefined,
                undefined,
                undefined,
                includeTablesAndDict
            ),
            this.load(
                'ootb-modifications-by-application',
                scanId,
                piesFilter,
                undefined,
                undefined,
                undefined,
                undefined,
                includeTablesAndDict
            ),
            this.OOTBTable(
                serviceId,
                instanceId,
                scanId,
                scanLongId,
                gridFilter,
                tableFilter,
                includeTablesAndDict
            )
        ]).pipe(
            map(data => {
                return {
                    data: data[0].data,
                    chart0: data[1].data,
                    chart1: data[2].data,
                    chart2: data[3].data,
                    table: data[4].data.map(({ id, attributes }) => ({
                        id,
                        ...attributes
                    })),
                    tableFilter: parseTableMeta(data[4].meta)
                };
            })
        );
    }

    ceAffectedByUpgrade(
        serviceId: number,
        instanceId: number,
        scanId: number,
        filter: any,
        tableFilter: any,
        includeTablesAndDict: boolean
    ): Observable<any> {
        return forkJoin([
            this.load(
                'ces-affected-by-upgrade',
                scanId,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                includeTablesAndDict
            ),
            this.load(
                'recommended-versions',
                scanId,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                includeTablesAndDict
            ),
            this.load('total-namespaces-compact', scanId, { providerId: 1 }),
            this.load('lines-code-compact', scanId),
            this.load('config-elem-extracompact', scanId),
            this.load('open-source-extra-compact', scanId),
            this.loadVersions()
        ]).pipe(
            map(data => {
                return {
                    bar: data[0].data,
                    table: data[1].data,
                    apps: data[2],
                    locs: data[3],
                    ces: data[4],
                    osl: data[5],
                    versions: data[6].data.reduce((result, el) => {
                        result[el.attributes.nameId] = {
                            name: el.attributes.name,
                            gaDate: el.attributes.gaDate,
                            supportDate: el.attributes.supportDate
                        };
                        return result;
                    }, {})
                };
            })
        );
    }

    loadVersions(): Observable<any> {
        const url = `${environment.apiEndPoint}api/v1/snow/ootb/releases?page%5Btotals%5D&sort=-gaDate`;
        return this.http.get(url);
    }

    governanceSummary(
        customerId: number,
        tableFilter: any,
        providerId: number,
        f: any = {}
    ): Observable<any> {
        const filter = {};
        filter['providerId'] = providerId;
        if (f.hasOwnProperty('labelNames')) {
            filter['labelNames'] = f['labelNames'];
        }
        return forkJoin([
            this.load('governance-summary', null, filter, tableFilter)
        ]).pipe(
            map((data: any) => {
                return {
                    table: data[0].data[0].data,
                    tableFilter: parseTableMetaBI_API(data[0].meta)
                };
            })
        );
    }

    earlyDetection(
        serviceId: number,
        instanceId: number,
        scanId: number,
        scanLongId: string,
        filter: any,
        tableFilter: any,
        timeFilters: any
    ): Observable<any> {
        const newTableFilter = tableFilter
            ? { sort: 'scanDate', direction: 'desc', ...tableFilter }
            : {
                  sort: 'scanDate',
                  direction: 'desc',
                  pageIndex: 0,
                  pageSize: 10
              };
        return forkJoin([
            this.load(
                'live-check-issues-by-severity',
                instanceId,
                filter,
                undefined,
                timeFilters
            ),
            this.load(
                'live-check-issues-by-area',
                instanceId,
                filter,
                undefined,
                timeFilters
            ),
            this.checkRequiredParams(
                instanceId,
                filter,
                undefined,
                timeFilters,
                'live-check-issues'
            ),
            this.checkRequiredParams(
                instanceId,
                filter,
                newTableFilter,
                timeFilters,
                'live-check-issues-by-scan'
            )
        ]).pipe(
            map(data => {
                const stackedData = this.fillBlankDates(data[2].data);
                return {
                    chart0: data[0].data,
                    chart1: data[1].data,
                    chart2: stackedData,
                    table: data[3].data
                        ? data[3].data.length === 1
                            ? data[3].data[0].data
                            : []
                        : [],
                    tableFilter: data[3].meta
                        ? parseTableMetaBI_API(data[3].meta)
                        : {}
                };
            })
        );
    }

    fillBlankDates(data) {
        let max = 0;
        let exampleSerie;
        const result = [...data];
        for (let i = 0; i < data.length; i++) {
            if (data[i].data.length > max) {
                max = data[i].data.length;
                exampleSerie = data[i].data;
            }
        }
        result.forEach(serie => {
            if (serie.data.length !== max) {
                serie.data = exampleSerie.map(item => {
                    const copyItem: { label: string; value: number } = {
                        ...item
                    };
                    const currentItem = serie.data.find(
                        i => i.label === item.label
                    );
                    if (currentItem) copyItem.value = currentItem.value;
                    else copyItem.value = 0;
                    return copyItem;
                });
            }
            return serie;
        });
        return result;
    }

    checkRequiredParams(instanceId, filter, tableFilter, timeFilters, path) {
        if (filter.ceName && filter.ceName.length > 0) {
            const filterCopy = { ...filter };
            return this.load(
                path,
                instanceId ? instanceId : null,
                filterCopy,
                tableFilter,
                timeFilters
            );
        }

        return new Observable(observer => {
            observer.next({
                data: [
                    {
                        data: []
                    }
                ],
                meta: {
                    total_resources: 0,
                    resources_per_page: 10,
                    from: 1,
                    to: 10
                }
            });
            observer.complete();
        });
    }

    livecheckUsage(
        serviceId: number,
        instanceId: number,
        scanId: number,
        scanLongId: string,
        filter: any,
        tableFilter: any,
        timeFilters: any
    ): Observable<any> {
        return forkJoin([
            this.load(
                'live-check-by-ootb',
                instanceId,
                filter,
                undefined,
                timeFilters
            ),
            this.load(
                'live-check-by-ce-type',
                instanceId,
                filter,
                undefined,
                timeFilters
            ),
            this.load(
                'live-check-by-developer',
                instanceId,
                filter,
                undefined,
                timeFilters
            ),
            this.load(
                'live-check-executions-by-day',
                instanceId,
                filter,
                undefined,
                timeFilters
            ),
            this.load(
                'live-check-scans',
                instanceId,
                filter,
                tableFilter,
                timeFilters
            )
        ]).pipe(
            map(data => {
                type Teams = Array<{
                    data: { label: string; value: string };
                    name: string;
                }>;
                const team: Teams = [];
                for (let i = 0; i < data[3].data.length; i++) {
                    team.push({
                        data: data[3].data[i].data.map(o => ({
                            label: o.label.substr(0, 10),
                            value: o.value
                        })),
                        name: data[3].data[i].name
                    });
                }
                return {
                    trend: team,
                    chart0: data[0].data,
                    chart1: data[1].data,
                    chart2: data[2].data,
                    table:
                        data[4].data.length === 1 ? data[4].data[0].data : [],
                    tableFilter: parseTableMetaBI_API(data[4].meta)
                };
            })
        );
    }

    formatValueLabels(value) {
        value.data = value.data.map(val => WidgetService.formatLabel(val));
        return value;
    }

    auditableElementsTable(
        scanLongId: string,
        filter: any,
        tableFilter: any = null,
        includeBaseline: boolean
    ): Observable<any> {
        const filtersParams = generateOldParams(filter);
        const tableParams = generateTableParams(tableFilter);
        const url = `${
            environment.apiEndPoint
        }api/v2/auditable-element?filter[scan_uuid]=${scanLongId}&${filtersParams}&${tableParams}${
            includeBaseline === true
                ? '&filter[include_baseline]=true'
                : includeBaseline === false
                ? '&filter[include_baseline]=false'
                : ''
        }`;
        return this.http.get(url);
    }

    issuesTable(
        serviceId: number,
        scanLongId: string,
        filter: any,
        tableFilter = null,
        includeBaseline = null,
        isCompareIssue = false
    ): Observable<any> {
        const { ...f } = filter;

        if (filter['condition'] === 'opened' && isCompareIssue) {
            delete f['condition'];
            f['type'] = 'created';
        } else if (filter['condition'] === 'closed' && isCompareIssue) {
            delete f['condition'];
            f['type'] = 'solved';
        } else if (filter['condition'] === 'net' && isCompareIssue) {
            delete f['condition'];
            f['type'] = 'net';
        }
        const tableParams = generateTableParams(tableFilter);
        const filtersParams = generateOldParams(f);
        const url = `${
            environment.apiEndPoint
        }api/v2/issue?filter[service_id]=${serviceId}&filter[scan]=${scanLongId}&${filtersParams}&${tableParams}${
            includeBaseline === true
                ? '&filter[include_baseline]=true'
                : includeBaseline === false
                ? '&filter[include_baseline]=false'
                : ''
        }`;
        return this.http.get(url);
    }

    issuesBreakDownSmall(
        scanID: number,
        mainFilter: any,
        includeBaseline: boolean = null
    ): Observable<IIssueBreakdownSmall> {
        return forkJoin([
            this.load(
                'issues-breakdown',
                scanID,
                {
                    groupIssuesType: 'Apex'
                },
                undefined,
                undefined,
                mainFilter,
                includeBaseline
            ),
            this.load(
                'issues-breakdown',
                scanID,
                {
                    groupIssuesType: 'Javascript'
                },
                undefined,
                undefined,
                mainFilter,
                includeBaseline
            ),
            this.load(
                'issues-breakdown',
                scanID,
                {
                    groupIssuesType: 'Org.config'
                },
                undefined,
                undefined,
                mainFilter,
                includeBaseline
            ),
            this.load(
                'issues-breakdown',
                scanID,
                {
                    groupIssuesType: 'ServiceNow'
                },
                undefined,
                undefined,
                mainFilter,
                includeBaseline
            ),
            this.load(
                'issues-breakdown',
                scanID,
                {
                    groupIssuesType: 'Office'
                },
                undefined,
                undefined,
                mainFilter,
                includeBaseline
            )
        ]).pipe(
            map((data: any) => ({
                salesforce: {
                    variation: data[0].variation,
                    value: data[0].value,
                    trend: data[0].trend,
                    prevScan: data[0].prevScan,
                    baseline: data[0].baseline,
                    inverseVariation: data[0].inverseVariation
                },
                js: {
                    variation: data[1].variation,
                    value: data[1].value,
                    trend: data[1].trend,
                    prevScan: data[1].prevScan,
                    baseline: data[1].baseline,
                    inverseVariation: data[1].inverseVariation
                },
                org: {
                    variation: data[2].variation,
                    value: data[2].value,
                    trend: data[2].trend,
                    prevScan: data[2].prevScan,
                    baseline: data[2].baseline,
                    inverseVariation: data[2].inverseVariation
                },
                servicenow: {
                    variation: data[3].variation,
                    value: data[3].value,
                    trend: data[3].trend,
                    prevScan: data[3].prevScan,
                    baseline: data[3].baseline,
                    inverseVariation: data[3].inverseVariation
                },
                office: {
                    variation: data[4].variation,
                    value: data[4].value,
                    trend: data[4].trend,
                    prevScan: data[4].prevScan,
                    baseline: data[4].baseline,
                    inverseVariation: data[4].inverseVariation
                }
            }))
        );
    }

    mostConfiguredAreas(scanId: number): Observable<any> {
        return this.load(
            'num-ce-by-namespace',
            scanId,
            { excludeCustomApps: true, isOotb: false },
            {
                pageSize: 20,
                pageIndex: 0,
                sort: 'sumNumConfElements',
                direction: 'desc'
            }
        ).pipe(
            map((data: any) => ({
                data: this.parseNamespaces(data.data)
            }))
        );
    }

    parseNamespaces(data: any) {
        data[0].data.map(value => {
            if (value.label.toUpperCase() === 'N/A') {
                value.label = 'Default';
            }
        });
        return data;
    }

    parseEndUsers(filter, target) {
        const filterCopy = { ...filter };
        if (target === 'rest') {
            if (filterCopy && filterCopy['includeEndUsers'] !== undefined) {
                filterCopy['include_end_users'] = filterCopy['includeEndUsers'];
                delete filterCopy['includeEndUsers'];
            } else {
                filterCopy['include_end_users'] = false;
            }
        } else if (
            filterCopy &&
            filterCopy.hasOwnProperty('includeEndUsers') &&
            filterCopy.includeEndUsers === undefined
        ) {
            filterCopy.includeEndUsers = true;
        }

        return filterCopy;
    }

    cascadeFiltersSingleCall(
        cascadeFilters: any[],
        currentFilter: any,
        instanceId: number,
        scanId: number,
        context: string,
        timeFilter = null
    ) {
        const queryParams = this.getQueryParamsFromFilter(
            currentFilter,
            null,
            context
        );
        return this.http
            .get(
                `${environment.apiEndPoint}api/v3/open-issue-filter?filter[instance_id]=${instanceId}` +
                    (timeFilter.begin
                        ? `&filter[issue_open_date_gte]=${this.formatTime(
                              timeFilter.begin
                          )}`
                        : '') +
                    (timeFilter.end
                        ? `&filter[issue_open_date_lte]=${this.formatTime(
                              timeFilter.end
                          )}`
                        : '') +
                    (queryParams === '' ? '' : `&${queryParams}`)
            )
            .pipe(
                map((res: any) =>
                    res.data
                        ? res.data
                              .reduce((result, current) => {
                                  result.push({
                                      filterName: current.attributes.field,
                                      filterElements: current.attributes.data.map(
                                          e => ({
                                              label:
                                                  e.label || e.attributes.label,
                                              value:
                                                  e.value || e.attributes.value,
                                              disabled: false
                                          })
                                      )
                                  });
                                  return result;
                              }, [])
                              .concat([
                                  {
                                      filterName: 'write_off_expiration_date',
                                      filterElements: []
                                  },
                                  {
                                      filterName: 'delta_issues_long_id',
                                      filterElements: []
                                  }
                              ])
                        : []
                )
            );
    }

    newCascadeFilters(
        filters: any[],
        filter: any,
        instanceId: number,
        scanId: number,
        context: string,
        timeFilter = null
    ): Observable<any[]> {
        return forkJoin(
            filters.map(f =>
                this.newCascadeFilter(
                    filter,
                    f,
                    instanceId,
                    scanId,
                    context,
                    timeFilter
                )
            )
        ).pipe(
            map(res => {
                return res.reduce((result: any[], current: any, index) => {
                    if (context === 'code-duplication') {
                        result.push({
                            filterName: filters[index],
                            filterElements: current.data
                                .find(el => el.id === filters[index])
                                .attributes.data.map(e => ({
                                    label: e.label || e.attributes.label,
                                    value: e.value || e.attributes.value,
                                    disabled: false
                                }))
                        });
                    } else
                        result.push({
                            filterName: filters[index],
                            filterElements: current.data.map(e => ({
                                label: e.label || e.attributes.label,
                                value: e.value || e.attributes.value,
                                disabled: false
                            }))
                        });
                    return result;
                }, []);
            })
        );
    }

    getUrlFromContext(
        currentFilter,
        instanceId,
        scanId,
        context,
        queryParams,
        timeFilter
    ): string {
        if (context === 'write-off')
            return (
                `${environment.apiEndPoint}api/v2/write-off-snapshot-filter?filter[instance_id]=${instanceId}&filter[selected_field]=${currentFilter}` +
                (queryParams === '' ? '' : `&${queryParams}`)
            );
        if (context === 'debt-manager') {
            const beginFilter = queryParams.includes(
                'filter[issue_status]=CLOSED'
            )
                ? 'issue_closed_date_gte'
                : 'issue_open_date_gte';
            const endFilter = queryParams.includes(
                'filter[issue_status]=CLOSED'
            )
                ? 'issue_closed_date_lte'
                : 'issue_open_date_lte';
            return (
                `${environment.apiEndPoint}api/v2/open-issue-filter?filter[instance_id]=${instanceId}` +
                `&filter[selected_field]=${currentFilter}` +
                (timeFilter.begin
                    ? `&filter[${beginFilter}]=${this.formatTime(
                          timeFilter.begin
                      )}`
                    : '') +
                (timeFilter.end
                    ? `&filter[${endFilter}]=${this.formatTime(timeFilter.end)}`
                    : '') +
                (queryParams === '' ? '' : `&${queryParams}`)
            );
        }
        if (context === 'code-duplication') {
            return `${environment.apiEndPoint}api/v2/duplicate-code-filters?filter[scan_id]=${scanId}`;
        }
        return (
            `${environment.apiEndPoint}widgets/v1/${this.getEpFromFilter(
                currentFilter
            )}/${scanId}` + (queryParams === '' ? '' : `?${queryParams}`)
        );
    }

    getEpFromFilter(filter: string): string {
        switch (filter) {
            case 'updatedBy':
                return 'updated-by/issues';
            case 'namespaces':
                return 'namespaces/issues';
            case 'CInamespaces':
                return 'namespaces/issues';
            case 'severity':
                return 'severities';
            default:
                return filter;
        }
    }

    private catalogItemDetailTable(
        scanId: number,
        catalogName: string,
        categoryName: string,
        tableFilter: any
    ): Observable<any> {
        const tableParams = generateTableParams(tableFilter);
        const url =
            `${environment.apiEndPoint}api/v2/catalog-item?filter[scan_id]=${scanId}&` +
            `filter[catalog_name]=${catalogName}&filter[category_name]=${categoryName}` +
            `${tableParams}`;
        return this.http.get(url).pipe(
            map((data: any) => {
                return {
                    data: data.data.map(({ id, attributes }) => ({
                        id,
                        ...attributes
                    })),
                    pagination: parseTableMetaBI_API(data.meta)
                };
            })
        );
    }

    private profilingCCTIR(instanceId: number): Observable<any> {
        return forkJoin([
            this.load('code-changes-to-issues-ratio', instanceId)
        ]).pipe(
            map(data => ({
                data: data[0].data
            }))
        );
    }

    private duplicatedCode(
        instanceId,
        scanId,
        tableFilter = {
            sort: '',
            direction: '',
            pageIndex: 0,
            pageSize: 10
        }
    ): Observable<any> {
        return forkJoin([
            this.load('duplicated-code-over-time', instanceId),
            this.load('duplicate-blocks-list', scanId, tableFilter)
        ]).pipe(
            map(data => ({
                data: data[0].data,
                table: data[1].data
            }))
        );
    }

    private performancePIData(
        serviceId: number,
        scanId: number,
        scanLongId: string,
        filter: any,
        tableFilter: any
    ): Observable<any> {
        return forkJoin([
            this.load('issues-by-scan', scanId, { ...filter, areaId: 2 }),
            this.issuesTable(serviceId, scanLongId, filter, tableFilter)
        ]).pipe(
            map((data: any) => {
                return {
                    chart: [data[0].data[0]],
                    table: data[1].data.map(({ id, attributes }) => {
                        const attr = parseAttributesWriteOffScan(attributes);
                        return {
                            id,
                            ...attr
                        };
                    }),
                    tableFilter: parseTableMeta(data[1].meta)
                };
            })
        );
    }

    private profilingNLOCS(instanceId: number, filter: any): Observable<any> {
        return forkJoin([
            this.load('new-lines-code-over-time', instanceId, filter)
        ]).pipe(
            map(data => ({
                trend: data[0].data
            }))
        );
    }

    private formatDateFilter(value: Date) {
        if (!value) return null;
        return [
            value.getFullYear(),
            ('0' + (value.getMonth() + 1)).slice(-2),
            ('0' + value.getDate()).slice(-2)
        ].join('-');
    }

    private getFormattedExpirationDate(
        expirationDate: string,
        expirationTime: string
    ): string {
        const d = new Date(expirationDate);
        const locale = this.latestUserTimeValue === 'US' ? 'en-US' : 'en-GB';
        return `${d.toLocaleDateString(locale)} ${expirationTime}`;
    }

    private manageBulkCall(params: {
        allSelected: boolean;
        url: string;
        data: any;
        filters: any;
        ids: any;
    }) {
        let url = params.url;
        const { allSelected, data, filters, ids } = params;
        if (allSelected) {
            url += '-all-instance';
            data.filters = this.generateBulkFilters(filters);
        } else {
            data.issuesIds = ids;
        }
        return this.http.post(url, {
            data
        });
    }

    private generateBulkFilters(filters: any) {
        const f = [];
        for (const at in filters) {
            if (filters.hasOwnProperty(at) && filters[at].length)
                f.push({
                    field: this.getFilterFromKey(at, 'debt-manager'),
                    value: filters[at]
                });
        }
        return f;
    }

    private newCascadeFilter(
        filter,
        currentFilter,
        instanceId,
        scanId,
        context,
        timeFilter
    ): Observable<any> {
        const queryParams = this.getQueryParamsFromFilter(
            filter,
            currentFilter,
            context
        );
        if (
            currentFilter === 'write_off_expiration_date' ||
            currentFilter === 'delta_issues_long_id'
        )
            return of({ data: [] });
        return this.http.get(
            this.getUrlFromContext(
                currentFilter,
                instanceId,
                scanId,
                context,
                queryParams,
                timeFilter
            )
        );
    }
}
