import { Component, Input } from '@angular/core';

export enum BannerType {
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS'
}

export interface LinkElement {
    text: string;
    href: string;
}

const COLORS: { [key in BannerType]: { background: string; font: string } } = {
    INFO: { background: '#5E9AEC', font: '#FFFFFF' },
    WARN: { background: '#f0ad4e', font: '#FFFFFF' },
    ERROR: { background: '#EC6A5E', font: '#FFFFFF' },
    SUCCESS: { background: '#62D393', font: '#FFFFFF' }
};

const ICONS: { [key in BannerType]: string } = {
    INFO: 'info',
    WARN: 'warning',
    ERROR: 'error',
    SUCCESS: 'check_circle'
};

@Component({
    selector: 'qcbi-banner',
    templateUrl: './banner.component.html',
    styles: [
        `
            .banner-container {
                width: 100%;
                height: 47px;
                padding-left: 8px;
                display: flex;
            }

            .icon-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .lock-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: auto;
                margin-right: 12px;
            }

            .message-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 8px;
                overflow: hidden;
                white-space: nowrap;
            }

            .text-wrapper {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        `
    ]
})
export class BannerComponent {
    @Input() set message(value: string) {
        this._message = value;
        this.processText();
    }
    @Input() type: BannerType = BannerType.INFO;
    @Input() set link(value: LinkElement[]) {
        this._links = value;
        this.processText();
    }
    @Input() iconLock: boolean;

    _message;
    _links;
    text;

    processText() {
        if (!this._message) this.text = '';
        else if (this._links) {
            const matches = this._message.match(/\$\{\d\}/g) || [];
            for (let i = 0; i < matches.length; i++) {
                const index = +matches[i].match(/\d/g);
                if (index > this._links.length) {
                    console.error('error on links set');
                    break;
                }
                this._message = this._message.replace(
                    matches[i],
                    `<a class='qg-banner-link' target='_blank' href='${this._links[index].href}'>${this._links[index].text}</a>`
                );
            }
            this.text = this._message;
        } else {
            this.text = this._message;
        }
    }

    getBackgroundColor(): string {
        if (this.iconLock) {
            return '#AEAEAE';
        } else {
            return COLORS[this.type].background;
        }
    }

    getFontColor(): string {
        return COLORS[this.type].font;
    }

    getIcon(): string {
        return ICONS[this.type];
    }
}
