import { Injectable, Injector } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { QCAuthService } from './service';
import {
    catchError,
    first,
    map,
    mergeMap,
    switchMap,
    tap
} from 'rxjs/operators';
import { calculateBlobError, calculateError } from './utils';
import { dashboardSelector } from '../state/general/general.selectors';

@Injectable()
export class QCAuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private service: QCAuthService;
    private tokenRefreshedSource = new Subject();
    private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    private dashboardId = null;

    constructor(private injector: Injector, private store: Store<any>) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return this.store.pipe(
            select(dashboardSelector),
            first(),
            mergeMap(dashboard => {
                if (dashboard) this.dashboardId = dashboard.id;

                this.service = this.injector.get(QCAuthService);

                const [hasAT, at, rt, hasET, et] = [
                    this.service.hasAccessToken(),
                    this.service.getAccessToken(),
                    this.service.getRefreshToken(),
                    this.service.hasEmulationToken(),
                    this.service.getEmulationToken()
                ];

                if (!hasAT) {
                    return next.handle(request);
                }

                if (request !== null) {
                    if (!request.headers.has('noDefaultHeader')) {
                        request = this.addToken(request, hasET ? et : at);
                    }
                    if (request.headers.has('skip')) {
                        return next.handle(request);
                    }
                }
                return next.handle(request).pipe(
                    map((event: HttpEvent<any>) => event),
                    catchError((error: HttpErrorResponse) => {
                        if (error.error instanceof Blob) {
                            const err = calculateBlobError(error);
                            return throwError(err);
                        }
                        const data = calculateError(error);
                        if (error.status === 401) {
                            if (hasET) {
                                this.service.removeEmulationToken();
                                return throwError(data);
                            } else {
                                return this.handle401Error(
                                    request,
                                    next,
                                    at,
                                    rt
                                );
                            }
                        } else {
                            return throwError(data);
                        }
                    })
                );
            })
        );
    }

    refreshToken(at, rt): Observable<any> {
        if (this.isRefreshing) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.isRefreshing = true;

            return this.service.refreshToken(at, rt).pipe(
                tap(data => {
                    const { refresh_token, access_token } = data;
                    this.service.setAccessToken(access_token);
                    this.service.setRefreshToken(refresh_token);
                    this.isRefreshing = false;
                    this.tokenRefreshedSource.next();
                }),
                catchError(err => {
                    this.isRefreshing = false;
                    this.service.loginRedirection();
                    return throwError(err);
                })
            );
        }
    }

    private handle401Error(
        request: HttpRequest<any>,
        next: HttpHandler,
        at: string,
        rt: string
    ) {
        return this.refreshToken(at, rt).pipe(
            switchMap(() => {
                return next.handle(
                    this.addToken(request, this.service.getAccessToken())
                );
            }),
            catchError(e => {
                if (e.status !== 401) {
                    console.error(e);
                } else {
                    this.service.loginRedirection();
                }
                return throwError(e);
            })
        );
    }

    private addToken(
        request: HttpRequest<any>,
        token: string,
        clientName = this.service.clientName
    ) {
        if (this.dashboardId === null) {
            if (request.headers.has('Content-Type')) {
                return request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                        'Client-Name': clientName
                    }
                });
            }
            if (request.headers.has('is-multipart')) {
                const clonedRequest = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                        'Client-Name': clientName
                    }
                });
                clonedRequest.headers.delete('is-multipart');
                return clonedRequest;
            }
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'Client-Name': clientName,
                    'Content-Type': 'application/vnd.api+json'
                }
            });
        } else {
            if (request.headers.has('Content-Type')) {
                return request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                        'Client-Name': clientName,
                        'bi-dashboard': this.dashboardId + ''
                    }
                });
            }
            if (request.headers.has('is-multipart')) {
                const clonedRequest = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                        'Client-Name': clientName
                    }
                });
                clonedRequest.headers.delete('is-multipart');
                return clonedRequest;
            }
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'Client-Name': clientName,
                    'Content-Type': 'application/vnd.api+json',
                    'bi-dashboard': this.dashboardId + ''
                }
            });
        }
    }
}
