<div
	class="container"
	*ngIf="configuration"
>
	<button
		id="config-button"
		aria-label="Configure table columns"
		mat-button
		matTooltip="Configure table columns"
		(click)="toggleMenu()"
	>
		<mat-icon id="config-icon">settings</mat-icon>
		Table configuration
	</button>
	<div
		*ngIf="show"
		qcbiClickOutside
		(clickOutside)="clickOutsideHandler($event)"
		class="position-absolute menu"
	>
		<div class="menu-title">Columns</div>
		<div
			cdkDropList
			class="example-list"
			cdkDropListLockAxis="y"
			(cdkDropListDropped)="drop($event)"
		>
			<div
				class="example-box capitalize"
				cdkDrag
				*ngFor="let column of configurationColumns"
			>
				<mat-icon class="drag-icon" cdkDragHandle>drag_indicator</mat-icon>
				<mat-checkbox
					class="config-checkbox"
					[checked]="getCheckboxState(column)"
					(change)="checkboxChangeHandler($event, column)"
				>
					{{ column }}
				</mat-checkbox>
			</div>
		</div>
	</div>
</div>
