<form [formGroup]="myForm" (ngSubmit)="createView()">
	<div class="content">
		<div class="info-box" *ngIf="showUpdateInfo()">
			<mat-icon>info</mat-icon>
			<span>Please note that updating the view details will affect all users with access.</span>
		</div>
		<div class="info-box" *ngIf="restrictEditOptions$ | async">
			<mat-icon>info</mat-icon>
			<span>The sharing options for this view can only be modified by its owner.</span>
		</div>
		<p>{{ mainText }}</p>
		<div class="title">Details</div>
		<div class="mt-2">
			<mat-form-field
                appearance="outline"
                class="w-full no-padding space-for-errors relative"
            >
				<mat-label>Name</mat-label>
				<input matInput formControlName="name">
				<mat-error
                    class="error-text"
                    *ngIf="myForm.get('name').hasError('maxlength')"
                >
					the maximum character limit is 40 characters
				</mat-error>
				<mat-error
                    class="error-text"
                    *ngIf="myForm.get('name').hasError('required')"
                >
					this field is required
				</mat-error>
				<mat-error
					class="error-text"
					*ngIf="myForm.get('name').hasError('invalidName')"
				>
					You already got a view with this name
				</mat-error>
				<div class="loading absolute" *ngIf="myForm.get('name').status === 'PENDING'">
					<mat-spinner color="primary" diameter="20"></mat-spinner>
				</div>
			</mat-form-field>

			<mat-form-field
                appearance="outline"
                class="w-full no-padding position-relative space-for-errors"
            >
				<mat-label>Description</mat-label>
				<textarea
                    rows="2"
                    matInput
                    formControlName="description"
                ></textarea>
				<div class="position-absolute text-area-counter">{{ myForm.value.description.length }}/255</div>
				<mat-error
                    class="mt-3"
                    *ngIf="myForm.get('description').hasError('maxlength')"
                >
					the maximum character limit is 255 characters
				</mat-error>
			</mat-form-field>
		</div>
		<ng-container *ngIf="canViewVisualizationDetails$ | async">
			<div class="title">Share with</div>
			<mat-radio-group [disabled]="restrictEditOptions$ | async" formControlName="sharedWith" class="radio-group">
				<mat-radio-button
					value="private"
					color="primary"
					[disabled]="(!data || !data.isPrivate) && maxPrivateViewsReached$ | async"
					[matTooltip]="(!data || !data.isPrivate) && (maxPrivateViewsReached$ | async) ? 'you have reached your private view limit' : ''"
				>
					Private
				</mat-radio-button>
				<mat-radio-button
					value="public"
					color="primary"
					[disabled]="(!data || data.isPrivate) && maxPublicViewsReached$ | async"
					[matTooltip]="(!data || data.isPrivate) && (maxPublicViewsReached$ | async) ? 'you have reached your public view limit' : ''"
				>
					Public
				</mat-radio-button>
				<mat-radio-button
					value="shared"
					color="primary"
					[disabled]="(!data || data.isPrivate) && maxPublicViewsReached$ | async"
					[matTooltip]="(!data || data.isPrivate) && (maxPublicViewsReached$ | async) ? 'you have reached your public view limit' : ''"
				>
					Pick users
				</mat-radio-button>
			</mat-radio-group>
			<qcbi-users-dropdown-search
				*ngIf="myForm.value.sharedWith === 'shared'"
				[disabled]="restrictEditOptions$ | async"
				(selectedUsersChange)="selectedUsersChangeHandler($event)"
				[initialIds]="initialIds"
			></qcbi-users-dropdown-search>
			<div class="info mt-3" *ngIf="myForm.value.sharedWith !== ''">
				{{ getInfo() }}
			</div>
		</ng-container>
	</div>
	<div class="actions">
        <button
            mat-raised-button
            color="primary"
            class="w-full"
            type="submit"
            [loading]="submitting"
            [disabled]="myForm.invalid || (myForm.value.sharedWith === 'shared' && selectedUsers.length === 0)"
        >
            {{ actionLabel }}
        </button>
		<button
			mat-stroked-button
			color="primary"
			class="w-full"
			type="button"
			[disabled]="submitting"
			(click)="cancelForm()"
		>
			Cancel
		</button>
	</div>
</form>