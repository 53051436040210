import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'qcbi-unwrite-off-dialog',
    templateUrl: './unwrite-off-dialog.component.html'
})
export class UnwriteOffDialogComponent implements OnInit {
    descriptionFormControl = new FormControl('', Validators.required);
    status = 'ACCEPT';
    options = [
        { label: 'Approve', value: 'ACCEPT' },
        { label: 'Decline', value: 'DECLINED' },
        { label: 'Reject', value: 'REJECTED' }
    ];
    reasons: any;

    constructor(
        public dialogRef: MatDialogRef<UnwriteOffDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        if (this.data.onlyDecline) {
            this.options = this.options.filter(opt => opt.value === 'DECLINED');
            this.status = 'DECLINED';
        }
    }

    close() {
        this.dialogRef.close();
    }

    handleSelectionChange(event: MatSelectChange) {
        this.status = event.value;
    }

    submitChanges() {
        this.dialogRef.close({
            ids: this.data.ids,
            description: Array.isArray(this.descriptionFormControl.value)
                ? this.descriptionFormControl.value[0]
                : this.descriptionFormControl.value,
            allSelected: this.data.allSelected,
            status: this.status
        });
    }

    validFields() {
        return this.descriptionFormControl.valid;
    }
}
