import {
    createReducer,
    on,
    Action,
    createAction,
    props,
    createSelector,
    createFeatureSelector,
    Store
} from '@ngrx/store';
import { StateFeatures, Widgets } from '../../../models/widgets';
import { Injectable } from '@angular/core';
import { WidgetService } from '../../../../services/widget.service';
import { Actions, Effect } from '@ngrx/effects';
import { AppState } from '../../../../core.state';
import { WidgetEffects } from '../../widget.effects';
import { ICatalogIssuesWarningLarge } from '../../../models/widgets/large/catalog/issues.model';

type State = typeof Widgets.Large.Catalog.ISSUES;
enum StateConfig {
    Name = 'Widget - Catalog - Overview - Issues and Warnings Large',
    Key = 'c_lw_issues'
}
// First storage state object
const initialState: State = {
    isLoaded: false,
    loading: false,
    error: '',
    filter: {
        ceTypeId: [
            'CAT',
            'CCS',
            'CUIP',
            'CUIPA',
            'WORKFLW',
            'CATITM',
            'RECPROD',
            'CATVAR'
        ],
        severityId: ['1', '2', '3']
    },
    data: {
        data: []
    },
    cascadeFilters: {
        severities: [],
        areas: [],
        bestPractices: [],
        updatedBy: [],
        namespaces: [],
        written_off: [],
        IssueId: []
    },
    tableFilter: {
        pageIndex: 0,
        pageSize: 10,
        total: undefined,
        sort: undefined,
        direction: undefined
    }
};

/*
 *  Actions express unique events that happen throughout your application
 */
const stateActions = {
    load: createAction(
        `[${StateConfig.Name}] Load`,
        props<{ filter: any; tableFilter: any }>()
    ),
    error: createAction(
        `[${StateConfig.Name}] Load error`,
        props<{ error: string }>()
    ),
    success: createAction(
        `[${StateConfig.Name}] Load success`,
        props<{ data: ICatalogIssuesWarningLarge }>()
    ),
    reset: createAction(`[${StateConfig.Name}] Reset`),
    saveCascadeFilters: createAction(
        `[${StateConfig.Name}] Save cascade filters`,
        props<{ data: any }>()
    )
};

/*
 * Reducer functions handle these transitions by determining which actions to
 * handle based on the action's type
 */
const reducers = createReducer(
    initialState,
    on(stateActions.load, (state, { filter }) => {
        if (
            state.filter['severityId'] == undefined &&
            filter['severityId'] == undefined
        ) {
            filter['severityId'] = ['1', '2', '3'];
        }
        if (
            state.filter['ceTypeId'] == undefined &&
            filter['ceTypeId'] == undefined
        ) {
            filter['ceTypeId'] = [
                'CAT',
                'CCS',
                'CUIP',
                'CUIPA',
                'WORKFLW',
                'CATITM',
                'RECPROD',
                'CATVAR'
            ];
        }
        return {
            ...state,
            loading: true,
            error: '',
            filter
        };
    }),
    on(stateActions.error, (state, { error }) => ({
        ...state,
        loading: false,
        error,
        isLoaded: true
    })),
    on(stateActions.success, (state, { data }) => {
        const { tableFilter, ...newData } = data;
        return {
            ...state,
            loading: false,
            isLoaded: true,
            data: newData,
            tableFilter
        };
    }),
    on(stateActions.reset, () => ({
        ...initialState
    })),
    on(stateActions.saveCascadeFilters, (state, { data }) => ({
        ...state,
        cascadeFilters: data
    }))
);

export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}

/*
 * Selectors are pure functions used for obtaining slices of store state.
 */
const stateSelector = createFeatureSelector<AppState, State>(StateConfig.Key);
const stateSelectors = {
    isLoaded: createSelector(stateSelector, (state: State) => state.isLoaded),
    loading: createSelector(stateSelector, (state: State) => state.loading),
    data: createSelector(stateSelector, (state: State) => state.data),
    error: createSelector(stateSelector, (state: State) => state.error),
    filter: createSelector(stateSelector, (state: State) => state.filter),
    cascadeFilters: createSelector(
        stateSelector,
        (state: State) => state.cascadeFilters
    ),
    tableFilter: createSelector(
        stateSelector,
        (state: State) => state.tableFilter
    )
};

/*
 * Effects use streams to provide new sources of actions to reduce state
 * based on external interactions such as network requests, web socket messages
 * and time-based events.
 */
@Injectable()
export class StateEffects extends WidgetEffects<ICatalogIssuesWarningLarge> {
    constructor(
        public actions$: Actions,
        public service: WidgetService,
        public store: Store<AppState>
    ) {
        super(
            actions$,
            service,
            store,
            stateActions,
            {
                cascadeFilters: [
                    'updatedBy',
                    'areas',
                    'best-practices',
                    'severities',
                    'namespaces'
                ],
                loadLarge: {
                    key: 'c-issues'
                }
            },
            stateSelector
        );
    }

    @Effect()
    _load = this.loadLarge;
    @Effect()
    _cascadeFilters = this.loadCascadeFilters;
    @Effect()
    _scanReset = this.scanReset;
    @Effect()
    _instanceReset = this.instanceReset;
}

export const stateFeatures: StateFeatures = {
    config: StateConfig,
    actions: stateActions,
    selectors: stateSelectors
};
