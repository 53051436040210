<mat-slide-toggle
  *ngIf="!disableWhenInvalidDate()"
  color="secondary"
  style="font-size: 12px"
  (change)="toggleIncludeBaseline()"
  [checked]="includeBaseline"
  aria-label="show written off"
>
  Show Written Off
</mat-slide-toggle>
