import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IssueModel } from '../../models/issue.model';
import { catchError, first, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    generateOldParams,
    generateTableParams,
    generateTableParamsString,
    parseAttributesWriteOffScan,
    parseTableMeta
} from '../../utils/widget-state-creator';
import {
    PagerOptions,
    SortOptions
} from '../../components/tables/server.component';

export type ServicenowElement = {
    sysId: string;
};

export type SalesforceElement = {
    ceName: string;
    ceType: string;
};

const KEY_FILTER_MAP = {
    sysId: 'filter[short_sys_id]=',
    ceName: 'filter[affected_element_name]=',
    ceType: 'filter[ce_type]='
};

@Injectable({
    providedIn: 'root'
})
export class IssuesService {
    constructor(private http: HttpClient, private snackbar: MatSnackBar) {}

    getIssueByIdAndInstance(
        issueShortId: number,
        instanceId: number
    ): Observable<IssueModel> {
        return this.http
            .get(
                `${environment.apiEndPoint}api/v2/issue/${issueShortId}?instanceId=${instanceId}`
            )
            .pipe(
                first(),
                map(({ data }: any) => {
                    return this.mapIssueToModel(data);
                }),
                catchError(err => {
                    this.snackbar.open(
                        'Something went wrong while trying to retrieve issue data',
                        null,
                        {
                            duration: 3000,
                            panelClass: 'error-notification-overlay'
                        }
                    );
                    console.error('!!!!', err);
                    return [];
                })
            );
    }

    getIssuesByScanAndElement(
        scanLongId: string,
        serviceId: number,
        element: ServicenowElement | SalesforceElement,
        tableFilter: PagerOptions & SortOptions
    ) {
        const tableParams = generateTableParamsString(tableFilter);
        const filters = [
            `filter[service_id]=${serviceId}`,
            `filter[scan]=${scanLongId}`,
            tableParams
        ];
        for (const k in element) {
            if (element.hasOwnProperty(k) && element[k]) {
                if (KEY_FILTER_MAP.hasOwnProperty(k)) {
                    filters.push(KEY_FILTER_MAP[k] + element[k]);
                }
            }
        }

        const cdUrl = `${environment.apiEndPoint}api/v2/issue?${filters.join(
            '&'
        )}`;

        return this.http.get(cdUrl).pipe(
            map((data: any) => {
                return {
                    data: data.data.map(({ id, attributes }) => {
                        const attr = parseAttributesWriteOffScan(attributes);
                        return {
                            id,
                            ...attr
                        };
                    }),
                    meta: parseTableMeta(data.meta)
                };
            })
        );
    }

    issuesTable(
        serviceId: number,
        scanLongId: string,
        filter: any,
        tableFilter = null,
        includeBaseline = null,
        isCompareIssue = false
    ): Observable<any> {
        const { ...f } = filter;

        if (filter['condition'] === 'opened' && isCompareIssue) {
            delete f['condition'];
            f['type'] = 'created';
        } else if (filter['condition'] === 'closed' && isCompareIssue) {
            delete f['condition'];
            f['type'] = 'solved';
        } else if (filter['condition'] === 'net' && isCompareIssue) {
            delete f['condition'];
            f['type'] = 'net';
        }
        const tableParams = generateTableParams(tableFilter);
        const filtersParams = generateOldParams(f);
        const url = `${
            environment.apiEndPoint
        }api/v2/issue?filter[service_id]=${serviceId}&filter[scan]=${scanLongId}&${filtersParams}&${tableParams}${
            includeBaseline === true
                ? '&filter[include_baseline]=true'
                : includeBaseline === false
                ? '&filter[include_baseline]=false'
                : ''
        }`;
        return this.http.get(url);
    }

    private mapIssueToModel(apiIssue: any): IssueModel {
        return {
            id: apiIssue.id,
            ...this.mapAttributes(apiIssue.attributes)
        };
    }

    private mapAttributes(itemAttributes: any): any {
        const result = {};
        for (const attribute in itemAttributes) {
            if (itemAttributes.hasOwnProperty(attribute)) {
                if (typeof itemAttributes[attribute] === 'object') {
                    if (Array.isArray(itemAttributes[attribute])) {
                        result[this.snakeToCamel(attribute)] = itemAttributes[
                            attribute
                        ].map(el => this.mapAttributes(el));
                    } else if (itemAttributes[attribute]) {
                        result[
                            this.snakeToCamel(attribute)
                        ] = this.mapAttributes(itemAttributes[attribute]);
                    } else {
                        result[this.snakeToCamel(attribute)] = null;
                    }
                } else {
                    result[this.snakeToCamel(attribute)] =
                        itemAttributes[attribute];
                }
            }
        }
        return result;
    }

    private snakeToCamel(str: string) {
        return str.toLowerCase().replace(/([-_][a-z])/g, group =>
            group
                .toUpperCase()
                .replace('-', '')
                .replace('_', '')
        );
    }
}
