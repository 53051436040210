import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BiParserService } from './widgets/general/bi-parser.service';
import { environment } from '../../../environments/environment';
import { FilterOptionsDictionary } from '../../shared/selectable-filters/selectable-filters.component';

type ServerResponse = {
    data: ServerTeam[];
};

type ServerTeam = {
    id: string;
    attributes: {
        name: string;
        description: string;
        'customer-id': number;
        'created-by': string;
        'created-at': string;
        'updated-by': string;
        'updated-at': string;
    };
};

type FilterValue = {
    label: string;
    value: string;
};

type OpenIssueFilter = {
    type: string;
    id: string;
    attributes: {
        data: FilterValue[];
        field: string;
        fieldName: string;
    };
};

type TeamsFilterSever = {
    data: OpenIssueFilter[];
};

export type Team = {
    id: number;
    name: string;
    description?: string;
};

@Injectable({
    providedIn: 'root'
})
export class TeamService {
    constructor(
        private http: HttpClient,
        private parserService: BiParserService
    ) {}

    getCustomerTeams(): Observable<Team[]> {
        return this.http
            .get<ServerResponse>(`${environment.apiEndPoint}api/v2/team`)
            .pipe(
                map(response => {
                    return response.data.map(team => ({
                        id: +team.id,
                        name: team.attributes.name,
                        description: team.attributes.description
                    }));
                })
            );
    }

    getTeamsWithFilterDictionary(
        instanceId: number,
        filter: FilterOptionsDictionary
    ): Observable<object> {
        const f = this.parserService.parse(filter);
        const url = `${environment.apiEndPoint}api/v4/open-issue-filter?filter[fields]=team&filter[instance_id]=${instanceId}${f}`;
        return this.http.get<TeamsFilterSever>(url).pipe(
            map(response => {
                return response.data[0].attributes.data.reduce((acc, next) => {
                    if (next.value !== '_blank_') acc[next.value] = true;
                    return acc;
                }, {});
            })
        );
    }
}
