import { NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CommonModule } from '@angular/common';
import { QCAuthService } from './service';
import { QCAuthGuard } from './guard';
import { QCAuthIdleComponent, QCAuthIdleDialogComponent } from './components';

// @dynamic
@NgModule({
    imports: [CommonModule],
    declarations: [QCAuthIdleComponent, QCAuthIdleDialogComponent],
    providers: [CookieService, QCAuthService, QCAuthGuard],
    exports: [QCAuthIdleComponent, QCAuthIdleDialogComponent]
})
export class QCModule {}
