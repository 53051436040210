<div>
	<div class="text-xl flex font-bold justify-center pt-3">New View</div>
	<div class="content">
		<qcbi-view-form
			(cancel)="closeModal()"
			(submitForm)="saveNewView($event)"
			[submitting]="saving$ | async"
			mainText="Create a custom view based on your selected filters and table configuration."
		></qcbi-view-form>
	</div>
</div>