import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../core.state';
import { TagsState } from './tags.models';

export const tagsSelector = createFeatureSelector<AppState, TagsState>('tags');

export const allTagsSelector = createSelector(tagsSelector, ({ tags }) => tags);

export const isLoadedSelector = createSelector(
    tagsSelector,
    ({ isLoaded }) => isLoaded
);
