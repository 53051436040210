import {
    Action,
    createAction,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
    Store
} from '@ngrx/store';
import { StateFeatures, Widgets } from '../../../models/widgets';
import { Injectable } from '@angular/core';
import { WidgetService } from '../../../../services/widget.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState } from '../../../../core.state';
import { WidgetEffects } from '../../widget.effects';
import { IClientSlowTransactionsLarge } from '../../../models/widgets/large/performance/cst.model';
import {
    customerIdSelector,
    instanceSelector,
    scanIdSelector,
    scanLongIdSelector,
    serviceIdSelector
} from '../../../general/general.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { successStateWithoutTableFilter } from '../../../../../utils/reducer-fn';

type State = typeof Widgets.Large.Performance.CST;
enum StateConfig {
    Name = 'Widget - Performance - Overview - Client Slow Transactions large',
    Key = 'pf_lw_cst'
}
// First storage state object
const initialState: State = {
    isLoaded: false,
    loading: false,
    loadingFiltered: false,
    error: '',
    filter: {
        txSlider: 5
    },
    data: {
        chart0: [],
        chart1: [],
        chart2: [],
        chart3: [],
        trend: [],
        table: []
    },
    cascadeFilters: {
        txSlider: []
    },
    tableFilter: {
        pageIndex: 0,
        pageSize: 10,
        total: undefined,
        sort: undefined,
        direction: undefined
    }
};

/*
 *  Actions express unique events that happen throughout your application
 */
const stateActions = {
    load: createAction(
        `[${StateConfig.Name}] Load`,
        props<{ filter: any; tableFilter: any }>()
    ),
    loadFiltered: createAction(
        `[${StateConfig.Name}] Load filtered`,
        props<{ filter: any }>()
    ),
    error: createAction(
        `[${StateConfig.Name}] Load error`,
        props<{ error: string }>()
    ),
    success: createAction(
        `[${StateConfig.Name}] Load success`,
        props<{ data: IClientSlowTransactionsLarge }>()
    ),
    loadFilteredSuccess: createAction(
        `[${StateConfig.Name}] Load filtered success`,
        props<{ data: any }>()
    ),
    reset: createAction(`[${StateConfig.Name}] Reset`),
    saveCascadeFilters: createAction(
        `[${StateConfig.Name}] Save cascade filters`,
        props<{ data: any }>()
    )
};

/*
 * Reducer functions handle these transitions by determining which actions to
 * handle based on the action's type
 */
const reducers = createReducer(
    initialState,
    on(stateActions.load, (state, { filter }) => ({
        ...state,
        loading: true,
        error: '',
        filter
    })),
    on(stateActions.loadFiltered, (state, { filter }) => ({
        ...state,
        loadingFiltered: true,
        error: '',
        filter: {
            ...state.filter,
            ...filter
        }
    })),
    on(stateActions.error, (state, { error }) => ({
        ...state,
        loading: false,
        loadingFiltered: false,
        error,
        isLoaded: true
    })),
    on(stateActions.success, successStateWithoutTableFilter),
    on(stateActions.loadFilteredSuccess, (state, { data }) => {
        return {
            ...state,
            loadingFiltered: false,
            data: {
                ...state.data,
                ...data
            }
        };
    }),
    on(stateActions.reset, () => ({
        ...initialState
    })),
    on(stateActions.saveCascadeFilters, (state, { data }) => ({
        ...state,
        cascadeFilters: data
    }))
);

export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}

/*
 * Selectors are pure functions used for obtaining slices of store state.
 */
const stateSelector = createFeatureSelector<AppState, State>(StateConfig.Key);
const stateSelectors = {
    isLoaded: createSelector(stateSelector, (state: State) => state.isLoaded),
    loading: createSelector(stateSelector, (state: State) => state.loading),
    loadingFiltered: createSelector(
        stateSelector,
        (state: State) => state.loadingFiltered
    ),
    data: createSelector(stateSelector, (state: State) => state.data),
    error: createSelector(stateSelector, (state: State) => state.error),
    filter: createSelector(stateSelector, (state: State) => state.filter),
    cascadeFilters: createSelector(
        stateSelector,
        (state: State) => state.cascadeFilters
    ),
    tableFilter: createSelector(
        stateSelector,
        (state: State) => state.tableFilter
    )
};

/*
 * Effects use streams to provide new sources of actions to reduce state
 * based on external interactions such as network requests, web socket messages
 * and time-based events.
 */
@Injectable()
export class StateEffects extends WidgetEffects<IClientSlowTransactionsLarge> {
    constructor(
        public actions$: Actions,
        public service: WidgetService,
        public store: Store<AppState>
    ) {
        super(
            actions$,
            service,
            store,
            stateActions,
            {
                cascadeFilters: [],
                loadLarge: {
                    key: 'pf-cst'
                }
            },
            stateSelector
        );
    }

    @Effect()
    _load = this.loadLarge;
    @Effect()
    _cascadeFilters = this.loadCascadeFilters;
    @Effect()
    _scanReset = this.scanReset;
    @Effect()
    _instanceReset = this.instanceReset;
    @Effect()
    _loadFiltered = this.actions$.pipe(
        ofType(this.actions.loadFiltered),
        withLatestFrom(this.store.select(serviceIdSelector)),
        withLatestFrom(this.store.select(instanceSelector)),
        withLatestFrom(this.store.select(scanIdSelector)),
        withLatestFrom(this.store.select(scanLongIdSelector)),
        withLatestFrom(this.store.select(customerIdSelector)),
        switchMap(
            ([
                [
                    [[[{ filter, tableFilter }, serviceId], instance], scanId],
                    scanLongId
                ],
                customerId
            ]) => {
                return this.service
                    .loadLarge(
                        { key: 'pf-cst-filtered' },
                        {
                            serviceId,
                            instanceId: instance,
                            scanId,
                            scanLongId,
                            filter,
                            tableFilter,
                            customerId,
                            providerId: instance ? instance.serviceId : null
                        }
                    )
                    .pipe(
                        map((data: any) => {
                            return this.actions.loadFilteredSuccess({ data });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                this.actions.error({ error: error.message })
                            );
                        })
                    );
            }
        )
    );
}

export const stateFeatures: StateFeatures = {
    config: StateConfig,
    actions: stateActions,
    selectors: stateSelectors
};
