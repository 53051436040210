import {
    createReducer,
    on,
    Action,
    createAction,
    props,
    createSelector,
    createFeatureSelector,
    Store
} from '@ngrx/store';
import { StateFeatures, Widgets } from '../../../models/widgets';
import { Injectable } from '@angular/core';
import { WidgetService } from '../../../../services/widget.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState } from '../../../../core.state';
import { IOOTBInventoryUpgradeabilityLarge } from '../../../models/widgets/large/upgradeability/ootb-inventory.model';
import { WidgetEffects } from '../../widget.effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { scanSelector } from '../../../general/general.selectors';

type State = typeof Widgets.Large.Upgradeability.OOTBINVENTORY;
const name = 'Widget - Upgraddeability -  Out of the box inventory large';
enum StateConfig {
    Name = 'Widget - Upgraddeability -  Out of the box inventory large',
    Key = 'up_lw_ootb_inv'
}
// First storage state object
const initialState: State = {
    isLoaded: false,
    loading: false,
    error: '',
    filter: {},
    data: {
        tree: [],
        dependenciesTypes: []
    },
    cascadeFilters: {},
    tableFilter: {
        pageIndex: 0,
        pageSize: 10,
        total: undefined,
        sort: undefined,
        direction: undefined
    }
};

/*
 *  Actions express unique events that happen throughout your application
 */
const stateActions = {
    load: createAction(
        `[${name}] Load`,
        props<{ filter: any; tableFilter: any }>()
    ),
    loadTreeNode: createAction(
        `[${name}] Load Tree Node`,
        props<{ index: number[]; typeId: string; pageNumber: number }>()
    ),
    error: createAction(`[${name}] Load error`, props<{ error: string }>()),
    success: createAction(
        `[${name}] Load success`,
        props<{ data: IOOTBInventoryUpgradeabilityLarge }>()
    ),
    successTreeNode: createAction(
        `[${name}] Success Load Tree Node`,
        props<{ index: number[]; data: any }>()
    ),
    reset: createAction(`[${name}] Reset`),
    resetTree: createAction(`[${name}] Reset Tree`),
    saveCascadeFilters: createAction(
        `[${name}] Save cascade filters`,
        props<{ data: any }>()
    )
};

/*
 * Reducer functions handle these transitions by determining which actions to
 * handle based on the action's type
 */
const reducers = createReducer(
    initialState,
    on(stateActions.load, (state, { filter }) => ({
        ...state,
        loading: true,
        error: '',
        filter
    })),
    on(stateActions.error, (state, { error }) => ({
        ...state,
        loading: false,
        error,
        isLoaded: true
    })),
    on(stateActions.success, (state, { data }) => {
        const { ...newData } = data;
        return {
            ...state,
            loading: false,
            isLoaded: true,
            data: newData
        };
    }),
    on(stateActions.successTreeNode, (state, { data, index }) => {
        const newData = JSON.parse(JSON.stringify(state.data));
        newData.tree[index[0]].children[index[1]].page = data.meta.pageIndex;
        newData.tree[index[0]].children[index[1]].children.splice(
            0,
            newData.tree[index[0]].children[index[1]].children.length,
            ...data.node.map(node => {
                return node;
            })
        );
        if (data.meta.total > 10) {
            newData.tree[index[0]].children[index[1]].children.push({
                value: {
                    'Configuration Elements': 'load more elements',
                    'Configuration Elements Total': '',
                    'Last Update': '',
                    'Created On': ''
                },
                pageElement: true,
                pageIndex: data.meta.pageIndex,
                total: data.meta.total,
                index: newData.tree[index[0]].children[index[1]].index,
                typeId: newData.tree[index[0]].children[index[1]].value.typeId,
                children: []
            });
        }
        return {
            ...state,
            data: newData
        };
    }),
    on(stateActions.reset, () => ({
        ...initialState
    })),
    on(stateActions.resetTree, state => ({
        ...state,
        data: {
            ...state.data,
            tree: []
        }
    })),
    on(stateActions.saveCascadeFilters, (state, { data }) => ({
        ...state,
        cascadeFilters: data
    }))
);
export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}

/*
 * Selectors are pure functions used for obtaining slices of store state.
 */
const stateSelector = createFeatureSelector<AppState, State>(StateConfig.Key);
const stateSelectors = {
    isLoaded: createSelector(stateSelector, (state: State) => state.isLoaded),
    loading: createSelector(stateSelector, (state: State) => state.loading),
    data: createSelector(stateSelector, (state: State) => state.data),
    error: createSelector(stateSelector, (state: State) => state.error),
    filter: createSelector(stateSelector, (state: State) => state.filter),
    cascadeFilters: createSelector(
        stateSelector,
        (state: State) => state.cascadeFilters
    ),
    tableFilter: createSelector(
        stateSelector,
        (state: State) => state.tableFilter
    )
};

/*
 * Effects use streams to provide new sources of actions to reduce state
 * based on external interactions such as network requests, web socket messages
 * and time-based events.
 */
@Injectable()
export class StateEffects extends WidgetEffects<
    IOOTBInventoryUpgradeabilityLarge
> {
    constructor(
        public actions$: Actions,
        public service: WidgetService,
        public store: Store<AppState>
    ) {
        super(
            actions$,
            service,
            store,
            stateActions,
            {
                cascadeFilters: [],
                loadLarge: {
                    key: 'up-ootb-inventory'
                }
            },
            stateSelector
        );
    }

    @Effect()
    _load = this.loadLarge;
    @Effect()
    _cascadeFilters = this.loadCascadeFilters;
    @Effect()
    _scanReset = this.scanReset;
    @Effect()
    _instanceReset = this.instanceReset;
    @Effect()
    _loadTreeNode = this.actions$.pipe(
        ofType(this.actions.loadTreeNode),
        withLatestFrom(this.store.select(scanSelector)),
        switchMap(([{ filter }, scan]) => {
            const { index } = filter;
            return this.service
                .loadLarge(
                    { key: 'up-ootb-inventory-node' },
                    {
                        instanceId: 0,
                        filter: { version: scan.version, ...filter }
                    }
                )
                .pipe(
                    map((data: any) => {
                        return this.actions.successTreeNode({ index, data });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(this.actions.error({ error: error.message }));
                    })
                );
        })
    );
}

export const stateFeatures: StateFeatures = {
    config: StateConfig,
    actions: stateActions,
    selectors: stateSelectors
};
