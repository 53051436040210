import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'csv2array'
})
export class Csv2ArrayPipe implements PipeTransform {
    constructor() {}

    transform(value: string, args: { decimal?: any; date?: any } = {}): any {
        try {
            if (
                value !== null &&
                value !== undefined &&
                typeof value === 'string'
            )
                return value.split(',');
            if (value !== null && value !== undefined && Array.isArray(value))
                return value.map(el => {
                    if (typeof el === 'string') return el;
                    return el.name || '';
                });
            return value;
        } catch (error) {
            console.error('csv2array pipe error:', error);
            return value || '';
        }
    }
}
