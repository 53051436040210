<div class="flex px-4 pt-1 text-xs">
  <div class="text-secondary-500 mr-2">Display</div>
  <mat-form-field style="margin-top: -12px; width: 88px;">
    <mat-select
      aria-label="Select to see only closed, open or All issues for the current scan"
      matTooltip="Select to see only closed, open or All issues for the current scan"
      [value]="mainFilter | async"
      [ngStyle]="{ 'width': '85px' }"
      name="current-display"
      color="primary"
      (selectionChange)="handleScanChange($event)"
    >
      <mat-option *ngFor="let displayType of [{ label: 'All', value: 'all' }, { label: 'Opened', value: 'opened' }, { label: 'Closed', value: 'closed' }]"
                  [value]="displayType.value">
        {{ displayType.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
