<div class="container">
  <mat-form-field appearance="outline" class="w-full dateSelector filter-mat-label">
    <mat-label>Reason *</mat-label>
    <mat-select
      [formControl]="reasonFormControl"
    >
      <mat-option
        *ngFor="let reason of reasons"
        [value]="reason.reason"
      >
        {{reason.reason}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="w-full" style="min-width: 400px; position: relative;" appearance="outline">
    <mat-label>Description *</mat-label>
    <textarea #desc maxlength="250" [formControl]="descriptionFormControl" matInput></textarea>
    <div style="position: absolute; bottom: -20px; right: -5px;">{{desc.value.length}}/250</div>
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Expiration Date</mat-label>
    <qcbi-expiration [formControl]="expirationControl"></qcbi-expiration>
  </mat-form-field>
</div>
<div class="actions flex justify-center gap-8">
  <button
	  mat-raised-button
	  color="primary"
	  class="w-full"
	  [disabled]="!validFields()"
	  (click)="submitChanges()"
	  cdkFocusInitial
  >
	  Write off
  </button>
  <button
	  mat-button
	  class="w-full"
	  (click)="close()"
  >
	  Cancel
  </button>
</div>
