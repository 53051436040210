export const filtersConfig: {
    [key: string]: {
        label: string;
        tooltip: string;
        filterKey?: string;
        noData?: boolean;
        type?: 'input' | 'date';
        filterDateKeys?: {
            begin: string;
            end: string;
        };
    };
} = {
    issue_status: {
        label: 'status',
        tooltip: 'status'
    },
    update_set_name: {
        label: 'update set name',
        tooltip: 'update set name'
    },
    application: {
        label: 'application',
        tooltip: 'application'
    },
    area: {
        label: 'impact area',
        tooltip: 'impact area',
        filterKey: 'area_id'
    },
    severity: {
        label: 'severity',
        tooltip: 'severity',
        filterKey: 'severity_id'
    },
    tag: {
        label: 'tags',
        tooltip: 'tags',
        filterKey: 'debt_manager_tag_id'
    },
    quality_gate_tag: {
        label: 'quality gate',
        tooltip: 'quality gate'
    },
    team: {
        label: 'teams',
        tooltip: 'teams',
        filterKey: 'teams'
    },
    issue_type: {
        label: 'issue type',
        tooltip: 'issue type',
        filterKey: 'best_practice_name_id'
    },
    ce_type: {
        label: 'CE type',
        tooltip: 'CE type'
    },
    ce_name: {
        label: 'CE name',
        tooltip: 'CE name'
    },
    write_off_status: {
        label: 'write off status',
        tooltip: 'write off status'
    },
    issue_id: {
        label: 'issue id',
        tooltip: 'issue id',
        noData: true,
        type: 'input',
        filterKey: 'delta_issues_long_id'
    },
    expiration_date: {
        label: 'write off expiration date',
        tooltip: 'write off expiration date',
        noData: true,
        type: 'date',
        filterDateKeys: {
            begin: 'expiration_date_gte',
            end: 'expiration_date_lte'
        }
    },
    issue_open_date: {
        label: 'issue open date',
        tooltip: 'issue open date range',
        noData: true,
        type: 'date',
        filterDateKeys: {
            begin: 'issue_open_date_gte',
            end: 'issue_open_date_lte'
        }
    },
    issue_closed_date: {
        label: 'issue closed date',
        tooltip: 'issue closed date range',
        noData: true,
        type: 'date',
        filterDateKeys: {
            begin: 'issue_closed_date_gte',
            end: 'issue_closed_date_lte'
        }
    },
    approver: {
        label: 'write off approver',
        tooltip: 'write off approver'
    },
    requester: {
        label: 'write off requester',
        tooltip: 'write off requester'
    },
    reason: {
        label: 'write off reason',
        tooltip: 'write off reason',
        filterKey: 'reason_name_id'
    }
};
