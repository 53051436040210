import {
    PagerOptions,
    SortOptions
} from '../../../../../components/tables/server.component';
import { FilterOptionsDictionary } from '../../../../../shared/selectable-filters/selectable-filters.component';
import { StateConfig } from './dm-new.config';
import { Action, createReducer, on } from '@ngrx/store';
import { stateActions } from './dm-new.actions';
import { GeneralActions } from '../../../general/general.actions';
import { dmNewViewActions } from '../../../views/debt-manager-new/dm-new-view.actions';

export type BaseFiltersDictionary = {
    [filterKey: string]: { [optionValue: string]: any };
};

export type ViewState = {
    loading: boolean;
    data: any[];
    kpis: {
        issues: number;
        td: number;
    };
    meta: PagerOptions & SortOptions;
    filters: {
        filterOptions: FilterOptionsDictionary;
        baseFilterOptions: FilterOptionsDictionary;
        selectedOptions: FilterOptionsDictionary;
    };
    columnsConfig?: { col: string[] };
    initialState: Omit<ViewState, 'initialState'>;
};

export type State = {
    home: ViewState;
    [viewId: number]: ViewState;
    currentViewData: string;
};

export type FeatureState = {
    [StateConfig.Key]: State;
};

const ISSUE_STATUS_OPTIONS = [
    { value: 'OPEN', label: 'open' },
    { value: 'REQUESTED', label: 'pending write off' },
    { value: 'WRITTENOFF', label: 'written off' },
    { value: 'CLOSED', label: 'closed' }
];

export const CONDITIONAL_FILTERS_TO_ADD = {
    COMMON: [
        'issue_status',
        'application',
        'area',
        'severity',
        'tag',
        'issue_type',
        'ce_type',
        'ce_name',
        'issue_id'
    ],
    OPEN: ['write_off_status', 'issue_open_date'],
    REQUESTED: ['expiration_date', 'requester', 'reason', 'issue_open_date'],
    WRITTENOFF: [
        'expiration_date',
        'approver',
        'requester',
        'reason',
        'issue_open_date'
    ],
    CLOSED: ['expiration_date', 'issue_closed_date']
};

const initialHomeState: Omit<ViewState, 'initialState'> = {
    loading: false,
    filters: {
        filterOptions: {
            issue_status: [...ISSUE_STATUS_OPTIONS]
        },
        baseFilterOptions: {},
        selectedOptions: {
            issue_status: [{ value: 'OPEN', label: 'open' }],
            area: [],
            severity: [
                {
                    label: 'high',
                    value: 1,
                    disabled: false
                },
                {
                    label: 'medium',
                    value: 2,
                    disabled: false
                },
                {
                    label: 'low',
                    value: 3,
                    disabled: false
                }
            ]
        }
    },
    meta: {
        pageIndex: 0,
        pageSize: 10,
        total: undefined,
        from: 1,
        to: undefined,
        lastPage: undefined,
        sort: undefined,
        direction: undefined
    },
    data: [],
    kpis: { issues: undefined, td: undefined }
};

export const initialState: State = {
    home: {
        ...initialHomeState,
        initialState: initialHomeState
    },
    currentViewData: 'home'
};

const reducers = createReducer(
    initialState,
    on(
        dmNewViewActions.createViewSuccess,
        dmNewViewActions.openSavedViewNew,
        (state: State, { newView }) => {
            const newViewInitialState: Omit<ViewState, 'initialState'> = {
                ...initialHomeState,
                meta: {
                    ...initialHomeState.meta,
                    pageSize:
                        newView.viewSettings.pageSize ||
                        initialHomeState.meta.pageSize,
                    sort:
                        newView.viewSettings.sort || initialHomeState.meta.sort,
                    direction:
                        newView.viewSettings.direction ||
                        initialHomeState.meta.direction
                },
                filters: {
                    ...initialHomeState.filters,
                    selectedOptions: newView.viewSettings.filters
                },
                columnsConfig: newView.viewSettings.columnsConfig
            };
            return {
                ...state,
                [newView.id]: {
                    ...newViewInitialState,
                    initialState: newViewInitialState
                }
            };
        }
    ),
    on(stateActions.createCurrentViewOptions, (state: State, { view }) => {
        const viewInitialState: Omit<ViewState, 'initialState'> = {
            ...initialHomeState,
            filters: {
                ...initialHomeState.filters,
                selectedOptions: view.viewSettings.filters
            },
            meta: {
                ...initialHomeState.meta,
                pageSize:
                    view.viewSettings.pageSize ||
                    initialHomeState.meta.pageSize,
                sort: view.viewSettings.sort || initialHomeState.meta.sort,
                direction:
                    view.viewSettings.direction ||
                    initialHomeState.meta.direction
            },
            columnsConfig: view.viewSettings.columnsConfig
        };
        return {
            ...state,
            [view.id]: {
                ...viewInitialState,
                initialState: viewInitialState
            },
            currentViewData: view.id
        };
    }),
    on(
        dmNewViewActions.updateCurrentViewFiltersSuccess,
        (
            state: State,
            { filters, viewId, columnsConfig, pageSize, sort, direction }
        ) => {
            return {
                ...state,
                [viewId]: {
                    ...state[viewId],
                    initialState: {
                        ...state[viewId].initialState,
                        filters: {
                            ...state[viewId].initialState.filters,
                            selectedOptions: filters
                        },
                        meta: {
                            ...state[viewId].initialState.meta,
                            sort,
                            direction,
                            pageSize
                        },
                        columnsConfig,
                        pageSize
                    }
                }
            };
        }
    ),
    on(stateActions.setCurrentView, (state: State, { viewId }) => {
        return {
            ...state,
            currentViewData: viewId
        };
    }),
    on(stateActions.load, GeneralActions.setDashboardDateRange, state => ({
        ...state,
        [state.currentViewData]: {
            ...state[state.currentViewData],
            loading: true
        }
    })),
    on(stateActions.loadSuccess, (state: State, { data, meta }) => ({
        ...state,
        [state.currentViewData]: {
            ...state[state.currentViewData],
            data,
            meta: {
                ...state[state.currentViewData].meta,
                ...meta
            },
            loading: false
        }
    })),
    on(stateActions.kpisSuccess, (state: State, { kpis }) => ({
        ...state,
        [state.currentViewData]: {
            ...state[state.currentViewData],
            kpis
        }
    })),
    on(stateActions.error, (state: State) => ({ ...state, loading: false })),
    on(
        stateActions.changeTablePageOrSort,
        (state: State, { type, ...meta }) => ({
            ...state,
            [state.currentViewData]: {
                ...state[state.currentViewData],
                loading: true,
                meta: { ...state[state.currentViewData].meta, ...meta }
            }
        })
    ),
    on(stateActions.loadSelectedFiltersSuccess, (state: State, { filters }) => {
        return {
            ...state,
            [state.currentViewData]: {
                ...state[state.currentViewData],
                filters: {
                    ...state[state.currentViewData].filters,
                    filterOptions: {
                        ...state[state.currentViewData].filters.filterOptions,
                        ...filters
                    }
                }
            }
        };
    }),
    on(stateActions.setBaseFilters, (state: State, { baseFilters }) => {
        return {
            ...state,
            [state.currentViewData]: {
                ...state[state.currentViewData],
                filters: {
                    ...state[state.currentViewData].filters,
                    baseFilterOptions: {
                        ...state[state.currentViewData].filters
                            .baseFilterOptions,
                        ...baseFilters
                    }
                }
            }
        };
    }),
    on(stateActions.changeSelectedOptions, (state: State, { options, key }) => {
        const newSelectedOptions = {
            ...state[state.currentViewData].filters.selectedOptions,
            [key]: [...options]
        };
        if (key === 'issue_status') {
            for (const k in CONDITIONAL_FILTERS_TO_ADD) {
                if (k !== key && k !== 'COMMON') {
                    CONDITIONAL_FILTERS_TO_ADD[k].map(f => {
                        if (newSelectedOptions.hasOwnProperty(f)) {
                            delete newSelectedOptions[f];
                        }
                    });
                }
            }
        }
        return {
            ...state,
            [state.currentViewData]: {
                ...state[state.currentViewData],
                loading: true,
                filters: {
                    ...state[state.currentViewData].filters,
                    selectedOptions: newSelectedOptions
                },
                meta: {
                    ...state[state.currentViewData].meta,
                    pageIndex: 0
                }
            }
        };
    }),
    on(stateActions.resetFilters, (state: State) => {
        const emptySelected = {
            ...state[state.currentViewData].filters.selectedOptions
        };
        const newAvailableFilters = [
            ...CONDITIONAL_FILTERS_TO_ADD.COMMON,
            ...CONDITIONAL_FILTERS_TO_ADD[
                state[state.currentViewData].initialState.filters
                    .selectedOptions.issue_status[0].value
            ]
        ];
        for (const k in emptySelected) {
            if (newAvailableFilters.includes(k)) {
                if (emptySelected.hasOwnProperty(k)) emptySelected[k] = [];
            } else {
                delete emptySelected[k];
            }
        }
        return {
            ...state,
            [state.currentViewData]: {
                ...state[state.currentViewData],
                loading: true,
                filters: {
                    ...state[state.currentViewData].initialState.filters,
                    selectedOptions: {
                        ...emptySelected,
                        ...state[state.currentViewData].initialState.filters
                            .selectedOptions
                    }
                },
                data: [],
                kpis: { issues: undefined, td: undefined },
                meta: {
                    ...state[state.currentViewData].initialState.meta
                }
            }
        };
    }),
    on(stateActions.addFilterToDisplay, (state: State, { key }) => ({
        ...state,
        [state.currentViewData]: {
            ...state[state.currentViewData],
            filters: {
                ...state[state.currentViewData].filters,
                selectedOptions: {
                    ...state[state.currentViewData].filters.selectedOptions,
                    [key]: []
                }
            }
        }
    })),
    on(stateActions.removeFilterFromDisplay, (state: State, { key }) => {
        const copyFilters = {
            ...state[state.currentViewData].filters.selectedOptions
        };
        delete copyFilters[key];
        const copyBaseFilters = {
            ...state[state.currentViewData].filters.baseFilterOptions
        };
        if (copyBaseFilters.hasOwnProperty(key)) delete copyBaseFilters[key];
        return {
            ...state,
            [state.currentViewData]: {
                ...state[state.currentViewData],
                loading: true,
                filters: {
                    ...state[state.currentViewData].filters,
                    selectedOptions: copyFilters,
                    baseFilterOptions: copyBaseFilters
                },
                meta: {
                    ...state[state.currentViewData].initialState.meta
                }
            }
        };
    }),
    on(dmNewViewActions.deleteViewSuccess, (state: State, { viewId }) => {
        const stateCopy = { ...state };
        if (stateCopy.hasOwnProperty(viewId)) delete stateCopy[viewId];
        return stateCopy;
    }),
    on(stateActions.changeColumnConfiguration, (state: State, { config }) => {
        return {
            ...state,
            [state.currentViewData]: {
                ...state[state.currentViewData],
                columnsConfig: config
            }
        };
    })
);

export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}
