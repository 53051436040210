<h1 mat-dialog-title>New Tag</h1>
<div mat-dialog-content>
	<mat-form-field class="searchField">
		<mat-label>Tag</mat-label>
		<input #tagName [(ngModel)]="inputNameValue" type="text" matInput placeholder="Write here">
	</mat-form-field>
</div>
<div mat-dialog-actions class="flex justify-between">
	<button
		mat-raised-button
		color="primary"
		[disabled]="tagName.value.length === 0"
		(click)="createTag()"
	>
		{{ data && data.id ? 'Edit' : 'Create' }}
	</button>
	<button
		mat-button
		mat-dialog-close
	>
		Close
	</button>
</div>
