export function formatDateToYYYYMMdd(d: Date): string {
    if (d === null) return null;
    const _d = new Date(d);
    return (
        _d.getFullYear().toString() +
        ('0' + (_d.getMonth() + 1)).slice(-2) +
        ('0' + _d.getDate()).slice(-2)
    );
}

export function formatDateToDashedYYYYMMdd(d: Date): string {
    if (d === null) return null;
    const formattedDate = formatDateToYYYYMMdd(d);
    return (
        formattedDate.substr(0, 4) +
        '-' +
        formattedDate.substr(4, 2) +
        '-' +
        formattedDate.substr(6, 2)
    );
}

export function formatDateFilterToDashedYYYYMMdd(filter: {
    begin: Date | null;
    end: Date | null;
}): { begin: string; end: string } {
    if (!filter) return { begin: null, end: null };
    return {
        begin: filter.begin ? formatDateToDashedYYYYMMdd(filter.begin) : null,
        end: filter.end ? formatDateToDashedYYYYMMdd(filter.end) : null
    };
}

export function getFormattedExpirationDate(
    expirationDate: string,
    expirationTime: string,
    timeFormat: string
): string {
    const d = new Date(expirationDate);
    const locale = timeFormat === 'US' ? 'en-US' : 'en-GB';
    return `${d.toLocaleDateString(locale)} ${expirationTime}`;
}
