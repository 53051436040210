import { Component, HostListener, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, iif, Observable, of } from 'rxjs';

import {
    AppState,
    LocalStorageService,
    routeAnimations
} from '../core/core.module';
import {
    dashboardSelector,
    initialLoadingSelector,
    instanceLoadingSelector,
    isEmptyView,
    mainFilterSetterSelector,
    scanLoadingSelector,
    showScansSelect,
    sideMenuExpandedSelector
} from '../core/state/general/general.selectors';
import {
    qcAuthActions,
    selectAuthStatusOff,
    selectAuthStatusOn,
    selectIsAuthenticated
} from '../core/auth-lib';
import {
    ActivatedRouteSnapshot,
    ChildrenOutletContexts,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    Router
} from '@angular/router';
import {
    debounce,
    debounceTime,
    delay,
    filter,
    map,
    scan,
    switchMap
} from 'rxjs/operators';
import { DebtManagerHomeLageComponent } from '../components/widgets/large/debt-manager-new/dm-home/debt-manager-home.component';

@Component({
    selector: 'qcbi-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [routeAnimations]
})
export class AppComponent implements OnInit {
    isAuthenticated$: Observable<boolean>;
    selectAuthStatusOn$: Observable<boolean>;
    selectAuthStatusOff$: Observable<boolean>;
    initialLoading$: Observable<boolean>;
    isEmptyView$: Observable<boolean>;
    showScans$: Observable<boolean>;
    isNotPeerReviewDashboard$: Observable<boolean>;
    loadingLayout$: Observable<boolean>;
    lazyLoading$: Observable<boolean>;
    sideMenuOpened$: Observable<boolean>;

    constructor(
        private store: Store<AppState>,
        private storageService: LocalStorageService,
        public router: Router,
        private contexts: ChildrenOutletContexts
    ) {}

    @HostListener('window:focus')
    handleWindowFocus() {
        this.store.dispatch(qcAuthActions.windowFocus());
    }

    @HostListener('window:blur')
    async handleWindowBlur() {
        this.store.dispatch(qcAuthActions.windowBlur());
    }

    ngOnInit(): void {
        this.store.dispatch(qcAuthActions.init());
        this.storageService.testLocalStorage();
        this.initObservables();
        this.defineRouterStrategy();
    }

    getRouterOutletState() {
        return this.contexts
            .getContext('primary')
            ?.route?.snapshot?.url.join('/');
    }

    isDownload(): boolean {
        return this.router.url.includes('/download');
    }

    private defineRouterStrategy(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = function(
            future: ActivatedRouteSnapshot,
            curr: ActivatedRouteSnapshot
        ) {
            return (
                (curr.component !== DebtManagerHomeLageComponent ||
                    Object.keys(future.queryParams).length > 0 ||
                    Object.keys(curr.queryParams).length > 0) &&
                future.routeConfig === curr.routeConfig
            );
        };
    }

    private initObservables(): void {
        this.sideMenuOpened$ = this.store.select(sideMenuExpandedSelector);
        this.initialLoading$ = this.store.select(initialLoadingSelector);
        this.isEmptyView$ = this.store.select(isEmptyView);
        this.showScans$ = this.store.select(showScansSelect);
        this.isNotPeerReviewDashboard$ = this.store
            .select(dashboardSelector)
            .pipe(map(dashboard => dashboard?.url !== 'peer-review'));
        this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
        this.selectAuthStatusOn$ = this.store.pipe(select(selectAuthStatusOn));
        this.selectAuthStatusOff$ = this.store.pipe(
            select(selectAuthStatusOff)
        );
        this.loadingLayout$ = combineLatest([
            this.initialLoading$,
            this.store.select(instanceLoadingSelector),
            this.store.select(mainFilterSetterSelector),
            this.store.select(scanLoadingSelector),
            this.selectAuthStatusOff$
        ]).pipe(map(obs => obs.some(x => x)));
        this.lazyLoading$ = this.router.events.pipe(
            filter(
                event =>
                    event instanceof RouteConfigLoadStart ||
                    event instanceof RouteConfigLoadEnd
            ),
            switchMap(event => {
                if (event instanceof RouteConfigLoadStart)
                    return of(true).pipe(delay(500));
                return of(false);
            })
        );
    }
}
