import { createReducer, on, Action } from '@ngrx/store';
import { AuthLibState, AuthLibStatus } from './models';
import { qcAuthActions } from './actions';

export const initialState: AuthLibState = {
    onWindow: true,
    windowId: Math.random()
        .toString(36)
        .substr(2, 9),
    status: AuthLibStatus.OFF,
    isAuthenticated: false,
    onEmulation: false,
    user: null,
    emulatedUser: null,
    logoutOnFocus: false
};

const reducer = createReducer(
    initialState,
    on(qcAuthActions.initSuccess, (state, { type, ...newState }) => {
        return {
            ...state,
            status: AuthLibStatus.ON,
            ...newState
        };
    }),
    on(qcAuthActions.startEmulationSuccess, (state, { emulatedUser }) => {
        return {
            ...state,
            onEmulation: true,
            emulatedUser
        };
    }),
    on(qcAuthActions.endEmulationSuccess, state => {
        return {
            ...state,
            onEmulation: false,
            emulatedUser: null
        };
    }),
    on(qcAuthActions.windowBlur, state => {
        return {
            ...state,
            onWindow: false
        };
    }),
    on(qcAuthActions.windowFocus, state => {
        return {
            ...state,
            onWindow: true
        };
    }),
    on(qcAuthActions.logoutOnFocus, state => {
        return {
            ...state,
            logoutOnFocus: true
        };
    })
);

export function authLibReducer(
    state: AuthLibState | undefined,
    action: Action
): AuthLibState {
    return reducer(state, action);
}
