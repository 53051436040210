import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from './tags.models';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TagsService {
    constructor(private http: HttpClient) {}

    fetchTags(): Observable<Tag[]> {
        return this.http.get(`${environment.apiUrl}/v2/debt-manager-tag`).pipe(
            map((res: any) => {
                return res.data.map(tag => {
                    const t = new Tag();
                    t.id = tag.id;
                    t.name = tag.attributes.name;
                    t.shouldPropagate = tag.attributes['should-propagate'];
                    return t;
                });
            })
        );
    }

    fetchInstanceTags(instanceId): Observable<Tag[]> {
        return this.http
            .get(
                `${environment.apiUrl}/v2/debt-manager-tag?filter[instance_id]=${instanceId}`
            )
            .pipe(
                map((res: any) => {
                    return res.data.map(tag => {
                        const t = new Tag();
                        t.id = tag.id;
                        t.name = tag.attributes.name;
                        t.shouldPropagate = tag.attributes['should-propagate'];
                        return t;
                    });
                })
            );
    }

    createTag(name: string) {
        return this.http.post(`${environment.apiUrl}/v2/debt-manager-tag`, {
            data: {
                type: 'debt-manager-tag',
                attributes: {
                    name
                }
            }
        });
    }

    editTag(id, name) {
        return this.http.patch(
            `${environment.apiUrl}/v2/debt-manager-tag/${id}`,
            {
                data: {
                    id,
                    type: 'debt-manager-tag',
                    attributes: {
                        name
                    }
                }
            }
        );
    }

    deleteTag(id) {
        return this.http.delete(
            `${environment.apiUrl}/v2/debt-manager-tag/${id}`
        );
    }
}
