<div class="mb-4">
    <mat-chip-list aria-label="Fish selection" *ngIf="tags.length; else noTags">
        <mat-chip
            *ngFor="let tag of tags"
            [ngStyle]="{
        background: colorService.getColor(tag),
        color: colorService.pickTextColorBasedOnBgColorSimple(tag)
      }"
        >
            {{ tag }}
        </mat-chip>
    </mat-chip-list>
    <ng-template #noTags>
        no tags defined
    </ng-template>
</div>
