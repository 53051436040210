import { DownloadXlsState } from './download-formatted-file.models';
import { Action, createReducer, on } from '@ngrx/store';
import { DownloadFormattedFileActions } from './download-formatted-file.actions';

export const initialState: DownloadXlsState = {
    downloadsInProgress: {}
};

const reducer = createReducer(
    initialState,
    on(DownloadFormattedFileActions.download, (state, { id, format }) => {
        const newDownloadProgress = {
            ...state.downloadsInProgress,
            [id + '_' + format]: true
        };
        return {
            ...state,
            downloadsInProgress: newDownloadProgress
        };
    }),
    on(
        DownloadFormattedFileActions.downloadSuccess,
        DownloadFormattedFileActions.downloadFail,
        (state, { id, format }) => {
            const newDownloadProgress = { ...state.downloadsInProgress };
            delete newDownloadProgress[id + '_' + format];
            return {
                ...state,
                downloadsInProgress: newDownloadProgress
            };
        }
    )
);

export function downloadFormattedFileReducer(
    state: DownloadXlsState,
    action: Action
): DownloadXlsState {
    return reducer(state, action);
}
