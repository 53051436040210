import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../core.state';
import { JiraIntegrationState } from './jira-integration.models';

export const jiraIntegrationsSelector = createFeatureSelector<
    AppState,
    JiraIntegrationState
>('jiraIntegrations');

export const batchProcessSelector = createSelector(
    jiraIntegrationsSelector,
    (state: JiraIntegrationState) => state.batchProcess
);

export const projectsSelector = createSelector(
    jiraIntegrationsSelector,
    (state: JiraIntegrationState) => state.projects
);

export const usersSelector = createSelector(
    jiraIntegrationsSelector,
    (state: JiraIntegrationState) => state.users
);

export const applicationsSelector = createSelector(
    jiraIntegrationsSelector,
    (state: JiraIntegrationState) => state.applications
);

export const configurationSelector = createSelector(
    jiraIntegrationsSelector,
    (state: JiraIntegrationState) => state.config
);

export const jiraIntegrationEnabledSelector = createSelector(
    jiraIntegrationsSelector,
    (state: JiraIntegrationState) => state.integrationEnabled
);

export const transitionsSelector = createSelector(
    jiraIntegrationsSelector,
    (state: JiraIntegrationState) => state.transitions
);

export const loadingSelector = createSelector(
    jiraIntegrationsSelector,
    (state: JiraIntegrationState) => state.loading
);

export const activityLogDataSelector = createSelector(
    jiraIntegrationsSelector,
    (state: JiraIntegrationState) => state.activityLog.data
);

export const activityLogMetaSelector = createSelector(
    jiraIntegrationsSelector,
    (state: JiraIntegrationState) => state.activityLog.meta
);
