import { Component, Input } from '@angular/core';

const SEVERITY_ICONS = {
    high: 'expand_less',
    medium: 'remove',
    low: 'expand_more'
};

@Component({
    selector: 'qcbi-severity-arrow',
    template: `
        <mat-icon [ngClass]="[severity?.toLowerCase(), 'arrow']">
            {{ getIconFromSeverity(severity?.toLowerCase()) }}
        </mat-icon>
    `,
    styleUrls: ['severity-arrow.component.scss']
})
export class SeverityArrowComponent {
    @Input() severity: string;

    getIconFromSeverity(severity: string) {
        return SEVERITY_ICONS[severity] || '';
    }
}
