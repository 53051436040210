import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState, selectRouterState } from '../../core/core.state';
import { Store } from '@ngrx/store';
import {
    dashboardSelector,
    instanceSelector,
    profilingScansSelector,
    scanIdSelector,
    scanQualityGateStatusSelector,
    scansSelector,
    selectedDateRangeSelector,
    showDateSelect,
    showScansSelect
} from '../../core/state/general/general.selectors';
import { GeneralActions } from '../../core/state/general/general.actions';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { SatDatepickerInputEvent } from 'saturn-datepicker';
import { MatSelectChange } from '@angular/material/select';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { selectCurrentUser } from '../../core/auth-lib';

const DEFAULT_URL =
    'https://docs.qualityclouds.com/qcd/quality-clouds-documentation-3997700.html';

@Component({
    selector: 'qcbi-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
    readonly TODAY = new Date();

    instance$: Observable<any>;
    scanId$: Observable<number | null>;
    scanQualityGateStatus$: Observable<string | null>;
    dashboard$: Observable<any>;
    showScansSelect$: Observable<boolean>;
    showDateSelect$: Observable<boolean>;
    breadcrumbs$: Observable<string>;
    helpUrl$: Observable<string>;

    scans: any[] = [];
    profilingScans: any[] = [];
    selectedDateRange: any;
    dateDropDown: string;
    form: FormGroup;
    dashboardUrl = '';
    dateOptions = [
        { label: 'Last week', value: 'W' },
        { label: 'Last month', value: 'M' },
        { label: 'Last quarter', value: 'Q' },
        { label: 'Last six months', value: '6' },
        { label: 'Last year', value: 'Y' }
    ];
    selectableScans = [];
    selectedDashboardUrl = '';
    timeFormat;
    private unsubscribe = new Subject<void>();

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        public router: Router
    ) {}

    ngOnInit(): void {
        this.store
            .select(selectCurrentUser)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(user => {
                this.timeFormat = user?.timeFormat;
            });
        this.scanId$ = this.store
            .select(scanIdSelector)
            .pipe(takeUntil(this.unsubscribe));
        this.scanQualityGateStatus$ = this.store
            .select(scanQualityGateStatusSelector)
            .pipe(takeUntil(this.unsubscribe));
        this.showScansSelect$ = this.store
            .select(showScansSelect)
            .pipe(takeUntil(this.unsubscribe));
        this.showDateSelect$ = this.store
            .select(showDateSelect)
            .pipe(takeUntil(this.unsubscribe));
        this.store
            .select(scansSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(scans => {
                this.scans = scans;
                this.setSelectableScans();
            });
        this.store
            .select(profilingScansSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(profilingScans => {
                this.profilingScans = profilingScans;
                this.setSelectableScans();
            });
        this.instance$ = this.store
            .select(instanceSelector)
            .pipe(takeUntil(this.unsubscribe));
        this.dashboard$ = this.store
            .select(dashboardSelector)
            .pipe(startWith({}), takeUntil(this.unsubscribe));
        this.helpUrl$ = this.store.select(dashboardSelector).pipe(
            startWith(DEFAULT_URL),
            map(
                dashboard => (dashboard && dashboard['help-url']) || DEFAULT_URL
            )
        );
        this.breadcrumbs$ = this.store.select(selectRouterState).pipe(
            map(data => {
                if (!data) return '';
                return data.state.url
                    .split('?')[0]
                    .split('/')
                    .reduce((result, urlSegment) => {
                        if (urlSegment !== '' && isNaN(+urlSegment)) {
                            urlSegment = urlSegment
                                .toLowerCase()
                                .replace(/\b\w/g, s => s.toUpperCase());
                            urlSegment = urlSegment.replace(
                                'Servicenow',
                                'ServiceNow'
                            );
                            result.push(urlSegment.replace(/-/g, ' '));
                        }
                        return result;
                    }, [])
                    .join(' / ');
            })
        );

        this.store
            .select(dashboardSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(d => {
                this.dashboardUrl = d && d.url;
                if (
                    (this.dashboardUrl === 'impact-analysis' ||
                        this.dashboardUrl === 'debt-manager' ||
                        this.dashboardUrl === 'debt-manager-new') &&
                    this.dateOptions.every(x => x.label !== 'All times')
                ) {
                    this.dateOptions.push({ label: 'All times', value: 'A' });
                } else {
                    this.dateOptions = this.dateOptions.filter(
                        o => o.label !== 'All times'
                    );
                }
            });
        this.store
            .select(dashboardSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(dashboard => {
                this.selectedDashboardUrl = dashboard ? dashboard.url : '';
                this.setSelectableScans();
            });
        this.store
            .select(selectedDateRangeSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(selectedDateRangeValue => {
                this.selectedDateRange = { ...selectedDateRangeValue };
                this.dateDropDown = undefined;
                if (this.form) this.form.reset();
            });

        this.form = new FormBuilder().group({ dateRangeSelector: undefined });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setSelectableScans() {
        if (this.selectedDashboardUrl === 'profiling') {
            this.selectableScans = this.profilingScans;
        } else if (this.selectedDashboardUrl === 'executive') {
            this.selectableScans = [];
            for (let i = 0; i < this.scans.length; i++) {
                this.selectableScans.push(this.scans[i]);
                if (this.scans[i].isBaseline) break;
            }
        } else {
            this.selectableScans = this.scans;
        }
    }

    handleScanChange({ value }) {
        const scan = this.selectableScans.find(x => +x.value === +value);
        this.store.dispatch(GeneralActions.setScan({ scan }));
    }

    handleDateChange(e: SatDatepickerInputEvent<Date>) {
        this.store.dispatch(
            GeneralActions.setDashboardDateRange({
                range: e.value || { begin: null, end: null }
            })
        );
    }

    handleDateDropDownChange(e: MatSelectChange) {
        switch (e.value) {
            case 'W':
                this.setLastWeek();
                break;
            case 'M':
                this.setLastMonth();
                break;
            case 'Q':
                this.setLastQuarter();
                break;
            case '6':
                this.setLastSixMonths();
                break;
            case 'Y':
                this.setLastYear();
                break;
            case 'A':
                this.removeDates();
                break;
            default:
                console.error(
                    `wrong date selected. there's no value for ${e.value}`
                );
                break;
        }
    }

    private removeDates() {
        this.store.dispatch(
            GeneralActions.setDashboardDateRange({
                range: { begin: null, end: null }
            })
        );
    }

    private setLastWeek() {
        const begin = new Date();
        begin.setDate(begin.getDate() - 7);
        const end = new Date();
        const range = { begin, end };
        this.store.dispatch(GeneralActions.setDashboardDateRange({ range }));
    }

    private setLastMonth() {
        this.setCurrentDateSubtractingNMonths(1);
    }

    private setLastQuarter() {
        this.setCurrentDateSubtractingNMonths(3);
    }

    private setLastSixMonths() {
        this.setCurrentDateSubtractingNMonths(6);
    }

    private setLastYear() {
        this.setCurrentDateSubtractingNMonths(12);
    }

    private setCurrentDateSubtractingNMonths(n: number) {
        const begin = new Date();
        begin.setMonth(begin.getMonth() - n);
        const end = new Date();
        const range = { begin, end };
        this.store.dispatch(GeneralActions.setDashboardDateRange({ range }));
    }
}
