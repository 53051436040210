<div class="small-input-container">
  <mat-form-field
    class="small-input"
    appearance="outline"
    floatLabel="never"
  >
    <input
      [(ngModel)]="code[0]"
      (ngModelChange)="valueChanged($event, inputOne)"
      (change)="$event.stopPropagation()"
      (paste)="pasteHandler($event)"
      matInput
      autocomplete="off"
      type="number"
      class="hide-arrows"
      maxlength="1"
      min="0"
      max="9"
      [disabled]="isDisabled"
    >
  </mat-form-field>
  <mat-form-field
    class="small-input"
    appearance="outline"
    floatLabel="never"
  >
    <input
      #inputOne
      [(ngModel)]="code[1]"
      (ngModelChange)="valueChanged($event, inputTwo)"
      (change)="$event.stopPropagation()"
      matInput
      autocomplete="off"
      type="number"
      class="hide-arrows"
      maxlength="1"
      min="0"
      max="9"
      [disabled]="isDisabled"
    >
  </mat-form-field>
  <mat-form-field
    class="small-input"
    appearance="outline"
    floatLabel="never"
  >
    <input
      #inputTwo
      [(ngModel)]="code[2]"
      (ngModelChange)="valueChanged($event, inputThree)"
      (change)="$event.stopPropagation()"
      matInput
      autocomplete="off"
      type="number"
      class="hide-arrows"
      maxlength="1"
      min="0"
      max="9"
      [disabled]="isDisabled"
    >
  </mat-form-field>
  <mat-form-field
    class="small-input"
    appearance="outline"
    floatLabel="never"
  >
    <input
      #inputThree
      [(ngModel)]="code[3]"
      (ngModelChange)="valueChanged($event, inputFour)"
      (change)="$event.stopPropagation()"
      matInput
      autocomplete="off"
      type="number"
      class="hide-arrows"
      maxlength="1"
      min="0"
      max="9"
      [disabled]="isDisabled"
    >
  </mat-form-field>
  <mat-form-field
    class="small-input"
    appearance="outline"
    floatLabel="never"
  >
    <input
      #inputFour
      [(ngModel)]="code[4]"
      (ngModelChange)="valueChanged($event, inputFive)"
      (change)="$event.stopPropagation()"
      matInput
      autocomplete="off"
      type="number"
      class="hide-arrows"
      maxlength="1"
      min="0"
      max="9"
      [disabled]="isDisabled"
    >
  </mat-form-field>
  <mat-form-field
    class="small-input"
    appearance="outline"
    floatLabel="never"
  >
    <input
      #inputFive
      [(ngModel)]="code[5]"
      (ngModelChange)="valueChanged($event, inputFive)"
      (change)="$event.stopPropagation()"
      matInput
      autocomplete="off"
      type="number"
      class="hide-arrows"
      maxlength="1"
      min="0"
      max="9"
      [disabled]="isDisabled"
    >
  </mat-form-field>
</div>
