import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'avatar' })
export class AvatarPipe implements PipeTransform {
    transform(value: string) {
        return value
            .split(' ')
            .map((el: any) => el[0])
            .slice(0, 2)
            .join('');
    }
}
