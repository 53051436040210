import {
    ComponentFactoryResolver,
    Injectable,
    InjectionToken,
    Injector
} from '@angular/core';
import { Subject } from 'rxjs';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';

export interface DrawerConfig {
    title: string;
    minWidthInPixels?: number;
    data?: any;
}

export const DRAWER_DATA = new InjectionToken<any>('drawer-data');

@Injectable({
    providedIn: 'root'
})
export class DrawerService {
    componentPortal: ComponentPortal<any>;
    title = '';
    minWidthInPixels = 0;

    private opener$ = new Subject<boolean>();

    readonly opened$ = this.opener$.asObservable();

    /**
     * opens a new drawer with the given component
     * @param componentType component to display on the drawer
     * @param config configuration of the drawer (title, min width in pixels and data to get from the component)
     * @param cfr Component Factory resolver to resolve the component
     */
    open(
        componentType: ComponentType<any>,
        config: DrawerConfig = { title: '', data: {} },
        cfr: ComponentFactoryResolver = null
    ) {
        this.title = config.title;
        this.minWidthInPixels = config.minWidthInPixels || 0;
        const portalInjector = Injector.create({
            providers: [{ provide: DRAWER_DATA, useValue: config.data }]
        });
        this.componentPortal = new ComponentPortal(
            componentType,
            null,
            portalInjector,
            cfr
        );
        this.opener$.next(true);
    }

    /**
     * closes the drawer
     */
    close() {
        this.opener$.next(false);
        this.componentPortal.detach();
    }
}
