import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserFormatService {
    private timeFormat;

    setTimeFormat(value) {
        this.timeFormat = value;
    }

    getTimeFormat(): string {
        return this.timeFormat;
    }
}
