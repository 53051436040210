import { generalSelector } from './general.selectors';
import { createSelector } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { selectAuth } from '../../auth-lib';
import { isAvailableService } from '../../../utils/general';

const ALL_CLOUDS = 99;
const SERVICENOW_ID = 1;
const SALESFORCE_ID = 2;
const OFFICE_ID = 3;

function getData(instances, onEmulation: boolean, dashboardContext) {
    return {
        top: [
            {
                name: 'All Clouds',
                image: './assets/icons/all-clouds.svg',
                type: 'all-clouds',
                path: 'all-clouds',
                disabled: !isAvailableService(dashboardContext, ALL_CLOUDS),
                id: ALL_CLOUDS
            }
        ],
        services: [
            {
                name: 'ServiceNow',
                type: 'group',
                image: './assets/icons/servicenow.svg',
                id: SERVICENOW_ID,
                disabled: !isAvailableService(dashboardContext, SERVICENOW_ID),
                children: parseInstances(instances, 'serviceNow'),
                filter: ''
            },
            {
                name: 'Salesforce',
                type: 'group',
                image: './assets/icons/salesforce.svg',
                id: SALESFORCE_ID,
                disabled: !isAvailableService(dashboardContext, SALESFORCE_ID),
                children: parseInstances(instances, 'salesForce'),
                filter: ''
            },
            {
                name: 'Office 365',
                type: 'group',
                image: './assets/icons/office365.svg',
                id: OFFICE_ID,
                disabled: !isAvailableService(dashboardContext, OFFICE_ID),
                children: parseInstances(instances, 'office365'),
                filter: ''
            }
        ],
        bottom: getBottomButtons(onEmulation)
    };
}

function getBottomButtons(onEmulation: boolean) {
    const result = [
        {
            name: 'Help',
            image: './assets/icons/help.svg',
            type: 'help',
            path: undefined
        }
    ];

    if (!onEmulation) {
        result.unshift({
            name: 'Admin',
            image: './assets/icons/open.svg',
            type: 'link',
            path: environment.adminUrl
        });
    }

    return result;
}

function parseInstances(instances, key) {
    if (!instances[key]) return [];
    return instances[key].map(instance => parseInstance(instance, key));
}

function parseInstance(instance, path) {
    return {
        name: instance.attributes.description,
        instanceId: parseInt(instance.id, 10),
        serviceId: instance.attributes['service-id'],
        type: 'instance',
        path: path.toLowerCase()
    };
}

export const sideBarSelector = createSelector(
    generalSelector,
    selectAuth,
    ({ instances, dashboardContext }, { onEmulation }) => {
        return getData(instances, onEmulation, dashboardContext);
    }
);

export const starredSideBarSelector = createSelector(
    generalSelector,
    selectAuth,
    ({ dashboardContext, userPortalConfiguration }, { onEmulation }) => {
        return getData(
            userPortalConfiguration.starredInstances,
            onEmulation,
            dashboardContext
        );
    }
);
