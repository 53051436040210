import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faBars,
    faBook,
    faCaretDown,
    faCaretUp,
    faCheck,
    faCog,
    faEdit,
    faExclamationTriangle,
    faFilter,
    faLanguage,
    faLightbulb,
    faPaintBrush,
    faPlayCircle,
    faPlus,
    faPowerOff,
    faRocket,
    faSquare,
    faStream,
    faTasks,
    faTimes,
    faTrash,
    faUserCircle,
    faWindowMaximize
} from '@fortawesome/free-solid-svg-icons';
import {
    faGithub,
    faInstagram,
    faMediumM,
    faTwitter,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { NgxSkltnModule, SkltnConfig } from 'ngx-skltn';
import { RtlSupportDirective } from './rtl-support/rtl-support.directive';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SideBarComponent } from './side-bar/side-bar.component';
import { HeaderComponent } from './header/header.component';
import { allComponents } from '../components';
import { allPipes } from '../pipes';
import { RouterModule } from '@angular/router';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { TitleDashboardComponent } from './title-dashboard/title.component';
import { SmallWidgetSkeletonComponent } from '../components/widgets/small/skeleton/small-widget-skeleton.component';
import { LargeWidgetSkeletonComponent } from '../components/widgets/large/skeleton/large-widget-skeleton.component';
import { AvatarComponent } from './header/avatar/avatar.component';
import { LargeWidgetHeaderComponent } from './lw-header/lw-header.component';
import { ClickdataViewComponent } from '../components/views/clickdata/clickdata-view.component';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { CdkTreeModule } from '@angular/cdk/tree';
import { ChartDataDialogComponent } from './chart-data-dialog/chart-data-dialog.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { DomSanitizer } from '@angular/platform-browser';
import { TermsComponent } from './terms/terms.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ChartTooltipComponent } from './chart-tooltip/chart-tooltip.component';
import { MatPopUpSelectModule } from './mat-pop-up-select/mat-pop-up-select.module';
import {
    MAT_DATE_LOCALE,
    SatDatepickerModule,
    SatNativeDateModule
} from 'saturn-datepicker';
import { DetailsRefDirective } from '../abstracts/table.component';
import { ChartVariationComponent } from './chart-variation/chart-variation.component';
import { PdfDownloadModule } from './pdf-download/pdf-download.module';
import { QualityGateStatusModule } from './quality-gate-status/quality-gate-status.module';
import { MatPopUpTableModule } from './mat-pop-up-table/mat-pop-up-table.module';
import { ColourBubbleComponent } from './colour-bubble/colour-bubble.component';
import { ChipsetComponent } from './chipset/chipset.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DownloadModule } from './download/download.module';
import { BannerModule } from './banner/banner.module';
import { QgBannerModule } from './qg-banner/qg-banner.module';
import { MainFilterModule } from './main-filter/main-filter.module';
import { TableModule } from '../components/tables/table.module';
import { ColourBubbleModule } from './colour-bubble/colour-bubble.module';
import { ChipsetModule } from './chipset/chipset.module';
import { NotificationsModule } from './notifications/notifications.module';
import { DashboardDialogModule } from './dashboard-dialog/dashboard-dialog.module';
import { WidgetClassToggleModule } from './widget-class-toggle/widget-class-toggle.module';
import { ExpandedWidgetModule } from './expanded-widget/expanded-widget.module';
import { PopUpIssuesModule } from './pop-up-issues/pop-up-issues.module';
import { FiltersBarModule } from './filters-bar/filters-bar.module';
import { ViewsBarModule } from './views-bar/views-bar.module';
import { BaselineToggleModule } from './baseline-toggle/baseline-toggle.module';
import { TablesAndDictionariesToggleModule } from './tables-dictionaries-toggle/tables-dictionaries-toggle.module';
import { UserFormatService } from './userFormat.service';
import { HotkeysDialogModule } from './hotkeys/hotkeys-dialog.module';
import { TwoFactorAuthenticationModule } from './two-factor-authentication/two-factor-authentication.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTreeModule } from '@angular/material/tree';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { UserDateTimeModule } from '../pipes/user-date-time/user-date-time.module';
import { RangeDateFieldComponent } from './lw-header/rangeDateField/range-date-field.component';

const skltnConfig: SkltnConfig = {
    rectRadius: 10,
    flareWidth: '150px',
    bgFill: '#d8d5d1',
    flareFill: 'rgba(255,255,255, 0.5)'
};

library.add(
    faBars,
    faUserCircle,
    faPowerOff,
    faCog,
    faRocket,
    faPlayCircle,
    faGithub,
    faMediumM,
    faTwitter,
    faInstagram,
    faYoutube,
    faPlus,
    faEdit,
    faTrash,
    faTimes,
    faCaretUp,
    faCaretDown,
    faExclamationTriangle,
    faFilter,
    faTasks,
    faCheck,
    faSquare,
    faLanguage,
    faPaintBrush,
    faLightbulb,
    faWindowMaximize,
    faStream,
    faBook
);

@NgModule({
    imports: [
        WidgetClassToggleModule,
        ExpandedWidgetModule,
        RouterModule,
        CommonModule,
        FormsModule,
        NgSelectModule,
        ReactiveFormsModule,
        TranslateModule,
        MatButtonModule,
        MatToolbarModule,
        MatSelectModule,
        MatTabsModule,
        MatExpansionModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatChipsModule,
        MatCardModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatListModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatTableModule,
        MatButtonToggleModule,
        MatPaginatorModule,
        MatSortModule,
        MatRippleModule,
        NgApexchartsModule,
        NgxSkltnModule.forRoot(skltnConfig),
        FontAwesomeModule,
        MatTreeModule,
        MatDialogModule,
        CdkTreeModule,
        MatSelectFilterModule,
        MatPopUpSelectModule,
        SatDatepickerModule,
        MatFormFieldModule,
        SatNativeDateModule,
        MatSliderModule,
        PdfDownloadModule,
        QualityGateStatusModule,
        DownloadModule,
        BannerModule,
        QgBannerModule,
        MainFilterModule,
        TableModule,
        ColourBubbleModule,
        ChipsetModule,
        NotificationsModule,
        DashboardDialogModule,
        PopUpIssuesModule,
        FiltersBarModule,
        ViewsBarModule,
        BaselineToggleModule,
        TablesAndDictionariesToggleModule,
        HotkeysDialogModule,
        TwoFactorAuthenticationModule,
        UserDateTimeModule
    ],
    declarations: [
        RtlSupportDirective,
        SideBarComponent,
        HeaderComponent,
        BreadcrumbsComponent,
        TitleDashboardComponent,
        SmallWidgetSkeletonComponent,
        LargeWidgetSkeletonComponent,
        AvatarComponent,
        RangeDateFieldComponent,
        LargeWidgetHeaderComponent,
        ClickdataViewComponent,
        HelpDialogComponent,
        NavigationComponent,
        ...allComponents,
        ...allPipes,
        ChartDataDialogComponent,
        TermsComponent,
        ChartTooltipComponent,
        ChartVariationComponent,
        DetailsRefDirective
    ],
    exports: [
        WidgetClassToggleModule,
        ExpandedWidgetModule,
        CommonModule,
        FormsModule,
        NgSelectModule,
        ReactiveFormsModule,
        TranslateModule,
        MatButtonModule,
        MatMenuModule,
        MatExpansionModule,
        MatTabsModule,
        MatChipsModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatCardModule,
        MatSidenavModule,
        MatListModule,
        MatSelectModule,
        MatToolbarModule,
        MatIconModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTableModule,
        MatButtonToggleModule,
        MatPaginatorModule,
        MatSortModule,
        MatRippleModule,
        NgApexchartsModule,
        FontAwesomeModule,
        RtlSupportDirective,
        ...allPipes,
        ...allComponents,
        SideBarComponent,
        HeaderComponent,
        BreadcrumbsComponent,
        TitleDashboardComponent,
        SmallWidgetSkeletonComponent,
        LargeWidgetSkeletonComponent,
        LargeWidgetHeaderComponent,
        NgxSkltnModule,
        MatTreeModule,
        AvatarComponent,
        ColourBubbleComponent,
        ChipsetComponent,
        ClickdataViewComponent,
        HelpDialogComponent,
        CdkTreeModule,
        ChartDataDialogComponent,
        NavigationComponent,
        ChartTooltipComponent,
        MatPopUpSelectModule,
        MatPopUpTableModule,
        MatFormFieldModule,
        SatDatepickerModule,
        SatNativeDateModule,
        DetailsRefDirective,
        ChartVariationComponent,
        PdfDownloadModule,
        DownloadModule,
        BannerModule,
        QgBannerModule,
        MainFilterModule,
        TableModule,
        ColourBubbleModule,
        ChipsetModule,
        DashboardDialogModule,
        PopUpIssuesModule,
        FiltersBarModule,
        ViewsBarModule,
        BaselineToggleModule,
        TablesAndDictionariesToggleModule
    ],
    providers: [
        {
            provide: MAT_DATE_LOCALE,
            useFactory: (userFormatService: UserFormatService) => {
                if (userFormatService.getTimeFormat() === 'US') return 'en-US';
                return 'en-GB';
            },
            deps: [UserFormatService]
        }
    ]
})
export class SharedModule {
    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
        matIconRegistry.addSvgIconSet(
            domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
        ); // Or whatever path you placed mdi.svg at
    }
}
