<div class="team-selector">
    <label class="flex w-full" [attr.aria-label]="[team.name]">
        <div class="colored-dot-wrapper">
            <qcbi-colored-dot [name]="team.name"></qcbi-colored-dot>
        </div>
        <div class="team-info">
            <div class="team-name" [matTooltip]="team.name">{{ team.name }}</div>
            <div class="team-description" *ngIf="team.description"
                 [matTooltip]="team.description">{{ team.description }}
            </div>
        </div>
        <div class="selector">
            <mat-checkbox color="primary" [checked]="team.selected"
                          (change)="toggle($event.checked)"></mat-checkbox>
        </div>
    </label>
</div>