import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';
import { mainFilterSelector } from '../../core/state/general/general.selectors';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import { GeneralActions } from '../../core/state/general/general.actions';

@Component({
    selector: 'qcbi-main-filter',
    templateUrl: './main-filter.component.html'
})
export class MainFilterComponent implements OnInit, OnDestroy {
    mainFilter: Observable<string>;

    private unsubscribe = new Subject<void>();

    constructor(private store: Store<AppState>) {}

    ngOnInit() {
        this.mainFilter = this.store
            .select(mainFilterSelector)
            .pipe(takeUntil(this.unsubscribe));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    handleScanChange(event: MatSelectChange) {
        this.store.dispatch(
            GeneralActions.setMainFilter({
                mainFilter: event.value,
                alreadyLoaded: true
            })
        );
    }
}
