import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonLoadingDirective } from './mat-button-loading.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule],
    declarations: [MatButtonLoadingDirective],
    exports: [MatButtonLoadingDirective]
})
export class MatButtonLoadingModule {}
