import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersBarComponent } from './filters-bar.component';
import { MatPopUpSelectModule } from '../mat-pop-up-select/mat-pop-up-select.module';
import { FilterTitlePipe } from './filter-title.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
    MAT_DATE_LOCALE,
    SatDatepickerModule,
    SatNativeDateModule
} from 'saturn-datepicker';
import { UserFormatService } from '../userFormat.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ServiceDependentLabelsModule } from '../../pipes/service-dependent-labels/service-dependent-labels.module';

@NgModule({
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule,
        MatPopUpSelectModule,
        MatIconModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatInputModule,
        SatDatepickerModule,
        SatNativeDateModule,
        ServiceDependentLabelsModule
    ],
    declarations: [FiltersBarComponent, FilterTitlePipe],
    exports: [FiltersBarComponent],
    providers: [
        {
            provide: MAT_DATE_LOCALE,
            useFactory: (userFormatService: UserFormatService) => {
                if (userFormatService.getTimeFormat() === 'US') return 'en-US';
                return 'en-GB';
            },
            deps: [UserFormatService]
        }
    ]
})
export class FiltersBarModule {}
