import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerComponent } from './drawer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
    imports: [CommonModule, MatIconModule, MatButtonModule, PortalModule],
    declarations: [DrawerComponent],
    exports: [DrawerComponent]
})
export class DrawerModule {}
