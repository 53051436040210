import { Inject, Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { HotkeysDialogComponent } from './hotkeys-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GeneralActions } from '../../core/state/general/general.actions';
import { AppState } from '../../core/core.state';
import { Store } from '@ngrx/store';

export interface Options {
    element: any;
    keys: string;
    description: string;
}

@Injectable({ providedIn: 'root' })
export class HotkeysService {
    hotkeys = new Map();
    defaults: Partial<Options> = {
        element: this.document
    };

    constructor(
        private eventManager: EventManager,
        private dialog: MatDialog,
        private store: Store<AppState>,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.addShortcut({ keys: 'control.shift.?' }).subscribe(() => {
            this.openHelpModal();
        });
        this.addShortcut({
            keys: 'control.shift.q',
            description: 'Toggle widget visualization'
        }).subscribe(() => {
            this.store.dispatch(GeneralActions.toggleLargeWidgetExpansion());
        });
    }

    addShortcut(options: Partial<Options>) {
        const merged = { ...this.defaults, ...options };
        const event = `keydown.${merged.keys}`;

        if (merged.description)
            this.hotkeys.set(merged.keys, merged.description);

        return new Observable(observer => {
            const handler = e => {
                e.preventDefault();
                observer.next(e);
            };

            const dispose = this.eventManager.addEventListener(
                merged.element,
                event,
                handler
            );

            return () => {
                dispose();
                this.hotkeys.delete(merged.keys);
            };
        });
    }

    openHelpModal() {
        this.dialog.open(HotkeysDialogComponent, {
            width: '500px',
            data: this.hotkeys
        });
    }
}
