import { Component } from '@angular/core';
import { DrawerService } from './drawer.service';

@Component({
    selector: 'qcbi-drawer',
    templateUrl: 'drawer.component.html',
    styleUrls: ['drawer.component.scss']
})
export class DrawerComponent {
    constructor(public drawerService: DrawerService) {}

    closeDrawer() {
        this.drawerService.close();
    }
}
