import { Injectable } from '@angular/core';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ScanIssuesActions } from './scan-issues.actions';
import { ScanIssuesService } from './scan-issues.service';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ScanIssuesEffects {
    constructor(
        private actions$: Actions,
        private service: ScanIssuesService
    ) {}

    load = createEffect(() =>
        this.actions$.pipe(
            ofType(ScanIssuesActions.load),
            switchMap(() =>
                this.service.load().pipe(
                    map((data: any) => ScanIssuesActions.loadSuccess(data)),
                    catchError((error: HttpErrorResponse) =>
                        of(ScanIssuesActions.error({ error: error.message }))
                    )
                )
            )
        )
    );
}
