import { Injectable } from '@angular/core';
import { ActionableService, ActionDispatchedEvent } from './action.models';

@Injectable({
    providedIn: 'root'
})
export class ActionsService {
    private actions: { [actionId: string]: ActionableService } = {};

    constructor() {}

    registerAction(action: ActionableService): void {
        this.actions[action.actionId] = action;
    }

    executeAction(event: ActionDispatchedEvent) {
        if (!this.actions.hasOwnProperty(event.actionId)) {
            console.error('action not registered');
            return;
        }
        this.actions[event.actionId].executeAction(event);
    }
}
