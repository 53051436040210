import { DefaultSettings } from '../core/state/general/general.models';

const SERVICENOW_ID = 1;
const SALESFORCE_ID = 2;
const OFFICE_ID = 3;

export function getDefaultSettings(): DefaultSettings {
    return {
        instanceId: null,
        serviceId: null,
        dashboardId: null
    };
}

export function getFirstDefaultSettings(
    dashboards,
    instances,
    context
): DefaultSettings {
    const _context = context.find(x => x.available === true);
    if (!_context) return null;
    if (
        _context.serviceId === 99 &&
        isAvailableService(context, _context.serviceId)
    ) {
        return {
            serviceId: _context.serviceId,
            dashboardId: dashboards[0].id
        };
    } else if (isAvailableService(context, _context.serviceId)) {
        const serviceKey = getServiceKey(_context.serviceId);
        return {
            serviceId: _context.serviceId,
            instanceId: instances[serviceKey][0].id,
            dashboardId: null
        };
    }
    return null;
}

function getServiceKey(id: number): string {
    switch (id) {
        case SERVICENOW_ID:
            return 'serviceNow';
        case SALESFORCE_ID:
            return 'salesForce';
        case OFFICE_ID:
            return 'office365';
    }
}

export function isAvailableService(context: any[], serviceId: number) {
    return context.some(x => x.serviceId === serviceId && x.available === true);
}

export function parseDashboardContext(data) {
    return data.map(item => ({
        serviceId: item.service_id,
        available: item.available,
        name: item.name
    }));
}

export function getYYYYMMDDfromDate(d: Date): string {
    if (d === null) return null;
    const _d = new Date(d);
    return (
        _d.getFullYear().toString() +
        ('0' + (_d.getMonth() + 1)).slice(-2) +
        ('0' + _d.getDate()).slice(-2)
    );
}
