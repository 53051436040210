import { DownloadXlsState } from './download-formatted-file.models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../core.state';

export const downloadXlsFeatureSelector = createFeatureSelector<
    AppState,
    DownloadXlsState
>('downloadXls');

export const downloadInProgressSelector = createSelector(
    downloadXlsFeatureSelector,
    (state: DownloadXlsState): object => {
        return state.downloadsInProgress;
    }
);
