import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    ViewChild,
    ElementRef,
    Renderer2,
    ChangeDetectorRef,
    HostListener,
    AfterViewInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Subscription,
    Observable,
    Subject,
    ReplaySubject,
    BehaviorSubject,
    forkJoin,
    fromEvent,
    combineLatest
} from 'rxjs';
import { AppState } from '../../../core/core.state';
import { Store } from '@ngrx/store';
import { GeneralActions } from '../../../core/state/general/general.actions';
import {
    dashboardSelector,
    dashboardsSelector,
    instanceIdSelector,
    serviceIdSelector
} from '../../../core/state/general/general.selectors';

import {
    takeUntil,
    first,
    distinctUntilChanged,
    debounceTime,
    map,
    filter
} from 'rxjs/operators';
import elementResizeDetectorMaker from 'element-resize-detector';
import { getServicePath } from '../../../utils/widget-state-creator';
import { qcAuthActions } from '../../../core/auth-lib';

@Component({
    selector: 'qcbi-clickdata-view',
    templateUrl: './clickdata-view.component.html',
    styleUrls: ['./clickdata-view.component.scss']
})
export class ClickdataViewComponent
    implements OnInit, OnDestroy, AfterViewInit {
    private unsubscribe = new Subject<void>();
    paramsSubscription: Subscription;
    dashboard: any;
    params: Observable<any>;

    $iframeClick: ReplaySubject<MouseEvent> = new ReplaySubject<MouseEvent>(1);
    $iframeKeyUp: ReplaySubject<KeyboardEvent> = new ReplaySubject<
        KeyboardEvent
    >(1);
    $iframeUnload: ReplaySubject<BeforeUnloadEvent> = new ReplaySubject<
        BeforeUnloadEvent
    >(1);
    $loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
    $styling: Observable<any>;
    iframeDocument: Document;
    iframeBody: HTMLElement;
    activeSource: string;
    elementResizeDetector: elementResizeDetectorMaker.Erd;
    private eventListeners: Array<any> = [];
    private $unsubscribe = new Subject();
    @ViewChild('iframe', { static: false }) elementRef: ElementRef;
    private _source: string;
    private _styles: string;
    private $bodyHeight: Subject<number> = new Subject<number>();
    private _styleUrls: Array<string>;
    private _autoResize = true;
    private _resizeDebounceMillis = 50;
    public iframeHeight = 450;
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.iframeHeight = window.innerHeight - 175;
    }

    constructor(
        public store: Store<AppState>,
        public activatedRoute: ActivatedRoute,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        public router: Router
    ) {}

    ngOnInit(): void {
        this.store
            .select(dashboardSelector)
            .pipe(first())
            .subscribe(dashboard => {
                if (dashboard['is-custom-bi']) {
                    combineLatest([
                        this.store.select(serviceIdSelector),
                        this.store.select(instanceIdSelector)
                    ])
                        .pipe(first())
                        .subscribe(data => {
                            this.router.navigate([
                                getServicePath(data[0]),
                                data[1],
                                dashboard.url
                            ]);
                        });
                }
            });
        this.iframeHeight = window.innerHeight - 175;
        this.store
            .select(dashboardSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((dashboard: any) => {
                if (!dashboard) return;
                if (!this.dashboard) this.dashboard = dashboard;
                if (this.dashboard.id !== dashboard.id)
                    this.dashboard = dashboard;
            });
        this.store
            .select(dashboardsSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(async () => this.dashboardCheck());
        this.activatedRoute.params
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(async () => this.dashboardCheck());

        this.activeSource = this.source;
        this.$loading
            .pipe(
                filter(value => value === false || value === null),
                takeUntil(this.$unsubscribe)
            )
            .subscribe(res => {});
        this.updateStyles();
    }

    get resizeDebounceMillis(): number {
        return this._resizeDebounceMillis;
    }

    async dashboardCheck() {
        // const dashboards: any[] = await this.store
        //     .select(dashboardsSelector)
        //     .pipe(first())
        //     .toPromise();
        // const params: any = await this.activatedRoute.params
        //     .pipe(first())
        //     .toPromise();
        // const old = await this.dashboard.pipe(first()).toPromise();
        // const dashboard = dashboards.find(
        //     item => item.id === parseInt(params.dashboardId)
        // );
        // if (!dashboard) return;
        // if (old !== null) {
        //     if (old.id !== dashboard.id) {
        //         this.store.dispatch(
        //             GeneralActions.setDashboard({
        //                 dashboard
        //             })
        //         );
        //     }
        // } else {
        //     this.store.dispatch(
        //         GeneralActions.setDashboard({
        //             dashboard
        //         })
        //     );
        // }
    }

    @Input() set resizeDebounceMillis(value: number) {
        if (this._resizeDebounceMillis !== value) {
            this.updateStyles();
        }
        this._resizeDebounceMillis = value;
    }
    get autoResize(): boolean {
        return this._autoResize;
    }

    @Input() set autoResize(value: boolean) {
        this._autoResize = value;
    }
    get styleUrls(): Array<string> {
        return this._styleUrls;
    }

    @Input() set styleUrls(value: Array<string>) {
        this._styleUrls = value;
    }
    get styles(): string {
        return this._styles;
    }

    @Input() set styles(value: string) {
        this._styles = value;
    }
    get source(): string {
        return this._source;
    }

    @Input() set source(value: string) {
        this._source = value;
    }

    private updateStyles() {
        this.$styling = this.$bodyHeight.pipe(
            distinctUntilChanged(),
            debounceTime(this.resizeDebounceMillis),
            map(height => ({ 'height.px': height }))
        );
    }

    private addStyleSheets(styleUrls) {
        if (styleUrls.length > 0) {
            // create placeholder for subjects
            const loadSubjects: Array<Subject<string>> = [];

            // loop through all style sheets...
            styleUrls.map((styleUrl: string) => {
                // create link element
                const linkElement: HTMLElement = this.iframeDocument.createElement(
                    'link'
                );
                linkElement['rel'] = 'stylesheet';
                linkElement['type'] = 'text/css';
                linkElement['href'] = styleUrl;

                // create load subject that will emit once the stylesheet has loaded
                const loadSubject: Subject<string> = new Subject<string>();
                loadSubjects.push(loadSubject);

                // listen to load event on link
                const stylesheetLoadListener = this.renderer.listen(
                    linkElement,
                    'load',
                    (test: Event) => {
                        this.iframeBody.style.overflow = 'inherit';
                        loadSubject.next(styleUrl);
                        loadSubject.complete();
                        return true;
                    }
                );

                // push listener to array so that we can remove them later
                this.eventListeners.push(stylesheetLoadListener);

                // add link to iframe head
                this.iframeDocument.head.appendChild(linkElement);

                // emit load event
            });

            forkJoin(loadSubjects)
                .pipe(takeUntil(this.$unsubscribe))
                .subscribe(res => {
                    if (styleUrls.length > 1) {
                    }
                    this.$loading.next(false);
                });
        }
    }

    private addCss(styles: string) {
        const styleElement = this.iframeDocument.createElement('style');
        styleElement.appendChild(this.iframeDocument.createTextNode(styles));
        this.iframeDocument
            .getElementsByTagName('head')[0]
            .appendChild(styleElement);
    }

    private addElementResizeDetector(body: HTMLElement, style: any) {
        this.elementResizeDetector = elementResizeDetectorMaker({
            strategy: 'scroll'
        });
        this.elementResizeDetector.listenTo(body, () => {
            const offsetHeight = body.offsetHeight;
            const marginTop = parseInt(
                style.getPropertyValue('margin-top'),
                10
            );
            const marginBottom = parseInt(
                style.getPropertyValue('margin-bottom'),
                10
            );
            const height = offsetHeight + marginTop + marginBottom;
            this.$bodyHeight.next(height);
        });
    }

    private removeElementResizeDetector() {
        if (this.iframeBody && this.elementResizeDetector) {
            this.elementResizeDetector.uninstall(this.iframeBody);
        }
    }

    reload() {
        if (this.iframeDocument && this.iframeDocument.location) {
            this.iframeDocument.location.reload();
        }
    }

    ngAfterViewInit() {
        if (!this.elementRef) return;
        const iframe = this.elementRef.nativeElement;
        this.$iframeClick
            .pipe(takeUntil(this.$unsubscribe))
            .subscribe(res => {});
        this.$iframeKeyUp
            .pipe(takeUntil(this.$unsubscribe))
            .subscribe(res => {});
        this.$iframeUnload.pipe(takeUntil(this.$unsubscribe)).subscribe(res => {
            this.$loading.next(true);
            this.iframeBody.style.overflow = 'hidden';
            this.cdr.detectChanges();
        });
        fromEvent(iframe, 'focus')
            .pipe(takeUntil(this.$unsubscribe))
            .subscribe(() => {
                this.store.dispatch(qcAuthActions.windowFocus());
            });
        fromEvent(iframe, 'load')
            .pipe(takeUntil(this.$unsubscribe))
            .subscribe(res => {
                try {
                    this.activeSource = iframe.contentWindow.location.href;

                    // declare iframe document and body
                    this.iframeDocument = iframe.contentDocument;
                    this.iframeBody = this.iframeDocument.body;

                    // add inline css
                    if (this.styles) {
                        this.addCss(this.styles);
                    }

                    // add external stylesheets
                    if (this.styleUrls && this.styleUrls.length > 0) {
                        this.addStyleSheets(this.styleUrls);
                    } else {
                        this.$loading.next(false);
                    }

                    // add element resize detector
                    if (this.autoResize) {
                        this.addElementResizeDetector(
                            this.iframeBody,
                            iframe.contentWindow.getComputedStyle(
                                this.iframeBody
                            )
                        );
                    }

                    // add click listener
                    const clickListener = this.renderer.listen(
                        iframe.contentWindow,
                        'click',
                        ($event: MouseEvent) => this.$iframeClick.next($event)
                    );
                    this.eventListeners.push(clickListener);

                    // add key up listener
                    const keyUpListener = this.renderer.listen(
                        iframe.contentWindow,
                        'keyup',
                        ($event: KeyboardEvent) =>
                            this.$iframeKeyUp.next($event)
                    );
                    this.eventListeners.push(keyUpListener);

                    // add unload listener
                    const unloadListener = this.renderer.listen(
                        iframe.contentWindow,
                        'beforeunload',
                        ($event: BeforeUnloadEvent) =>
                            this.$iframeUnload.next($event)
                    );
                    this.eventListeners.push(unloadListener);
                } catch (error) {
                    console.error(
                        'Event listeners and/or styles and resize listener could not be added due to a cross-origin frame error.'
                    );
                    console.warn(error);
                    this.$loading.next(null);
                }
            });
    }

    ngOnDestroy(): void {
        this.$unsubscribe.next();
        this.$unsubscribe.complete();

        // detach event listeners
        this.eventListeners.map(listener => listener());

        // if auto resize...
        if (this.autoResize) {
            // ...try and remove element resize detector
            this.removeElementResizeDetector();
        }
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
