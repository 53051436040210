import { createAction, props } from '@ngrx/store';
import { StateConfig } from './dm-new-view.config';
import {
    AvailableViews,
    DebtManagerView,
    NewView,
    OwnedView,
    OwnedViewDetails
} from './dm-new-view.reducer';
import { FilterOptionsDictionary } from '../../../../shared/selectable-filters/selectable-filters.component';
import { SortDirection } from '@angular/material/sort/sort-direction';

export const dmNewViewActions = {
    navigateToView: createAction(
        `[${StateConfig.Name}] navigate to view`,
        props<{ viewId: string }>()
    ),
    openView: createAction(
        `[${StateConfig.Name}] open view`,
        props<{ viewId: number }>()
    ),
    redirectAndClose: createAction(
        `[${StateConfig.Name}] redirect close view`,
        props<{ viewId: string }>()
    ),
    closeView: createAction(
        `[${StateConfig.Name}] close view`,
        props<{ viewId: string }>()
    ),
    closeAllViews: createAction(`[${StateConfig.Name}] close all views`),
    closeOtherViews: createAction(
        `[${StateConfig.Name}] close other views`,
        props<{ viewId: string }>()
    ),
    createView: createAction(
        `[${StateConfig.Name}] create view`,
        props<{ newView: NewView }>()
    ),
    createViewSuccess: createAction(
        `[${StateConfig.Name}] create view success`,
        props<{ newView: OwnedView }>()
    ),
    updateView: createAction(
        `[${StateConfig.Name}] update view`,
        props<{ viewToUpdate: OwnedViewDetails }>()
    ),
    updateViewSuccess: createAction(
        `[${StateConfig.Name}] update view success`,
        props<{ viewToUpdate: OwnedViewDetails }>()
    ),
    tryToUpdateCurrentViewWithCurrentFilters: createAction(
        `[${StateConfig.Name}] try to update current view current filters`
    ),
    updateCurrentViewWithCurrentFilters: createAction(
        `[${StateConfig.Name}] update view filters`
    ),
    updateCurrentViewFiltersSuccess: createAction(
        `[${StateConfig.Name}] update view filters success`,
        props<{
            viewId: string;
            filters: FilterOptionsDictionary;
            columnsConfig: any;
            pageSize: number;
            sort: string;
            direction: SortDirection;
        }>()
    ),
    deleteView: createAction(
        `[${StateConfig.Name}] delete view`,
        props<{ viewId: string }>()
    ),
    deleteCurrentView: createAction(
        `[${StateConfig.Name}] delete current view`
    ),
    deleteViewSuccess: createAction(
        `[${StateConfig.Name}] delete current view success`,
        props<{ viewId: string }>()
    ),
    loadViews: createAction(`[${StateConfig.Name}] load views`),
    error: createAction(`[${StateConfig.Name}] error`),
    getSavedViews: createAction(`[${StateConfig.Name}] get saved views`),
    getSavedViewsSuccess: createAction(
        `[${StateConfig.Name}] get saved views success`,
        props<AvailableViews>()
    ),
    getSavedViewsError: createAction(
        `[${StateConfig.Name}] get saved views error`
    ),
    openSavedView: createAction(
        `[${StateConfig.Name}] open saved view`,
        props<{ id: string }>()
    ),
    openSavedViewNew: createAction(
        `[${StateConfig.Name}] open saved view new`,
        props<{ newView: DebtManagerView }>()
    ),
    pinView: createAction(
        `[${StateConfig.Name}] pin view`,
        props<{ viewId: string }>()
    ),
    unpinView: createAction(
        `[${StateConfig.Name}] unpin view`,
        props<{ viewId: string }>()
    ),
    pinToggleSuccess: createAction(`[${StateConfig.Name}] pin toggle success`)
};
