<div [formGroup]="formGroup">
	<mat-form-field appearance="outline" class="w-full mt-3 position-relative">
		<input
			matInput
			type="text"
			[matAutocomplete]="auto"
			formControlName="userSearch"
			placeholder="search users..."
		>
		<mat-error *ngIf="formGroup.get('userSearch').hasError('noUsers')">you need to pick at least one user</mat-error>
		<mat-autocomplete
            #auto="matAutocomplete"
			(optionsScroll)="scrollHandler()"
            (optionSelected)="optionSelectedHandler($event)"
            [displayWith]="displayFn"
		>
			<mat-option
				*ngFor="let option of filteredOptions$ | async"
				[value]="option"
			>
				<div class="flex items-center">
					<qcbi-avatar [marginTop]="false" [name]="option.username"></qcbi-avatar>
					<div class="user-text">{{ option.username }}</div>
				</div>
			</mat-option>
			<mat-option disabled *ngIf="(filteredOptions$ | async)?.length === 0">
				no users found
			</mat-option>
		</mat-autocomplete>
		<mat-spinner
			*ngIf="loading"
			class="loading-spinner"
			[diameter]="24"
		></mat-spinner>
	</mat-form-field>
	<mat-chip-list>
		<mat-chip
			class="flex items-center py-2 px-2 user-chip"
			*ngFor="let selectedUser of selectedUsers"
			[removable]="true"
			(removed)="removeFromList(selectedUser.id)"
		>
			<qcbi-avatar [marginTop]="false" [name]="selectedUser.username"></qcbi-avatar>
			<div class="user-text">{{ selectedUser.username }}</div>
			<mat-icon matChipRemove *ngIf="!disabled">close</mat-icon>
		</mat-chip>
	</mat-chip-list>
</div>