import { Injectable } from '@angular/core';
import {
    ActionableService,
    ActionDispatchedEvent
} from '../../../../../tables/services/action.models';
import { UnwriteOffDialogComponent } from '../../../../../../shared/unwrite-off-dialog/unwrite-off-dialog.component';
import { bulkActions } from '../../../../../../core/state/widgets/large/debt-manager-new/bulk.actions';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/core.state';

@Injectable({
    providedIn: 'root'
})
export class UnwriteOffActionService implements ActionableService {
    readonly actionId = 'un-write-off';

    constructor(private dialog: MatDialog, private store: Store<AppState>) {}

    executeAction(event: ActionDispatchedEvent): void {
        const unwoRef = this.dialog.open(UnwriteOffDialogComponent, {
            width: '600px',
            data: {
                ids: event.selectedItems.map(el => +el['Element Id']),
                allSelected: event.allSelected
            }
        });
        unwoRef.afterClosed().subscribe(res => {
            if (res) {
                const { status, ...rest } = res;
                if (status === 'ACCEPT') {
                    this.store.dispatch(
                        bulkActions.bulkWriteOff({
                            ...rest
                        })
                    );
                } else if (status === 'DECLINED' || status === 'REJECTED') {
                    this.store.dispatch(
                        bulkActions.bulkUnwriteOff({
                            ...rest,
                            status
                        })
                    );
                } else {
                    console.error('unknown option');
                }
            }
        });
    }
}
