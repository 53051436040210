import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UsersData } from '../../components/widgets/large/debt-manager-new/components/new-view-form/components/users-dropdown-search/users-dropdown-search.component';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

type UsersPerInstanceServer = {
    type: 'users-per-instance';
    data: {
        id: number;
        username: string;
    }[];
    meta: {
        total_items: number;
        total_pages: number;
        current_page: number;
        per_page: number;
    };
};

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(private http: HttpClient) {}

    getUsers(
        only: number[],
        skip: number[],
        search: string = '',
        pageNumber: number,
        instanceId: number
    ): Observable<UsersData> {
        const url = `${environment.apiEndPoint}api/v2/users-per-instance/${instanceId}?`;
        const filters = [];
        if (only && only.length) {
            filters.push(`include=${only.join(',')}`);
        } else {
            filters.push('page[size]=10');
            filters.push(`page[number]=${pageNumber}`);
            if (search && search !== '') filters.push(`search=${search}`);
            if (skip && skip.length > 0) filters.push(`skip=${skip.join(',')}`);
        }
        return this.http.get(url + filters.join('&')).pipe(
            map((res: UsersPerInstanceServer) => {
                return {
                    data: res.data.map(u => {
                        return {
                            id: u.id,
                            username: u.username
                        };
                    }),
                    nextPage:
                        res.meta.current_page < res.meta.total_pages
                            ? res.meta.current_page + 1
                            : -1
                };
            })
        );
    }
}
