import { createAction, props } from '@ngrx/store';
import {
    NotificationsDetail,
    NotificationsUpdate
} from './notifications.models';

export const NotificationsActions = {
    initNotifications: createAction('[Notifications] init notifications'),
    fetchNotifications: createAction(
        '[Notifications] fetch notifications',
        props<{ date?: string }>()
    ),
    setNotifications: createAction(
        '[Notifications] set notifications',
        props<NotificationsUpdate>()
    ),
    appendNotifications: createAction(
        '[Notifications] append notifications',
        props<NotificationsUpdate>()
    ),
    markAllNotificationsAsRead: createAction(
        '[Notifications] mark notifications as read'
    ),
    markNotificationAsRead: createAction(
        '[Notifications] mark notification as read',
        props<{ notificationId: string }>()
    ),
    getNextPage: createAction(
        '[Notifications] get next page',
        props<{ lastNotificationDate: string; firstNotificationDate: string }>()
    ),
    getUnreadDetail: createAction(
        '[Notifications] get unread detail',
        props<{ date?: string; reset?: boolean }>()
    ),
    setUnreadDetail: createAction(
        '[Notifications] set unread detail',
        props<NotificationsDetail>()
    ),
    getReadDetail: createAction(
        '[Notifications] get read detail',
        props<{ date?: string; reset?: boolean }>()
    ),
    setReadDetail: createAction(
        '[Notifications] set read detail',
        props<NotificationsDetail>()
    )
};
