<div class="flex" [ngClass]="{ 'display-in-column': displayInOneColumn }">
    <div class="details">
        <qcbi-label-value label="Status">
            <div class="lowercase capitalize-first-letter">{{ item.status }}</div>
        </qcbi-label-value>
        <qcbi-label-value label="Issue Id">
            {{ item.id }}
        </qcbi-label-value>
        <qcbi-label-value label="Issue Type">
            {{ item.issueType }}
        </qcbi-label-value>
        <qcbi-label-value label="Impact area">
            <div class="lowercase capitalize-first-letter">{{ item.impactArea }}</div>
        </qcbi-label-value>
    </div>
	<div class="details">
		<qcbi-label-value label="Severity">
			<div class="flex">
				<div class="lowercase capitalize-first-letter">{{ item.severity }}</div>
				<div class="arrow-wrapper">
					<qcbi-severity-arrow [severity]="item.severity"></qcbi-severity-arrow>
				</div>
			</div>
		</qcbi-label-value>
		<qcbi-label-value label="Est. fix time">
			{{ item.timeToFix || '-' }}m
		</qcbi-label-value>
        <qcbi-label-value label="Creation date">
            {{ item.createdOn | userDateTime : 'dateTime' : '-' }}
        </qcbi-label-value>
        <qcbi-label-value label="Tags">
            <qcbi-readonly-tags [tags]="item['tags'] | mapToAttribute: 'name'"></qcbi-readonly-tags>
        </qcbi-label-value>
	</div>
</div>
