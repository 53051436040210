import { Injectable } from '@angular/core';
import {
    ActionableService,
    ActionDispatchedEvent
} from '../../../../../tables/services/action.models';
import {
    ModifyDateResult,
    ModifyWriteOffExpirationDateDialogComponent
} from '../../../debt-manager/components/modify-write-off-expiration-date-dialog/modify-write-off-expiration-date-dialog.component';
import { first } from 'rxjs/operators';
import { stateSelectors as dmNewSelectors } from '../../../../../../core/state/widgets/large/debt-manager-new/dm-new.selectors';
import { bulkActions } from '../../../../../../core/state/widgets/large/debt-manager-new/bulk.actions';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/core.state';

@Injectable({
    providedIn: 'root'
})
export class ModifyExpirationDateActionService implements ActionableService {
    readonly actionId = 'modify-expiration-date';

    constructor(private dialog: MatDialog, private store: Store<AppState>) {}

    executeAction(event: ActionDispatchedEvent): void {
        const refModifyWoExpDateDialog = this.dialog.open(
            ModifyWriteOffExpirationDateDialogComponent,
            {
                width: '600px'
            }
        );
        refModifyWoExpDateDialog
            .afterClosed()
            .subscribe((res: ModifyDateResult) => {
                this.store.dispatch(
                    bulkActions.bulkChangeWriteOffExpirationDate({
                        allSelected: event.allSelected,
                        expirationDate: res.expirationDate,
                        ids: event.selectedItems.map(el => +el['Element Id'])
                    })
                );
            });
    }
}
