import {
    AbstractControl,
    AsyncValidatorFn,
    ValidationErrors
} from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/core.state';
import { viewsSelectors } from '../../../../../../core/state/views/debt-manager-new/dm-new-view.selectors';
import { first, map } from 'rxjs/operators';
import { AvailableViews } from '../../../../../../core/state/views/debt-manager-new/dm-new-view.reducer';

export class ViewNameValidator {
    static createValidator(
        store: Store<AppState>,
        initialName: string
    ): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            return store.select(viewsSelectors.availableViews).pipe(
                first(),
                map((availableViews: AvailableViews) => {
                    if (
                        (!initialName || control.value !== initialName) &&
                        availableViews.myViews
                            .concat(availableViews.sharedViews)
                            .some(v => v.name === control.value)
                    ) {
                        return { invalidName: true };
                    }
                    return null;
                })
            );
        };
    }
}
