import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualityGateStatusComponent } from './quality-gate-status.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
    imports: [CommonModule, MatChipsModule, MatTooltipModule],
    declarations: [QualityGateStatusComponent],
    exports: [QualityGateStatusComponent]
})
export class QualityGateStatusModule {}
