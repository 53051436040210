import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TwoFactorAuthenticationService } from './two-factor-authentication.service';
import { TwoFactorAuthenticationActions } from './two-factor-authentication.actions';
import {
    catchError,
    first,
    map,
    switchMap,
    withLatestFrom
} from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TwoFactorAuthenticationDialogComponent } from './components/two-factor-authentication-dialog/two-factor-authentication-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';
import { selectCurrentUser } from '../../core/auth-lib';

@Injectable()
export class TwoFactorAuthenticationEffects {
    constructor(
        private actions$: Actions,
        private service: TwoFactorAuthenticationService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private store: Store<AppState>
    ) {}

    @Effect()
    fetchMfaStatus = this.actions$.pipe(
        ofType(TwoFactorAuthenticationActions.fetchStatus),
        switchMap(({ userId }) =>
            this.service.fetchMfaStatus(userId).pipe(
                map(({ status }) => {
                    return TwoFactorAuthenticationActions.setStatus({ status });
                })
            )
        )
    );

    @Effect({ dispatch: false })
    showMfaPopUp = this.actions$.pipe(
        ofType(TwoFactorAuthenticationActions.setStatus),
        withLatestFrom(this.store.select(selectCurrentUser)),
        first(),
        switchMap(([{ status }, user]) => {
            if (!user) return [];
            if (
                user.customerHasMfa &&
                user.forceMfa &&
                status !== 'SYNCHRONIZED'
            ) {
                const ref = this.dialog.open(
                    TwoFactorAuthenticationDialogComponent,
                    {
                        width: '800px',
                        data: {
                            showInfo: true
                        }
                    }
                );
                ref.afterClosed().subscribe(() => {
                    this.store.dispatch(
                        TwoFactorAuthenticationActions.fetchStatus({
                            userId: user.id
                        })
                    );
                });
            }
            return [];
        })
    );

    @Effect()
    disableMfa = this.actions$.pipe(
        ofType(TwoFactorAuthenticationActions.disableMfa),
        switchMap(() => {
            return this.service.disableMfa().pipe(
                map(({ status }) => {
                    return TwoFactorAuthenticationActions.setStatus({ status });
                })
            );
        })
    );

    @Effect()
    fetchQrCode = this.actions$.pipe(
        ofType(TwoFactorAuthenticationActions.fetchQrData),
        switchMap(() =>
            this.service.fetchQrCode().pipe(
                map(({ secret, uri }) => {
                    return TwoFactorAuthenticationActions.setQrData({
                        secret,
                        uri
                    });
                }),
                catchError(error => {
                    console.error(error);
                    this.snackBar.open('error: ' + error.reason, null, {
                        panelClass: 'snackbar-error',
                        duration: 5000
                    });
                    return null;
                })
            )
        )
    );

    @Effect()
    validateCode = this.actions$.pipe(
        ofType(TwoFactorAuthenticationActions.validateCode),
        switchMap(({ code }) => {
            return this.service.validateCode(code).pipe(
                map(() => {
                    return TwoFactorAuthenticationActions.validateCodeResult({
                        result: 'valid',
                        message: 'activated'
                    });
                }),
                catchError(err => {
                    if (err.status === 400)
                        return [
                            TwoFactorAuthenticationActions.validateCodeResult({
                                result: 'invalid',
                                message: err.reason
                            })
                        ];
                    if (err.status === 422)
                        return [
                            TwoFactorAuthenticationActions.validateCodeResult({
                                result: 'valid',
                                message: 'activated'
                            })
                        ];
                })
            );
        })
    );
}
