<div class="dialog-container">
  <mat-icon color="alert">warning</mat-icon>
  <h1 mat-dialog-title>{{ data.msg }}</h1>
  <div mat-dialog-content>
    <p>{{ data.subtitle || 'Would you like to proceed?' }}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial color="primary">Yes</button>
    <button mat-button (click)="onNoClick()">No</button>
  </div>
</div>
