import { createAction, props } from '@ngrx/store';
import { FilterOptionsDictionary } from '../../../../../shared/selectable-filters/selectable-filters.component';
import { FilterOptions } from '../../../../../shared/selectable-filters/filters/filter.component';
import {
    PagerOptions,
    SortOptions
} from '../../../../../components/tables/server.component';
import { StateConfig } from './dm-new.config';
import { DebtManagerView } from '../../../views/debt-manager-new/dm-new-view.reducer';

export const stateActions = {
    setCurrentView: createAction(
        `[${StateConfig.Name}] set current view`,
        props<{ viewId: string }>()
    ),
    createCurrentViewOptions: createAction(
        `[${StateConfig.Name}] create view options`,
        props<{ view: DebtManagerView }>()
    ),
    viewHasOptions: createAction(
        `[${StateConfig.Name}] view already has options`
    ),
    load: createAction(`[${StateConfig.Name}] load`),
    loadSuccess: createAction(
        `[${StateConfig.Name}] load success`,
        props<{ data: any[]; meta: any }>()
    ),
    loadSelectedFilters: createAction(
        `[${StateConfig.Name}] load selected filters`
    ),
    loadSelectedFiltersSuccess: createAction(
        `[${StateConfig.Name}] load selected filters success`,
        props<{
            filters: FilterOptionsDictionary;
        }>()
    ),
    setBaseFilters: createAction(
        `[${StateConfig.Name}] set base filters`,
        props<{ baseFilters: FilterOptionsDictionary }>()
    ),
    changeSelectedOptions: createAction(
        `[${StateConfig.Name}] change selected options`,
        props<{ key: string; options: FilterOptions[] }>()
    ),
    addFilterToDisplay: createAction(
        `[${StateConfig.Name}] add filter to Display`,
        props<{ key: string }>()
    ),
    addFilterToDisplaySuccess: createAction(
        `[${StateConfig.Name}] add filter to display success`,
        props<{ key: string; options: FilterOptions[] }>()
    ),
    removeFilterFromDisplay: createAction(
        `[${StateConfig.Name}] remove filter to Display`,
        props<{ key: string }>()
    ),
    resetFilters: createAction(`[${StateConfig.Name}] reset filters`),
    kpisSuccess: createAction(
        `[${StateConfig.Name}] load kpis success`,
        props<{ kpis: { issues: number; td: number } }>()
    ),
    error: createAction('error'),
    changeTablePageOrSort: createAction(
        `[${StateConfig.Name}] change table page or sort`,
        props<Partial<PagerOptions & SortOptions>>()
    ),
    changeColumnConfiguration: createAction(
        `[${StateConfig.Name}] change columns configurations`,
        props<{ config: any }>()
    )
};
