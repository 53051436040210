<div class="container">
  <mat-form-field class="w-full" style="min-width: 400px; position: relative;" appearance="outline">
    <mat-label>Status</mat-label>
    <mat-select
      [value]="status"
      (selectionChange)="handleSelectionChange($event)"
      disableOptionCentering
    >
      <mat-option
        *ngFor="let opt of options"
        [value]="opt.value"
      >
        {{ opt.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="w-full" style="min-width: 400px; position: relative;" appearance="outline">
    <mat-label>Description *</mat-label>
    <textarea #desc maxlength="250" [formControl]="descriptionFormControl" matInput></textarea>
    <div style="position: absolute; bottom: -20px; right: -5px;">{{desc.value.length}}/250</div>
  </mat-form-field>
</div>
<div class="actions flex justify-center gap-8 px-3">
  <button
	  mat-raised-button
	  color="primary"
	  class="w-full"
	  [disabled]="!validFields()"
	  (click)="submitChanges()"
	  cdkFocusInitial
  >
	  Edit
  </button>
  <button
	  mat-button
	  class="w-full"
	  (click)="close()"
  >
	  Cancel
  </button>
</div>
