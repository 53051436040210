import {
    Action,
    createAction,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
    Store
} from '@ngrx/store';
import { StateFeatures, Widgets } from '../../../models/widgets';
import { Injectable } from '@angular/core';
import { WidgetService } from '../../../../services/widget.service';
import { Actions, Effect } from '@ngrx/effects';
import { AppState } from '../../../../core.state';
import { WidgetEffects } from '../../widget.effects';
import { IUsageGovernanceSnowSmall } from '../../../models/widgets/small/governance-overview/usage-service-now.model';

type State = typeof Widgets.Small.Governance.USNOW;
enum StateConfig {
    Name = 'Widget - Governance - Usage',
    Key = 'ov_u_sn'
}
// First storage state object
const initialState: State = {
    isLoaded: false,
    loading: false,
    error: '',
    filter: {},
    data: {
        numLivechecks: 0,
        numUpdatesetScans: 0,
        numFullScans: 0
    }
};

/*
 *  Actions express unique events that happen throughout your application
 */
const stateActions = {
    load: createAction(`[${StateConfig.Name}] Load`, props<{ filter: any }>()),
    error: createAction(
        `[${StateConfig.Name}] Load error`,
        props<{ error: string }>()
    ),
    success: createAction(
        `[${StateConfig.Name}] Load success`,
        props<{ data: IUsageGovernanceSnowSmall }>()
    ),
    reset: createAction(`[${StateConfig.Name}] Reset`)
};

/*
 * Reducer functions handle these transitions by determining which actions to
 * handle based on the action's type
 */
const reducers = createReducer(
    initialState,
    on(stateActions.load, (state, { filter }) => ({
        ...state,
        loading: true,
        error: '',
        filter
    })),
    on(stateActions.error, (state, { error }) => ({
        ...state,
        loading: false,
        error,
        isLoaded: true
    })),
    on(stateActions.success, (state, { data }) => ({
        ...state,
        loading: false,
        isLoaded: true,
        data
    })),
    on(stateActions.reset, () => ({
        isLoaded: false,
        loading: false,
        error: '',
        filter: {},
        data: {
            numLivechecks: 0,
            numUpdatesetScans: 0,
            numFullScans: 0
        }
    }))
);
export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}

/*
 * Selectors are pure functions used for obtaining slices of store state.
 */
const stateSelector = createFeatureSelector<AppState, State>(StateConfig.Key);
const stateSelectors = {
    isLoaded: createSelector(stateSelector, (state: State) => state.isLoaded),
    loading: createSelector(stateSelector, (state: State) => state.loading),
    data: createSelector(stateSelector, (state: State) => state.data),
    error: createSelector(stateSelector, (state: State) => state.error),
    filter: createSelector(stateSelector, (state: State) => state.filter)
};

/*
 * Effects use streams to provide new sources of actions to reduce state
 * based on external interactions such as network requests, web socket messages
 * and time-based events.
 */
@Injectable()
export class StateEffects extends WidgetEffects<IUsageGovernanceSnowSmall> {
    constructor(
        public actions$: Actions,
        public service: WidgetService,
        public store: Store<AppState>
    ) {
        super(
            actions$,
            service,
            store,
            stateActions,
            {
                load: {
                    path: 'usage-compact-sn',
                    service: 1,
                    serviceName: 'servicenow'
                }
            },
            stateSelector
        );
    }
    @Effect()
    _load = this.loadCompactByInstance;
    @Effect()
    _scanReset = this.scanReset;
}

export const stateFeatures: StateFeatures = {
    config: StateConfig,
    actions: stateActions,
    selectors: stateSelectors
};
