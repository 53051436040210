import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablesAndDictionariesToggleComponent } from './tables-dictionaries-toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [CommonModule, MatSlideToggleModule, MatTooltipModule],
    declarations: [TablesAndDictionariesToggleComponent],
    exports: [TablesAndDictionariesToggleComponent]
})
export class TablesAndDictionariesToggleModule {}
