import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TagsActions } from './tags.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TagsService } from './tags.service';
import { Tag } from './tags.models';
import { of } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { instanceIdSelector } from '../general/general.selectors';
import { AppState } from '../../core.state';
import { Store } from '@ngrx/store';

@Injectable()
export class TagsEffects {
    constructor(
        private actions$: Actions,
        private service: TagsService,
        private store: Store<AppState>
    ) {}

    @Effect()
    fetchTags = this.actions$.pipe(
        ofType(TagsActions.fetchTags),
        switchMap(() => {
            return this.service.fetchTags().pipe(
                map((tags: Tag[]) => TagsActions.fetchTagsSuccess({ tags })),
                catchError((error: any) => {
                    return of(TagsActions.fetchTagsError({ error }));
                })
            );
        })
    );

    @Effect()
    fetchInstanceTags = this.actions$.pipe(
        ofType(TagsActions.fetchInstanceTags),
        withLatestFrom(this.store.select(instanceIdSelector)),
        switchMap(([{}, instanceId]) => {
            return this.service.fetchInstanceTags(instanceId).pipe(
                map((tags: Tag[]) => TagsActions.fetchTagsSuccess({ tags })),
                catchError((error: any) => {
                    return of(TagsActions.fetchTagsError({ error }));
                })
            );
        })
    );

    @Effect()
    create = this.actions$.pipe(
        ofType(TagsActions.createTag),
        switchMap(({ tagName }) => {
            return this.service
                .createTag(tagName)
                .pipe(map(() => TagsActions.fetchTags()));
        })
    );

    @Effect()
    edit = this.actions$.pipe(
        ofType(TagsActions.editTag),
        switchMap(({ id, tagName }) => {
            return this.service
                .editTag(id, tagName)
                .pipe(
                    switchMap(() => [
                        TagsActions.fetchTags(),
                        TagsActions.editTagSuccess()
                    ])
                );
        })
    );

    @Effect()
    delete = this.actions$.pipe(
        ofType(TagsActions.deleteTag),
        switchMap(({ tagId }) => {
            return this.service.deleteTag(tagId).pipe(
                map(() => TagsActions.fetchTags()),
                catchError((error: any) => {
                    return of(TagsActions.deleteTagError(error));
                })
            );
        })
    );
}
