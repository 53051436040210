import { Component, OnInit } from '@angular/core';
import { AppState } from '../../core/core.state';
import { Store } from '@ngrx/store';
import {
    readNotificationsLoadingSelector,
    readNotificationsLoadMoreSelector,
    readNotificationsSelector,
    unreadNotificationsLoadingSelector,
    unreadNotificationsLoadMoreSelector,
    unreadNotificationsSelector
} from '../../core/state/notifications/notifications.selectors';
import { Observable } from 'rxjs';
import { Notification } from '../../core/state/notifications/notifications.models';
import { NotificationsActions } from '../../core/state/notifications/notifications.actions';

@Component({
    selector: 'qcbi-notifications-page',
    template: `
        <section class="px-3">
            <div class="view-card">
                <div class="px-4">
                    <div class="text-3xl font-bold pt-4 pb-4">
                        Notifications
                    </div>
                    <div class="subtitle">Unread notifications</div>
                    <div
                        class="mb-4"
                        *ngIf="(unreadNotifications$ | async)?.length === 0"
                    >
                        There are no pending notifications to read
                    </div>
                    <qcbi-notification-card
                        [message]="n.message"
                        [instanceId]="n.instanceId"
                        [notificationId]="n.id"
                        [unread]="!n.isRead"
                        *ngFor="let n of unreadNotifications$ | async"
                    ></qcbi-notification-card>
                    <div *ngIf="unreadNotificationsLoadMore$ | async">
                        <ng-container
                            *ngIf="
                                unreadNotifications$
                                    | async as unreadNotifications
                            "
                        >
                            <div
                                (click)="
                                    loadMoreUnread(
                                        unreadNotifications[
                                            unreadNotifications.length - 1
                                        ]
                                    )
                                "
                                class="load-more"
                            >
                                load more
                            </div>
                        </ng-container>
                    </div>
                    <div
                        *ngIf="unreadNotificationsLoading$ | async"
                        class="spinner-container"
                        style="margin-left: 16px; margin-bottom: 16px;"
                    >
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </div>
                    <div class="subtitle">Read notifications</div>
                    <qcbi-notification-card
                        [message]="n.message"
                        [instanceId]="n.instanceId"
                        [notificationId]="n.id"
                        [unread]="!n.isRead"
                        *ngFor="let n of readNotifications$ | async"
                    ></qcbi-notification-card>
                    <div *ngIf="readNotificationsLoadMore$ | async">
                        <ng-container
                            *ngIf="
                                readNotifications$ | async as readNotifications
                            "
                        >
                            <div
                                (click)="
                                    loadMoreRead(
                                        readNotifications[
                                            readNotifications.length - 1
                                        ]
                                    )
                                "
                                class="load-more"
                            >
                                load more
                            </div>
                        </ng-container>
                    </div>
                    <div
                        *ngIf="readNotificationsLoading$ | async"
                        class="spinner-container"
                        style="margin-left: 16px; margin-bottom: 16px;"
                    >
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </div>
                    <div
                        class="mb-4"
                        *ngIf="(readNotifications$ | async)?.length === 0"
                    >
                        There are no read notifications
                    </div>
                </div>
            </div>
        </section>
    `,
    styles: [
        `
            .view-card {
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
                width: 100%;
                border-radius: 5px;
                box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
                background-color: #fff;
            }

            .subtitle {
                color: rgba(96, 96, 96, 0.87);
                font-family: Roboto;
                font-size: 17px;
                letter-spacing: 0;
                line-height: 20px;
                margin-bottom: 24px;
            }

            .load-more {
                padding: 0px 16px;
                margin-bottom: 16px;
                text-decoration: underline;
                cursor: pointer;
                color: #41639f;
            }
        `
    ]
})
export class NotificationsPageComponent implements OnInit {
    readNotifications$: Observable<Notification[]>;
    unreadNotifications$: Observable<Notification[]>;
    readNotificationsLoading$: Observable<boolean>;
    unreadNotificationsLoading$: Observable<boolean>;
    readNotificationsLoadMore$: Observable<boolean>;
    unreadNotificationsLoadMore$: Observable<boolean>;

    constructor(private store: Store<AppState>) {}

    ngOnInit() {
        this.readNotifications$ = this.store.select(readNotificationsSelector);
        this.readNotificationsLoading$ = this.store.select(
            readNotificationsLoadingSelector
        );
        this.readNotificationsLoadMore$ = this.store.select(
            readNotificationsLoadMoreSelector
        );
        this.unreadNotifications$ = this.store.select(
            unreadNotificationsSelector
        );
        this.unreadNotificationsLoading$ = this.store.select(
            unreadNotificationsLoadingSelector
        );
        this.unreadNotificationsLoadMore$ = this.store.select(
            unreadNotificationsLoadMoreSelector
        );

        this.store.dispatch(
            NotificationsActions.getUnreadDetail({ reset: true })
        );
        this.store.dispatch(
            NotificationsActions.getReadDetail({ reset: true })
        );
    }

    loadMoreUnread(lastNotification) {
        this.store.dispatch(
            NotificationsActions.getUnreadDetail({
                date: lastNotification.date
            })
        );
    }

    loadMoreRead(lastNotification) {
        this.store.dispatch(
            NotificationsActions.getReadDetail({ date: lastNotification.date })
        );
    }
}
