import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TwoFactorAuthenticationDialogComponent } from './components/two-factor-authentication-dialog/two-factor-authentication-dialog.component';
import { TwoFactorAuthenticationActions } from './two-factor-authentication.actions';
import { AppState } from '../../core/core.state';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { statusSelector } from './two-factor-authentication.selectors';
import { takeUntil } from 'rxjs/operators';
import { selectCurrentUser } from '../../core/auth-lib';

@Component({
    selector: 'qcbi-two-factor-authentication',
    templateUrl: 'two-factor-authentication.component.html',
    styleUrls: ['two-factor-authentication.component.scss']
})
export class TwoFactorAuthenticationComponent implements OnInit, OnDestroy {
    status$: Observable<'DISABLED' | 'ENABLED' | 'SYNCHRONIZED'>;

    private userId;
    private unsubscribe$ = new Subject();

    constructor(private dialog: MatDialog, private store: Store<AppState>) {}

    ngOnInit() {
        this.store
            .select(selectCurrentUser)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                this.userId = res.id;
                this.store.dispatch(
                    TwoFactorAuthenticationActions.fetchStatus({
                        userId: this.userId
                    })
                );
            });
        this.status$ = this.store
            .select(statusSelector)
            .pipe(takeUntil(this.unsubscribe$));
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * it opens the 2FA dialog. once it closes, it checks 2FA status again
     */
    manageTwoFactorAuthentication() {
        const ref = this.dialog.open(TwoFactorAuthenticationDialogComponent, {
            width: '800px'
        });
        ref.afterClosed().subscribe(() => {
            this.store.dispatch(
                TwoFactorAuthenticationActions.fetchStatus({
                    userId: this.userId
                })
            );
        });
    }
}
