import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from '@angular/core';
import { SelectableTeam } from '../../../../models/selectable-team.model';

@Component({
    selector: 'qcbi-selectable-list',
    templateUrl: 'selectable-list.component.html',
    styleUrls: ['selectable-list.component.scss']
})
export class SelectableListComponent implements OnChanges {
    @Input() teamsList: SelectableTeam[] = [];

    @Output() selectedChange = new EventEmitter();

    constructor() {}

    ngOnChanges() {}

    selectedChangeHandler(id: number) {
        this.selectedChange.emit(id);
    }

    trackByFn(_: number, item: SelectableTeam): number {
        return item.id;
    }
}
