import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { first, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../core/core.state';
import { selectCurrentUser } from '../../../../../../../core/auth-lib';
import { environment } from '../../../../../../../../environments/environment';

@Component({
    selector: 'qcbi-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {
    @Input() issueId;
    @Input() instanceId;

    userName = '';
    comments$: Observable<any[]>;

    unsubscribe$ = new Subject();

    options: FormGroup;
    addComment = new FormControl('');

    constructor(
        public fb: FormBuilder,
        private http: HttpClient,
        private store: Store<AppState>
    ) {
        this.options = fb.group({
            addComment: this.addComment
        });
    }

    ngOnInit(): void {
        this.getComments();
        this.store
            .select(selectCurrentUser)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(user => {
                this.userName = user.username;
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getComments(): void {
        this.comments$ = this.http
            .get(
                `${environment.apiEndPoint}api/v2/issue-comment?filter[issue_id]=${this.issueId}&filter[instance_id]=${this.instanceId}&sort=-created_on`
            )
            .pipe(
                first(),
                map((res: any) =>
                    res.data.map(el => {
                        return {
                            id: el.id,
                            ...el.attributes
                        };
                    })
                )
            );
    }

    getAvatarInitials(userName) {
        return userName
            .split(' ')
            .map(word => word[0])
            .join('');
    }

    hashCode(str: string) {
        // java String#hashCode
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    intToRGB(i: number) {
        const c = (i & 0x00ffffff).toString(16).toUpperCase();

        return '#' + '00000'.substring(0, 6 - c.length) + c;
    }

    postComment() {
        const url = `${environment.apiEndPoint}api/v2/issue-comment`;
        this.http
            .post(url, {
                data: {
                    type: 'issue-comment',
                    attributes: {
                        issueId: Number(this.issueId),
                        instanceId: this.instanceId,
                        comment: this.options.value.addComment
                    }
                }
            })
            .subscribe(() => {
                this.getComments();
                this.options.reset();
            });
    }
}
