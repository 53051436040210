import { Component, Input } from '@angular/core';
import { ColorService } from '../../core/services/color.service';

@Component({
    selector: 'qcbi-colored-dot',
    template: `
        <div
            class="colored-dot"
            [ngStyle]="{
                background: colorService.getColor(name),
                width: size + 'px',
                height: size + 'px'
            }"
        ></div>
    `,
    styles: [
        `
            .colored-dot {
                background: #acacac;
                border-radius: 50%;
            }
        `
    ]
})
export class ColoredDotComponent {
    @Input() name: string;
    @Input() size = 16;

    constructor(public colorService: ColorService) {}
}
