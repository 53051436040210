import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'qcbi-avatar',
    template: `
        <div
            class="avatar"
            [ngClass]="{ 'margin-top-12': marginTop }"
            [ngStyle]="{ background: getBackgroundColor(name) }"
        >
            {{ initial ? (name | avatar) : '' }}
        </div>
    `,
    styles: [
        `
            .avatar {
                border-radius: 50%;
                float: left;
                width: 24px;
                height: 24px;
                margin-right: 8px;
                text-align: center;
                font-size: 10px;
                color: white;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                flex-direction: column;
            }

            .margin-top-12 {
                margin-top: 12px;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {
    @Input() name: string;
    @Input() marginTop = true;
    @Input() initial = true;

    getBackgroundColor(name: string) {
        if (name === 'unassigned') return 'gray';
        return this.intToRGB(this.hashCode(name));
    }
    hashCode(str: string) {
        if (!str) return 0;
        // java String#hashCode
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            // tslint:disable-next-line:no-bitwise
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    intToRGB(i: number) {
        // tslint:disable-next-line:no-bitwise
        const c = (i & 0x00ffffff).toString(16).toUpperCase();
        return '#' + '00000'.substring(0, 6 - c.length) + c;
    }
}
