import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../core/core.state';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import {
    includeBaselineSelector,
    scanSelector
} from '../../core/state/general/general.selectors';
import { GeneralActions } from '../../core/state/general/general.actions';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'qcbi-baseline-toggle',
    templateUrl: './baseline-toggle.component.html'
})
export class BaselineToggleComponent implements OnInit, OnDestroy {
    includeBaseline: boolean;

    scanDate: Date;
    thresholdDate = new Date('04/27/2022');

    private unsubscribe = new Subject<void>();

    constructor(private store: Store<AppState>) {}

    ngOnInit() {
        this.store
            .select(includeBaselineSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                includeBaseline => (this.includeBaseline = includeBaseline)
            );

        this.store
            .select(scanSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(scan => {
                if (scan.hasOwnProperty('scan-date')) {
                    this.scanDate = new Date(scan['scan-date']);
                    if (
                        !this.includeBaseline &&
                        this.disableWhenInvalidDate()
                    ) {
                        this.store.dispatch(
                            GeneralActions.toggleIncludeBaseline()
                        );
                    }
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    disableWhenInvalidDate(): boolean {
        return (
            this.scanDate === undefined ||
            this.scanDate.getTime() < this.thresholdDate.getTime()
        );
    }

    toggleIncludeBaseline(): void {
        this.store.dispatch(GeneralActions.toggleIncludeBaseline());
    }
}
