import { Injectable } from '@angular/core';
import { DownloadFormattedFileService } from './download-formatted-file.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState } from '../../core.state';
import { Store } from '@ngrx/store';
import { DownloadFormattedFileActions } from './download-formatted-file.actions';
import { switchMap, catchError, withLatestFrom } from 'rxjs/operators';
import {
    instanceIdSelector,
    scanIdSelector,
    scanLongIdSelector,
    selectedDateRangeSelector
} from '../general/general.selectors';

@Injectable()
export class DownloadFormattedFileEffects {
    constructor(
        private actions$: Actions,
        private service: DownloadFormattedFileService,
        private store: Store<AppState>
    ) {}

    @Effect()
    downloadXls = this.actions$.pipe(
        ofType(DownloadFormattedFileActions.download),
        withLatestFrom(this.store.select(scanLongIdSelector)),
        withLatestFrom(this.store.select(instanceIdSelector)),
        withLatestFrom(this.store.select(selectedDateRangeSelector)),
        withLatestFrom(this.store.select(scanIdSelector)),
        switchMap(
            ([
                [
                    [[{ id, queryParams, format }, scanLongId], instanceId],
                    dateRange
                ],
                scanId
            ]) =>
                this.service
                    .downloadFormattedFile(
                        id,
                        queryParams,
                        scanLongId,
                        scanId,
                        instanceId,
                        dateRange,
                        format
                    )
                    .pipe(
                        switchMap(() => {
                            return [
                                DownloadFormattedFileActions.downloadSuccess({
                                    id,
                                    format
                                })
                            ];
                        }),
                        catchError(() => {
                            return [
                                DownloadFormattedFileActions.downloadFail({
                                    id,
                                    format
                                })
                            ];
                        })
                    )
        )
    );
}
