import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TagFormComponent } from '../tag-form/tag-form.component';
import { Observable, Subject } from 'rxjs';
import { ColoredTag } from '../../../core/state/tags/tags.models';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/core.state';
import { allTagsSelector } from '../../../core/state/tags/tags.selectors';
import { first, map, takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { TagsActions } from '../../../core/state/tags/tags.actions';
import { Actions, ofType } from '@ngrx/effects';

@Component({
    selector: 'qcbi-tag-manager',
    templateUrl: './tag-manager.component.html',
    styleUrls: ['./tag-manager.component.scss']
})
export class TagManagerComponent implements OnInit, OnDestroy {
    tags$: Observable<ColoredTag[]>;
    unsubscribe$ = new Subject();

    constructor(
        public dialog: MatDialog,
        private store: Store<AppState>,
        private snackBar: MatSnackBar,
        private actions$: Actions
    ) {}

    ngOnInit() {
        this.tags$ = this.store.pipe(
            select(allTagsSelector),
            map((data: ColoredTag[]) => {
                return data.map(label => {
                    return {
                        ...label,
                        bgColor: this.getColor(label.name),
                        textColor: this.pickTextColorBasedOnBgColorSimple(
                            this.getColor(label.name)
                        )
                    };
                });
            })
        );

        this.actions$
            .pipe(
                ofType(TagsActions.deleteTagError),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.snackBar.open(
                    'The tag cannot be deleted as it is in use. Please remove it from all issues before deleting',
                    null,
                    {
                        duration: 3000,
                        panelClass: 'error-notification-overlay'
                    }
                );
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    editTag(item) {
        this.dialog.open(TagFormComponent, {
            data: { id: item.id, name: item.name }
        });
    }

    deleteTag(tagId: string) {
        const ref = this.dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: { msg: 'You are going to delete a tag' }
        });
        ref.afterClosed()
            .pipe(first())
            .subscribe(res => {
                if (res) this.store.dispatch(TagsActions.deleteTag({ tagId }));
            });
        // this.store.dispatch(TagsActions.deleteTag({ tagId }));
    }

    private hashCode(str: string) {
        if (str === undefined || str === null) return 0;
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            // tslint:disable-next-line:no-bitwise
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    private intToRGB(i: number) {
        // tslint:disable-next-line:no-bitwise
        const c = (i & 0x00ffffff).toString(16).toUpperCase();

        return '#' + '00000'.substring(0, 6 - c.length) + c;
    }

    getColor(tagName) {
        return this.intToRGB(this.hashCode(tagName));
    }

    pickTextColorBasedOnBgColorSimple(
        bgColor,
        lightColor = '#fff',
        darkColor = '#000'
    ) {
        const color =
            bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
        const r = parseInt(color.substring(0, 2), 16); // hexToR
        const g = parseInt(color.substring(2, 4), 16); // hexToG
        const b = parseInt(color.substring(4, 6), 16); // hexToB
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
    }
}
