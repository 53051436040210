import { ApiUser, User } from './models';
import { HttpErrorResponse } from '@angular/common/http';
import { ActionReducer } from '@ngrx/store';

export type HttpCustomErrorResponse = {
    reason: string;
    status: number;
};

export function parseApiUser(raw: ApiUser): User {
    return {
        id: raw.id,
        username: raw.attributes.username,
        firstName: raw.attributes['first-name'],
        lastName: raw.attributes['last-name'],
        email: raw.attributes['personal-company-email'],
        lastLogged: raw.attributes['last-logged'],
        customerId: raw.attributes['customer-id'],
        customerName: raw.attributes['customer-name'],
        termsAccepted: raw.attributes['terms-accepted'],
        active: raw.attributes.active,
        canScan: raw.attributes['can-scan'],
        launchUpdateSet: raw.attributes['launch-update-set'],
        role: raw.attributes.role,
        instanceIds: raw.attributes['instance-ids'],
        dashboardIds: raw.attributes['dashboard-ids'],
        profileId: raw.attributes['profile-id'],
        canDownload: raw.attributes['can-download'],
        validUntil: raw.attributes['valid-until'],
        expires: raw.attributes.expires,
        forceChange: raw.attributes['force-change'],
        pwdExpirationDays: raw.attributes['pwd-expiration-days'],
        showPwdWarning: raw.attributes['show-pwd-warning'],
        pwdChanged: raw.attributes['pwd-changed'],
        roleName: raw.attributes.role_name,
        numDashboards: raw.attributes.num_dashboards,
        loggedIn: raw.attributes['logged-in'],
        canBeEmulated: raw.attributes['can-be-emulated'],
        showNewPortalPopup: raw.attributes['show-new-portal-popup'],
        self: raw.attributes.self,
        emulatedUser: raw.attributes.emulatedUser,
        timeFormat: raw.attributes['time-format'],
        customerHasMfa: raw.attributes['customer-has-mfa'],
        forceMfa: raw.attributes['force-mfa'],
        hasSso: raw.attributes['has-sso'],
        isApprover: raw.attributes['is-approver']
    };
}

export function getHeaders(
    token: string,
    skipErrors: boolean,
    defaultHeaders: boolean
) {
    const headers: any = {
        Authorization: 'Bearer ' + token
    };
    if (skipErrors) headers.skip = 'true';
    if (defaultHeaders) headers.noDefaultHeader = 'true';
    return {
        headers
    };
}

export function calculateBlobError(err: HttpErrorResponse): Promise<any> {
    return new Promise<any>((_, reject) => {
        const reader = new FileReader();
        reader.onload = (e: Event) => {
            try {
                const errMsg = JSON.parse((<any>e.target).result);
                reject(
                    new HttpErrorResponse({
                        error: errMsg,
                        headers: err.headers,
                        status: err.status,
                        statusText: err.statusText,
                        url: err.url
                    })
                );
            } catch (e) {
                reject(err);
            }
        };
        reader.onerror = () => {
            reject(err);
        };
        reader.readAsText(err.error);
    });
}

export function calculateError(
    response: HttpErrorResponse
): HttpCustomErrorResponse {
    let reason: any = '';
    let items =
        response && response.error && response.error['errors'] !== undefined
            ? response.error['errors'][0]['title']
            : null;
    if (items != null) {
        try {
            items = JSON.parse(items);
            for (const key in items) {
                if (items.hasOwnProperty(key)) {
                    const value = items[key];
                    reason = reason + ' ' + value;
                }
            }
        } catch (error) {
            reason = items;
        }
    } else {
        if (response && response.error) {
            if (
                typeof response.error === 'string' ||
                response.error instanceof String
            )
                reason = response.error;
            else if (
                typeof response.error === 'object' &&
                response.error.hasOwnProperty('error')
            )
                if (
                    typeof response.error.error === 'object' &&
                    response.error.error.hasOwnProperty('message')
                )
                    reason = response.error.error.message;
                else reason = response.error.error;
            else reason = '';
        }
    }
    return {
        reason:
            typeof reason === 'string' || reason instanceof String
                ? reason.trim()
                : '',
        status: response.status
    };
}

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function(state, action) {
        const newState = reducer(state, action);
        if (!action.type.includes('Heartbeat')) {
        }
        return newState;
    };
}
