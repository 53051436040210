import { createAction, props } from '@ngrx/store';
import { ScanIssuesResponse } from './scan-issues.models';

export const ScanIssuesActions = {
    load: createAction(
        '[Scan issues] Load',
        props<{ filters: any | undefined }>()
    ),
    loadSuccess: createAction(
        '[Scan issues] Load success',
        props<ScanIssuesResponse>()
    ),
    error: createAction('[Scan issues] Load error', props<{ error: string }>())
};
