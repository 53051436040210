import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, Observer, of } from 'rxjs';
import { AuthLibState, HeartbeatInfo, InitSuccess, User } from './models';
import { getHeaders, parseApiUser } from './utils';
import { environment } from '../../../environments/environment';
import { UserFormatService } from '../../shared/userFormat.service';

@Injectable({ providedIn: 'root' })
export class QCAuthService implements OnDestroy {
    apiEndpoint: string;
    cookiePrefix: string;
    domain: string;
    clientName: string;
    loginUrl: string;
    isLogin = false;
    isLocalhost = false;
    secureCookie = true;
    sameSiteCookie: 'Lax' | 'None' | 'Strict' = 'Strict';
    windowId: string;
    cookieChangeHandler = () => {
        if (!this.hasAccessToken() && !this.hasRefreshToken()) {
            console.log('logout');
            location.reload();
        }
    };

    constructor(
        public http: HttpClient,
        public cookieService: CookieService,
        private userFormatService: UserFormatService
    ) {
        if (window.location.href.includes('localhost')) {
            this.isLocalhost = true;
        }
        this.cookiePrefix = environment.cookiePrefix;
        this.domain = environment.cookieDomain;
        this.apiEndpoint = environment.apiUrl;
        this.loginUrl = environment.loginUrl;
        this.isLogin = false;
        this.clientName = 'QualityCloudsBI';
        this.windowId = Math.random()
            .toString(36)
            .substr(2, 9);

        // cookies

        this.cookieInterception();
        this.cookieSubscriptionToLogout();
    }

    ngOnDestroy() {
        document.removeEventListener('cookiechange', this.cookieChangeHandler);
    }

    // COOKIES INTERCEPTION

    cookieInterception() {
        let lastCookie = document.cookie;
        const expando = '_cookie';
        const nativeCookieDesc = Object.getOwnPropertyDescriptor(
            Document.prototype,
            'cookie'
        );
        Object.defineProperty(Document.prototype, expando, nativeCookieDesc);
        Object.defineProperty(Document.prototype, 'cookie', {
            enumerable: true,
            configurable: true,
            get() {
                return this[expando];
            },
            set(value) {
                this[expando] = value;
                // check cookie change
                const cookie = this[expando];
                if (cookie !== lastCookie) {
                    try {
                        // dispatch cookie-change messages to other same-origin tabs/frames
                        const detail = {
                            oldValue: lastCookie,
                            newValue: cookie
                        };
                        this.dispatchEvent(
                            new CustomEvent('cookiechange', {
                                detail: detail
                            })
                        );
                        channel.postMessage(detail);
                    } finally {
                        lastCookie = cookie;
                    }
                }
            }
        });
        const channel = new BroadcastChannel('cookie-channel');
        channel.onmessage = e => {
            lastCookie = e.data.newValue;
            document.dispatchEvent(
                new CustomEvent('cookiechange', {
                    detail: e.data
                })
            );
        };
    }

    cookieSubscriptionToLogout() {
        document.addEventListener('cookiechange', this.cookieChangeHandler);
    }

    // COOKIE FUNCTIONS
    hasToken = (name: string): boolean =>
        this.cookieService.check(this.cookiePrefix + name);
    hasAccessToken = (): boolean => this.hasToken('access_token');
    hasRefreshToken = (): boolean => this.hasToken('refresh_token');
    hasEmulationToken = (): boolean => this.hasToken('emulation_token');

    getToken = (name: string) =>
        this.cookieService.get(this.cookiePrefix + name);
    getAccessToken = (): string => this.getToken('access_token');
    getRefreshToken = (): string => this.getToken('refresh_token');
    getEmulationToken = (): string => this.getToken('emulation_token');

    setToken(name: string, token: string, expiration: number) {
        if (this.hasToken(name)) {
            this.removeToken(name);
        }
        this.cookieService.set(
            this.cookiePrefix + name,
            token,
            expiration,
            '/',
            this.domain,
            this.isLocalhost ? undefined : this.secureCookie,
            this.isLocalhost ? undefined : this.sameSiteCookie
        );
    }
    setAccessToken = (token: string, expiration = 18000): void =>
        this.setToken('access_token', token, expiration);
    setRefreshToken = (token: string, expiration = 18000): void =>
        this.setToken('refresh_token', token, expiration);
    setEmulationToken = (token: string, expiration = 18000): void =>
        this.setToken('emulation_token', token, expiration);

    removeToken = (name: string): void => {
        this.cookieService.delete(
            this.cookiePrefix + name,
            '/',
            this.domain,
            this.isLocalhost ? undefined : this.secureCookie,
            this.isLocalhost ? undefined : this.sameSiteCookie
        );
    };
    removeAccessToken = () => this.removeToken('access_token');
    removeRefreshToken = () => this.removeToken('refresh_token');
    removeEmulationToken = () => this.removeToken('emulation_token');
    getOpenWindowId = (): string => this.getToken('open_window_id');
    setOpenWindowId = (id: string) =>
        this.setToken('open_window_id', id, 18000);
    compareWindowId(id) {
        const openWindowId = this.getOpenWindowId();
        return openWindowId === id;
    }

    cookieClean = (): void => {
        if (this.hasAccessToken) {
            this.removeAccessToken();
        }
        if (this.hasRefreshToken) {
            this.removeRefreshToken();
        }
        if (this.hasEmulationToken) {
            this.removeEmulationToken();
        }
        if (this.hasToken('sso_autologin')) this.removeToken('sso_autologin');
    };

    // Effects observables
    init(): Observable<InitSuccess> {
        return Observable.create(async (observer: Observer<InitSuccess>) => {
            if (this.hasAccessToken()) {
                const initState = {
                    isAuthenticated: false,
                    onEmulation: false,
                    user: null,
                    emulatedUser: null
                };
                const accessToken = this.getAccessToken();
                const isAccessTokenValid = await this.checkToken(
                    accessToken
                ).toPromise();
                if (isAccessTokenValid) {
                    initState.isAuthenticated = true;
                    initState.user = await this.getUser(
                        accessToken
                    ).toPromise();
                    this.userFormatService.setTimeFormat(
                        initState.user.timeFormat
                    );
                    if (this.hasEmulationToken()) {
                        const emulationToken = this.getEmulationToken();
                        const isEmulationTokenValid = await this.checkToken(
                            accessToken
                        ).toPromise();
                        if (isEmulationTokenValid) {
                            initState.onEmulation = true;
                            initState.emulatedUser = await this.getUser(
                                emulationToken
                            ).toPromise();
                            observer.next(initState);
                        } else {
                            this.removeEmulationToken();
                            observer.next(initState);
                        }
                    } else {
                        observer.next(initState);
                    }
                } else {
                    observer.error('Access token not valid');
                }
            } else {
                observer.error('No access token set');
            }
            observer.complete();
        });
    }
    // Keeps the token refreshed and emulation valid
    heartbeat(state: AuthLibState): Observable<HeartbeatInfo> {
        return Observable.create(async (observer: Observer<HeartbeatInfo>) => {
            const [hasAT, hasET, response] = [
                this.hasAccessToken(),
                this.hasEmulationToken(),
                {
                    heartbeat: false,
                    refreshed: false,
                    emulationOutdated: false,
                    reloadState: false,
                    onWindow: this.compareWindowId(state.windowId)
                }
            ];

            if (hasAT) {
                const at = this.getAccessToken();
                await this.checkToken(at, false, false).toPromise();
                response.heartbeat = true;
                if (hasET) {
                    const et = this.getEmulationToken();
                    const isETValid = await this.checkToken(et, false, false);
                    if (!isETValid) {
                        this.removeEmulationToken();
                        response.emulationOutdated = true;
                    } else if (!state.onEmulation) {
                        response.reloadState = true;
                    }
                } else if (state.onEmulation) {
                    response.emulationOutdated = true;
                }
            }
            observer.next(response);
            observer.complete();
        });
    }

    // Api calls
    login(credentials: {
        username: string;
        password: string;
    }): Observable<any> {
        const url = this.apiEndpoint + '/v2/login';
        return this.http.post(url, credentials, {
            headers: {
                noDefaultHeader: 'true',
                skip: 'true'
            }
        });
    }

    logout(): Observable<any> {
        const url = this.apiEndpoint + '/web/register-logout';
        return this.http.post(url, {});
    }

    getUser(token): Observable<any> {
        const url = this.apiEndpoint + '/v2/user/me';
        const headers = getHeaders(token, true, true);
        return this.http
            .get(url, headers)
            .pipe(map((response: any) => parseApiUser(response.data)));
    }

    refreshToken(token: string, refreshToken: string): Observable<any> {
        const url = this.apiEndpoint + '/v2/refresh';
        const headers = getHeaders(token, true, true);
        return this.http.post(url, { refresh_token: refreshToken }, headers);
    }

    checkToken(
        token: string,
        skip = true,
        defaultHeaders = true
    ): Observable<boolean> {
        const url = this.apiEndpoint + '/v2/token-health';
        const headers = getHeaders(token, skip, defaultHeaders);
        return this.http.get(url, headers).pipe(
            map(() => true),
            catchError(() => of(false))
        );
    }

    startEmulation(id: string): Observable<User> {
        const url = this.apiEndpoint + '/web/start-emulation';
        return this.http.post(url, { 'user-id': id }).pipe(
            tap(async (response: any) => {
                this.setEmulationToken(response.access_token);
                const apiUser = await this.getUser(
                    response.access_token
                ).toPromise();
                return parseApiUser(apiUser);
            })
        );
    }

    endEmulation(): Observable<any> {
        const url = this.apiEndpoint + '/web/stop-emulation';
        const token = this.getAccessToken();
        const headers = getHeaders(token, true, true);
        return this.http.post(url, {}, headers);
    }

    loginRedirection() {
        let url = this.loginUrl;
        if (!this.isLogin) {
            url += '?redirectTo=' + window.location.href;
        }
        window.open(url, '_self');
    }

    termsRedirection() {
        let url = this.loginUrl + 'accept';
        url += '?redirectTo=' + window.location.href;
        window.open(url, '_self');
    }

    changePasswordRedirection() {
        let url = this.loginUrl + 'change/auth';
        url += '?redirectTo=' + window.location.href;
        window.open(url, '_self');
    }

    dismissWelcome(userId: string): Observable<any> {
        const url = this.apiEndpoint + '/v2/user/' + userId;
        const params = {
            data: {
                type: 'user',
                id: userId,
                attributes: {
                    'show-new-portal-popup': false
                }
            }
        };
        return this.http.patch(url, params);
    }
}
