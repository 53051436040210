import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppState } from '../../../../core/core.state';
import { Store } from '@ngrx/store';
import { qrSecretSelector } from '../../two-factor-authentication.selectors';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'qcbi-code-dialog',
    templateUrl: 'code-dialog.component.html',
    styleUrls: ['code-dialog.component.scss']
})
export class CodeDialogComponent implements OnInit, OnDestroy {
    qrSecret$: Observable<string>;

    private unsubscribe$ = new Subject();

    constructor(
        private store: Store<AppState>,
        private dialogRef: MatDialogRef<CodeDialogComponent>
    ) {}

    ngOnInit() {
        this.qrSecret$ = this.store
            .select(qrSecretSelector)
            .pipe(takeUntil(this.unsubscribe$));
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * closes the dialog
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
