import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    dashboardsSelector,
    instancesSelector
} from '../../../core/state/general/general.selectors';
import { first, takeUntil } from 'rxjs/operators';
import { GeneralActions } from '../../../core/state/general/general.actions';
import { AppState } from '../../../core/core.state';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationsActions } from '../../../core/state/notifications/notifications.actions';

@Component({
    selector: 'qcbi-notification-card',
    template: `
        <div class="card">
            <div class="title mb-2">{{ notificationType }}</div>
            <div class="message">{{ message }}</div>
            <div class="actions">
                <div class="action" (click)="notificationClickedHandler()">
                    Go to instance executive Dashboard
                </div>
                <div class="action" *ngIf="unread" (click)="markAsRead()">
                    Mask as read
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            .card {
                border-radius: 5px;
                box-shadow: 0 3px 15px 0 rgba(125, 125, 125, 0.14);
                border: 1px solid rgba(223, 223, 223, 0.7);
                padding: 24px 16px;
                margin-bottom: 24px;
            }

            .title {
                font-weight: bold;
                color: rgba(0, 0, 0, 0.87);
                font-size: 20px;
                margin-bottom: 2px;
                display: flex;
                font-family: 'Roboto';
                letter-spacing: 0;
                line-height: 24px;
            }

            .message {
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.87);
                font-family: 'Roboto';
            }

            .actions {
                margin-top: 32px;
                display: flex;
            }

            .action {
                color: #41639f;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 16px;
                text-decoration: underline;
                cursor: pointer;
                margin-right: 32px;
            }
        `
    ]
})
export class NotificationCardComponent implements OnInit, OnDestroy {
    @Input() notificationType = 'Scan Notification';
    @Input() message: string;
    @Input() unread = true;
    @Input() instanceId: number;
    @Input() notificationId: string;

    executiveDashboard;
    unsubscribe = new Subject();

    constructor(private store: Store<AppState>, private router: Router) {}

    ngOnInit() {
        this.store
            .select(dashboardsSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
                // TODO: search a solution for snow executive
                const d = res.find(dashboard => dashboard.url === 'executive');
                if (d) {
                    this.executiveDashboard = d;
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    markAsRead() {
        this.store.dispatch(
            NotificationsActions.markNotificationAsRead({
                notificationId: this.notificationId
            })
        );
    }

    notificationClickedHandler() {
        this.store
            .select(instancesSelector)
            .pipe(first())
            .subscribe(res => {
                const k = Object.keys(res);
                let i;
                for (i = 0; i < k.length; ++i) {
                    const instance = res[k[i]].find(
                        inst => +inst.id === +this.instanceId
                    );
                    if (instance) {
                        break;
                    }
                }
                this.router.navigate([
                    '/' + k[i].toLowerCase(),
                    this.instanceId,
                    'executive'
                ]);
                this.store.dispatch(
                    GeneralActions.instanceLoad({
                        serviceId: 2,
                        instanceId: +this.instanceId,
                        dashboardId: this.executiveDashboard.id
                    })
                );
                this.store.dispatch(
                    GeneralActions.setDashboard({
                        dashboard: this.executiveDashboard
                    })
                );
            });
    }
}
