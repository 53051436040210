import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
    catchError,
    map,
    switchMap,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { qcAuthActions } from './actions';
import { QCAuthService } from './service';
import { AuthLibState, User } from './models';
import {
    logoutOnFocusSelector,
    selectAuth,
    selectOnWindow,
    selectWindowId
} from './selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class QCAuthEffects {
    constructor(
        private actions$: Actions,
        private service: QCAuthService,
        private store: Store<any>
    ) {}

    @Effect()
    login = this.actions$.pipe(
        ofType(qcAuthActions.login),
        switchMap(credentials =>
            this.service.login(credentials).pipe(
                map((data: any) => qcAuthActions.loginSuccess(data)),
                catchError((error: any) =>
                    of(qcAuthActions.loginError({ error }))
                )
            )
        )
    );

    @Effect()
    logout = this.actions$.pipe(
        ofType(qcAuthActions.logout),
        switchMap(() =>
            this.service.logout().pipe(
                map(() => {
                    this.service.cookieClean();
                    return qcAuthActions.loginRedirection();
                }),
                catchError(() => {
                    this.service.cookieClean();
                    return of(qcAuthActions.loginRedirection());
                })
            )
        )
    );

    @Effect()
    @Effect()
    loginSuccess = this.actions$.pipe(
        ofType(qcAuthActions.loginSuccess),
        map(({ access_token, refresh_token }) => {
            this.service.setAccessToken(access_token);
            this.service.setRefreshToken(refresh_token);
            return qcAuthActions.init();
        })
    );

    @Effect()
    init = this.actions$.pipe(
        ofType(qcAuthActions.init),
        switchMap(() =>
            this.service.init().pipe(
                map((initState: AuthLibState) => {
                    const {
                        termsAccepted,
                        forceChange
                    } = initState.user as User;
                    if (!termsAccepted) {
                        return qcAuthActions.termsRedirection();
                    } else if (forceChange) {
                        return qcAuthActions.changePasswordRedirection();
                    } else {
                        return qcAuthActions.initSuccess(initState);
                    }
                }),
                catchError(() => of(qcAuthActions.loginRedirection()))
            )
        )
    );

    @Effect()
    initFocus = this.actions$.pipe(
        ofType(qcAuthActions.initSuccess),
        switchMap(() => of(qcAuthActions.windowFocus()))
    );

    @Effect()
    reload = this.actions$.pipe(
        ofType(qcAuthActions.reload),
        switchMap(() =>
            this.service.init().pipe(
                map((initState: AuthLibState) =>
                    qcAuthActions.initSuccess(initState)
                ),
                catchError(() => of(qcAuthActions.loginRedirection()))
            )
        )
    );

    @Effect({ dispatch: false })
    termsRedirection = this.actions$.pipe(
        ofType(qcAuthActions.termsRedirection),
        tap(() => this.service.termsRedirection())
    );

    @Effect({ dispatch: false })
    loginRedirection = this.actions$.pipe(
        ofType(qcAuthActions.loginRedirection),
        tap(() => this.service.loginRedirection())
    );

    @Effect({ dispatch: false })
    changePasswordRedirection = this.actions$.pipe(
        ofType(qcAuthActions.changePasswordRedirection),
        tap(() => this.service.changePasswordRedirection())
    );

    @Effect()
    heartbeat = this.actions$.pipe(
        ofType(qcAuthActions.heartbeat),
        withLatestFrom(this.store.select(selectAuth)),
        switchMap(([{}, state]) =>
            state.onWindow
                ? this.service.heartbeat(state).pipe(
                      map(response => {
                          if (!response.heartbeat)
                              return qcAuthActions.loginRedirection();
                          if (response.refreshed)
                              return qcAuthActions.refreshTokenHandle();
                          if (response.emulationOutdated)
                              return qcAuthActions.endEmulation();
                          if (response.reloadState) {
                              return qcAuthActions.reload();
                          }
                          return qcAuthActions.heartbeatSuccess();
                      }),
                      catchError(() => of(qcAuthActions.heartbeatError()))
                  )
                : EMPTY
        )
    );

    @Effect()
    windowFocus = this.actions$.pipe(
        ofType(qcAuthActions.windowFocus),
        withLatestFrom(this.store.select(selectWindowId)),
        withLatestFrom(this.store.select(logoutOnFocusSelector)),
        map(([[{}, windowId], logoutOnFocus]) => {
            this.service.setOpenWindowId(windowId);
            if (logoutOnFocus) return qcAuthActions.logout();
            return qcAuthActions.windowFocusNoLogoutSuccess();
        })
    );

    @Effect()
    logoutOnFocus = this.actions$.pipe(
        ofType(qcAuthActions.logoutOnFocus),
        withLatestFrom(this.store.select(selectOnWindow)),
        map(([{}, focus]) => {
            if (focus) return qcAuthActions.logout();
            return qcAuthActions.windowFocusNoLogoutSuccess();
        })
    );

    @Effect()
    startEmulation = this.actions$.pipe(
        ofType(qcAuthActions.startEmulation),
        switchMap(({ id }) =>
            this.service.startEmulation(id).pipe(
                map(emulatedUser =>
                    qcAuthActions.startEmulationSuccess({ emulatedUser })
                ),
                catchError(() =>
                    of(qcAuthActions.startEmulationError({ reason: '' }))
                )
            )
        )
    );

    @Effect()
    endEmulation = this.actions$.pipe(
        ofType(qcAuthActions.endEmulation),
        switchMap(() =>
            this.service.endEmulation().pipe(
                map(() => qcAuthActions.endEmulationSuccess()),
                catchError(() => of(qcAuthActions.endEmulationSuccess()))
            )
        )
    );

    @Effect({ dispatch: false })
    endEmulationSuccess = this.actions$.pipe(
        ofType(qcAuthActions.endEmulationSuccess),
        tap(() => this.service.removeEmulationToken())
    );

    @Effect({ dispatch: false })
    dismissShowPortalPopup$ = this.actions$.pipe(
        ofType(qcAuthActions.dismissShowPortalPopup),
        switchMap(({ userId }) =>
            this.service
                .dismissWelcome(userId)
                .pipe(
                    map((user: any) =>
                        qcAuthActions.dismissShowPortalSuccess({ user })
                    )
                )
        )
    );
}
