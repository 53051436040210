<div class="dialog">
    <div class="row justify-content-center position-relative mb-3">
        <img alt="" src="./assets/images/logoInverted.png" />
                <button tabindex="-1" mat-icon-button class="position-absolute close" (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
    </div>
    <div mat-dialog-content class="justify-content-center">
        <div class="row pl-4 pr-4">
            <div class="col-12" style="overflow-y: scroll;">
                <div class="row-term">
                    <div class="left-term title-term">1</div>
                    <div class="right-term title-term">DEFINITIONS AND INTERPRETATION</div>
                </div>
                <div class="row-term">
                    <div class="left-term">1.1</div>
                    <div class="right-term">
                        In this agreement unless the context otherwise requires, the following
                        words and expressions shall have the following meanings:
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term"></div>
                    <div class="right-term">
                        <ul>
                            <li><b>"Agreement"</b> means these terms and conditions</li>
                            <li>
                                <b>"Access Period"</b> means the period agreed with Quality Clouds
                                Ltd (QCL) for the selected Product unless terminated earlier in
                                accordance with this agreement
                            </li>
                            <li>
                                <b>"Authorised Users"</b> means employees or contractors of the
                                Client issued with passwords by QCL to access the Product
                            </li>
                            <li>
                                <b>"Client Data"</b> means data input by the Client and stored by
                                QCL on behalf of the Client including but not limited to data
                                identifying Authorised Users
                            </li>
                            <li>
                                <b>"Confidential Information"</b> means all information concerning
                                the business, finances, technology, clients or commercial affairs
                                of a party or its clients which is described as confidential by
                                the party providing the information or which, by its nature,
                                should be assumed to be confidential, including but not limited
                                to, the identity of Authorised Users
                            </li>
                            <li>
                                <b>"Environment"</b> means the code available for scanning by the
                                Product at the Client specified URL
                            </li>
                            <li>
                                <b>"Fees"</b> means the fees agreed with QCL as amended pursuant
                                to clause 6.2
                            </li>
                            <li>
                                <b>"Intellectual Property Rights"</b> means patents, trade marks,
                                Internet domain names, service marks, registered designs,
                                applications for registration of any of the foregoing, copyright,
                                database rights, design rights, trade and business names and any
                                other similar protected rights in any country
                            </li>
                            <li>
                                <b>"Portal"</b> means the users point of access to the Product at
                                QCL's then current website as the same may be varied pursuant to
                                this agreement
                            </li>
                            <li>
                                <b>"QCL"</b> means Quality Clouds Limited (Company Number 09522753
                                registered in England and Wales)
                            </li>
                            <li>
                                <b>"Product Description"</b> means the description of the Product
                                in the end user information provided to the Client in hard copy or
                                electronic form and or made available through the Portal or within
                                the Product
                            </li>
                            <li>
                                <b>"Product"</b> means the SaaS quality assessment product you are
                                using and accessed via the Portal together with the results
                                generated as a consequence of using the Product
                            </li>
                            <li>
                                <b>"Scan"</b> means a scan of the Environment performed by the
                                Product
                            </li>
                            <li>
                                <b>"Support Services"</b> means the services described in clause
                                5.1.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">1.2</div>
                    <div class="right-term">
                        If there are any additional terms agreed between QCL and the Client
                        then these shall apply to and form part of this agreement and if there
                        is any inconsistency or ambiguity between such terms and those below
                        then the additional terms shall prevail to the extent necessary to
                        resolve such inconsistency or ambiguity.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">2</div>
                    <div class="right-term title-term">PROVISION OF THE SERVICES</div>
                </div>
                <div class="row-term">
                    <div class="left-term">2.1</div>
                    <div class="right-term">
                        In consideration of the payment of the Fees (when apply) and subject
                        to the terms and conditions of this agreement, QCL shall provide the
                        Client with:
                        <ul>
                            <li>
                                <div class="left-term">2.1.1</div>
                                <div class="right-term">
                                    access to and use of the Product for the specified number of
                                    Scans on the specified maximum number of Environments by its
                                    Authorised Users via the Portal during the Access Period;
                                </div>
                            </li>
                            <li>
                                <div class="left-term">2.1.2</div>
                                <div class="right-term">the Support Services;</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">2.2</div>
                    <div class="right-term">
                        Any services provided by QCL in addition to those referred to in
                        clause 2.1 shall be charged to the Client on a time and materials
                        basis.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">2.3</div>
                    <div class="right-term">
                        Any customisation of the Product for the Client shall be the subject
                        of a separate professional services agreement but once completed the
                        customised version and any changes to the Product Documentation shall
                        be licensed pursuant to this agreement.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">2.4</div>
                    <div class="right-term">
                        Following the Expiry Date the Client will be given the option for the
                        data held by QCL in connection with the Product to be returned,
                        deleted or stored subject to payment of the applicable charge. If the
                        Client does not make any election within 28 days of being given the
                        option by QCL, QCL shall serve notice on the Client that it intends to
                        delete the data and if no such election is received within a further 7
                        days QCL may delete the data and the Client will pay QCL's then
                        current fees for deletion.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">3</div>
                    <div class="right-term title-term">ACCESS TO QUALITYCLOUDS</div>
                </div>
                <div class="row-term">
                    <div class="left-term">3.1</div>
                    <div class="right-term">
                        The Client is responsible for providing QCL with the details of the
                        individuals that are to be issued with personal identification
                        information (username and password) ("PII") that will allow access to
                        the Product.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">3.2</div>
                    <div class="right-term">
                        Subject to the Client providing the necessary information QCL will
                        issue the PII to the Authorised Users using the e mail addresses
                        supplied by the Client so that the Authorised Users may access the
                        Product during the Access Period where apply.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">3.3</div>
                    <div class="right-term">
                        The Client will be responsible for any unauthorised use or misuse of
                        PII issued to its Authorised Users. The Client shall keep confidential
                        the PII issued by QCL and not disclose it to third parties. The Client
                        will notify QCL immediately in writing upon becoming aware of any
                        known or suspected misuse of PII.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">3.4</div>
                    <div class="right-term">
                        QCL shall use its reasonable endeavours to ensure that during the term
                        of this Agreement the Authorised Users have access to the Product
                        during the Access Period subject to routine and emergency maintenance
                        and repairs.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">3.5</div>
                    <div class="right-term">
                        The Client is responsible for ensuring that: (i) the Authorised Users
                        have adequate access to the internet, (ii) the Client's systems,
                        system activity and firewalls do not hinder or prevent access to the
                        Product, (iii) the Authorised User's have an up to date browser
                        supported by the Product (iv) the Environment to be subject to a Scan
                        is supported by the Product. QCL will provide the Client with
                        information necessary for the Client to be able to meet its
                        responsibility under this clause 3.5.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">3.6</div>
                    <div class="right-term">
                        QCL shall have no liability to the Client in respect of the Client’s
                        or Authorised Users’ inability to access the Product or errors in the
                        functioning of the Product which are attributable to: (i) an Event of
                        Force Majeure (see clause 14); (ii) non compliance with Clause 7.1;
                        (iii) operator error; or (iv) provision of incorrect information by
                        the Client.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">3.7</div>
                    <div class="right-term">
                        QCL shall use up to date industry recognised virus protection software
                        in the operation of the Product.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">3.8</div>
                    <div class="right-term">
                        QCL shall be entitled to suspend access to the Product: (i) on
                        reasonable notice to the Client for such period as may be reasonably
                        required for maintenance, repairs or improvements; and (ii) without
                        prior notice to the Client for exceptional operational reasons and in
                        such circumstances QCL shall provide the Client with an explanation
                        for any suspension.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">4</div>
                    <div class="right-term title-term">QUALITYCLOUDS WARRANTY</div>
                </div>
                <div class="row-term">
                    <div class="left-term">4.1</div>
                    <div class="right-term">
                        The Clients acknowledges and accepts that the use of the Product shall
                        not be uninterrupted or error free. QCL warrants that the Product
                        shall be free from material defects and shall function substantially
                        in accordance with the Product Description.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">5</div>
                    <div class="right-term title-term">
                        SUPPORT SERVICES FOR SUBSCRIPTIONS
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">5.1</div>
                    <div class="right-term">
                        The support services supplied to the Client are as follows:
                        <ul>
                            <li>
                                <div class="left-term">5.1.1</div>
                                <div class="right-term">
                                    set up of Client's named individuals as Authorised Users;
                                </div>
                            </li>
                            <li>
                                <div class="left-term">5.1.2</div>
                                <div class="right-term">
                                    if requested, assisting the Client to add, remove or amend the
                                    details of any Authorised User;
                                </div>
                            </li>
                            <li>
                                <div class="left-term">5.1.3</div>
                                <div class="right-term">
                                    guidance to Client Rep on configuration of the Product using
                                    tools within the Product accessible by the Client's Authorised
                                    User;
                                </div>
                            </li>
                            <li>
                                <div class="left-term">5.1.4</div>
                                <div class="right-term">
                                    correction of errors or faults in the functionality of the
                                    Product up to it's interface with the world wide web;
                                </div>
                            </li>
                            <li>
                                <div class="left-term">5.1.5</div>
                                <div class="right-term">
                                    improvements in the functionality and operation of the Product
                                    released within the Product subscribed to by the Client;
                                </div>
                            </li>
                            <li>
                                <div class="left-term">5.1.6</div>
                                <div class="right-term">
                                    hosting of the Client's data stored within the Product subject
                                    to reasonable use.
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">5.2</div>
                    <div class="right-term">
                        QCL may make an additional charge for any assistance or support
                        supplied by QCL and requested by the Client which falls outside of the
                        Support Services. Additional charges will be levied on a time and
                        materials basis and shall be charged at QCL's standard rates last
                        notified/agreed to/with the Client.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">5.3</div>
                    <div class="right-term">
                        QCL shall provide support only to the Client's nominated
                        representative ("Client Rep") using either e mail or phone. Support
                        requests should be made by the Client Rep using the support e mail
                        address or telephone number supplied by QCL. Requests can be made
                        between GMT 0900 and 1800 hours on weekdays other than public
                        holidays.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">5.4</div>
                    <div class="right-term">
                        QCL shall notify the Client if it considers that the storage capacity
                        being used by the Client within the Product exceeds what QCL considers
                        reasonable. In which case QCL shall advise the Client of any
                        additional charge that may be payable and the parties shall seek in
                        good faith to agree a reasonable charge for such excess for the
                        remainder of the Access Period. In the absence of any agreement within
                        28 days of such notice, QCL may remove Client data on a FIFO basis to
                        reduce the storage capacity being used by the Client.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">5.5</div>
                    <div class="right-term">
                        When requiring access to the Support Services the Client shall provide
                        sufficient material and information to enable QCL to duplicate or
                        identify the problem being reported by the Client. QCL shall attempt
                        to solve such problem as soon as reasonably possible after
                        notification of the problem by the Client.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">5.6</div>
                    <div class="right-term">
                        QCL shall not be obliged to provide support in respect of any problem
                        attributable to an Event of Force Majeure (see clause 14). Should QCL
                        agree to provide any support that falls within such exclusion, QCL
                        shall be entitled to make an additional charge in accordance with its
                        standard scale of charges from time to time in force.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">6</div>
                    <div class="right-term title-term">FEES AND OFF-LINE PAYMENTS</div>
                </div>
                <div class="row-term">
                    <div class="left-term">6.1</div>
                    <div class="right-term">
                        The Client shall pay QCL’s invoices within 30 days of the date of
                        invoice, without deduction, set-off or counterclaim. All amounts
                        stated are gross amounts but exclusive of VAT or other sales tax which
                        shall be paid by the Client, if applicable, at the then prevailing
                        rate subject to receipt of a valid VAT invoice or other sales tax
                        invoice. The Fees are payable in advance and are non refundable.
                        Additional support fees are payable monthly in arrears.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">6.2</div>
                    <div class="right-term">
                        QCL may increase the Fees on not less than 3 months notice to the
                        Client any such increase shall not be made at less than 12 month
                        intervals and shall apply with effect from the payment period
                        following the increase.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">6.3</div>
                    <div class="right-term">
                        The Client shall reimburse QCL's travel, accommodation and subsistence
                        expenses incurred in the provision of the Support Services with the
                        prior approval of the Client.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">6.4</div>
                    <div class="right-term">
                        If the Client does not make payment by the due date, QCL may, without
                        prejudice to any other rights and remedies at its option: (i) charge
                        the Client interest in accordance with the Late Payment of Commercial
                        Debts (Interest) Act 1998; and (ii) suspend the Authorised Users’
                        access to the Product.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">7</div>
                    <div class="right-term title-term">THE CLIENT’S PARTICIPATION</div>
                </div>
                <div class="row-term">
                    <div class="left-term">7.1</div>
                    <div class="right-term">
                        The Client shall ensure that its computer systems used for accessing
                        and participating in the Product meet the minimum requirements as
                        required by and made known to the Client by QCL.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">7.2</div>
                    <div class="right-term">
                        The Client shall provide QCL in a timely fashion with such assistance
                        and information as QCL may reasonably require in the set up and
                        provision of the Product and the Support Services.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">7.3</div>
                    <div class="right-term">
                        The Client shall not nor shall it seek to:
                        <ul>
                            <li>
                                <div class="left-term">7.3.1</div>
                                <div class="right-term">
                                    attempt to test or penetrate any of our servers, databases or
                                    application code;
                                </div>
                            </li>
                            <li>
                                <div class="left-term">7.3.2</div>
                                <div class="right-term">
                                    remove or circumvent security and authentication processes and
                                    logic;
                                </div>
                            </li>
                            <li>
                                <div class="left-term">7.3.3</div>
                                <div class="right-term">
                                    disrupt the Product by means of frequent and repeated access to
                                    the Portal beyond what would be considered reasonable use for
                                    its own business purposes;
                                </div>
                            </li>
                            <li>
                                <div class="left-term">7.3.4</div>
                                <div class="right-term">
                                    access or modify any application code, server or client side;
                                </div>
                            </li>
                            <li>
                                <div class="left-term">7.3.5</div>
                                <div class="right-term">
                                    access any area of the Portal to which it is not allowed access;
                                </div>
                            </li>
                            <li>
                                <div class="left-term">7.3.6</div>
                                <div class="right-term">
                                    use any of the Product to distribute virus applications,
                                    spamming, or malware;
                                </div>
                            </li>
                            <li>
                                <div class="left-term">7.3.7</div>
                                <div class="right-term">
                                    offer, supply or resell any or all of the Product to any third
                                    party
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">8</div>
                    <div class="right-term title-term">NO-CHARGE PRODUCTS</div>
                </div>
                <div class="row-term">
                    <div class="left-term">8.1</div>
                    <div class="right-term">
                        We may offer certain Products to you at no charge, including free
                        accounts, trial use, and access to Beta Versions as defined below
                        <b>(“No-Charge Products”)</b>. Your use of No-Charge Products is
                        subject to any additional terms that we specify and is only permitted
                        for the period designated by us. You may not use No-Charge Products
                        for commercial purposes. We may terminate your right to use No-Charge
                        Products at any time and for any reason in our sole discretion,
                        without liability to you. You understand that any pre-release and beta
                        products we make available <b>(“Beta Versions”)</b> are still under
                        development, may be inoperable or incomplete and are likely to contain
                        more errors and bugs than generally available Products. We make no
                        promises that any Beta Versions will ever be made generally available.
                        In some circumstances, we may charge a fee in order to allow you to
                        access Beta Versions, but the Beta Versions will still remain subject
                        to this Section (No-Charge Products). All information regarding the
                        characteristics, features or performance of Beta Versions constitutes
                        QC’s Confidential Information. To the maximum extent permitted by
                        applicable law, we disclaim all obligations or liabilities with
                        respect to No-Charge Products, including any Support and Maintenance,
                        warranty, and indemnity obligations.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">9</div>
                    <div class="right-term title-term">INTELLECTUAL PROPERTY RIGHTS</div>
                </div>
                <div class="row-term">
                    <div class="left-term">9.1</div>
                    <div class="right-term">
                        The Client acknowledges that the Intellectual Property Rights in the
                        Product, the Portal and the structure and arrangement of the Client
                        Data within the Product shall be the property of QCL. The Client will
                        not obtain any rights in the Product, the Portal and the databases
                        therein other than those expressly granted to it under this agreement.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">9.2</div>
                    <div class="right-term">
                        Intellectual Property Rights in the Client Data shall not be affected
                        by their inclusion and storage within the Product. Save that the
                        method of storage and the structure of the data within the Product may
                        be protected as a compilation or database and all Intellectual
                        Property Rights in such compilation and/or database shall belong to
                        QCL.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">9.3</div>
                    <div class="right-term">
                        QCL may use the results of the Client's use of the Product for
                        analytical purposes provided that neither the Client nor any other
                        employee, officer, agent or contractor of the Client shall be
                        identifiable from such use.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">10</div>
                    <div class="right-term title-term">QUALITYCLOUDS INDEMNITY</div>
                </div>
                <div class="row-term">
                    <div class="left-term">10.1</div>
                    <div class="right-term">
                        QCL shall at its own expense defend or, at its option, settle any
                        third party claim or suit alleging that the use by the Client of the
                        Product but not any data stored therein, in accordance with this
                        agreement or any part of it infringes any third party’s Intellectual
                        Property Rights ("a Claim") and agrees to be responsible for any
                        reasonable costs involved and to pay any damages finally awarded
                        against the Client in any such Claim by a court of competent
                        jurisdiction provided that: (i) the Client shall immediately notify
                        QCL if a Claim is made against the Client and agrees to grant to QCL
                        exclusive control of all litigation and negotiations in connection
                        with such Claim; (ii) the Client shall at the request and expense of
                        QCL afford to QCL all reasonable assistance for the purpose of
                        contesting any Claim; (iii) the Client shall not make any admissions
                        (save where required by court order or governmental regulations) which
                        may be prejudicial to the defence or settlement of any Claim without
                        the approval of QCL (not to be unreasonably withheld or delayed).
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">11</div>
                    <div class="right-term title-term">
                        CONTENT, SECURITY AND DATA PROTECTION
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">11.1</div>
                    <div class="right-term">
                        The Client shall ensure that any data or software made available to
                        QCL by the Client in connection with this agreement is lawful. To the
                        extent that such data contains Personal Data (as defined in the Data
                        Protection Act 1998 as amended ("the Act")), the Client warrants, as
                        separate and independent warranties, that: (i) it has obtained the
                        Data Subject's consent to use their Personal Data for processing and
                        storage as set out in this agreement and the relevant Product
                        Description; and, (ii) it has complied with its obligations under the
                        Act.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">11.2</div>
                    <div class="right-term">
                        QCL will carry out the processing (as defined by the Act) of Personal
                        Data made available to QCL by or on behalf of the Client or an
                        Authorised User only to the extent necessary for the proper operation
                        of the Product and will not divulge the whole or any part of the
                        Personal Data to any person, except to the extent necessary for the
                        provision of the Product or as permitted by the Client or the Data
                        Subject of such Personal Data.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">11.3</div>
                    <div class="right-term">
                        QCL will ensure that it has appropriate security measures in place to
                        safeguard against any unauthorised access or damage to or accidental
                        loss, destruction or disclosure of Client Data. If requested by the
                        Client, QCL shall provide an up to date copy of its Security Policy
                        and shall adhere to the same with respect to the Product and the
                        Client Data.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">11.4</div>
                    <div class="right-term">
                        QCL shall promptly notify the Client of any known or suspected breach
                        of security or unauthorised disclosure of the Client's Data and shall
                        take all reasonable steps to limit and or prevent such breach or
                        disclosure. If there is such a breach or disclosure QCL shall keep the
                        Client regularly and frequently informed of the extent of such breach
                        or disclosure and the steps being taken by QCL to deal with the same.
                        QCL shall comply with all reasonable request of the Client to limit
                        any such breach or disclosure provided that it is technically feasible
                        and does not result in QCL incurring any additional cost or expense.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">12</div>
                    <div class="right-term title-term">LIMITATION OF LIABILITY</div>
                </div>
                <div class="row-term">
                    <div class="left-term">12.1</div>
                    <div class="right-term">
                        This clause 11 sets out the full extent of QCL's liability to the
                        Client under or in connection with this agreement no matter how such
                        liability arises and whether for breach of contract, tortious acts or
                        omissions (not limited to negligence) or breach of statutory duty.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">12.2</div>
                    <div class="right-term">
                        A party’s liability to the other party for death or personal injury
                        resulting from its negligence or for fraudulent misrepresentation
                        shall not be limited.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">12.3</div>
                    <div class="right-term">
                        Subject to Clause 11.2, QCL shall not be liable to the Client for: (i)
                        any indirect or consequential loss or damage; (ii) loss of profit;
                        (iii) loss of business; (iv) loss of reputation; (v) depletion of
                        goodwill; and, (vi) subject to clause 11.4 any loss of, damage to or
                        corruption of Client Data.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">12.4</div>
                    <div class="right-term">
                        Subject to Clause 11.2, QCL’s liability in respect of: (i) damage to
                        the Client’s tangible property resulting directly from QCL’s
                        negligence or that of its employees shall not exceed £500,000 for any
                        one event or series of connected events; (ii) any loss of, damage to
                        or corruption of Client Data shall be limited to reconstituting such
                        data from back ups without charge to the Client (iii) impaired,
                        limited or no access to the Product in any year shall not exceed the
                        Fees for such year proportionate to such access calculated on a
                        straight line basis; and, (iv) any other and all losses not covered by
                        the foregoing shall not exceed, in aggregate, the Fees paid by the
                        Client under this agreement for the year in which the incident giving
                        rise to the liability occurred and if more than one incident gives
                        rise to the same liability then the year in which the first in the
                        series of incidents occurred.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">12.5</div>
                    <div class="right-term">
                        Other than for breach of clause 10, QCL shall not have any liability
                        under this agreement for the Client Data.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">12.6</div>
                    <div class="right-term">
                        Unless expressly set out in this agreement all conditions, warranties,
                        representations (unless made fraudulently) or other terms implied by
                        statute or law are excluded to the fullest extent permitted by law.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">13</div>
                    <div class="right-term title-term">TERMINATION OF THIS AGREEMENT</div>
                </div>
                <div class="row-term">
                    <div class="left-term">13.1</div>
                    <div class="right-term">
                        This agreement shall come into force on the date on which it is
                        signed/accepted by the parties and, unless terminated earlier pursuant
                        to this Clause 12, shall continue until the Client Data has been
                        returned or removed pursuant to clause 12.5
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">13.2</div>
                    <div class="right-term">
                        QCL may terminate this agreement by 5 Business Days’ notice to the
                        Client if the Client: (i) fails to pay any fees as they fall due; or,
                        (ii) has accessed or used or permitted access to or use of the Product
                        otherwise than in accordance with the terms of this agreement.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">13.3</div>
                    <div class="right-term">
                        Either Party may terminate this agreement immediately by notice in
                        writing to the other if:
                        <ul>
                            <li>
                                <div class="left-term">13.3.1</div>
                                <div class="right-term">
                                    the other commits any material breach of any term of this
                                    agreement which (in the case of a breach capable of being
                                    remedied) shall not have been remedied within 30 days of a
                                    written request to remedy the same;
                                </div>
                            </li>
                            <li>
                                <div class="left-term">13.3.2</div>
                                <div class="right-term">
                                    an order is made or a resolution is passed for the winding-up of
                                    the other or an order is made for the appointment of an
                                    administrator to manage the affairs, business and property of
                                    the other or such an administrator is appointed or documents are
                                    filed with the court for the appointment of an administrator or
                                    notice of intention to appoint an administrator is given by the
                                    other or its directors or by a qualifying floating charge holder
                                    (as defined in paragraph 14 of Schedule B1 to the Insolvency Act
                                    1986), or a receiver and/or manager or administrative receiver
                                    is appointed in respect of all or any of the other's assets or
                                    undertaking or circumstances arise which entitle the Court or a
                                    creditor to appoint a receiver and/or manager or administrative
                                    receiver or which entitle the Court to make a winding-up or
                                    bankruptcy order or the other takes or suffers any similar or
                                    analogous action in consequence of debt.
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">13.4</div>
                    <div class="right-term">
                        The Client may terminate this agreement at the end of the Access
                        Period or each anniversary thereof by giving QCL not less than 3
                        months written notice. Otherwise, the agreement shall automatically
                        renew for a further period of the same duration as the Access Period.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">13.5</div>
                    <div class="right-term">
                        Any termination shall be without prejudice to any other rights or
                        remedies a party may be entitled to hereunder or at law and shall not
                        affect any accrued rights or liabilities of either party nor the
                        coming into or continuance in force of any provision hereof which is
                        expressly or by implication intended to come into or continue in force
                        on or after such termination.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">14</div>
                    <div class="right-term title-term">CONFIDENTIALITY</div>
                </div>
                <div class="row-term">
                    <div class="left-term">14.1</div>
                    <div class="right-term">
                        Each party shall keep strictly confidential all Confidential
                        Information received directly or indirectly from the other party. Such
                        Confidential Information shall not be disclosed to any third party
                        other than the employees and authorised subcontractors of the
                        receiving party without the prior written consent of the party to whom
                        such Confidential Information belongs. Neither the party receiving
                        Confidential Information nor its employees or authorised
                        subcontractors shall use any such Confidential Information for any
                        purpose other than the performance of the agreement. Further, each
                        party shall procure that such employees and subcontractors act in a
                        manner consistent with the obligations of confidentiality set out
                        herein. This clause shall not apply to Confidential Information if and
                        to the extent that: (i) it is required to be disclosed by any court of
                        competent jurisdiction or by a governmental or regulatory authority or
                        where there is a legal right, duty or requirement to disclose,
                        provided that where possible and without breaching any such
                        requirements, 2 days’ prior notice is given to the other party of such
                        disclosure; (ii) it is information which is, at the date of this
                        agreement or subsequently, in the public domain through no fault of
                        the party receiving such information; (iii) the receiving party can
                        demonstrate subsequently came into its knowledge by means of
                        disclosure by a third party free from any obligation of
                        confidentiality; (iv) the receiving party can show it was information
                        in the possession of the receiving party prior to disclosure under
                        this agreement; or (v) it is information that is independently
                        developed by personnel of the receiving party having no access to the
                        other party’s Confidential Information.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">14.2</div>
                    <div class="right-term">
                        The obligations of this clause shall survive termination of this
                        agreement.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">15</div>
                    <div class="right-term title-term">FORCE MAJEURE</div>
                </div>
                <div class="row-term">
                    <div class="left-term">15.1</div>
                    <div class="right-term">
                        Neither party shall have any liability to the other under this
                        agreement to the extent that the performance of its obligations is
                        delayed, hindered or prevented by an Event of Force Majeure. "Event of
                        Force Majeure" shall mean fire, storm, flood, earthquake, accident,
                        adverse weather conditions, explosions, Acts of God, terrorism or the
                        threat thereof, nuclear, chemical or biological contamination,
                        compliance with any law, governmental controls, restrictions or
                        prohibitions, strikes, lock-outs, industrial action, employment
                        dispute, protests, public disorder, traffic congestion, accident,
                        breakdown, vandalism, interruptions in communications or power supply,
                        shortages of materials or supplies, failure or malfunction of computer
                        systems or any other event or circumstance outside the control of a
                        party to this agreement.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">16</div>
                    <div class="right-term title-term">NOTICES</div>
                </div>
                <div class="row-term">
                    <div class="left-term">16.1</div>
                    <div class="right-term">
                        Any notice, request, instruction or other document to be given by a
                        party under this agreement shall be delivered by hand, sent by
                        pre-paid first class post, or by e-mail transmission to the
                        recipient's usual address or such other address as may be notified by
                        that party in accordance with this clause 15.1.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term">16.2</div>
                    <div class="right-term">
                        Unless proved otherwise, a notice shall be deemed to have been
                        received if delivered by hand, at the time of delivery; if sent by
                        e-mail during the day of transmission as long as the sender can show
                        satisfactory transmission on a week day (not a public holiday) between
                        9.00am and 5.00pm CET; and if sent by post, 48 hours after posting.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">17</div>
                    <div class="right-term title-term">GENERAL</div>
                </div>
                <div class="row-term">
                    <div class="left-term">17.1</div>
                    <div class="right-term">
                        The terms and conditions of this agreement are the entire agreement
                        between the parties with regard to their subject matter. Each party
                        acknowledges that in entering into this agreement it does not do so on
                        the basis of, and does not rely on any representation, unless made
                        fraudulently, warranty or other provision not expressly contained in
                        this agreement. Any variation to this agreement must be in writing and
                        signed on behalf of both parties. If a court decides that any part of
                        this agreement cannot be enforced, that particular part of this
                        agreement will not apply, but the rest of this agreement will. A
                        waiver by a party of a breach of any provision shall not be deemed a
                        continuing waiver or a waiver of any subsequent breach of the same or
                        any other provisions. Failure or delay in exercising any right under
                        this agreement shall not prevent the exercise of that or any other
                        right. The Client may not assign or transfer any benefit, interest or
                        obligation under this agreement. The provisions of the Contracts
                        (Rights of Third Parties) Act 1999 shall not apply to this agreement.
                    </div>
                </div>
                <div class="row-term">
                    <div class="left-term title-term">18</div>
                    <div class="right-term title-term">GOVERNING LAW AND JURISDICTION</div>
                </div>
                <div class="row-term">
                    <div class="left-term">18.1</div>
                    <div class="right-term">
                        This Agreement shall be governed by and construed in accordance with
                        the laws of England and Wales and the parties hereby submit to the
                        non-exclusive jurisdiction of the English Courts.
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>