import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'qcbi-chipset',
    templateUrl: './chipset.component.html',
    styleUrls: ['./chipset.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipsetComponent {
    @Input() set statusSemaphoreValue(value) {
        if (+value < 25) this.status = 'low';
        else if (+value < 100) this.status = 'medium';
        else this.status = 'high';
    }

    status = '';
}
