import { Injectable } from '@angular/core';
import { FilterToParamsParserService } from './filter-to-params-parser.service';
import { filtersConfig } from '../../../../shared/selectable-filters/filters/filters.config';
import { formatDateFilterToDashedYYYYMMdd } from '../../../../../utils/dateHelpers';

@Injectable({
    providedIn: 'root'
})
export class BiParserService extends FilterToParamsParserService {
    parse(
        filters: any,
        timeFilter: { begin: string; end: string } = null,
        exclude: string = null
    ): string {
        const filter = this.getFilterWithTime(filters, timeFilter);
        const keys = Object.keys(filter);
        const queryParams = keys
            .reduce((result, key) => {
                if (key !== exclude && filter[key] && filter[key].length > 0) {
                    const value = filter[key]
                        .map(val => {
                            if (typeof val === 'object') {
                                if (val.hasOwnProperty('value'))
                                    return val.value;
                                else console.error('invalid filter value');
                            }
                            return val;
                        })
                        .join(',');
                    const filterKey = this.getKeyFromFiltersConfig(key);
                    if (filterKey === 'issue_status' && value === 'REQUESTED') {
                        result.push(`filter[issue_status]=OPEN`);
                        result.push(`filter[write_off_status]=REQUESTED`);
                    } else {
                        if (
                            filtersConfig[key] &&
                            filtersConfig[key].type === 'date'
                        ) {
                            const formattedDates = formatDateFilterToDashedYYYYMMdd(
                                JSON.parse(value)
                            );
                            if (formattedDates.begin)
                                result.push(
                                    `filter[${filtersConfig[key].filterDateKeys.begin}]=` +
                                        `${formattedDates.begin}`
                                );
                            if (formattedDates.end)
                                result.push(
                                    `filter[${filtersConfig[key].filterDateKeys.end}]=` +
                                        `${formattedDates.end}`
                                );
                        } else {
                            result.push(
                                `filter[${filterKey}]=${this.encode(value)}`
                            );
                        }
                    }
                }
                return result;
            }, [])
            .join('&');
        if (queryParams !== '') return '&' + queryParams;
        return queryParams;
    }

    parseForDownload(
        filters: any,
        timeFilter: { begin: string; end: string } = null
    ): { [filterKey: string]: (number | string)[] } {
        const result = {};
        for (const k in filters) {
            if (filters.hasOwnProperty(k)) {
                if (filters[k].length > 0) {
                    if (
                        k === 'issue_status' &&
                        filters[k][0].value === 'REQUESTED'
                    ) {
                        result['issue_status'] = ['OPEN'];
                        result['write_off_status'] = ['REQUESTED'];
                    } else {
                        const filterConfig = filtersConfig[k];
                        if (filterConfig.type === 'date') {
                            const formattedDates = formatDateFilterToDashedYYYYMMdd(
                                JSON.parse(filters[k][0].value)
                            );
                            if (formattedDates.begin)
                                result[filterConfig.filterDateKeys.begin] =
                                    formattedDates.begin;
                            if (formattedDates.end)
                                result[filterConfig.filterDateKeys.end] =
                                    formattedDates.end;
                        } else
                            result[this.getKeyFromFiltersConfig(k)] = filters[
                                k
                            ].map(
                                (v: { label: string; value: string }) => v.value
                            );
                    }
                }
            }
        }
        return result;
    }

    generateBulkFilters(filters: any) {
        const f = [];
        for (const at in filters) {
            if (filters.hasOwnProperty(at) && filters[at].length) {
                const filterConfig = filtersConfig[at];
                if (filterConfig.type === 'date') {
                    const formattedDates = formatDateFilterToDashedYYYYMMdd(
                        JSON.parse(filters[at][0].value)
                    );
                    if (formattedDates.begin)
                        f.push({
                            field: filterConfig.filterDateKeys.begin,
                            value: formattedDates.begin
                        });
                    if (formattedDates.end)
                        f.push({
                            field: filterConfig.filterDateKeys.end,
                            value: formattedDates.end
                        });
                } else
                    f.push({
                        field: this.getKeyFromFiltersConfig(at),
                        value: filters[at].map(e =>
                            e.hasOwnProperty('value') ? e.value : e
                        )
                    });
            }
        }
        return f;
    }

    getFields(filter: object, key: string | null): string {
        if (key) return `&filter[fields]=${key}`;
        return (
            '&filter[fields]=' +
            Object.keys(filter)
                .filter(f => f !== 'issue_status' && !filtersConfig[f].noData)
                .join(',')
        );
    }

    private encode(value: string): string {
        return encodeURI(value)
            .replace(/&/g, '%26')
            .replace(/\+/g, '%2B');
    }

    private getKeyFromFiltersConfig(key: string): string {
        return filtersConfig[key]?.filterKey || key;
    }

    private getFilterWithTime(
        selectedOptions,
        timeFilter,
        insideArray = true
    ): any {
        const filter = { ...selectedOptions };
        if (timeFilter === null) return filter;
        const formattedTimeFilter = formatDateFilterToDashedYYYYMMdd(
            timeFilter
        );
        if (formattedTimeFilter.begin && formattedTimeFilter.end) {
            let beginDateFilter = 'issue_open_date_gte';
            let endDateFilter = 'issue_open_date_lte';
            if (
                selectedOptions.hasOwnProperty('issue_status') &&
                selectedOptions.issue_status.length > 0 &&
                (selectedOptions.issue_status[0].value === 'CLOSED' ||
                    selectedOptions.issue_status[0] === 'CLOSED')
            ) {
                beginDateFilter = 'issue_closed_date_gte';
                endDateFilter = 'issue_closed_date_lte';
            }
            filter[beginDateFilter] = insideArray
                ? [formattedTimeFilter.begin]
                : formattedTimeFilter.begin;
            filter[endDateFilter] = insideArray
                ? [formattedTimeFilter.end]
                : formattedTimeFilter.end;
        }
        return filter;
    }
}
