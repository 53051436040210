import {
    createReducer,
    on,
    Action,
    createAction,
    props,
    createSelector,
    createFeatureSelector,
    Store
} from '@ngrx/store';
import { StateFeatures, Widgets } from '../../../models/widgets';
import { Injectable } from '@angular/core';
import { WidgetService } from '../../../../services/widget.service';
import { Actions, Effect } from '@ngrx/effects';
import { AppState } from '../../../../core.state';
import { WidgetEffects } from '../../widget.effects';
import { IUsageLarge } from '../../../models/widgets/large/livecheck-overview/usage.model';
import { Router } from '@angular/router';
import {
    detailSuccessState,
    modalSuccessState,
    successStateWithoutTableFilter
} from '../../../../../utils/reducer-fn';

type State = typeof Widgets.Large.Overview.USAGE;
enum StateConfig {
    Name = 'Widget - Livecheck - Overview - Usage large',
    Key = 'ov_lw_usage'
}
// First storage state object
const initialState: State = {
    isLoaded: false,
    loading: false,
    error: '',
    filter: {},
    data: {
        trend: [],
        chart0: [],
        chart1: [],
        chart2: [],
        table: [],
        details: {},
        modal: {}
    },
    cascadeFilters: {
        dateUsers: [],
        dateTeams: [],
        dateCeTypes: [],
        dateScansCeTypes: [],
        dateScansDevelopers: [],
        dateScansQualityGatesResults: []
    },
    tableFilter: {
        pageIndex: 0,
        pageSize: 10,
        total: undefined,
        sort: undefined,
        direction: undefined
    }
};

/*
 *  Actions express unique events that happen throughout your application
 */
const stateActions = {
    load: createAction(
        `[${StateConfig.Name}] Load`,
        props<{ filter: any; tableFilter: any }>()
    ),
    loadDetails: createAction(
        `[${StateConfig.Name}] Load Details`,
        props<{ filter: any; tableFilter?: any }>()
    ),
    loadModal: createAction(
        `[${StateConfig.Name}] Load Modal`,
        props<{ filter: any; tableFilter?: any }>()
    ),
    error: createAction(
        `[${StateConfig.Name}] Load error`,
        props<{ error: string }>()
    ),
    success: createAction(
        `[${StateConfig.Name}] Load success`,
        props<{ data: IUsageLarge }>()
    ),
    reset: createAction(`[${StateConfig.Name}] Reset`),
    saveCascadeFilters: createAction(
        `[${StateConfig.Name}] Save cascade filters`,
        props<{ data: any }>()
    ),
    detailsSuccess: createAction(
        `[${StateConfig.Name}] Load details success`,
        props<{ data: any; key: string }>()
    ),
    modalSuccess: createAction(
        `[${StateConfig.Name}] Load modal success`,
        props<{ data: any; key: string }>()
    )
};

/*
 * Reducer functions handle these transitions by determining which actions to
 * handle based on the action's type
 */
const reducers = createReducer(
    initialState,
    on(stateActions.load, (state, { filter }) => ({
        ...state,
        loading: true,
        error: '',
        filter
    })),
    on(stateActions.loadDetails, state => ({
        ...state,
        loading: true,
        error: ''
    })),
    on(stateActions.loadModal, state => ({
        ...state,
        loading: true,
        error: ''
    })),
    on(stateActions.error, (state, { error }) => ({
        ...state,
        loading: false,
        error,
        isLoaded: true
    })),
    on(stateActions.success, successStateWithoutTableFilter),
    on(stateActions.detailsSuccess, detailSuccessState),
    on(stateActions.modalSuccess, modalSuccessState),
    on(stateActions.reset, () => ({
        ...initialState
    })),
    on(stateActions.saveCascadeFilters, (state, { data }) => ({
        ...state,
        cascadeFilters: data
    }))
);

export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}

/*
 * Selectors are pure functions used for obtaining slices of store state.
 */
const stateSelector = createFeatureSelector<AppState, State>(StateConfig.Key);
const stateSelectors = {
    isLoaded: createSelector(stateSelector, (state: State) => state.isLoaded),
    loading: createSelector(stateSelector, (state: State) => state.loading),
    data: createSelector(stateSelector, (state: State) => state.data),
    error: createSelector(stateSelector, (state: State) => state.error),
    filter: createSelector(stateSelector, (state: State) => state.filter),
    cascadeFilters: createSelector(
        stateSelector,
        (state: State) => state.cascadeFilters
    ),
    tableFilter: createSelector(
        stateSelector,
        (state: State) => state.tableFilter
    )
};

/*
 * Effects use streams to provide new sources of actions to reduce state
 * based on external interactions such as network requests, web socket messages
 * and time-based events.
 */
@Injectable()
export class StateEffects extends WidgetEffects<any> {
    constructor(
        public actions$: Actions,
        public service: WidgetService,
        public store: Store<AppState>,
        public router: Router
    ) {
        super(
            actions$,
            service,
            store,
            stateActions,
            {
                cascadeFilters: [
                    'dateUsers',
                    'dateCeTypes',
                    'dateScansCeTypes',
                    'dateScansDevelopers',
                    'dateScansQualityGatesResults',
                    'dateTeams'
                ],
                loadLarge: {
                    key: 'ov-usage',
                    instanceOrService: true
                },
                loadDetails: {
                    key: 'ov-usage'
                },
                loadModal: {
                    key: 'ov-usage-lw-iss'
                }
            },
            stateSelector,
            undefined,
            router
        );
    }

    @Effect()
    _load = this.loadLarge;
    @Effect()
    _loadDetails = this.loadDetails;
    @Effect()
    _loadModal = this.loadModal;
    @Effect()
    _cascadeFilters = this.loadCascadeFilters;
    @Effect()
    _dateChangedReset = this.dateChangedReset;
    @Effect()
    _instanceReset = this.instanceReset;
}

export const stateFeatures: StateFeatures = {
    config: StateConfig,
    actions: stateActions,
    selectors: stateSelectors
};
