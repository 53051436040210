export class Tag {
    id: string;
    name: string;
    shouldPropagate: boolean;
}

export class ColoredTag extends Tag {
    bgColor: string;
    textColor: string;
}

export interface TagsState {
    isLoaded: boolean;
    tags: Tag[];
}
