<div class="banner-container" [ngStyle]="{'background-color': getBackgroundColor(), 'color': getFontColor()}">
  <div class="icon-wrapper">
    <mat-icon aria-hidden="false">{{ getIcon() }}</mat-icon>
  </div>
  <div class="message-wrapper">
    <div class="text-wrapper" [innerHTML]="text">
    </div>
  </div>
  <div class="lock-wrapper">
    <mat-icon class="lock" *ngIf="iconLock">lock</mat-icon>
  </div>
</div>
