<div class="card mb-4">
	<div class="main-label">Issue assigned to:</div>
	<div class="label">Team</div>
    <qcbi-search-multi-selector
        [selectedItems]="selectedTeams$ | async"
        (elementRemovedFromList)="removeElementFromSelectedTeams($event)"
        (elementAddedToList)="addElementToSelectedTeams($event)"
        [filterFunction]="filterFunction"
        noItemsText="there are no teams assigned"
    >
        <ng-template
            #chipContent
            let-element="element"
        >
            <qcbi-colored-dot [name]="element.name"></qcbi-colored-dot>
            <span class="ml-2">{{ element.name }}</span>
        </ng-template>
        <ng-template
            #optionContent
            let-option="option"
        >
            <div class="flex items-center">
                <qcbi-colored-dot [name]="option.name"></qcbi-colored-dot>
                <div class="ml-2 py-2 flex flex-column justify-center team-info">
                    <div class="team-name">{{ option.name }}</div>
                    <div class="text-xs truncate" [matTooltip]="option.description">
                        {{ option.description }}
                    </div>
                </div>
            </div>
        </ng-template>
    </qcbi-search-multi-selector>
	<div class="flex justify-end mt-2" *ngIf="(assignationChanged$ | async)">
		<button
			mat-button
			(click)="applyChanges()"
			class="save"
            [loading]="saving"
		>
			Apply changes
		</button>
	</div>
</div>