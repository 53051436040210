import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    LargeWidgetService,
    OtherOptions
} from '../general/large-widget.service';
import { BiParserService } from '../general/bi-parser.service';
import { environment } from '../../../../../environments/environment';
import {
    generateTableParams,
    parseTableMeta
} from '../../../../utils/widget-state-creator';
import { map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core.state';
import { selectCurrentUser } from '../../../auth-lib';
import { Observable, Subject } from 'rxjs';
import { FilterOptionsDictionary } from '../../../../shared/selectable-filters/selectable-filters.component';
import { BaseFiltersDictionary } from '../../../state/widgets/large/debt-manager-new/dm-new.reducer';
import { formatDateToDashedYYYYMMdd } from '../../../../../utils/dateHelpers';
import { PagerOptions } from '../../../../components/tables/server.component';

const FIRST_REASON_ID = 1;

export type DebtManagerIssue = {
    id: string;
    'write-off-id': number;
    'write-off-status': string;
    'write-off-expiration-date': string;
    'write-off-expiration-time': string;
    'instance-id': number;
    'service-id': number;
    source: string;
    'delta-issue-id': number;
    'reason-name-id': string;
    reason: string;
    'request-date': string;
    'approve-date': string;
    'request-description': string;
    'approver-description': string;
    requester: string;
    approver: string;
    'issue-type': string;
    'best-practice-name-id': string;
    'ce-name': string;
    'ce-type': string;
    application: string;
    'line-number': number;
    'line-hash': string;
    'severity-id': number;
    severity: string;
    'issue-open-date': string;
    'issue-closed-date': string;
    'area-id': number;
    area: string;
    'time-to-fix': number;
    'doc-link': string;
    'created-by': string;
    'created-on': string;
    'updated-by': string;
    'updated-on': string;
    'issue-status': string;
    'write-off': boolean;
    'sys-id': string;
    tags: { id: string; name: string }[];
    'opened-after-qg-activation': boolean;
    'quality-gate-result': string;
    impact: string;
    action: string;
    'delta-issues-long-id': string;
    'jira-id': string;
    'update-set-name': string;
    'issue-created-by': string;
    'quality-gate-tag': string;
    'manually-smart-tagged': boolean;
    age: number;
    baseline: number;
    owner: string;
    'pr-unique-id': string;
    'write-off-source-id': string;
    'write-off-long-id': string;
    teams: any[];
    'manually-team-assigned': boolean;
};

type ServerResponse = {
    meta: {
        'current-page': number;
        'per-page': number;
        from: number;
        to: number;
        total: number;
        'last-page': number;
        total_resources: number;
        resources_per_page: number;
    };
    data: ServerDebtManagerIssue[];
};

type ServerDebtManagerIssue = {
    type: 'open-issue';
    id: string;
    attributes: {
        'write-off-id': number;
        'write-off-status': string;
        'write-off-expiration-date': string;
        'write-off-expiration-time': string;
        'instance-id': number;
        'service-id': number;
        source: string;
        'delta-issue-id': number;
        'reason-name-id': string;
        reason: string;
        'request-date': string;
        'approve-date': string;
        'request-description': string;
        'approver-description': string;
        requester: string;
        approver: string;
        'issue-type': string;
        'best-practice-name-id': string;
        'ce-name': string;
        'ce-type': string;
        application: string;
        'line-number': number;
        'line-hash': string;
        'severity-id': number;
        severity: string;
        'issue-open-date': string;
        'issue-closed-date': string;
        'area-id': number;
        area: string;
        'time-to-fix': number;
        'doc-link': string;
        'created-by': string;
        'created-on': string;
        'updated-by': string;
        'updated-on': string;
        'issue-status': string;
        'write-off': boolean;
        'sys-id': string;
        tags: { id: string; name: string }[];
        'opened-after-qg-activation': boolean;
        'quality-gate-result': string;
        impact: string;
        action: string;
        'delta-issues-long-id': string;
        'jira-id': string;
        'update-set-name': string;
        'issue-created-by': string;
        'quality-gate-tag': string;
        'manually-smart-tagged': boolean;
        age: number;
        baseline: number;
        owner: string;
        'pr-unique-id': string;
        'write-off-source-id': string;
        'write-off-long-id': string;
        teams: any[];
        'manually-team-assigned': boolean;
    };
};

type BulkParams = {
    allSelected: boolean;
    url: string;
    data: any;
    filters: any;
    ids: any;
};

@Injectable({
    providedIn: 'root'
})
export class DebtManagerService extends LargeWidgetService<any>
    implements OnDestroy {
    private latestUserTimeValue: 'US' | 'EU' | undefined;
    private unsubscribe$ = new Subject();

    constructor(
        protected http: HttpClient,
        protected parserService: BiParserService,
        private store: Store<AppState>
    ) {
        super(http, parserService);
        this.store
            .select(selectCurrentUser)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(user => {
                this.latestUserTimeValue = user?.timeFormat;
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    load(
        filter: any,
        otherOptions: Partial<OtherOptions>
    ): Observable<{ data: DebtManagerIssue[]; meta: PagerOptions }> {
        const { instanceId } = otherOptions;
        const f: string = this.parserService.parse(filter);
        const url: string =
            `${environment.apiEndPoint}api/v2/open-issue?` +
            `filter[instance_id]=${instanceId}` +
            generateTableParams(otherOptions.tableOptions) +
            f;

        return this.http.get<ServerResponse>(url).pipe(
            map((res): { data: any[]; meta: any } => {
                return {
                    data: res.data.map((el: any) => ({
                        id: el.id,
                        ...el.attributes,
                        'write-off-expiration-date-time': el.attributes[
                            'write-off-expiration-date'
                        ]
                            ? this.getFormattedExpirationDate(
                                  el.attributes['write-off-expiration-date'],
                                  el.attributes['write-off-expiration-time']
                              )
                            : 'Never'
                    })),
                    meta: parseTableMeta(res.meta)
                };
            })
        );
    }

    loadKpis(
        filter: any,
        otherOptions: Partial<OtherOptions>
    ): Observable<{ issues: number; td: number }> {
        const { instanceId } = otherOptions;
        const f: string = this.parserService.parse(filter);
        const url = `${environment.apiEndPoint}api/v2/open-issues-kpis/${instanceId}?${f}`;
        return this.http.get(url).pipe(
            map((res: any): { issues: number; td: number } => {
                return {
                    issues: res.data.attributes['issues'],
                    td: res.data.attributes['technical-debt']
                };
            })
        );
    }

    loadFilters(
        filter: any,
        otherOptions: Partial<OtherOptions>,
        key: string = null
    ): Observable<BaseFiltersDictionary> {
        const { instanceId } = otherOptions;
        const f: string = this.parserService.parse(filter);
        const fields = this.parserService.getFields(filter, key);
        const url = `${environment.apiEndPoint}api/v4/open-issue-filter?filter[instance_id]=${instanceId}${f}${fields}`;
        return this.http.get(url).pipe(
            map((res: any) => {
                return res.data.reduce((result: object, next: any) => {
                    if (filter.hasOwnProperty(next.attributes.field)) {
                        const dict = Object.fromEntries(
                            filter[next.attributes.field].map((o: any) => [
                                o.value,
                                o
                            ])
                        );
                        const resultDictionary = next.attributes.data.reduce(
                            (r: object, n: any) => {
                                if (dict.hasOwnProperty(n.value))
                                    delete dict[n.value];
                                r[n.value] = n.label;
                                return r;
                            },
                            {}
                        );
                        result[next.attributes.field] = {
                            ...dict,
                            ...resultDictionary
                        };
                    }
                    return result;
                }, {});
            })
        );
    }

    loadFiltersBase(
        filter: any,
        otherOptions: Partial<OtherOptions>,
        key: string = null
    ): Observable<FilterOptionsDictionary> {
        const { instanceId } = otherOptions;
        const f: string = this.parserService.parse({});
        const fields = this.parserService.getFields(filter, key);
        const url = `${environment.apiEndPoint}api/v4/open-issue-filter?filter[instance_id]=${instanceId}${f}${fields}`;
        return this.http.get(url).pipe(
            map((res: any) => {
                return res.data.reduce((result, next) => {
                    if (filter.hasOwnProperty(next.attributes.field)) {
                        const dict = Object.fromEntries(
                            filter[next.attributes.field].map(o => [o.value, o])
                        );
                        const resultArray = next.attributes.data.reduce(
                            (r, n) => {
                                if (dict.hasOwnProperty(n.value))
                                    delete dict[n.value];
                                r.push(n);
                                return r;
                            },
                            []
                        );
                        const k = Object.keys(dict);
                        for (let i = 0; i < k.length; ++i) {
                            resultArray.push(dict[k[i]]);
                        }
                        result[next.attributes.field] = resultArray;
                    }
                    return result;
                }, {});
            })
        );
    }

    bulkWriteOff(
        instanceId,
        reasonNameId,
        description,
        ids,
        allSelected,
        filters,
        expirationDate,
        expectedNumberOfIssues = null
    ) {
        const data: any = {
            instanceId,
            description,
            expirationDate:
                expirationDate !== '' && expirationDate
                    ? formatDateToDashedYYYYMMdd(expirationDate)
                    : null,
            reasonNameId: reasonNameId ? reasonNameId : FIRST_REASON_ID
        };
        if (allSelected && expectedNumberOfIssues)
            data.expectedNumberOfIssues = expectedNumberOfIssues;
        const url = `${environment.apiEndPoint}api/v2/bulk-write-off`;
        return this.manageBulkCall({
            allSelected,
            data,
            ids,
            filters,
            url
        });
    }

    bulkTags(
        assignTagsIds,
        unassignTagsIds,
        issuesIds,
        instanceId,
        allSelected,
        filters
    ) {
        const data = {
            instanceId
        };
        if (assignTagsIds.length) {
            data['assignTagsIds'] = assignTagsIds.map(id => +id);
        } else {
            data['assignTagsIds'] = [];
        }
        if (unassignTagsIds.length)
            data['unassignTagsIds'] = unassignTagsIds.map(id => +id);
        else data['unassignTagsIds'] = [];

        if (allSelected) {
            data['filters'] = this.parserService.generateBulkFilters(filters);
            return this.http.post(
                `${environment.apiEndPoint}api/v2/bulk-tag-issues-all-instance`,
                {
                    data
                }
            );
        }
        data['issuesIds'] = issuesIds.map(id => +id);

        return this.http.post(
            `${environment.apiEndPoint}api/v2/bulk-tag-issues`,
            {
                data
            }
        );
    }

    bulkSmartTag(
        ids,
        smartTag,
        allSelected,
        filters,
        instanceId,
        expectedNumberOfIssues = null
    ): Observable<any> {
        const data: any = {
            instanceId,
            assignSmartTagId: smartTag
        };
        const url = `${environment.apiEndPoint}api/v2/bulk-smart-tag`;
        if (allSelected && expectedNumberOfIssues)
            data.expectedNumberOfIssues = expectedNumberOfIssues;
        return this.manageBulkCall({
            allSelected,
            data,
            ids,
            filters,
            url
        });
    }

    bulkAssignTeam(
        ids,
        assignTeamsIds,
        unassignTeamsIds,
        allSelected,
        filters,
        instanceId
    ): Observable<any> {
        const data = {
            instanceId,
            assignTeamsIds,
            unassignTeamsIds
        };
        const url = `${environment.apiEndPoint}api/v2/bulk-teams-issues-assignment`;
        return this.manageBulkCall({
            allSelected,
            data,
            ids,
            filters,
            url
        });
    }

    bulkUnwriteOff(
        instanceId,
        description,
        ids,
        allSelected,
        filters,
        status,
        expectedNumberOfIssues = null
    ) {
        const data: any = {
            instanceId,
            description
        };
        if (status) {
            data['status'] = status;
        }
        if (allSelected && expectedNumberOfIssues)
            data.expectedNumberOfIssues = expectedNumberOfIssues;
        const url = `${environment.apiEndPoint}api/v2/bulk-unwrite-off`;
        return this.manageBulkCall({
            allSelected,
            data,
            ids,
            filters,
            url
        });
    }

    bulkChangeWriteOffExpirationDate(
        ids,
        allSelected,
        filters,
        expirationDate,
        instanceId,
        expectedNumberOfIssues = null
    ): Observable<any> {
        const data: any = {
            instanceId,
            expirationDate: formatDateToDashedYYYYMMdd(expirationDate)
        };
        if (allSelected && expectedNumberOfIssues)
            data.expectedNumberOfIssues = expectedNumberOfIssues;
        const url = `${environment.apiEndPoint}api/v2/bulk-update-issues`;
        return this.manageBulkCall({
            allSelected,
            data,
            ids,
            filters,
            url
        });
    }

    private getFormattedExpirationDate(
        expirationDate: string,
        expirationTime: string
    ): string {
        const d = new Date(expirationDate);
        const locale = this.latestUserTimeValue === 'US' ? 'en-US' : 'en-GB';
        return `${d.toLocaleDateString(locale)} ${expirationTime}`;
    }

    private manageBulkCall(params: BulkParams) {
        let url = params.url;
        const { allSelected, data, filters, ids } = params;
        if (allSelected) {
            url += '-all-instance';
            data.filters = this.parserService.generateBulkFilters(filters);
        } else {
            data.issuesIds = ids;
        }
        return this.http.post(url, {
            data
        });
    }
}
