import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServiceDependentLabelsPipe } from './service-dependent-labels.pipe';

@NgModule({
    imports: [CommonModule],
    exports: [ServiceDependentLabelsPipe],
    declarations: [ServiceDependentLabelsPipe]
})
export class ServiceDependentLabelsModule {}
