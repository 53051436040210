<div class='add-comment-container'>
  <div class='flex'>
    <div class='avatar-container'>
      <div class="avatar" [ngStyle]="{ 'background': intToRGB(hashCode(userName)) }">
        {{ getAvatarInitials(userName) }}
      </div>
    </div>
    <div class='add-comment-textarea'>
      <mat-form-field style="width: 100%;" floatPlaceholder="never" appearance="outline">
        <textarea
          #desc
          maxlength="700"
          style="width: 100%"
          rows="2"
          matInput
          placeholder="Add a comment"
          [formControl]="addComment"
        ></textarea>
        <div class="text-area-counter">{{ desc.value.length }}/700</div>
      </mat-form-field>
    </div>
  </div>
  <div class="button-container flex justify-start">
    <button
      mat-stroked-button
      [disabled]="desc.value.length === 0"
      (click)="postComment()"
    >
      Post
    </button>
  </div>
</div>
<div class='comments-container'>
  <div class='comment-container flex' *ngFor="let comment of comments$ | async">
    <div class='avatar-container'>
      <div class="avatar" [ngStyle]="{ 'background': intToRGB(hashCode(comment['created-by'] || 'unknown')) }">
        {{ getAvatarInitials(comment['created-by'] || 'unknown') }}
      </div>
    </div>
    <div class='comment-content'>
      <div class='flex justify-between'>
        <div class='user-name'>{{ comment['created-by'] || 'unknown' }}</div>
        <div class='date'>{{ comment['created-on'] | userDateTime : 'dateTime' : '-' }}</div>
      </div>
      <div class='comment'>
        {{ comment.comment }}
      </div>
    </div>
  </div>
</div>
