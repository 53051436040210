import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tagsFilter'
})
export class TagsPipe implements PipeTransform {
    transform(value: any[], filter: string): any {
        return value.filter(tag => new RegExp(filter, 'i').test(tag.name));
    }
}

@Pipe({
    name: 'filterByInstance'
})
export class FilterByInstancePipe implements PipeTransform {
    transform(value: any, instanceLabels: any[]): boolean {
        return (
            instanceLabels &&
            instanceLabels.some(item => item.name === value.name)
        );
    }
}
