import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DownloadComponent } from './download.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { DownloadWrapperComponent } from './download-wrapper/download-wrapper.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule, RouterModule, MatProgressBarModule, MatIconModule],
    declarations: [DownloadComponent, DownloadWrapperComponent],
    exports: [DownloadComponent, DownloadWrapperComponent]
})
export class DownloadModule {}
