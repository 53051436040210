<div class="flex flex-column justify-center loading-vertical-center">
  <div class="flex justify-center">
    <div class="loading-layout">
      <div class="loading-content">
        <img *ngIf="status === 'DOWNLOADING'" src="assets/logo.png" alt="Quality clouds" />
        <div class="flex justify-center" *ngIf="status === 'ERROR' || status === 'PARAMS' || status === 'DOWNLOADED'">
          <mat-icon
            [ngClass]="[getIcon()]"
          >
            {{ getIcon() }}
          </mat-icon>
        </div>
        <h1>{{ getText() }}</h1>
        <mat-progress-bar *ngIf="status === 'DOWNLOADING'" mode="indeterminate" aria-label="downloading"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>
