import { createAction, props } from '@ngrx/store';

const TWO_FACTOR_AUTHENTICATION_REDUX_NAME = 'Two factor authentication';
export const TwoFactorAuthenticationActions = {
    fetchStatus: createAction(
        `[${TWO_FACTOR_AUTHENTICATION_REDUX_NAME}] fetch status`,
        props<{ userId: string }>()
    ),
    setStatus: createAction(
        `[${TWO_FACTOR_AUTHENTICATION_REDUX_NAME}] set status`,
        props<{ status: 'DISABLED' | 'ENABLED' | 'SYNCHRONIZED' }>()
    ),
    disableMfa: createAction(
        `[${TWO_FACTOR_AUTHENTICATION_REDUX_NAME} disable mfa`
    ),
    fetchQrData: createAction(
        `[${TWO_FACTOR_AUTHENTICATION_REDUX_NAME}] fetch qr data`
    ),
    setQrData: createAction(
        `[${TWO_FACTOR_AUTHENTICATION_REDUX_NAME}] set qr data`,
        props<{ secret: string; uri: string }>()
    ),
    validateCode: createAction(
        `[${TWO_FACTOR_AUTHENTICATION_REDUX_NAME}] validate code`,
        props<{ code: string }>()
    ),
    validateCodeResult: createAction(
        `[${TWO_FACTOR_AUTHENTICATION_REDUX_NAME}] validate code result`,
        props<{ result: 'valid' | 'invalid'; message: string }>()
    ),
    fetchError: createAction(
        `[${TWO_FACTOR_AUTHENTICATION_REDUX_NAME}] fetch error`
    )
};
