import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChipsetComponent } from './chipset.component';

@NgModule({
    declarations: [ChipsetComponent],
    imports: [CommonModule],
    exports: [ChipsetComponent]
})
export class ChipsetModule {}
