import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfDownloadDialogComponent } from './pdf-download-dialog/pdf-download-dialog.component';
import { PdfDownloadComponent } from './pdf-download.component';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [PdfDownloadDialogComponent, PdfDownloadComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatTooltipModule,
        FormsModule,
        MatFormFieldModule,
        MatRadioModule,
        MatIconModule
    ],
    exports: [PdfDownloadComponent]
})
export class PdfDownloadModule {}
