import { createAction, props } from '@ngrx/store';
import {
    Addon,
    DefaultSettings,
    Filters,
    InitialLoadSuccessData,
    InstanceLoadSuccessData,
    ScanLoadSuccessData,
    ViewType
} from './general.models';
import { SatDatepickerRangeValue } from 'saturn-datepicker';

export const GeneralActions = {
    init: createAction('[General] Init'),
    initSuccess: createAction(
        '[General] Init success',
        props<{ instances; user; severities; ceTypes; impactAreas }>()
    ),
    initError: createAction('[General] Init error', props<{ error }>()),
    toggleSideMenu: createAction('[General] toggle side menu'),
    changeView: createAction(
        '[General] Change view',
        props<{ view: ViewType }>()
    ),
    setInstance: createAction(
        '[General] Set instance',
        props<{ id: string; service: string }>()
    ),
    setInstanceSuccess: createAction(
        '[General] Set instance success',
        props<{ instance: any }>()
    ),
    setScan: createAction(
        '[General] Set scan',
        props<{ scan: any; noInstance?: boolean }>()
    ),
    setDashboardDateRange: createAction(
        '[General] Set Dashboard Date Range',
        props<{ range: SatDatepickerRangeValue<Date> | Date }>()
    ),
    setScanSuccess: createAction(
        '[General] Set scan success',
        props<{ scan: any }>()
    ),
    noScanError: createAction('[General] No Scan error'),
    error: createAction('[General] Error', props<{ error: string }>()),
    saveFilters: createAction('[General] Save filters', props<Filters>()),
    saveScans: createAction(
        '[General] Save scans',
        props<{
            scans: any[];
        }>()
    ),

    loadInstance: createAction('[General] Load instance'),
    loadInstanceSuccess: createAction(
        '[General] Load success',
        props<{ instance: any }>()
    ),
    saveDashboards: createAction(
        '[General] Save dashboards',
        props<{ dashboards: any }>()
    ),
    setDashboard: createAction(
        '[General] Set dashboard',
        props<{ dashboard: any }>()
    ),
    loadSettings: createAction('[General] Load settings'),
    setDefaultSettings: createAction('[General] Default settings'),
    updateSettings: createAction(
        '[General] Update settings',
        props<{ settings: DefaultSettings }>()
    ),

    initialLoad: createAction('[General] Initial load'),
    initialLoadSuccess: createAction(
        '[General] Initial load success',
        props<InitialLoadSuccessData>()
    ),
    initialLoadError: createAction(
        '[General] Initial load error',
        props<{ error: string }>()
    ),

    instanceLoad: createAction(
        '[General] Instance load',
        props<{ serviceId: number; instanceId: number; dashboardId?: number }>()
    ),
    instanceLoadSuccess: createAction(
        '[General] Instance load success',
        props<InstanceLoadSuccessData>()
    ),
    instanceLoadError: createAction(
        '[General] Instance load error',
        props<{ error: string }>()
    ),
    resetInstance: createAction('[General] Instance reset'),
    scanLoad: createAction('[General] Scan load', props<{ scanId: number }>()),
    scanLoadSuccess: createAction(
        '[General] Scan load success',
        props<ScanLoadSuccessData>()
    ),
    scanLoadError: createAction(
        '[General] Scan load error',
        props<{ error: string }>()
    ),
    allCloudsLoad: createAction(
        '[General] All clouds load',
        props<{ dashboardId?: number }>()
    ),
    allCloudsInit: createAction(
        '[General] All clouds init',
        props<{ dashboardId: number; filters: any }>()
    ),
    noSettings: createAction('[General] No settings'),
    emptyView: createAction('[General] Empty view'),
    invalidateGlobalFilters: createAction(
        '[General] Invalidate Global Filters'
    ),
    getLastScans: createAction('[General] Create Last Scans'),
    getLastScansSuccess: createAction(
        '[General] Last Scans Success',
        props<{ lastScans: any }>()
    ),
    setAddons: createAction(
        '[General] Set customer Addons',
        props<{ addons: Addon[] }>()
    ),
    noInitialLoad: createAction('[General] No initial load'),
    setMainFilter: createAction(
        '[General] Set Main Filter',
        props<{
            mainFilter: 'opened' | 'closed' | 'all';
            alreadyLoaded?: boolean;
        }>()
    ),
    setMainFilterLoaded: createAction('[General] Set Main filter Loaded'),
    userConfigurationInitialLoad: createAction(
        '[General] User Configuration Initial Load'
    ),
    setInitialUserConfiguration: createAction(
        '[General] Set Initial User Configuration',
        props<{ initialConfig: any }>()
    ),
    setUserConfigurationItem: createAction(
        '[General] Set User Configuration Item',
        props<{ key: string; value: any }>()
    ),
    toggleLargeWidgetExpansion: createAction(
        '[General] Toggle Large Widget Expansion'
    ),
    saveBaseFilters: createAction(
        '[General] Set Base Filters',
        props<{ baseFilters: any }>()
    ),
    removeBaseFilters: createAction('[General] Remove Base Filters'),
    toggleIncludeBaseline: createAction('[General] Toggle Include Baseline'),
    setIncludeBaseline: createAction('[General] Set Include Baseline'),
    toggleIncludeTablesAndDict: createAction(
        '[General] Toggle Show Tables and Dictionaries'
    ),
    setIncludeTablesAndDict: createAction(
        '[General] Set Show Tables and Dictionaries'
    )
};
