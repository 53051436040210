import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActivityTrackingService {
    constructor(private http: HttpClient) {}

    /**
     * notifies that a user has accessed a dashboard
     * @param instanceId information of the active instance
     * @param dashboardId information of the accessed dashboard
     */
    notifyDashboardAccess(
        instanceId: string | number,
        dashboardId: string | number,
        onEmulation: boolean
    ) {
        if (
            instanceId === undefined ||
            instanceId === null ||
            dashboardId === undefined ||
            dashboardId === null ||
            onEmulation
        ) {
            console.error(
                'values cannot be tracked. ' +
                    `instance: ${instanceId} | dashboard: ${dashboardId}`
            );
            return of({});
        }
        return this.http.post(
            environment.apiDevEndPoint + `api/v2/tracking-dashboard-access`,
            {
                data: {
                    type: 'tracking-dashboard-access',
                    attributes: {
                        'instance-id': instanceId,
                        'dashboard-id': dashboardId
                    }
                }
            }
        );
    }

    /**
     * notifies that a user has accessed the documentation
     * @param instanceId instance from where the link was clicked
     * @param dashboardId dashboard from where the link was clicked
     * @param issueId issue from where the link was clicked
     */
    notifyDocumentationAccess(
        instanceId: string | number,
        dashboardId: string | number,
        issueId: string
    ) {
        if (
            instanceId === undefined ||
            instanceId === null ||
            dashboardId === undefined ||
            dashboardId === null ||
            issueId === undefined ||
            issueId === null
        ) {
            console.error(
                'values cannot be tracked. ' +
                    `instance: ${instanceId} | dashboard: ${dashboardId} | issue: ${issueId}`
            );
            return of({});
        }
        return this.http.post(
            environment.apiDevEndPoint + `api/v2/tracking-link-click-issue`,
            {
                data: {
                    type: 'tracking-link-click-issue',
                    attributes: {
                        'instance-id': instanceId,
                        'dashboard-id': dashboardId,
                        'link-type': 'DOCS',
                        'issue-id': issueId
                    }
                }
            }
        );
    }

    /**
     * notifies when a user has cliecked a ce link
     * @param instanceId instance from where the link was clicked
     * @param dashboardId dashboard from where the link was clicked
     * @param elementId element from where the link was clicked
     * @param elementType specifies if the element was an issue or a config element
     */
    notifyCeLinkAcess(
        instanceId: string | number,
        dashboardId: string | number,
        elementId: string,
        elementType: 'CE' | 'ISSUE'
    ) {
        if (
            instanceId === undefined ||
            instanceId === null ||
            dashboardId === undefined ||
            dashboardId === null ||
            elementId === undefined ||
            elementId === null ||
            elementType === undefined ||
            elementType === null
        ) {
            console.error(
                'values cannot be tracked. ' +
                    `instance: ${instanceId} | dashboard: ${dashboardId} | element: ${elementId} | elementType: ${elementType}`
            );
            return of({});
        }
        if (elementType === 'CE')
            return this.http.post(
                environment.apiDevEndPoint +
                    `api/v2/tracking-link-click-ce-auditelement`,
                {
                    data: {
                        type: 'tracking-link-click-ce-auditelement',
                        attributes: {
                            'instance-id': instanceId,
                            'dashboard-id': dashboardId,
                            'link-type': 'CE_LINK',
                            'audit-element-id': elementId
                        }
                    }
                }
            );
        else
            return this.http.post(
                environment.apiDevEndPoint + `api/v2/tracking-link-click-issue`,
                {
                    data: {
                        type: 'tracking-link-click-issue',
                        attributes: {
                            'instance-id': instanceId,
                            'dashboard-id': dashboardId,
                            'link-type': 'CE_LINK',
                            'issue-id': elementId
                        }
                    }
                }
            );
    }
}
