import { createAction, props } from '@ngrx/store';

export const DownloadFormattedFileActions = {
    download: createAction(
        '[Download formatted file] download',
        props<{
            id: string;
            queryParams: { key: string; value: string | number }[];
            format: 'xls' | 'sarif';
        }>()
    ),
    downloadSuccess: createAction(
        '[Download formatted file] download success',
        props<{ id: string; format: 'xls' | 'sarif' }>()
    ),
    downloadFail: createAction(
        '[Download formatted file] download fail',
        props<{ id: string; format: 'xls' | 'sarif' }>()
    )
};
