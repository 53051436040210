import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { first, map, scan, shareReplay, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../core/core.state';
import { bulkActions } from '../../../../../../../core/state/widgets/large/debt-manager-new/bulk.actions';
import {
    Team,
    TeamService
} from '../../../../../../../core/services/teams.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

type Assignations = {
    assign: {
        [teamId: number]: Team;
    };
    unassign: {
        [teamId: number]: Team;
    };
};

@Component({
    selector: 'qcbi-assign-teams',
    templateUrl: './assign-teams.component.html',
    styleUrls: ['./assign-teams.component.scss']
})
export class AssignTeamsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() issueId: any;
    @Input() initialValue: Team[] = [];

    assignedTeams$ = new BehaviorSubject<Team[]>([]);
    assignationsReset$ = new BehaviorSubject<boolean>(null);
    toggleTeam$ = new BehaviorSubject<{
        element: Team;
        action: 'add' | 'remove';
    }>({
        element: null,
        action: null
    });
    selectedTeams$: Observable<Team[]>;
    assignations$: Observable<Assignations>;
    assignationChanged$: Observable<boolean>;
    saving = false;

    constructor(
        private store: Store<AppState>,
        private teamService: TeamService
    ) {}

    filterFunction = (search: string) => {
        return this.selectedTeams$.pipe(
            first(),
            switchMap(selectedTeams => {
                return this.teamService.getCustomerTeams().pipe(
                    map(data => {
                        return {
                            data: data.filter(
                                team =>
                                    team.name
                                        .toLowerCase()
                                        .includes(search.toLowerCase()) &&
                                    !selectedTeams.some(
                                        selectedTeam =>
                                            team.id === selectedTeam.id
                                    )
                            ),
                            nextPage: -1
                        };
                    })
                );
            })
        );
    };

    ngOnInit(): void {
        this.assignations$ = this.assignationsReset$.pipe(
            switchMap(assignationsReset => {
                let reset = assignationsReset;
                return this.toggleTeam$.pipe(
                    scan(
                        (acc, { element, action }) => {
                            if (reset) {
                                reset = false;
                                return {
                                    assign: {},
                                    unassign: {}
                                };
                            }
                            switch (action) {
                                case 'add':
                                    if (acc.unassign.hasOwnProperty(element.id))
                                        delete acc.unassign[element.id];
                                    else acc.assign[element.id] = element;
                                    break;
                                case 'remove':
                                    if (acc.assign.hasOwnProperty(element.id))
                                        delete acc.assign[element.id];
                                    else acc.unassign[element.id] = element;
                                    break;
                            }
                            return acc;
                        },
                        {
                            assign: {},
                            unassign: {}
                        }
                    ),
                    shareReplay(1)
                );
            }),
            shareReplay(1)
        );
        this.selectedTeams$ = combineLatest([
            this.assignedTeams$,
            this.assignations$
        ]).pipe(
            map(([teams = [], assignations]) => {
                return teams
                    .filter(
                        (t: Team) => !assignations.unassign.hasOwnProperty(t.id)
                    )
                    .concat(Object.values(assignations.assign));
            })
        );
        this.assignationChanged$ = combineLatest([this.assignations$]).pipe(
            map(
                ([assignations]) =>
                    Object.keys(assignations.assign).length > 0 ||
                    Object.keys(assignations.unassign).length > 0
            )
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        this.assignedTeams$.next(this.initialValue);
        this.assignationsReset$.next(true);
        this.saving = false;
    }

    ngOnDestroy() {
        this.assignationsReset$.complete();
        this.toggleTeam$.complete();
    }

    removeElementFromSelectedTeams(event: Team) {
        this.toggleTeam$.next({ element: event, action: 'remove' });
    }

    addElementToSelectedTeams(event: Team) {
        this.toggleTeam$.next({ element: event, action: 'add' });
    }

    applyChanges() {
        this.assignations$.pipe(first()).subscribe(assignations => {
            this.store.dispatch(
                bulkActions.bulkAssignTeam({
                    ids: [this.issueId],
                    allSelected: false,
                    assignTeamsIds: Object.keys(assignations.assign),
                    unassignTeamsIds: Object.keys(assignations.unassign)
                })
            );
        });
        this.saving = true;
    }
}
