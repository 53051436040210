import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotkeysDialogComponent } from './hotkeys-dialog.component';

@NgModule({
    imports: [CommonModule],
    declarations: [HotkeysDialogComponent],
    exports: [HotkeysDialogComponent]
})
export class HotkeysDialogModule {}
