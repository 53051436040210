import {
    Action,
    createAction,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
    Store
} from '@ngrx/store';
import { StateFeatures, Widgets } from '../../../models/widgets';
import { Injectable } from '@angular/core';
import { WidgetService } from '../../../../services/widget.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState } from '../../../../core.state';
import { WidgetEffects } from '../../widget.effects';
import { Router } from '@angular/router';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { GeneralService } from '../../../general/general.service';
import {
    instanceIdSelector,
    instanceSelector,
    scanIdSelector,
    selectedDateRangeSelector,
    serviceIdSelector,
    showDateSelect
} from '../../../general/general.selectors';
import { EMPTY, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

type State = typeof Widgets.Large.AssetManager.AM;
enum StateConfig {
    Name = 'Widget - Asset Manager - Overview',
    Key = 'am_lw_am'
}
// First storage state object
const initialState: State = {
    isLoaded: false,
    loading: false,
    error: '',
    filter: {
        active: true
    },
    data: {
        table: [],
        selectedInstance: null,
        instance: {},
        scans: [],
        scan: {},
        secondaryInfo: {},
        loading: {
            instance: false,
            scanDetails: false,
            scanData: false,
            details: false
        },
        scanId: null,
        scanData: {
            table: [],
            secondaryInfo: {},
            cascadeFilters: {},
            filters: {}
        },
        mainCE: []
    },
    cascadeFilters: {
        cmNamespaces: []
    },
    tableFilter: {
        pageIndex: 0,
        pageSize: 10,
        total: undefined,
        sort: undefined,
        direction: undefined
    }
};

/*
 *  Actions express unique events that happen throughout your application
 */
const stateActions = {
    load: createAction(
        `[${StateConfig.Name}] Load`,
        props<{ filter: any; tableFilter: any }>()
    ),
    loadDetails: createAction(
        `[${StateConfig.Name}] Load Details`,
        props<{ filter: any; tableFilter?: any }>()
    ),
    error: createAction(
        `[${StateConfig.Name}] Load error`,
        props<{ error: string }>()
    ),
    success: createAction(
        `[${StateConfig.Name}] Load success`,
        props<{ data: { table: any[]; tableFilter?: any } }>()
    ),
    reset: createAction(`[${StateConfig.Name}] Reset`),
    saveCascadeFilters: createAction(
        `[${StateConfig.Name}] Save cascade filters`,
        props<{ data: any }>()
    ),
    detailsSuccess: createAction(
        `[${StateConfig.Name}] Load details success`,
        props<{ data: any; key: string }>()
    ),
    selectedInstanceLoadSuccess: createAction(
        `[${StateConfig.Name}] Selected instance load success`,
        props<{ instance: any; scans: any[]; scan: any }>()
    ),
    selectedScanLoadSuccess: createAction(
        `[${StateConfig.Name}] Selected scan load success`,
        props<{ scan: any }>()
    ),
    selectedScanDataSuccess: createAction(
        `[${StateConfig.Name}] Selected scan load data success`,
        props<{ data: { table: any[]; tableFilter?: any } }>()
    ),
    selectedScanChange: createAction(
        `[${StateConfig.Name}] Selected scan change`,
        props<{ scan: any }>()
    ),
    activeFilterChange: createAction(
        `[${StateConfig.Name}] Active filter changed`,
        props<{ scan: any; filter: {} }>()
    )
};

/*
 * Reducer functions handle these transitions by determining which actions to
 * handle based on the action's type
 */
const reducers = createReducer(
    initialState,
    on(stateActions.load, (state, { filter }) => ({
        ...state,
        loading: true,
        error: '',
        filter,
        data: {
            ...state.data,
            loading: {
                instance: true,
                scanDetails: true,
                scanData: true,
                details: false
            }
        }
    })),
    on(stateActions.error, (state, { error }) => ({
        ...state,
        loading: false,
        error,
        isLoaded: true,
        data: {
            ...state.data,
            loading: {
                instance: false,
                scanDetails: false,
                scanData: false,
                details: false
            }
        }
    })),
    on(stateActions.success, (state, { data }) => {
        const { tableFilter, ...newData } = data;
        return {
            ...state,
            loading: false,
            isLoaded: true,
            data: {
                ...state.data,
                ...newData,
                loading: {
                    instance: false,
                    scanDetails: false,
                    scanData: false,
                    details: false
                }
            },
            tableFilter
        };
    }),
    on(stateActions.reset, () => ({
        ...initialState
    })),
    on(stateActions.saveCascadeFilters, (state, { data }) => ({
        ...state,
        cascadeFilters: data
    })),
    on(
        stateActions.selectedInstanceLoadSuccess,
        (state, { instance, scans, scan }) => ({
            ...state,
            data: {
                ...state.data,
                instance,
                scans,
                scan,
                scanId: scan && scan['short-id'],
                loading: {
                    instance: false,
                    scanDetails: true,
                    scanData: false,
                    details: false
                },
                scanData: {
                    table: [],
                    cascadeFilters: {},
                    filters: {}
                }
            }
        })
    ),
    on(stateActions.selectedScanChange, (state, { scan }) => ({
        ...state,
        data: {
            ...state.data,
            scan: scan,
            scanId: scan && scan['short-id'],
            loading: {
                instance: false,
                scanDetails: true,
                scanData: true,
                details: false
            }
        }
    })),
    on(stateActions.selectedScanLoadSuccess, (state, { scan }) => {
        const scanDataTable = state.data.table.map(el => {
            return (
                state.data.scanData['table'].find(
                    e => e.ceTypeName === el.ceTypeName
                ) || el
            );
        });
        return {
            ...state,
            data: {
                ...state.data,
                scan,
                loading: {
                    instance: false,
                    scanDetails: false,
                    scanData: false,
                    details: false
                },
                scanData: {
                    ...state.data.scanData,
                    table: scanDataTable,
                    filter: {}
                }
            }
        };
    }),
    on(stateActions.selectedScanDataSuccess, (state, { data }) => {
        const scanDataTable = state.data.table.map(el => {
            return (
                data['table'].find(e => e.ceTypeName === el.ceTypeName) || el
            );
        });
        return {
            ...state,
            data: {
                ...state.data,
                scanData: {
                    ...state.data.scanData,
                    ...data,
                    table: scanDataTable
                },
                loading: {
                    instance: false,
                    scanDetails: false,
                    scanData: false,
                    details: false
                }
            }
        };
    }),
    on(stateActions.loadDetails, state => ({
        ...state,
        data: {
            ...state.data,
            loading: {
                ...state.data.loading,
                details: true
            }
        }
    })),
    on(stateActions.detailsSuccess, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            ...data,
            loading: {
                ...state.data.loading,
                details: false
            }
        }
    })),
    on(stateActions.activeFilterChange, (state, {}) => ({
        ...state,
        data: {
            ...state.data,
            mainCE: []
        }
    }))
);

export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}

/*
 * Selectors are pure functions used for obtaining slices of store state.
 */
const stateSelector = createFeatureSelector<AppState, State>(StateConfig.Key);
const stateSelectors = {
    isLoaded: createSelector(stateSelector, (state: State) => state.isLoaded),
    loading: createSelector(stateSelector, (state: State) => state.loading),
    data: createSelector(stateSelector, (state: State) => state.data),
    error: createSelector(stateSelector, (state: State) => state.error),
    filter: createSelector(stateSelector, (state: State) => state.filter),
    cascadeFilters: createSelector(
        stateSelector,
        (state: State) => state.cascadeFilters
    ),
    tableFilter: createSelector(
        stateSelector,
        (state: State) => state.tableFilter
    )
};

/*
 * Effects use streams to provide new sources of actions to reduce state
 * based on external interactions such as network requests, web socket messages
 * and time-based events.
 */
@Injectable()
export class StateEffects extends WidgetEffects<{
    table: any[];
    tableFilter?: any;
}> {
    constructor(
        public actions$: Actions,
        public service: WidgetService,
        public store: Store<AppState>,
        public router: Router,
        public generalService: GeneralService
    ) {
        super(
            actions$,
            service,
            store,
            stateActions,
            {
                cascadeFilters: ['cmNamespaces'],
                loadLarge: {
                    key: 'am-am'
                },
                loadDetails: {
                    key: 'am-am'
                }
            },
            stateSelector,
            undefined,
            router
        );
    }

    @Effect()
    _load = this.loadLarge;

    @Effect()
    _cascadeFilters = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(this.store.select(scanIdSelector)),
        withLatestFrom(this.store.select(showDateSelect)),
        withLatestFrom(this.store.select(selectedDateRangeSelector)),
        withLatestFrom(this.store.select(instanceIdSelector)),
        withLatestFrom(this.store.select(serviceIdSelector)),
        switchMap(
            ([
                [[[[{ filter }, scanId], show], dateRange], instance],
                serviceId
            ]) => {
                return this.service
                    .cascadeFilters(
                        scanId,
                        this.params.cascadeFilters,
                        {},
                        show,
                        dateRange,
                        instance,
                        serviceId
                    )
                    .pipe(
                        map((specificFilters: any) => {
                            return this.actions.saveCascadeFilters({
                                data: specificFilters
                            });
                        }),
                        catchError((error: HttpErrorResponse) =>
                            of(this.actions.error({ error: error.message }))
                        )
                    );
            }
        )
    );

    @Effect()
    _scanReset = this.scanReset;

    @Effect()
    _instanceReset = this.instanceReset;

    @Effect()
    _loadDetails = this.loadDetails;

    @Effect()
    _getScanInfo = this.actions$.pipe(
        ofType(
            this.actions.selectedInstanceLoadSuccess,
            this.actions.selectedScanChange
        ),
        switchMap(({ scan }) => {
            if (scan === undefined || scan === null) return EMPTY;
            return this.generalService.getScan(scan['short-id']).pipe(
                map(data => {
                    return this.actions.selectedScanLoadSuccess({
                        scan: data
                    });
                })
            );
        })
    );

    @Effect()
    _getScanData = this.actions$.pipe(
        ofType(
            this.actions.selectedInstanceLoadSuccess,
            this.actions.selectedScanChange,
            this.actions.activeFilterChange
        ),
        withLatestFrom(this.store.select(instanceSelector)),
        withLatestFrom(this.store.select(serviceIdSelector)),
        withLatestFrom(this.store.select(stateSelectors.filter)),
        switchMap(
            ([[[{ scan, filter = {} }, instance], serviceId], mainFilter]) => {
                const activeFilter = {};
                if (mainFilter.hasOwnProperty('active'))
                    activeFilter['active'] = mainFilter['active'];
                if (
                    scan === null ||
                    scan === undefined ||
                    scan['short-id'] === undefined ||
                    scan['short-id'] === null
                )
                    return EMPTY;
                return this.service
                    .loadLarge(this.params.loadLarge, {
                        instanceId: undefined,
                        serviceId: serviceId,
                        scanId: scan['short-id'],
                        scanLongId: undefined,
                        filter: { ...activeFilter, ...filter },
                        tableFilter: undefined,
                        timeFilter: undefined,
                        providerId: instance ? instance.serviceId : null
                    })
                    .pipe(
                        map((data: any) => {
                            return this.actions.selectedScanDataSuccess({
                                data
                            });
                        })
                    );
            }
        )
    );

    @Effect()
    _getScanFilters = this.actions$.pipe(
        ofType(
            this.actions.selectedInstanceLoadSuccess,
            this.actions.selectedScanChange
        ),
        switchMap(({ scan, filter = {} }) => {
            if (
                scan === null ||
                scan === undefined ||
                scan['short-id'] === undefined ||
                scan['short-id'] === null
            )
                return EMPTY;
            return this.service.cascadeFilters(
                scan['short-id'],
                this.params.cascadeFilters,
                {},
                false,
                undefined,
                undefined
            );
        })
    );
}

export const stateFeatures: StateFeatures = {
    config: StateConfig,
    actions: stateActions,
    selectors: stateSelectors
};
