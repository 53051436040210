import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { JiraIntegrationService } from './jira-integration.service';
import { JiraIntegrationActions } from './jira-integration.actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppState } from '../../core.state';
import { Store } from '@ngrx/store';
import { instanceIdSelector } from '../general/general.selectors';
import { configurationSelector } from './jira-integration.selectors';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class JiraIntegrationEffects {
    constructor(
        private action$: Actions,
        private service: JiraIntegrationService,
        private store: Store<AppState>,
        private snack: MatSnackBar
    ) {}

    @Effect()
    connect = this.action$.pipe(
        ofType(JiraIntegrationActions.connect),
        withLatestFrom(this.store.select(configurationSelector)),
        withLatestFrom(this.store.select(instanceIdSelector)),
        switchMap(
            ([[{ email, subdomain, token }, currentConfig], instanceId]) => {
                return this.service
                    .saveJiraConfig(
                        { email, token, subdomain, enabled: true },
                        currentConfig,
                        instanceId
                    )
                    .pipe(
                        switchMap(({ config }) => {
                            return [
                                JiraIntegrationActions.connectionSuccess({
                                    config
                                }),
                                JiraIntegrationActions.fetchProjects({
                                    email,
                                    token,
                                    subdomain
                                })
                            ];
                        }),
                        catchError(error => {
                            console.error(error);
                            this.snack.open('something went wrong', null, {
                                panelClass: 'snackbar-error',
                                duration: 5000
                            });
                            return of(
                                JiraIntegrationActions.connectionError(error)
                            );
                        })
                    );
            }
        )
    );

    @Effect({
        dispatch: false
    })
    disableJiraIntegration = this.action$.pipe(
        ofType(JiraIntegrationActions.disableJiraIntegration),
        withLatestFrom(this.store.select(instanceIdSelector)),
        switchMap(([{}, instanceId]) => {
            return this.service
                .disableJiraIntegration(instanceId)
                .pipe(map(() => []));
        })
    );

    @Effect()
    disconnect = this.action$.pipe(
        ofType(JiraIntegrationActions.disconnect),
        withLatestFrom(this.store.select(configurationSelector)),
        withLatestFrom(this.store.select(instanceIdSelector)),
        switchMap(([[{}, currentConfig], instanceId]) => {
            return this.service
                .saveJiraConfig({ enabled: false }, currentConfig, instanceId)
                .pipe(
                    map(({ config }) => {
                        return JiraIntegrationActions.disconnectionSuccess({
                            config
                        });
                    }),
                    catchError(error => {
                        console.error(error);
                        this.snack.open('something went wrong', null, {
                            panelClass: 'snackbar-error',
                            duration: 5000
                        });
                        return [];
                    })
                );
        })
    );

    @Effect()
    fetchApplications = this.action$.pipe(
        ofType(JiraIntegrationActions.fetchApplications),
        withLatestFrom(this.store.select(instanceIdSelector)),
        switchMap(([{}, instanceId]) => {
            return this.service.fetchApplications(instanceId).pipe(
                map(({ applications }) => {
                    return JiraIntegrationActions.setApplications({
                        applications
                    });
                }),
                catchError(error => {
                    console.error(error);
                    this.snack.open('something went wrong', null, {
                        panelClass: 'snackbar-error',
                        duration: 5000
                    });
                    return of(JiraIntegrationActions.fetchApplicationsError());
                })
            );
        })
    );

    @Effect()
    fetchJiraConfig = this.action$.pipe(
        ofType(JiraIntegrationActions.fetchJiraConfig),
        withLatestFrom(this.store.select(instanceIdSelector)),
        switchMap(([{}, instanceId]) => {
            return this.service.fetchJiraIntegrationInfo(instanceId).pipe(
                switchMap(({ config }) => {
                    if (
                        config.enabled &&
                        config.email &&
                        config.token &&
                        config.subdomain
                    )
                        return [
                            JiraIntegrationActions.setJiraConfig({ config }),
                            JiraIntegrationActions.fetchProjects({
                                email: config.email,
                                token: config.token,
                                subdomain: config.subdomain
                            })
                        ];
                    return [JiraIntegrationActions.setJiraConfig({ config })];
                }),
                catchError(error => {
                    console.error(error);
                    this.snack.open('something went wrong', null, {
                        panelClass: 'snackbar-error',
                        duration: 5000
                    });
                    return [];
                })
            );
        })
    );

    @Effect()
    fetchProjects = this.action$.pipe(
        ofType(JiraIntegrationActions.fetchProjects),
        switchMap(({ email, token, subdomain }) => {
            return this.service.fetchProjects(email, token, subdomain).pipe(
                map(data => {
                    return JiraIntegrationActions.fetchProjectsSuccess({
                        projects: data.map(p => {
                            return {
                                value: p.id,
                                label: p.name,
                                issueTypes: p.issueTypes.map(it => ({
                                    value: it.id,
                                    label: it.name
                                })),
                                style: p.style
                            };
                        })
                    });
                }),
                catchError(error => {
                    console.error(error);
                    this.snack.open('something went wrong', null, {
                        panelClass: 'snackbar-error',
                        duration: 5000
                    });
                    return of(JiraIntegrationActions.fetchProjectsError(error));
                })
            );
        })
    );

    @Effect()
    saveConfig = this.action$.pipe(
        ofType(JiraIntegrationActions.saveConfig),
        withLatestFrom(this.store.select(configurationSelector)),
        withLatestFrom(this.store.select(instanceIdSelector)),
        switchMap(
            ([[{ application, impactArea, severity }, config], instanceId]) => {
                return this.service
                    .saveJiraConfig(
                        { application, impactArea, severity },
                        config,
                        instanceId
                    )
                    .pipe(
                        map(() => {
                            this.snack.open('rules saved successfully', null, {
                                duration: 2000
                            });
                            return JiraIntegrationActions.saveConfigSuccess();
                        }),
                        catchError(error => {
                            console.error(error);
                            this.snack.open('something went wrong', null, {
                                panelClass: 'snackbar-error',
                                duration: 5000
                            });
                            return of(
                                JiraIntegrationActions.saveConfigError(error)
                            );
                        })
                    );
            }
        )
    );

    @Effect()
    saveProjectAndStates = this.action$.pipe(
        ofType(JiraIntegrationActions.saveProjectAndStates),
        withLatestFrom(this.store.select(configurationSelector)),
        withLatestFrom(this.store.select(instanceIdSelector)),
        switchMap(
            ([
                [
                    {
                        project,
                        initialState,
                        finalState,
                        issueType,
                        initialStatus,
                        finalStatus,
                        assignee
                    },
                    config
                ],
                instanceId
            ]) => {
                return this.service
                    .saveJiraConfig(
                        {
                            project,
                            initialState,
                            finalState,
                            issueType,
                            initialStatus,
                            finalStatus,
                            assignee
                        },
                        config,
                        instanceId
                    )
                    .pipe(
                        map(() => {
                            this.snack.open(
                                'project and states saved successfully',
                                null,
                                {
                                    duration: 2000
                                }
                            );
                            return JiraIntegrationActions.saveProjectAndStatesSuccess();
                        }),
                        catchError(error => {
                            console.error(error);
                            this.snack.open('something went wrong', null, {
                                panelClass: 'snackbar-error',
                                duration: 5000
                            });
                            return of(
                                JiraIntegrationActions.saveProjectAndStatesError(
                                    error
                                )
                            );
                        })
                    );
            }
        )
    );

    @Effect()
    fetchTransitions = this.action$.pipe(
        ofType(JiraIntegrationActions.fetchTransitions),
        withLatestFrom(this.store.select(configurationSelector)),
        switchMap(
            ([{ projectId, issueTypeId }, { email, token, subdomain }]) => {
                return this.service
                    .fetchProjectIssue(
                        email,
                        token,
                        subdomain,
                        projectId,
                        issueTypeId
                    )
                    .pipe(
                        switchMap((res: any) => {
                            if (res.issues && res.issues.length)
                                return this.service
                                    .fetchIssueTransitions(
                                        email,
                                        token,
                                        subdomain,
                                        res.issues[0].id
                                    )
                                    .pipe(
                                        map((tx: any) => {
                                            if (
                                                !tx.hasOwnProperty(
                                                    'transitions'
                                                ) ||
                                                tx.transitions.length === 0
                                            ) {
                                                this.snack.open(
                                                    'There are no available statuses. ' +
                                                        'Remember that a valid status should be able to transition from any status',
                                                    null,
                                                    {
                                                        panelClass:
                                                            'snackbar-error',
                                                        duration: 5000
                                                    }
                                                );
                                                console.error('no transitions');
                                                return JiraIntegrationActions.setTransitionsError(
                                                    { error: 'no transitions' }
                                                );
                                            }
                                            return JiraIntegrationActions.setTransitions(
                                                {
                                                    transitions: tx.transitions.map(
                                                        tr => ({
                                                            label: tr.name,
                                                            value: tr.id
                                                        })
                                                    )
                                                }
                                            );
                                        })
                                    );
                            return this.service
                                .fetchProjectWorkflowSchema(
                                    email,
                                    token,
                                    subdomain,
                                    projectId
                                )
                                .pipe(
                                    switchMap((wf: any) => {
                                        if (
                                            !wf.hasOwnProperty('values') ||
                                            wf.values.length !== 1
                                        ) {
                                            this.snack.open(
                                                'There are no available statuses. ' +
                                                    'Remember that a valid status should be able to transition from any status',
                                                null,
                                                {
                                                    panelClass:
                                                        'snackbar-error',
                                                    duration: 5000
                                                }
                                            );
                                            console.error(
                                                'no transitions',
                                                wf.values
                                            );
                                            return [
                                                JiraIntegrationActions.setTransitionsError(
                                                    { error: 'no transitions' }
                                                )
                                            ];
                                        }
                                        return this.service
                                            .fetchWorkflowTransitions(
                                                email,
                                                token,
                                                subdomain,
                                                wf.values[0].workflowScheme
                                                    .defaultWorkflow
                                            )
                                            .pipe(
                                                map((r: any) => {
                                                    if (
                                                        !r.hasOwnProperty(
                                                            'values'
                                                        ) ||
                                                        r.values.length !== 1 ||
                                                        !r.values[0].hasOwnProperty(
                                                            'transitions'
                                                        )
                                                    ) {
                                                        this.snack.open(
                                                            'There are no available statuses. ' +
                                                                'Remember that a valid status should be able to transition from any status',
                                                            null,
                                                            {
                                                                panelClass:
                                                                    'snackbar-error',
                                                                duration: 5000
                                                            }
                                                        );
                                                        console.error(
                                                            'no transitions'
                                                        );
                                                        return JiraIntegrationActions.setTransitionsError(
                                                            {
                                                                error:
                                                                    'no transitions'
                                                            }
                                                        );
                                                    }
                                                    return JiraIntegrationActions.setTransitions(
                                                        {
                                                            transitions: r.values[0].transitions.map(
                                                                tr => ({
                                                                    label:
                                                                        tr.name,
                                                                    value: tr.id
                                                                })
                                                            )
                                                        }
                                                    );
                                                })
                                            );
                                    })
                                );
                        })
                    );
            }
        )
    );

    @Effect()
    fetchActivityLog = this.action$.pipe(
        ofType(JiraIntegrationActions.getActivityLog),
        withLatestFrom(this.store.select(instanceIdSelector)),
        switchMap(([{ tableFilter }, instanceId]) => {
            return this.service.fetchActivityLog(instanceId, tableFilter).pipe(
                map((res: any) => {
                    return JiraIntegrationActions.setActivityLog({
                        data: res.data.map(el => ({
                            ...el.attributes,
                            hasErrors: el.attributes.status === 'ERROR'
                        })),
                        meta: {
                            total: res.meta.total,
                            pageSize: res.meta['per-page'],
                            pageIndex: res.meta['current-page'] - 1,
                            ...res.meta
                        }
                    });
                })
            );
        })
    );

    @Effect()
    fetchUsers = this.action$.pipe(
        ofType(JiraIntegrationActions.fetchUsers),
        withLatestFrom(this.store.select(configurationSelector)),
        switchMap(([{ projectId, query }, { email, token, subdomain }]) => {
            return this.service
                .fetchUsers(email, token, subdomain, projectId, query)
                .pipe(
                    map((data: any[]) => {
                        return JiraIntegrationActions.fetchUsersSuccess({
                            users: data.map(us => ({
                                value: us.accountId,
                                label: us.displayName
                            }))
                        });
                    })
                );
        })
    );
}
