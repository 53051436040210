import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../core.state';
import { Filters, GeneralState } from './general.models';

const ALL_CLOUDS = 99;

export const generalSelector = createFeatureSelector<AppState, GeneralState>(
    'general'
);

export const sideMenuExpandedSelector = createSelector(
    generalSelector,
    ({ sideMenuExpanded }) => sideMenuExpanded
);

export const filtersSelector = createSelector(
    generalSelector,
    ({ dashboardFilters }: GeneralState): Filters => ({ ...dashboardFilters })
);

export const filtersLoadedSelector = createSelector(
    generalSelector,
    ({ generalFiltersLoaded }) => generalFiltersLoaded
);

export const instanceIdSelector = createSelector(
    generalSelector,
    ({ instance }) => (instance != null ? parseInt(instance.id, 10) : null)
);

export const scanIdSelector = createSelector(generalSelector, ({ scan }) =>
    scan != null ? scan['short-id'] : null
);

export const scanQualityGateStatusSelector = createSelector(
    generalSelector,
    ({ scan }) =>
        scan !== null ? scan['quality-gate-result'] || 'not set' : 'not set'
);

export const scanQualityGateMessageSelector = createSelector(
    generalSelector,
    ({ scan }) => (scan !== null ? scan['quality-gate-message'] || '' : '')
);

export const mainFilterSelector = createSelector(
    generalSelector,
    ({ mainFilter }) => mainFilter
);

export const scanSelector = createSelector(generalSelector, ({ scan }) => scan);

export const scanLongIdSelector = createSelector(generalSelector, ({ scan }) =>
    scan != null ? scan.id || null : null
);

export const serviceIdSelector = createSelector(
    generalSelector,
    ({ instance, settings }) =>
        instance !== null
            ? instance.serviceId
            : settings
            ? settings.serviceId
            : null
);

export const loadingSelector = createSelector(
    generalSelector,
    ({ loading }) => loading
);
export const isLoadedSelector = createSelector(
    generalSelector,
    ({ isLoaded }) => isLoaded
);

export const scansSelector = createSelector(generalSelector, ({ scans }) =>
    scans ? scans : []
);

export const profilingScansSelector = createSelector(
    generalSelector,
    ({ profilingScans }) => (profilingScans ? profilingScans : [])
);

export const instanceSelector = createSelector(
    generalSelector,
    ({ instance }) => (instance != null ? instance : null)
);

export const instanceServiceIdSelector = createSelector(
    generalSelector,
    ({ instance }) => (instance !== null ? instance.serviceId : null)
);

export const hasQualityGatesSelector = createSelector(
    generalSelector,
    ({ instance }): boolean =>
        instance !== null ? instance['has-quality-gates'] : false
);

export const instancesSelector = createSelector(
    generalSelector,
    ({ instances }) => instances
);

export const instancesOnlyFullScansSelector = createSelector(
    generalSelector,
    ({ instancesOnlyFullScans }) => instancesOnlyFullScans
);

export const dashboardsSelector = createSelector(
    generalSelector,
    ({ dashboards }) => dashboards
);

export const dashboardsAvailableSelector = createSelector(
    generalSelector,
    ({ dashboards }) => dashboards.filter(d => d['is-available-for-user'])
);

export const dashboardsUnavailableSelector = createSelector(
    generalSelector,
    ({ dashboards }) => dashboards.filter(d => !d['is-available-for-user'])
);

export const allCloudsDashboardSelector = createSelector(
    generalSelector,
    ({ allCloudsDashboards }) => allCloudsDashboards
);

export const allCloudsDashboardAvailableSelector = createSelector(
    generalSelector,
    ({ allCloudsDashboards }) =>
        allCloudsDashboards.filter(d => d['is-available-for-user'])
);

export const allCloudsDashboardUnavailableSelector = createSelector(
    generalSelector,
    ({ allCloudsDashboards }) =>
        allCloudsDashboards.filter(d => !d['is-available-for-user'])
);

export const dashboardSelector = createSelector(
    generalSelector,
    ({ dashboard }) => (dashboard != null ? dashboard : null)
);

export const showScansSelect = createSelector(
    generalSelector,
    ({ scans, dashboard, profilingScans }) => {
        let response = false;
        if (dashboard) {
            response =
                (scans.length > 0 ||
                    (profilingScans.length > 0 &&
                        dashboard.url === 'profiling')) &&
                dashboard['is-custom-bi'] &&
                dashboard['dashboard-type'] !== 50 &&
                dashboard['dashboard-type'] !== ALL_CLOUDS &&
                !dashboard.url.includes('user-adoption') &&
                dashboard.url !== 'licensing' &&
                dashboard.url !== 'cross-org-licensing' &&
                dashboard.url !== 'alerts' &&
                dashboard.url !== 'live-check' &&
                dashboard.url !== 'livecheck' &&
                dashboard.url !== 'release-management' &&
                dashboard.url !== 'write-offs' &&
                dashboard.url !== 'impact-analysis' &&
                dashboard.url !== 'debt-manager' &&
                dashboard.url !== 'debt-manager-new';
        }
        return response;
    }
);

export const showDateSelect = createSelector(
    generalSelector,
    ({ scans, dashboard }) => {
        let response;
        if (dashboard) {
            response =
                (dashboard['is-custom-bi'] &&
                    dashboard['dashboard-type'] === 50) ||
                dashboard.url === 'live-check' ||
                dashboard.url === 'livecheck' ||
                dashboard.url.includes('user-adoption') ||
                dashboard.url === 'alerts' ||
                dashboard.url === 'write-offs' ||
                dashboard.url === 'debt-manager' ||
                dashboard.url === 'release-management' ||
                dashboard.url === 'impact-analysis';
        }
        return response;
    }
);

export const selectedDateRangeSelector = createSelector(
    generalSelector,
    ({ selectedDateRange }) => selectedDateRange
);

export const initialLoadingSelector = createSelector(
    generalSelector,
    ({ initialLoading }) => initialLoading
);

export const instanceLoadingSelector = createSelector(
    generalSelector,
    ({ instanceLoading }) => instanceLoading
);

export const scanLoadingSelector = createSelector(
    generalSelector,
    ({ scanLoading }) => scanLoading
);

export const lastScanIdSelector = createSelector(
    generalSelector,
    ({ scans, profilingScans }) => {
        return scans.length > 0
            ? scans[0].value
            : profilingScans.length > 0
            ? profilingScans[0].value
            : null;
    }
);

export const settingsSelector = createSelector(
    generalSelector,
    ({ settings }) => settings
);

export const mainFilterSetterSelector = createSelector(
    generalSelector,
    ({ mainFilterSet }) => mainFilterSet
);

export const allCloudsSelector = createSelector(
    generalSelector,
    ({ onAllClouds }) => onAllClouds
);

export const isEmptyView = createSelector(
    generalSelector,
    ({ emptyView }) => emptyView
);

export const dashboardContextSelector = createSelector(
    generalSelector,
    ({ dashboardContext }) => dashboardContext
);

export const lastScansSelector = createSelector(
    generalSelector,
    ({ lastScans }) => lastScans
);

export const customerIdSelector = createSelector(
    generalSelector,
    ({ user }) => user && user['customer-id']
);

export const customerAddons = createSelector(
    generalSelector,
    ({ addons }) => addons
);

export const currentInstanceServiceAddons = createSelector(
    generalSelector,
    ({ addons, instance }) =>
        addons.filter(addon => addon.serviceId === instance.serviceId)
);

const SMART_TAG_NAME_ID_BY_SERVICE = [
    'smart_tagging_sn',
    'smart_tagging_sf',
    'smart_tagging_of'
];

export const currentInstanceHasSmartTags = createSelector(
    generalSelector,
    ({ addons, instance }) =>
        addons.some(
            addon =>
                +addon.serviceId === +instance.serviceId &&
                addon.nameId ===
                    SMART_TAG_NAME_ID_BY_SERVICE[+instance.serviceId - 1]
        )
);

const TEAMS_NAME_ID_BY_SERVICE = ['teams_sn', 'teams_sf', 'teams_of'];

export const currentInstanceHasTeams = createSelector(
    generalSelector,
    ({ addons, instance }) =>
        addons.some(
            addon =>
                +addon.serviceId === +instance.serviceId &&
                addon.nameId ===
                    TEAMS_NAME_ID_BY_SERVICE[+instance.serviceId - 1]
        )
);

export const currentInstanceHasManualSmartTags = createSelector(
    generalSelector,
    ({ instance }) => instance['has-manual-smart-tag'] === 'enabled'
);

export const generalUserSelector = createSelector(
    generalSelector,
    ({ user }) => user
);

export const userPortalConfigurationSelector = createSelector(
    generalSelector,
    ({ userPortalConfiguration }) => userPortalConfiguration
);

export const largeWidgetExpandedSelector = createSelector(
    generalSelector,
    ({ largeWidgetExpanded }) => largeWidgetExpanded
);

export const includeBaselineSelector = createSelector(
    generalSelector,
    ({ includeBaseline }) => includeBaseline
);

export const tablesAndDictionariesSelector = createSelector(
    generalSelector,
    ({ includeTablesAndDictionaries }) => includeTablesAndDictionaries
);

export const baseFilterSelector = createSelector(
    generalSelector,
    ({ baseFilters }) => baseFilters
);
