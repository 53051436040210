import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input
} from '@angular/core';

@Component({
    selector: 'qcbi-colour-bubble',
    templateUrl: './colour-bubble.component.html',
    styleUrls: ['./colour-bubble.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColourBubbleComponent implements OnInit {
    @Input()
    userName?: string;
    @Input()
    size = 25;
    @Input()
    statusSemaphoreValue = '';
    @Input()
    type = 'string';

    bgcolor = '#28a745';
    initials = '';

    constructor() {}

    ngOnInit() {
        if (this.statusSemaphoreValue) {
            this.bgcolor = this.getStatusBg(this.statusSemaphoreValue);
            this.initials = '';
        } else {
            this.getInitials(this.userName);
        }
    }

    stringToColour(firstName, lastName) {
        const str = (firstName + lastName).toString();
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    getInitials(userName) {
        let firstLetter;
        let secondLetter;
        let firstName;
        let lastName;

        if (!userName) return;

        const name = userName.split(' ');

        firstLetter = name[0].slice(0, 1).toUpperCase();
        firstName = name[0];
        if (name.length > 1) {
            secondLetter = name[1].slice(0, 1).toUpperCase();
            lastName = name[1];
        } else {
            secondLetter = name[0].slice(1, 2).toUpperCase();
            lastName = '';
        }

        this.bgcolor = this.stringToColour(firstName, lastName);
        this.initials = firstLetter + secondLetter;
    }

    getStatusBg(value) {
        if (typeof value === 'number') {
            if (value > 0) {
                return '#d45643';
            } else {
                return '#45855d';
            }
        } else if (typeof value === 'string') {
            if (
                value.toUpperCase() === 'SUCCESS' ||
                value.toUpperCase() === 'PASSED'
            ) {
                return '#45855d';
            } else if (value.toUpperCase() === 'FAIL') {
                return '#d45643';
            } else {
                return '#e0e0e0';
            }
        }
    }
}
