import {
    ChangeDetectorRef,
    OnDestroy,
    Pipe,
    PipeTransform
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';
import { selectCurrentUser } from '../../core/auth-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Pipe({
    name: 'userDateTime'
})
export class UserDateTimePipe implements OnDestroy, PipeTransform {
    private latestUserTimeValue;
    private unsubscribe$ = new Subject();

    constructor(
        private datePipe: DatePipe,
        private cdr: ChangeDetectorRef,
        private store: Store<AppState>
    ) {
        this.store
            .select(selectCurrentUser)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(user => {
                this.latestUserTimeValue = user.timeFormat;
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    transform(
        value: string,
        type: 'date' | 'dateTime' = 'date',
        notADateString: string = '',
        timezone: string = null
    ): string {
        if (!this.isValidDate(value)) {
            if (notADateString === '') console.error('not a date');
            return notADateString;
        }
        const d = new Date(Date.parse(value));
        return this.datePipe.transform(
            d,
            ...this.getTimeFormatFromUserConfigAndType(type, timezone)
        );
    }

    private getTimeFormatFromUserConfigAndType(
        type: 'date' | 'dateTime',
        timezone: string
    ): string[] {
        if (type === 'date') {
            if (
                this.latestUserTimeValue === undefined ||
                this.latestUserTimeValue === null ||
                this.latestUserTimeValue === 'EU'
            )
                return ['dd/MM/yyyy'];
            return ['MM/dd/yyyy'];
        }
        if (
            this.latestUserTimeValue === undefined ||
            this.latestUserTimeValue === null ||
            this.latestUserTimeValue === 'EU'
        )
            return timezone
                ? ['dd/MM/yyyy HH:mm', timezone]
                : ['dd/MM/yyyy HH:mm'];
        return timezone ? ['MM/dd/yyyy HH:mm', timezone] : ['MM/dd/yyyy HH:mm'];
    }

    private isValidDate(d: string) {
        return !isNaN(Date.parse(d));
    }
}
