import { TwoFactorAuthenticationState } from './two-factor-authentication.models';
import { Action, createReducer, on } from '@ngrx/store';
import { TwoFactorAuthenticationActions } from './two-factor-authentication.actions';

export const initialState: TwoFactorAuthenticationState = {
    status: 'DISABLED',
    secret: null,
    uri: null,
    codeStatus: null,
    codeStatusMsg: null
};

const reducer = createReducer(
    initialState,
    on(TwoFactorAuthenticationActions.disableMfa, () => ({
        ...initialState
    })),
    on(TwoFactorAuthenticationActions.setStatus, (state, { status }) => ({
        ...state,
        status
    })),
    on(TwoFactorAuthenticationActions.setQrData, (state, { uri, secret }) => {
        return {
            ...state,
            secret,
            uri
        };
    }),
    on(TwoFactorAuthenticationActions.validateCode, state => ({
        ...state,
        codeStatus: 'validating'
    })),
    on(
        TwoFactorAuthenticationActions.validateCodeResult,
        (state, { result, message }) => ({
            ...state,
            codeStatus: result,
            codeStatusMsg: message
        })
    )
);

export function twoFactorAuthenticationReducer(
    state: TwoFactorAuthenticationState,
    action: Action
): TwoFactorAuthenticationState {
    return reducer(state, action);
}
