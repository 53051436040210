import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Spotted } from '../../../../../../../models/issue.model';

/**
 * spotted component is responsible for display the spotted info
 */
@Component({
    selector: 'qcbi-spotted-on',
    templateUrl: 'spotted-on.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpottedOnComponent {
    /**** PUBLIC ATTRIBUTES ****/
    // INPUTS AND OUTPUTS
    @Input() spotted: Spotted[] = [];
    // ATTRIBUTES
    columnNames = {
        Environment: 'instanceDescription',
        Created_On: 'createdOn',
        Is_Written_Off: 'isWrittenOff'
    };
}
