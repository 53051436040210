import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewsBarComponent } from './views-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonToggleModule,
        MatButtonModule,
        MatTooltipModule
    ],
    declarations: [ViewsBarComponent],
    exports: [ViewsBarComponent]
})
export class ViewsBarModule {}
