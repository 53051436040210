import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../core/core.state';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable, Subject } from 'rxjs';
import { GeneralActions } from '../../core/state/general/general.actions';
import { allCloudsDashboardSelector } from '../../core/state/general/general.selectors';
import { first } from 'rxjs/internal/operators';

@Component({
    selector: 'qcbi-all-clouds-page',
    templateUrl: './all-clouds.component.html'
})
export class AllCloudsPageComponent implements OnInit, OnDestroy {
    public subcription: Subscription;
    public dashboards: Observable<any>;
    unsubscribe: Subject<any> = new Subject<any>();
    constructor(
        public store: Store<AppState>,
        public activatedRoute: ActivatedRoute,
        public router: Router
    ) {}

    ngOnInit() {
        this.dashboards = this.store.select(allCloudsDashboardSelector);
        this.subcription = this.activatedRoute.params.subscribe(
            (params: any) => {
                if (params.dashboardId) {
                    this.store.dispatch(
                        GeneralActions.allCloudsLoad({
                            dashboardId: parseInt(params.dashboardId)
                        })
                    );
                    this.dashboards
                        .pipe(first())
                        .toPromise()
                        .then(dashboard => {
                            const dash = dashboard.find(
                                d => +d.id === +params.dashboardId
                            );
                            // TODO: review this navigation
                            if (dash && /^((?!clicdata).)*$/.test(dash.url)) {
                                this.router.navigate(['./', dash.url], {
                                    relativeTo: this.activatedRoute
                                });
                            }
                        });
                }
            }
        );
    }

    ngOnDestroy() {
        if (this.subcription) this.subcription.unsubscribe();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
