import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TwoFactorAuthenticationService {
    constructor(private http: HttpClient) {}

    /**
     * Disables the mfa for the user
     */
    disableMfa(): Observable<{ status: 'DISABLED' }> {
        return this.http
            .post(`${environment.apiDevEndPoint}api/v2/disable-mfa`, {})
            .pipe(
                map(res => {
                    return {
                        status: 'DISABLED'
                    };
                })
            );
    }

    /**
     * Retrieve information of the mfa for the user
     * @param userId user id to retrieve the info from
     */
    fetchMfaStatus(
        userId: string
    ): Observable<{ status: 'DISABLED' | 'ENABLED' | 'SYNCHRONIZED' }> {
        return this.http
            .get(`${environment.apiDevEndPoint}api/v2/mfa-status/${userId}`)
            .pipe(
                map((res: any) => {
                    return {
                        status: res.data.status
                    };
                })
            );
    }

    /**
     * fetches de qr info (uri and secret) to generate the qr and the code
     */
    fetchQrCode(): Observable<{ secret: string; uri: string }> {
        return this.http
            .post(`${environment.apiDevEndPoint}api/v2/mfa-secret`, {})
            .pipe(
                map((res: { data: { secret: string; uri: string } }) => {
                    return {
                        secret: res.data.secret,
                        uri: res.data.uri
                    };
                })
            );
    }

    /**
     * validates the 2fa code to synchronize the 2fa
     * @param code to verify
     */
    validateCode(code): Observable<any> {
        return this.http.post(
            `${environment.apiDevEndPoint}api/v2/verify-mfa`,
            {
                data: {
                    code
                }
            }
        );
    }
}
