import { createAction, props } from '@ngrx/store';
import { StateConfig } from './dm-new.config';

export const bulkActions = {
    bulkWriteOff: createAction(
        `[${StateConfig.Name}] bulk write off`,
        props<{
            ids;
            description;
            reasonNameId;
            allSelected;
            filters;
            expirationDate;
        }>()
    ),
    bulkTags: createAction(
        `[${StateConfig.Name}] bulk tags`,
        props<{
            assignTagsIds;
            unassignTagsIds;
            issuesIds;
            allSelected;
            filters;
        }>()
    ),
    bulkChangeWriteOffExpirationDate: createAction(
        `[${StateConfig.Name}] bulk change write off expiration date`,
        props<{
            ids;
            allSelected;
            expirationDate;
        }>()
    ),
    bulkUnwriteOff: createAction(
        `[${StateConfig.Name}] bulk unwrite off`,
        props<{ ids; description; allSelected; status }>()
    ),
    bulkSmartTag: createAction(
        `[${StateConfig.Name}] bulk smart tag`,
        props<{ ids; smartTag; allSelected }>()
    ),
    bulkAssignTeam: createAction(
        `[${StateConfig.Name}] bulk change owner`,
        props<{ ids; assignTeamsIds; unassignTeamsIds; allSelected }>()
    ),
    bulkSuccess: createAction(
        `[${StateConfig.Name}] bulk success`,
        props<{ filter }>()
    ),
    bulkError: createAction(`[${StateConfig.Name}] bulk error`)
};
