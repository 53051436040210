import * as fromNewFeatures from './new-features.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectNewFeaturesState = createFeatureSelector<
    fromNewFeatures.State
>('newFeatures');

export const selectIntegrationsAvailable = createSelector(
    selectNewFeaturesState,
    state =>
        state.newFeatures.some(
            f => f.nameid === 'jira_integration' && f.enabled
        )
);

export const selectTimeMachineAvailable = createSelector(
    selectNewFeaturesState,
    state =>
        state.newFeatures.some(f => f.nameid === 'time_machine' && f.enabled)
);

export const selectIssueOwnerAvailable = createSelector(
    selectNewFeaturesState,
    state =>
        state.newFeatures.some(f => f.nameid === 'issue_owner' && f.enabled)
);
