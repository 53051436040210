export function parseModel(item) {
    const keys = Object.keys(item.attributes);
    return keys.reduce(
        (result, key) => ({ [key]: item.attributes[key], ...result }),
        { id: item.id }
    );
}

export function parseList(list) {
    return list.map(item => parseModel(item));
}
