<div *ngIf="item$ | async as item; else loading">
    <mat-tab-group>
        <mat-tab label="Overview">
            <div class="px-4 py-4">
                <div class="flex w-full justify-between pb-3">
                    <div class="secondary-title">Issue Details</div>
                    <ng-container *ngIf="(hasQualityGateEnabled$ | async) && item.qualityGateTag !== 'N/A'">
                        <div [ngClass]="['smart-tag', item.qualityGateTag]">{{ item.qualityGateTag }}</div>
                    </ng-container>
                </div>
                <div class="grid-column w-full bl" [ngClass]="{ twoCols: hasAssignTeams$ | async}">
                    <qcbi-issue-main-info-container [item]="item"
                                                    [displayInOneColumn]="hasAssignTeams$ | async"></qcbi-issue-main-info-container>
                    <qcbi-assign-teams *ngIf="hasAssignTeams$ | async" [initialValue]="item?.teams || []"
                                       [issueId]="item.shortId"></qcbi-assign-teams>
                </div>
                <div class="pt-4 pb-3 bl">
                    <div class="flex w-full justify-between pb-3">
                        <div class="secondary-title">Affected Element</div>
                    </div>
                    <qcbi-label-value label="CE Name">{{ item.affectedElementName }}</qcbi-label-value>
                    <qcbi-label-value label="CE Type">{{ item.configurationElementType }}</qcbi-label-value>
                    <qcbi-label-value label="Line">{{ item.lineNumber }}</qcbi-label-value>
                    <qcbi-label-value label="Link to element">
                        <mat-icon class="text-base cursor-pointer icon-lh" (click)="openLink(item.ceLink)">
                            open_in_new
                        </mat-icon>
                    </qcbi-label-value>
                </div>
                <div class="pt-4 pb-3 bl">
                    <div class="flex w-full justify-between pb-3">
                        <div class="secondary-title">Write Off Details</div>
                    </div>
                    <div class="flex">
                        <div class="w-min-half">
                            <qcbi-label-value label="Status">
                                <div class="lowercase capitalize-first-letter">
                                    {{ item.writeOff.writeOffStatus || '-' }}
                                </div>
                            </qcbi-label-value>
                            <ng-container *ngIf="item.writeOff.writeOffStatus">
                                <qcbi-label-value label="Expiration">
                                    {{ item.writeOff.expirationDate | userDateTime : 'date' : 'never' }}
                                </qcbi-label-value>
                                <qcbi-label-value label="Description">{{ item.writeOff.requestDescription }}
                                </qcbi-label-value>
                                <qcbi-label-value label="Requester">
                                    <div class="flex items-center">
                                        <ng-container *ngIf="item.writeOff.requester; else noRequestor">
                                            <qcbi-avatar [name]="item.writeOff.requester"
                                                         [marginTop]="false"></qcbi-avatar>
                                            <div>{{ item.writeOff.requester }}</div>
                                        </ng-container>
                                        <ng-template #noRequestor>-</ng-template>
                                    </div>
                                </qcbi-label-value>
                            </ng-container>
                        </div>
                        <div class="w-min-half"
                             *ngIf="item.writeOff.validationReason || item.writeOff.validator">
                            <qcbi-label-value label="Justification"
                                              *ngIf="item.writeOff.validationReason">{{ item.writeOff.validationReason }}
                            </qcbi-label-value>
                            <qcbi-label-value label="Approver" *ngIf="item.writeOff.validator">
                                <div class="flex items-center">
                                    <qcbi-avatar [name]="item.writeOff.validator"
                                                 [marginTop]="false"></qcbi-avatar>
                                    <div>{{ item.writeOff.validator }}</div>
                                </div>
                            </qcbi-label-value>
                        </div>
                    </div>
                </div>
                <div class="pt-4 pb-1">
                    <mat-tab-group>
                        <mat-tab label="Comments">
                            <qcbi-comments [issueId]="item.shortId"
                                           [instanceId]="instanceId$ | async"></qcbi-comments>
                        </mat-tab>
                        <mat-tab label="Activity">
                            <qcbi-activity [issueId]="item.shortId"
                                           [instanceId]="instanceId$ | async"></qcbi-activity>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Best Practice">
            <div class="px-4 py-4">
                <div class="flex w-full justify-between pb-3">
                    <div class="secondary-title">Best practice info</div>
                </div>
                <div class="info">
                    <qcbi-label-value label="Impact">
                        <div>{{ item['impact'] }}</div>
                    </qcbi-label-value>
                    <qcbi-label-value label="Action">
                        <div>{{ item.action }}</div>
                    </qcbi-label-value>
                    <qcbi-label-value label="Documentation">
                        <mat-icon class="text-base cursor-pointer icon-lh"
                                  (click)="openLink(item.bestPracticeLink)">open_in_new
                        </mat-icon>
                    </qcbi-label-value>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Also spotted in">
            <div class="px-4" *ngIf="item">
                <qcbi-spotted-on [spotted]="item.spotted"></qcbi-spotted-on>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<ng-template #loading>
    <mat-progress-bar mode="indeterminate" aria-label="loading issue"></mat-progress-bar>
    <div class="loading-container">
        loading issue...
    </div>
</ng-template>
