import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Feature } from './new-features.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NewFeaturesService {
    constructor(private http: HttpClient) {}

    getNewFeatures(): Observable<Feature[]> {
        return this.http.get(`${environment.apiUrl}/v2/new-feature`).pipe(
            map((res: any) => {
                return res.data.map((el: any) => {
                    return { id: el.id, ...el.attributes } as Feature;
                });
            })
        );
    }
}
