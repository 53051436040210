<div class='container'>
    <h1 class="mb-4 font-semibold text-xl flex w-full justify-center">Assign issues to Teams</h1>
    <div>
        <h2 class="font-semibold text-l">Currently Assigned Teams</h2>
        <ng-container *ngIf="(selectedTeams$ | async) && !loadingSelected; else loadingSelectedContent">
            <ng-container *ngIf="(selectedTeams$ | async)?.length > 0; else noElements">
                <qcbi-selectable-list
                    [teamsList]="selectedTeams$ | async"
                    (selectedChange)="toggleTeamToUnassign($event)"
                ></qcbi-selectable-list>
            </ng-container>
            <ng-template #noElements>
                <div class="py-2">no teams currently assigned</div>
            </ng-template>
        </ng-container>
        <ng-template #loadingSelectedContent>
            <div class="py-2 flex align-items-center gap-16">
                <mat-spinner diameter="20" color="primary"></mat-spinner>
                <div>loading selected teams...</div>
            </div>
        </ng-template>
    </div>
    <div>
        <div class="flex mt-3">
            <h2 class="font-semibold text-l">Available Teams</h2>
            <div class="ml-2">({{ teamsToAssignCount$ | async }} teams selected)</div>
        </div>
        <ng-container *ngIf="availableTeams$ | async as availableTeams; else loadingContent">
            <mat-form-field appearance="outline" class="w-full no-padding mb-2">
                <input
                    #search
                    type="text"
                    matInput
                    placeholder="search team..."
                    aria-label="search team"
                >
            </mat-form-field>
            <qcbi-selectable-list
                [teamsList]="availableTeams | teamsFilter: search.value"
                (selectedChange)="toggleTeamToAssign($event)"
            ></qcbi-selectable-list>
        </ng-container>
        <ng-template #loadingContent>
            <div class="py-2 flex align-items-center gap-16">
                <mat-spinner [diameter]="16" color="primary"></mat-spinner>
                <div>loading available teams...</div>
            </div>
        </ng-template>
    </div>
    <div class="actions flex justify-center gap-8 mt-4">
        <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="assignButtonDisabled$ | async"
            [loading]="savingTeams"
            (click)="submitChanges()"
            class="w-full"
        >
            Apply Changes
        </button>
        <button
            mat-button
            type="button"
            (click)="close()"
            class="w-full"
            [disabled]="savingTeams"
        >
            Cancel
        </button>
    </div>
</div>