<div>
    <div class="error" *ngIf="dashboard == null">
        <h1 >No dashboard available</h1>
    </div>
    <iframe
        #iframe
        *ngIf="dashboard != null && dashboard['full-bi-url'].startsWith('https')"
        [title]="dashboard.name"
        [src]="dashboard['full-bi-url'] | safe"
        class="border border-gray-600 bg-white"
        [ngStyle]="$styling | async"
        [style.height]="iframeHeight + 'px'"
        [style.min-height]="iframeHeight + 'px'"
        [style.max-height]="iframeHeight + 'px'"
    ></iframe>
</div>
