import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultTableComponent } from './default.component';
import { ServerTableComponent } from './server.component';
import { ColourBubbleModule } from '../../shared/colour-bubble/colour-bubble.module';
import { ChipsetModule } from '../../shared/chipset/chipset.module';
import { TablePipe } from '../../pipes/table.pipe';
import { ResizableModule } from 'angular-resizable-element';
import { CustomTooltipDirective } from '../../shared/tables/table-tooltip/tooltip.directive';
import { TableConfigurationModule } from './table-configuration/table-configuration.module';
import { Csv2ArrayPipe } from '../../pipes/csv2array.pipe';
import { MatButtonLoadingModule } from '../../shared/button-loading/mat-button-loading.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UserDateTimeModule } from '../../pipes/user-date-time/user-date-time.module';
import { ColoredDotModule } from '../../shared/colored-dot/colored-dot.module';

@NgModule({
    declarations: [
        ServerTableComponent,
        DefaultTableComponent,
        TablePipe,
        Csv2ArrayPipe,
        CustomTooltipDirective
    ],
    imports: [
        MatTableModule,
        CommonModule,
        ColourBubbleModule,
        ChipsetModule,
        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatToolbarModule,
        MatSelectModule,
        MatTabsModule,
        MatExpansionModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatChipsModule,
        MatCardModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatListModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatButtonToggleModule,
        MatPaginatorModule,
        MatSortModule,
        MatRippleModule,
        ResizableModule,
        TableConfigurationModule,
        MatButtonLoadingModule,
        UserDateTimeModule,
        ColoredDotModule
    ],
    exports: [
        ServerTableComponent,
        DefaultTableComponent,
        TablePipe,
        Csv2ArrayPipe
    ]
})
export class TableModule {}
