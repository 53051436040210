import { createAction, props } from '@ngrx/store';
import { AuthLibState, Credentials, User } from './models';

const init = createAction('[Auth Lib] Init');
const reload = createAction('[Auth Lib] Reload');
const initSuccess = createAction(
    '[Auth Lib] Init success',
    props<AuthLibState>()
);
const initError = createAction('[Auth Lib] Init error');
const loginRedirection = createAction('[Auth Lib] back To Login');
const termsRedirection = createAction('[Auth Lib] back To Terms');
const changePasswordRedirection = createAction('[Auth Lib] back To Change');
const login = createAction('[Auth Lib] Login', props<Credentials>());
const loginSuccess = createAction(
    '[Auth Lib] Login success',
    props<{ access_token: string; refresh_token: string; expires_in: number }>()
);
const loginError = createAction(
    '[Auth Lib] Login success',
    props<{ error: string }>()
);
const logout = createAction('[Auth Lib] Logout');
const heartbeat = createAction('[Auth Lib] Heartbeat');
const heartbeatSuccess = createAction('[Auth Lib] Heartbeat success');
const heartbeatError = createAction('[Auth Lib] Heartbeat error');
const startEmulation = createAction(
    '[Auth Lib] Start emulation',
    props<{ id: string }>()
);
const startEmulationSuccess = createAction(
    '[Auth Lib] Start emulation success',
    props<{ emulatedUser: User }>()
);
const startEmulationError = createAction(
    '[Auth Lib] Start emulation error',
    props<{ reason: string }>()
);
const endEmulation = createAction('[Auth] End emulation');
const endEmulationSuccess = createAction('[Auth Lib] End emulation success');
const endEmulationError = createAction('[Auth Lib] End emulation error');
const refreshTokenHandle = createAction('[Auth Lib] Refresed token');
const dismissShowPortalPopup = createAction(
    '[Auth Lib] Patch Show Portal Popup',
    props<{ userId: string }>()
);
const dismissShowPortalSuccess = createAction(
    '[Auth Lib] Patch Show Portal Popup success',
    props<{ user }>()
);
const windowFocus = createAction('[Auth Lib] Windows focus');
const windowFocusNoLogoutSuccess = createAction(
    '[Auth Lib] Windows focus no logot success'
);
const windowBlur = createAction('[Auth Lib] Windows blur');
const logoutOnFocus = createAction('[Auth Lib] Logout on focus');

export const qcAuthActions = {
    init,
    reload,
    termsRedirection,
    initSuccess,
    initError,
    login,
    loginSuccess,
    loginError,
    logout,
    loginRedirection,
    heartbeat,
    heartbeatSuccess,
    heartbeatError,
    startEmulation,
    startEmulationSuccess,
    startEmulationError,
    endEmulation,
    endEmulationSuccess,
    endEmulationError,
    refreshTokenHandle,
    dismissShowPortalPopup,
    dismissShowPortalSuccess,
    windowFocus,
    windowBlur,
    changePasswordRedirection,
    logoutOnFocus,
    windowFocusNoLogoutSuccess
};
