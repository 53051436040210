<div class="header-wrapper">
  <div class="header">
    <img src="./assets/logos/black-logo.png" alt="Quality Clouds Logo">
    <div class="two-factor-container">
      <em class="las la-shield-alt mr-1 logo"></em>
      <div>Two-factor authentication setup</div>
    </div>
  </div>
</div>
<div class="content">
  <div *ngIf="data && data.showInfo" class="info">
    <mat-icon fontSet="material-icons-round">warning</mat-icon>
    <div>
      You have been requested to enable 2FA. Please enable it to complete the process
    </div>
  </div>
  <div class="content-row">
    <div class="label">Enable 2FA</div>
    <mat-slide-toggle
        [(ngModel)]="enabled"
        (change)="sliderChangeHandler($event)"
        [disabled]="mfaStatus === 'ENABLED' && !(qrUri$ | async)"
    ></mat-slide-toggle>
    <div class="help">Use a phone app like
      <a class="cursor-pointer" target="_blank" href="https://safety.google/authentication/">Google Authenticatior</a>,
      <a class="cursor-pointer" target="_blank" href="https://1password.com/">1Password</a>,
      <a class="cursor-pointer" target="_blank" href="https://authy.com/">Authy</a>,
      <a class="cursor-pointer" target="_blank" href="https://www.lastpass.com/">LastPass Authenticator</a>, or
      <a class="cursor-pointer" target="_blank" href="https://www.microsoft.com/en-us/security/mobile-authenticator-app">Microsoft Authenticator</a>,
      etc. to get 2Fa codes when prompted during sign-in</div>
  </div>
  <div *ngIf="enabled && mfaStatus !== 'SYNCHRONIZED'">
    <div class="label">Scan the QR code</div>
    <div class="help mt-2">Use an authenticator app from your phone to scan. If you are unable to scan,
      <a class="cursor-pointer" (click)="openCodeDialog()">enter this text code</a> instead.
    </div>
    <div class="ml-4 mt-4 mb-4 qr-container">
      <div *ngIf="qrUri$ | async; else qrLoader">
        <qr-code [value]="qrUri$ | async" [size]="200"></qr-code>
      </div>
      <ng-template #qrLoader>
        <div class="qr-loader">
          <mat-spinner color="primary" diameter="50"></mat-spinner>
        </div>
      </ng-template>
    </div>
    <div class="label mb-2">Verify code from the app</div>
    <div class="code-validator-container">
      <qcbi-num-code-input
        (change)="checkCode($event)"
        [disabled]="(codeStatus$ | async) === 'valid' || mfaStatus === 'SYNCHRONIZED' || !(qrUri$ | async)"
      ></qcbi-num-code-input>
      <div class="sync-container">
        <mat-spinner *ngIf="(codeStatus$ | async) === 'validating'" color="primary" diameter="20"></mat-spinner>
        <div class="sync success" *ngIf="(codeStatus$ | async) === 'valid'">
          <mat-icon>done</mat-icon>
          <div>{{ codeStatusMessage$ | async }}</div>
        </div>
        <div *ngIf="(codeStatus$ | async) === 'invalid'" class="sync fail">
          <mat-icon>close</mat-icon>
          <div>{{ codeStatusMessage$ | async }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4" *ngIf="mfaStatus === 'SYNCHRONIZED'">
    <div class="sync success">
      <mat-icon>done</mat-icon>
      <div>2FA Successfully Activated</div>
    </div>
  </div>
  <div class="actions">
    <button mat-raised-button (click)="closeDialog()" color="primary">Close</button>
  </div>
</div>
