import { createAction, props } from '@ngrx/store';
import { Feature } from './new-features.model';

const FEATURE_NAME = 'New Features';

export const NewFeaturesActions = {
    getNewFeatures: createAction(`[${FEATURE_NAME}] get`),
    newFeaturesFetched: createAction(
        `[${FEATURE_NAME}] fetched`,
        props<{ features: Feature[] }>()
    ),
    newFeatureError: createAction(`[${FEATURE_NAME}], error`)
};
