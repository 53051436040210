import { Component, Input } from '@angular/core';

@Component({
    selector: 'qcbi-kpis',
    templateUrl: 'kpis.component.html',
    styleUrls: ['kpis.component.scss']
})
export class KpisComponent {
    @Input() kpis: { td: number; issues: number } = {
        issues: undefined,
        td: undefined
    };
}
