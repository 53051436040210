import { Component, OnInit, Inject } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialog
} from '@angular/material/dialog';
import { TermsComponent } from '../terms/terms.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-help-dialog',
    templateUrl: './help-dialog.component.html',
    styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements OnInit {
    version = '';
    unsubscribe = new Subject<any>();
    constructor(
        public dialogRef: MatDialogRef<HelpDialogComponent>,
        private dialog: MatDialog,
        private http: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.http
            .get(`${environment.apiDevEndPoint}api/v2/version`)
            .pipe(
                map((res: any) => {
                    return res;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((res: any) => {
                this.version = res.version;
            });
    }

    close() {
        this.dialogRef.close();
    }

    showTermsDialog() {
        this.dialog.open(TermsComponent, {
            width: '800px'
        });
    }
}
