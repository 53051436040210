import { createAction, props } from '@ngrx/store';
import { Tag } from './tags.models';

export const TagsActions = {
    fetchTags: createAction('[Tags] fetch tags'),
    fetchInstanceTags: createAction('[Tags] fetch instance tags'),
    fetchTagsSuccess: createAction(
        '[Tags] fetch tags success',
        props<{ tags: Tag[] }>()
    ),
    fetchTagsError: createAction(
        '[Tags] fetch tags error',
        props<{ error: string }>()
    ),
    createTag: createAction('[Tags] create tag', props<{ tagName: string }>()),
    createTagSuccess: createAction('`[Tags] create tag success'),
    createTagError: createAction('[Tags] create tag error'),
    editTag: createAction(
        '[Tags] edit tag',
        props<{ id: string; tagName: string }>()
    ),
    editTagSuccess: createAction('`[Tags] edit tag success'),
    editTagError: createAction('[Tags] edit tag error'),
    deleteTag: createAction('[Tags] delete tag', props<{ tagId: string }>()),
    deleteTagSuccess: createAction('[Tags] delete tag success'),
    deleteTagError: createAction(
        '[Tags] delete tag error',
        props<{ error: string }>()
    )
};
