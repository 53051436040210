import {
    Directive,
    Input,
    OnInit,
    ElementRef,
    HostListener,
    ViewContainerRef,
    TemplateRef
} from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    OverlayRef,
    Overlay,
    OverlayPositionBuilder
} from '@angular/cdk/overlay';

@Directive({ selector: '[customTooltip]' })
export class CustomTooltipDirective implements OnInit {
    @Input('customTooltip') tooltipContent: TemplateRef<any>;

    _overlayRef: OverlayRef;
    constructor(
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit(): void {
        if (this.tooltipContent) {
            const position = this.overlayPositionBuilder
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                        offsetX: 0,
                        offsetY: 8
                    },
                    {
                        originX: 'center',
                        originY: 'top',
                        overlayX: 'center',
                        overlayY: 'bottom',
                        offsetX: 0,
                        offsetY: -8
                    }
                ]);

            this._overlayRef = this.overlay.create({
                positionStrategy: position,
                scrollStrategy: this.overlay.scrollStrategies.close(),
                panelClass: 'custom-tooltip'
            });
        }
    }

    @HostListener('mouseenter')
    public _show(): void {
        if (this._overlayRef) {
            let containerPortal: TemplatePortal<any>;

            containerPortal = new TemplatePortal(
                this.tooltipContent,
                this.viewContainerRef
            );

            this._overlayRef.attach(containerPortal);
        }
    }

    @HostListener('mouseout')
    public _hide(): void {
        if (this._overlayRef) {
            this._overlayRef.detach();
        }
    }
}
