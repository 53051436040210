import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../core/core.state';
import { Store } from '@ngrx/store';
import { dashboardSelector } from '../../core/state/general/general.selectors';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'qcbi-title-dashboard',
    templateUrl: './title.component.html'
})
export class TitleDashboardComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    titleDashboard: Observable<any>;
    constructor(private store: Store<AppState>, public router: Router) {}

    ngOnInit(): void {
        this.store
            .select(dashboardSelector)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(d => {
                this.titleDashboard = d ? d.name : '';
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
