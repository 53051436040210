<ng-container [formGroup]="form">
  <mat-select
    class="select"
    formControlName="select"
    (focusin)="onFocusIn($event)"
    (focusout)="onFocusOut($event)"
  >
    <mat-option value="">Never</mat-option>
    <mat-option value="1d">1 day</mat-option>
    <mat-option value="1w">1 week</mat-option>
    <mat-option value="1m">1 month</mat-option>
    <mat-option value="sd">Specific date</mat-option>
  </mat-select>
  <ng-container *ngIf="form.value.select !== ''">
    <mat-divider [vertical]="true" class="divider"></mat-divider>
    <input  (focusin)="onFocusIn($event)"
            (focusout)="onFocusOut($event)"
            formControlName="date"
            matInput
            [min]="tomorrow"
            [matDatepicker]="picker"
            placeholder="Choose a date"
    >
    <mat-datepicker-toggle matSuffix [disabled]="disabled" [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </ng-container>
</ng-container>
