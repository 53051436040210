import {
    createAction,
    createReducer,
    props,
    on,
    Action,
    createSelector,
    createFeatureSelector,
    Store
} from '@ngrx/store';
import { StateFeatures, Widgets } from '../../../models/widgets';
import { Injectable } from '@angular/core';
import { WidgetService } from '../../../../services/widget.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState } from '../../../../core.state';
import { WidgetEffects } from '../../widget.effects';
import { ICMILarge } from '../../../models/widgets/large/compare-multiple-instances/cmi-large.model';
import { map, switchMap, withLatestFrom } from 'rxjs/internal/operators';
import { serviceIdSelector } from '../../../general/general.selectors';
import { SimpleInstanceLoadSuccessData } from '../../../general/general.models';
import { GeneralService } from '../../../general/general.service';
import { EMPTY, forkJoin } from 'rxjs';

type State = typeof Widgets.Large.CompareMultipleInstances.CMI;
enum StateConfig {
    Name = 'Widget - Compare Multiple Instances - Large',
    Key = 'cmi_lw'
}

const initialState: State = {
    isLoaded: false,
    loading: false,
    error: '',
    filter: {},
    data: {
        instancesToCompare: [
            { scan: {}, instance: {}, scans: [], widgetsData: {} }
        ]
    },
    cascadeFilters: {},
    tableFilter: {
        pageIndex: 0,
        pageSize: 10,
        total: undefined,
        sort: undefined,
        direction: undefined
    }
};

const stateActions = {
    load: createAction(
        `[${StateConfig.Name}] Load Details`,
        props<{ filter: any; tableFilter: any }>()
    ),
    error: createAction(
        `[${StateConfig.Name}] Load error`,
        props<{ error: string }>()
    ),
    success: createAction(
        `[${StateConfig.Name}] Load success`,
        props<{ data: { table: any[]; tableFilter?: any } }>()
    ),
    reset: createAction(`[${StateConfig.Name}] Reset`),
    saveCascadeFilters: createAction(
        `[${StateConfig.Name}] Save cascade filters`,
        props<{ data: any }>()
    ),
    addPanel: createAction(
        `[${StateConfig.Name}] Add Panel`,
        props<{ instanceId: string }>()
    ),
    removePanel: createAction(
        `[${StateConfig.Name}] Remove Panel`,
        props<{ index: number }>()
    ),
    removeAll: createAction(`[${StateConfig.Name}] Remove Panel`),
    selectInstance: createAction(
        `[${StateConfig.Name}] Select Instance`,
        props<{ instanceId: number; index: number }>()
    ),
    selectInstanceSuccess: createAction(
        `[${StateConfig.Name}] Select Instance success`,
        props<{ instance: any; scan: any; scans: any; index: number }>()
    ),
    selectedScanLoadSuccess: createAction(
        `[${StateConfig.Name}] Scan Load Success`,
        props<{ scan: any; index: number }>()
    ),
    selectedScanChange: createAction(
        `[${StateConfig.Name}] Scan Changed`,
        props<{ scan: any; index: number }>()
    ),
    selectedScanDataLoadSuccess: createAction(
        `[${StateConfig.Name}] Scan Data Load Success`,
        props<{ data: any; index: number }>()
    )
};

const reducers = createReducer(
    initialState,
    on(stateActions.load, (state, { filter }) => ({
        ...state,
        loading: true,
        error: '',
        filter
    })),
    on(stateActions.error, (state, { error }) => ({
        ...state,
        loading: false,
        error,
        isLoaded: true
    })),
    on(stateActions.success, (state, { data }) => {
        const { tableFilter, ...newData } = data;
        return {
            ...state,
            loading: false,
            isLoaded: true,
            data: {
                ...state.data,
                ...newData
            },
            tableFilter
        };
    }),
    on(stateActions.reset, () => ({
        ...initialState
    })),
    on(stateActions.addPanel, state => ({
        ...state,
        data: {
            ...state.data,
            instancesToCompare: [
                ...state.data.instancesToCompare,
                { scan: {}, instance: {}, scans: [], widgetsData: {} }
            ]
        }
    })),
    on(stateActions.removePanel, (state, { index }) => {
        const instancesToCompareCopy = [...state.data.instancesToCompare];
        instancesToCompareCopy.splice(index, 1);
        return {
            ...state,
            data: {
                ...state.data,
                instancesToCompare: instancesToCompareCopy
            }
        };
    }),
    on(stateActions.removeAll, state => {
        const instancesToCompareCopy = [...state.data.instancesToCompare];
        instancesToCompareCopy.splice(0, instancesToCompareCopy.length);
        return {
            ...state,
            data: {
                ...state.data,
                instancesToCompare: instancesToCompareCopy
            }
        };
    }),
    on(stateActions.selectInstance, (state, { instanceId, index }) => {
        const instancesToCompareCopy = [...state.data.instancesToCompare];
        instancesToCompareCopy[index] = {
            instance: { id: instanceId },
            scan: {},
            scans: [],
            widgetsData: { loading: true }
        };
        return {
            ...state,
            data: {
                ...state.data,
                instancesToCompare: instancesToCompareCopy
            }
        };
    }),
    on(
        stateActions.selectInstanceSuccess,
        (state, { instance, scan, scans, index }) => {
            const instancesToCompareCopy = [...state.data.instancesToCompare];
            instancesToCompareCopy[index] = {
                instance,
                scan,
                scans,
                widgetsData: { loading: true }
            };
            return {
                ...state,
                data: {
                    ...state.data,
                    instancesToCompare: instancesToCompareCopy
                }
            };
        }
    ),
    on(stateActions.selectedScanChange, (state, { index }) => {
        const instancesToCompareCopy = [...state.data.instancesToCompare];
        instancesToCompareCopy[index] = {
            ...instancesToCompareCopy[index],
            widgetsData: { loading: true }
        };
        return {
            ...state,
            data: {
                ...state.data,
                instancesToCompare: instancesToCompareCopy
            }
        };
    }),
    on(stateActions.selectedScanLoadSuccess, (state, { scan, index }) => {
        const instancesToCompareCopy = [...state.data.instancesToCompare];
        instancesToCompareCopy[index] = {
            ...instancesToCompareCopy[index],
            scan
        };
        return {
            ...state,
            data: {
                ...state.data,
                instancesToCompare: instancesToCompareCopy
            }
        };
    }),
    on(stateActions.selectedScanDataLoadSuccess, (state, { data, index }) => {
        const instancesToCompareCopy = [...state.data.instancesToCompare];
        instancesToCompareCopy[index] = {
            ...instancesToCompareCopy[index],
            widgetsData: data
        };
        return {
            ...state,
            data: {
                ...state.data,
                instancesToCompare: instancesToCompareCopy
            }
        };
    })
);

export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}

const stateSelector = createFeatureSelector<AppState, State>(StateConfig.Key);
const stateSelectors = {
    isLoaded: createSelector(stateSelector, (state: State) => state.isLoaded),
    loading: createSelector(stateSelector, (state: State) => state.loading),
    data: createSelector(stateSelector, (state: State) => state.data),
    error: createSelector(stateSelector, (state: State) => state.error),
    filter: createSelector(stateSelector, (state: State) => state.filter),
    cascadeFilters: createSelector(
        stateSelector,
        (state: State) => state.cascadeFilters
    ),
    tableFilter: createSelector(
        stateSelector,
        (state: State) => state.tableFilter
    )
};

@Injectable()
export class StateEffects extends WidgetEffects<ICMILarge> {
    constructor(
        public actions$: Actions,
        public service: WidgetService,
        public store: Store<AppState>,
        public generalService: GeneralService
    ) {
        super(
            actions$,
            service,
            store,
            stateActions,
            {
                cascadeFilters: [],
                loadLarge: {
                    key: 'cmi'
                }
            },
            stateSelector
        );
    }

    @Effect()
    _scanReset = this.scanReset;
    @Effect()
    _instanceReset = this.instanceReset;
    @Effect()
    _selectComparissionInstance = this.actions$.pipe(
        ofType(this.actions.selectInstance),
        withLatestFrom(this.store.select(serviceIdSelector)),
        switchMap(([{ instanceId, index }, serviceId]) => {
            return this.generalService
                .simpleInstanceLoad({
                    serviceId,
                    instanceId
                })
                .pipe(
                    map((data: SimpleInstanceLoadSuccessData) => {
                        return this.actions.selectInstanceSuccess({
                            instance: data.instance,
                            scans: data.scans,
                            scan: data.scan,
                            index
                        });
                    })
                );
        })
    );
    @Effect()
    _getScanInfo = this.actions$.pipe(
        ofType(
            this.actions.selectInstanceSuccess,
            this.actions.selectedScanChange
        ),
        switchMap(({ scan, index }) => {
            if (scan === undefined || scan === null) return EMPTY;
            return this.generalService.getScan(scan['short-id']).pipe(
                map(data => {
                    return this.actions.selectedScanLoadSuccess({
                        scan: data,
                        index
                    });
                })
            );
        })
    );

    @Effect()
    _getScanData = this.actions$.pipe(
        ofType(
            this.actions.selectInstanceSuccess,
            this.actions.selectedScanChange
        ),
        switchMap(({ scan, index, instance }) => {
            if (scan === undefined || scan === null) return EMPTY;
            if (
                (instance && +instance.serviceId === 1) ||
                +scan.serviceId === 1
            ) {
                return forkJoin([
                    this.service.load(
                        'issues-over-time-compact',
                        scan['short-id']
                    ),
                    this.service.load('tech-debt-compact', scan['short-id']),
                    this.service.load('quality-of-cloud', scan['short-id']),
                    this.service.load(
                        'config-elem-extracompact',
                        scan['short-id']
                    ),
                    this.service.load(
                        'lines-code-extracompact',
                        scan['short-id']
                    ),
                    this.service.load('ootb-modifications', scan['short-id']),
                    this.service.load('integrations-compact', scan['short-id']),
                    this.service.load(
                        'total-namespaces-compact',
                        scan['short-id'],
                        { providerId: 1 }
                    ),
                    this.service.load('number-of-bps', scan['short-id'])
                ]).pipe(
                    map(data => {
                        if (data[0] && data[0].numIssuesByGroupType) {
                            data[0].numIssuesByGroupType = data[0].numIssuesByGroupType.filter(
                                (item: any) => {
                                    return (
                                        item &&
                                        item.label &&
                                        item.label.toLowerCase() !==
                                            'data privacy' &&
                                        item.label.toLowerCase() !== 'custom'
                                    );
                                }
                            );
                        }
                        return this.actions.selectedScanDataLoadSuccess({
                            data: {
                                iot: data[0],
                                td: data[1],
                                qoc: data[2],
                                ce: data[3],
                                locs: data[4],
                                ootb: data[5],
                                int: data[6],
                                tan: data[7],
                                nobp: data[8]
                            },
                            index
                        });
                    })
                );
            } else if (
                (instance && +instance.serviceId === 2) ||
                +scan.serviceId === 2
            ) {
                return forkJoin([
                    this.service.load(
                        'issues-over-time-compact',
                        scan['short-id']
                    ),
                    this.service.load('tech-debt-compact', scan['short-id']),
                    this.service.load('quality-of-cloud', scan['short-id']),
                    this.service.load(
                        'config-elem-extracompact',
                        scan['short-id']
                    ),
                    this.service.load(
                        'lines-code-extracompact',
                        scan['short-id']
                    ),
                    this.service.load(
                        'total-namespaces-compact',
                        scan['short-id'],
                        { providerId: 2 }
                    ),
                    this.service.load('number-of-bps', scan['short-id'])
                ]).pipe(
                    map(data => {
                        return this.actions.selectedScanDataLoadSuccess({
                            data: {
                                iot: data[0],
                                td: data[1],
                                qoc: data[2],
                                ce: data[3],
                                locs: data[4],
                                tan: data[5],
                                nobp: data[6]
                            },
                            index
                        });
                    })
                );
            } else {
                return forkJoin([
                    this.service.load(
                        'issues-over-time-compact',
                        scan['short-id']
                    ),
                    this.service.load('tech-debt-compact', scan['short-id']),
                    this.service.load('quality-of-cloud', scan['short-id']),
                    this.service.load(
                        'config-elem-extracompact',
                        scan['short-id']
                    ),
                    this.service.load(
                        'lines-code-extracompact',
                        scan['short-id']
                    ),
                    this.service.load('number-of-bps', scan['short-id'])
                ]).pipe(
                    map(data => {
                        return this.actions.selectedScanDataLoadSuccess({
                            data: {
                                iot: data[0],
                                td: data[1],
                                qoc: data[2],
                                ce: data[3],
                                locs: data[4],
                                nobp: data[5]
                            },
                            index
                        });
                    })
                );
            }
        })
    );
}

export const stateFeatures: StateFeatures = {
    config: StateConfig,
    actions: stateActions,
    selectors: stateSelectors
};
