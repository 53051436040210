import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
    filter,
    map,
    shareReplay,
    startWith,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';
import { AppState } from '../../../../../../core/core.state';
import {
    currentInstanceHasSmartTags,
    currentInstanceHasTeams,
    hasQualityGatesSelector,
    instanceIdSelector,
    instanceSelector,
    instanceServiceIdSelector
} from '../../../../../../core/state/general/general.selectors';
import { Store } from '@ngrx/store';
import {
    DRAWER_DATA,
    DrawerService
} from '../../../../../../shared/drawer/drawer.service';
import { IssuesService } from '../../../../../../core/services/issues.service';
import { IssueModel } from '../../../../../../models/issue.model';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { bulkActions } from '../../../../../../core/state/widgets/large/debt-manager-new/bulk.actions';

/**
 * debt manager drawer component is responsible for getting the issue and layout
 * the issue information
 */
@Component({
    selector: 'qcbi-debt-manager-drawer-content',
    templateUrl: './drawer-content.component.html',
    styleUrls: ['./drawer-content.component.scss']
})
export class DebtManagerDrawerContentComponent implements OnInit {
    userName = '';

    item$: Observable<IssueModel>;
    instanceId$: Observable<number>;
    serviceId$: Observable<number>;
    hasQualityGateEnabled$: Observable<boolean>;
    hasTeams$: Observable<boolean>;
    routerUrl$: Observable<string>;
    hasAssignTeams$: Observable<boolean>;

    private unsubscribe$ = new Subject();

    constructor(
        @Inject(DRAWER_DATA) public data: any,
        private store: Store<AppState>,
        private issueService: IssuesService,
        private router: Router,
        private actions$: Actions,
        private drawerService: DrawerService
    ) {}

    ngOnInit() {
        this.serviceId$ = this.store.select(instanceServiceIdSelector);
        this.instanceId$ = this.store.select(instanceIdSelector);
        this.item$ = combineLatest([
            this.instanceId$,
            this.actions$.pipe(
                ofType(bulkActions.bulkSuccess, bulkActions.bulkError),
                startWith(null)
            )
        ]).pipe(
            switchMap(([instanceId]) => {
                return this.issueService
                    .getIssueByIdAndInstance(this.data.issueId, instanceId)
                    .pipe(
                        tap(item => {
                            this.drawerService.title = item.issueType;
                        }),
                        map(issues => {
                            const item = issues;
                            item.spotted = item.spotted.map(el => ({
                                ...el,
                                isWrittenOff:
                                    el.status === 'WRITTENOFF' ? 'Yes' : 'No'
                            }));
                            return item;
                        })
                    );
            })
        );
        this.hasQualityGateEnabled$ = this.store.select(
            hasQualityGatesSelector
        );
        this.hasTeams$ = this.store.select(currentInstanceHasTeams);
        this.routerUrl$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.router.url),
            startWith(this.router.url)
        );
        this.hasAssignTeams$ = combineLatest([
            this.hasTeams$,
            this.routerUrl$
        ]).pipe(
            map(([hasTeams, url]) => {
                return hasTeams && url.includes('debt-manager-new');
            }),
            shareReplay(1)
        );
    }

    openLink(link: string) {
        window.open(link, '_blank');
    }
}
