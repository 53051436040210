import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../core.state';
import { Notification, NotificationsState } from './notifications.models';

export const notificationsFeatureSelector = createFeatureSelector<
    AppState,
    NotificationsState
>('notifications');

export const notificationsSelector = createSelector(
    notificationsFeatureSelector,
    (state: NotificationsState): Notification[] => state.notifications
);

export const unreadNotificationsSelector = createSelector(
    notificationsFeatureSelector,
    (state: NotificationsState): Notification[] =>
        state.detailedUnreadNotifications
);

export const readNotificationsSelector = createSelector(
    notificationsFeatureSelector,
    (state: NotificationsState): Notification[] =>
        state.detailedReadNotifications
);

export const notificationsTotalItemsSelector = createSelector(
    notificationsFeatureSelector,
    (state: NotificationsState): number => state.totalItems
);

export const notificationsCounterSelector = createSelector(
    notificationsFeatureSelector,
    (state: NotificationsState): string | undefined => {
        if (state.unreadNotifications === 0) return undefined;
        if (state.unreadNotifications >= 99) return '99+';
        return state.unreadNotifications.toString();
    }
);

export const notificationsLoadingSelector = createSelector(
    notificationsFeatureSelector,
    (state: NotificationsState): boolean => state.loading
);

export const readNotificationsLoadingSelector = createSelector(
    notificationsFeatureSelector,
    (state: NotificationsState): boolean => state.detailedReadLoading
);

export const unreadNotificationsLoadingSelector = createSelector(
    notificationsFeatureSelector,
    (state: NotificationsState): boolean => state.detailedUnreadLoading
);

export const unreadNotificationsLoadMoreSelector = createSelector(
    notificationsFeatureSelector,
    (state: NotificationsState): boolean =>
        state.detailedUnreadNotifications.length > 0 &&
        state.detailedUnreadNotifications.length < state.detailedUnreadTotal
);

export const readNotificationsLoadMoreSelector = createSelector(
    notificationsFeatureSelector,
    (state: NotificationsState): boolean =>
        state.detailedReadNotifications.length > 0 &&
        state.detailedReadNotifications.length < state.detailedReadTotal
);

export const notificationsLatestNotification = createSelector(
    notificationsFeatureSelector,
    (state: NotificationsState): Notification =>
        state.notifications.length > 0 ? state.notifications[0] : undefined
);
