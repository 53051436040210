import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ChangeDetectionStrategy
} from '@angular/core';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnChanges {
    @Input() user;
    //@Input() surname: string;
    @Input() size: number;

    //variabels
    tamano = 0;
    usuario;
    bgcolor = '#28a745';
    initials = 'QC';

    constructor() {}

    async ngOnChanges(changes: SimpleChanges) {
        this.tamano = this.size;
        if (
            this.user != null &&
            this.user != undefined &&
            this.user.firstName
        ) {
            this.initials = this.getInitials();
        }
    }

    //Generate initials from name
    getInitials() {
        let usuario = Object.assign({}, this.user);
        let firstLetter;
        let secondLetter;
        let initials;

        firstLetter = usuario.firstName.slice(0, 1).toUpperCase();
        if (this.user.lastName) {
            secondLetter = usuario.lastName.slice(0, 1).toUpperCase();
        } else {
            secondLetter = usuario.firstName.slice(1, 2).toUpperCase();
        }
        initials = firstLetter + secondLetter;

        this.bgcolor = this.stringToColour();
        return initials;
    }

    //Generate color from name
    stringToColour() {
        var str = (this.user.firstName + this.user.lastName).toString();
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xff;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }
}
