import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../core/core.state';
import { TwoFactorAuthenticationState } from './two-factor-authentication.models';

export const twoFactorAuthenticationSelector = createFeatureSelector<
    AppState,
    TwoFactorAuthenticationState
>('twoFactorAuthentication');

export const qrSecretSelector = createSelector(
    twoFactorAuthenticationSelector,
    (state: TwoFactorAuthenticationState) => state.secret
);

export const qrUriSelector = createSelector(
    twoFactorAuthenticationSelector,
    (state: TwoFactorAuthenticationState) => state.uri
);

export const codeStatusSelector = createSelector(
    twoFactorAuthenticationSelector,
    (state: TwoFactorAuthenticationState) => state.codeStatus
);

export const codeStatusMessageSelector = createSelector(
    twoFactorAuthenticationSelector,
    (state: TwoFactorAuthenticationState) => state.codeStatusMsg
);

export const statusSelector = createSelector(
    twoFactorAuthenticationSelector,
    (state: TwoFactorAuthenticationState) => state.status
);
