<header class="flex justify-between mb-2 container-fluid mt-3">
    <div class="text-base text-secondary-500">
        <span class="breadcrumb">
            {{ breadcrumbs$ | async }}
        </span>
        <a
            target="_blank"
            [href]="helpUrl$ | async"
            role="button"
            aria-label="click to view dashboard info"
        >
            <mat-icon
                class="mat-info-icon"
                matTooltip="Click to view dashboard info"
            >
                info
            </mat-icon>
        </a>

    </div>
    <div class="flex">
        <div class="flex items-center -mx-2"
             *ngIf="(showScansSelect$ | async) && (dashboard$ | async)?.url !== 'peer-review' && router.url !== '/notifications'">
            <div class="flex px-1 pt-1 text-xs">
                <div class="text-secondary-500 mr-2" id="select-date-select">Select Date</div>
                <mat-form-field style="margin-top: -12px; width: 152px;">
                    <mat-select
                        [value]="scanId$ | async"
                        aria-labelledby="select-date-select"
                        name="current-scan"
                        (selectionChange)="handleScanChange($event)"
                    >
                        <mat-option
                            *ngFor="let scan of selectableScans; let i = index"
                            [value]="scan.value">
                            {{
                                scan.label | date:(timeFormat === undefined ||
                                timeFormat === null ||
                                timeFormat === 'EU'
                                    ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm') : '+0000'
                            }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="flex items-center -mx-2"
             *ngIf="(showDateSelect$ | async) && router.url !== '/notifications'">
            <div class="flex px-4 text-xs">
                <div [formGroup]="form">
                    <mat-form-field style="width: 136px">
                        <mat-select
                            placeholder="select a range"
                            (selectionChange)="handleDateDropDownChange($event)"
                            [(ngModel)]="dateDropDown"
                            formControlName="dateRangeSelector"
                            aria-label="date options"
                        >
                            <mat-option
                                *ngFor="let option of dateOptions"
                                [value]="option.value"
                            >
                                {{ option.label | titlecase }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex px-4 text-xs">
                <mat-form-field>
                    <input matInput
                           placeholder="Choose a date"
                           [satDatepicker]="picker3"
                           [value]="selectedDateRange"
                           (dateChange)="handleDateChange($event)"
                           [max]="TODAY"
                           aria-label="date select"
                    >
                    <sat-datepicker #picker3 [rangeMode]="true">
                    </sat-datepicker>
                    <sat-datepicker-toggle matSuffix [for]="picker3"></sat-datepicker-toggle>
                </mat-form-field>
            </div>
        </div>
        <ng-container *ngIf="(dashboard$ | async) && (dashboard$ | async).url === 'executive'
      && (dashboard$ | async)['is-custom-bi'] && router.url !== '/notifications'">
            <qcbi-main-filter></qcbi-main-filter>
            <qcbi-baseline-toggle></qcbi-baseline-toggle>
        </ng-container>
    </div>
</header>
