import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ActivityTrackingService } from './activity-tracking.service';
import { AppState } from '../../core.state';
import { Store } from '@ngrx/store';
import { ActivityTrackingActions } from './activity-tracking.actions';
import { map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
    dashboardSelector,
    instanceIdSelector
} from '../general/general.selectors';
import { GeneralActions } from '../general/general.actions';
import { selectOnEmulation } from '../../../core/auth-lib';
import { Subject } from 'rxjs';

@Injectable()
export class ActivityTrackingEffects {
    unsubscribe = new Subject<any>();

    constructor(
        private action$: Actions,
        private service: ActivityTrackingService,
        private store: Store<AppState>
    ) {}

    @Effect()
    notifyDashboardAccess = this.action$.pipe(
        ofType(GeneralActions.setDashboard, GeneralActions.instanceLoadSuccess),
        withLatestFrom(this.store.select(instanceIdSelector)),
        withLatestFrom(this.store.select(dashboardSelector)),
        switchMap(([[{}, instanceId], dashboard]) => {
            let onEmulation = false;
            this.store
                .select(selectOnEmulation)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(res => {
                    onEmulation = res;
                });

            return this.service
                .notifyDashboardAccess(instanceId, dashboard.id, onEmulation)
                .pipe(
                    map(() =>
                        ActivityTrackingActions.notifyDashboardAccessSuccess()
                    )
                );
        })
    );

    @Effect()
    notifyDocumentationAccess = this.action$.pipe(
        ofType(ActivityTrackingActions.notifyDocumentationAccess),
        withLatestFrom(this.store.select(instanceIdSelector)),
        withLatestFrom(this.store.select(dashboardSelector)),
        switchMap(([[{ issueId }, instanceId], dashboard]) => {
            return this.service
                .notifyDocumentationAccess(instanceId, dashboard.id, issueId)
                .pipe(
                    map(() =>
                        ActivityTrackingActions.notifyDocumentationAccessSuccess()
                    )
                );
        })
    );

    @Effect()
    notifyLinkAccess = this.action$.pipe(
        ofType(ActivityTrackingActions.notifyLinkAccess),
        withLatestFrom(this.store.select(instanceIdSelector)),
        withLatestFrom(this.store.select(dashboardSelector)),
        switchMap(([[{ elementId, elementType }, instanceId], dashboard]) => {
            return this.service
                .notifyCeLinkAcess(
                    instanceId,
                    dashboard.id,
                    elementId,
                    elementType
                )
                .pipe(
                    map(() => ActivityTrackingActions.notifyLinkAccessSuccess())
                );
        })
    );
}
