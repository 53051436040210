import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopUpSelectDialogComponent } from './pop-up-select-dialog/pop-up-select-dialog.component';
import { PopUpSelectComponent } from './pop-up-select/pop-up-select.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ServiceDependentLabelsModule } from '../../pipes/service-dependent-labels/service-dependent-labels.module';
import { A11yModule } from '@angular/cdk/a11y';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScrollDirective } from './pop-up-select-dialog/scroll.directive';
import { SelectOptionComponent } from './pop-up-select-dialog/select-option.component';

@NgModule({
    imports: [
        CommonModule,
        MatSelectModule,
        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        MatDialogModule,
        MatButtonModule,
        ServiceDependentLabelsModule,
        A11yModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        FormsModule
    ],
    declarations: [
        PopUpSelectDialogComponent,
        PopUpSelectComponent,
        ScrollDirective,
        SelectOptionComponent
    ],
    exports: [PopUpSelectDialogComponent, PopUpSelectComponent]
})
export class MatPopUpSelectModule {}
