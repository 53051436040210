import { createAction, props } from '@ngrx/store';

const ACTIVITY_TRACKING_REDUX_NAME = 'Activity Tracking';

export const ActivityTrackingActions = {
    notifyDashboardAccess: createAction(
        `[${ACTIVITY_TRACKING_REDUX_NAME}] notify Dashboard Access`
    ),
    notifyDashboardAccessSuccess: createAction(
        `[${ACTIVITY_TRACKING_REDUX_NAME}] notify Dashboard Access Success`
    ),
    notifyDocumentationAccess: createAction(
        `[${ACTIVITY_TRACKING_REDUX_NAME}] notify Documentation Access`,
        props<{ issueId: string }>()
    ),
    notifyDocumentationAccessSuccess: createAction(
        `[${ACTIVITY_TRACKING_REDUX_NAME}] notify Documentation Access Success`
    ),
    notifyLinkAccess: createAction(
        `[${ACTIVITY_TRACKING_REDUX_NAME}] notify Link Access`,
        props<{ elementId: string; elementType: 'ISSUE' | 'CE' }>()
    ),
    notifyLinkAccessSuccess: createAction(
        `[${ACTIVITY_TRACKING_REDUX_NAME}] notify LinkAccess Success`
    )
};
