<div class="header-wrapper">
  <div class="header">
    <img src="./assets/logos/black-logo.png" alt="Quality Clouds Logo">
    <div class="two-factor-container">
      <em class="las la-shield-alt mr-1 logo"></em>
      <div>Your 2FA Key</div>
    </div>
  </div>
</div>
<div class="content">
  <div class="flex justify-center" *ngIf="!(qrSecret$ | async); else codeContainer">
    <mat-spinner color="primary" diameter="20"></mat-spinner>
  </div>
  <ng-template #codeContainer>
    {{ qrSecret$ | async }}
  </ng-template>
  <div class="actions">
    <button mat-raised-button (click)="closeDialog()" color="primary">Close</button>
  </div>
</div>
