export const SERVICENOW_ID = 1;
export const SALESFORCE_ID = 2;
export const OFFICE_ID = 3;

export const ROLES = {
    QC_ADMIN: 1,
    CUSTOMER_ADMIN: 2
};

export const SEVERITIES = {
    WARNING: '4',
    LOW: '3',
    MEDIUM: '2',
    HIGH: '1'
};
