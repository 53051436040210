import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AppState } from '../../../../core/core.state';
import { Store } from '@ngrx/store';
import { TwoFactorAuthenticationActions } from '../../two-factor-authentication.actions';
import { Observable, Subject } from 'rxjs';
import {
    codeStatusMessageSelector,
    codeStatusSelector,
    qrUriSelector,
    statusSelector
} from '../../two-factor-authentication.selectors';
import { takeUntil } from 'rxjs/operators';
import { CodeDialogComponent } from '../code-dialog/code-dialog.component';

@Component({
    selector: 'qcbi-two-factor-authentication-dialog',
    templateUrl: 'two-factor-authentication-dialog.component.html',
    styleUrls: ['two-factor-authentication-dialog.component.scss']
})
export class TwoFactorAuthenticationDialogComponent
    implements OnInit, OnDestroy {
    qrUri$: Observable<string>;
    codeStatus$: Observable<string>;
    codeStatusMessage$: Observable<string>;
    enabled = false;
    code;
    mfaStatus;

    private unsubscribe$ = new Subject();

    constructor(
        public dialogRef: MatDialogRef<TwoFactorAuthenticationDialogComponent>,
        private store: Store<AppState>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: { showInfo?: boolean }
    ) {}

    ngOnInit() {
        this.qrUri$ = this.store
            .select(qrUriSelector)
            .pipe(takeUntil(this.unsubscribe$));
        this.codeStatus$ = this.store
            .select(codeStatusSelector)
            .pipe(takeUntil(this.unsubscribe$));
        this.codeStatusMessage$ = this.store
            .select(codeStatusMessageSelector)
            .pipe(takeUntil(this.unsubscribe$));
        this.store
            .select(statusSelector)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                this.mfaStatus = res;
                this.enabled = res !== 'DISABLED';
                if (this.mfaStatus === 'ENABLED') {
                    this.store.dispatch(
                        TwoFactorAuthenticationActions.fetchQrData()
                    );
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * handler for code check. it dispatches a validate code action
     * @param event specified code
     */
    checkCode(event) {
        this.store.dispatch(
            TwoFactorAuthenticationActions.validateCode({ code: event })
        );
    }

    /**
     * closes the dialog
     */
    closeDialog() {
        this.dialogRef.close();
    }

    /**
     * opens a code dialog
     */
    openCodeDialog() {
        this.dialog.open(CodeDialogComponent, {
            width: '500px'
        });
    }

    /**
     * slider change handler.
     * when checked dispatches a qr fetch action.
     * when unchecked dispatches an disable mfa action
     * @param event MatSlideToggleChange event, it gets event info of the toggle
     */
    sliderChangeHandler(event: MatSlideToggleChange) {
        if (event.checked) {
            this.store.dispatch(TwoFactorAuthenticationActions.fetchQrData());
        } else {
            this.store.dispatch(TwoFactorAuthenticationActions.disableMfa());
        }
    }
}
