import {
    createReducer,
    on,
    Action,
    createAction,
    props,
    createSelector,
    createFeatureSelector,
    Store
} from '@ngrx/store';
import { StateFeatures, Widgets } from '../../../models/widgets';
import { Injectable } from '@angular/core';
import { WidgetService } from '../../../../services/widget.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState } from '../../../../core.state';
import { WidgetEffects } from '../../widget.effects';
import { ICustomizationRatioLarge } from '../../../models/widgets/large/customization-vs-configuration/cr.model';
import {
    customerIdSelector,
    filtersSelector,
    instanceSelector,
    scanIdSelector,
    scanLongIdSelector,
    selectedDateRangeSelector,
    serviceIdSelector,
    showDateSelect
} from '../../../general/general.selectors';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { getYYYYMMDDfromDate } from '../../../../../utils/general';
import { successStateWithoutTableFilter } from '../../../../../utils/reducer-fn';

type State = typeof Widgets.Large.CustomizationVsConfiguration.CR;
enum StateConfig {
    Name = 'Widget - Customization vs Configuration - Overview - Customization Ratio large',
    Key = 'cvsc_lw_cr'
}
// First storage state object
const initialState: State = {
    isLoaded: false,
    loading: false,
    error: '',
    filter: {
        onlyChanges: true
    },
    data: {
        chart0: [],
        chart1: [],
        data: []
    },
    cascadeFilters: {
        auditElement: [],
        cetypesnames: [],
        typeOfChange: [],
        ootbModifications: []
    },
    tableFilter: {
        pageIndex: 0,
        pageSize: 10,
        total: undefined,
        sort: undefined,
        direction: undefined
    }
};

/*
 *  Actions express unique events that happen throughout your application
 */
const stateActions = {
    load: createAction(
        `[${StateConfig.Name}] Load`,
        props<{ filter: any; tableFilter: any }>()
    ),
    error: createAction(
        `[${StateConfig.Name}] Load error`,
        props<{ error: string }>()
    ),
    success: createAction(
        `[${StateConfig.Name}] Load success`,
        props<{ data: ICustomizationRatioLarge }>()
    ),
    reset: createAction(`[${StateConfig.Name}] Reset`),
    saveCascadeFilters: createAction(
        `[${StateConfig.Name}] Save cascade filters`,
        props<{ data: any }>()
    )
};

/*
 * Reducer functions handle these transitions by determining which actions to
 * handle based on the action's type
 */
const reducers = createReducer(
    initialState,
    on(stateActions.load, (state, { filter }) => ({
        ...state,
        loading: true,
        error: '',
        filter
    })),
    on(stateActions.error, (state, { error }) => ({
        ...state,
        loading: false,
        error,
        isLoaded: true
    })),
    on(stateActions.success, successStateWithoutTableFilter),
    on(stateActions.reset, () => ({
        ...initialState
    })),
    on(stateActions.saveCascadeFilters, (state, { data }) => ({
        ...state,
        cascadeFilters: data
    }))
);

export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}

/*
 * Selectors are pure functions used for obtaining slices of store state.
 */
const stateSelector = createFeatureSelector<AppState, State>(StateConfig.Key);
const stateSelectors = {
    isLoaded: createSelector(stateSelector, (state: State) => state.isLoaded),
    loading: createSelector(stateSelector, (state: State) => state.loading),
    data: createSelector(stateSelector, (state: State) => state.data),
    error: createSelector(stateSelector, (state: State) => state.error),
    filter: createSelector(stateSelector, (state: State) => state.filter),
    cascadeFilters: createSelector(
        stateSelector,
        (state: State) => state.cascadeFilters
    ),
    tableFilter: createSelector(
        stateSelector,
        (state: State) => state.tableFilter
    )
};

/*
 * Effects use streams to provide new sources of actions to reduce state
 * based on external interactions such as network requests, web socket messages
 * and time-based events.
 */
@Injectable()
export class StateEffects extends WidgetEffects<ICustomizationRatioLarge> {
    constructor(
        public actions$: Actions,
        public service: WidgetService,
        public store: Store<AppState>
    ) {
        super(
            actions$,
            service,
            store,
            stateActions,
            {
                cascadeFilters: [
                    'auditElement',
                    'cetypesnames',
                    'typeOfChange',
                    'ootbModifications'
                ],
                loadLarge: {
                    key: 'cvsc-cr'
                }
            },
            stateSelector
        );
    }

    @Effect()
    _load = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(this.store.select(serviceIdSelector)),
        withLatestFrom(this.store.select(instanceSelector)),
        withLatestFrom(this.store.select(scanIdSelector)),
        withLatestFrom(this.store.select(scanLongIdSelector)),
        withLatestFrom(this.store.select(showDateSelect)),
        withLatestFrom(this.store.select(selectedDateRangeSelector)),
        withLatestFrom(this.store.select(customerIdSelector)),
        withLatestFrom(this.store.select(filtersSelector)),
        switchMap(
            ([
                [
                    [
                        [
                            [
                                [
                                    [
                                        [{ filter, tableFilter }, serviceId],
                                        instance
                                    ],
                                    scanId
                                ],
                                scanLongId
                            ],
                            isDateDashboard
                        ],
                        dateRange
                    ],
                    customerId
                ],
                filters
            ]) => {
                const filterToService = { ...filter };
                let defaultInitialDate;
                if (isDateDashboard && dateRange === null) {
                    defaultInitialDate = this.getInitialDate();
                }
                if (filterToService.hasOwnProperty('cetypesnames')) {
                    filterToService[
                        'cetypesnames'
                    ] = filters.cetypesnames
                        .filter(cetn =>
                            filterToService['cetypesnames'].includes(cetn.value)
                        )
                        .map(item => item.label);
                }
                return this.service
                    .loadLarge(this.params.loadLarge, {
                        serviceId,
                        instanceId: instance ? instance.id : null,
                        scanId,
                        scanLongId,
                        filter: filterToService,
                        tableFilter,
                        timeFilter: isDateDashboard
                            ? {
                                  begin: getYYYYMMDDfromDate(
                                      dateRange
                                          ? dateRange.begin
                                          : defaultInitialDate.begin
                                  ),
                                  end: getYYYYMMDDfromDate(
                                      dateRange
                                          ? dateRange.end
                                          : defaultInitialDate.end
                                  )
                              }
                            : undefined,
                        customerId,
                        providerId: instance ? instance.serviceId : null
                    })
                    .pipe(
                        map((data: any) => {
                            return this.actions.success({ data });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                this.actions.error({ error: error.message })
                            );
                        })
                    );
            }
        )
    );
    @Effect()
    _cascadeFilters = this.loadCascadeFilters;
    @Effect()
    _scanReset = this.scanReset;
    @Effect()
    _instanceReset = this.instanceReset;
}

export const stateFeatures: StateFeatures = {
    config: StateConfig,
    actions: stateActions,
    selectors: stateSelectors
};
