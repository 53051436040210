import { Pipe, PipeTransform } from '@angular/core';
import { SelectableTeam } from '../../models/selectable-team.model';

@Pipe({
    name: 'teamsFilter'
})
export class TeamsFilterPipe implements PipeTransform {
    transform(value: SelectableTeam[], arg: string): any {
        return value.filter((item: SelectableTeam) =>
            item.name.toLowerCase().includes(arg.toLowerCase())
        );
    }
}
