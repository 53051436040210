import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppState } from '../../core/core.state';
import { Store } from '@ngrx/store';
import {
    notificationsCounterSelector,
    notificationsLatestNotification,
    notificationsLoadingSelector,
    notificationsSelector,
    notificationsTotalItemsSelector
} from '../../core/state/notifications/notifications.selectors';
import { NotificationsActions } from '../../core/state/notifications/notifications.actions';
import { Notification } from '../../core/state/notifications/notifications.models';
import { first, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { instancesSelector } from '../../core/state/general/general.selectors';
import { GeneralService } from '../../core/state/general/general.service';

const NOTIFICATIONS_FETCH_TIME = 5 * 60 * 1000; // 5 minutes

@Component({
    selector: 'qcbi-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @Output() notificationClicked = new EventEmitter<Notification>();

    notifications$: Observable<Notification[]>;
    counter$: Observable<string | undefined>;
    loading$: Observable<boolean>;
    totalItems = 0;
    timer;

    unsubscribe$ = new Subject();

    hasSfAccess = false;
    hasSnowAccess = false;
    instances;

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private generalService: GeneralService
    ) {}

    ngOnInit(): void {
        this.store.dispatch(NotificationsActions.fetchNotifications({}));
        this.timer = setInterval(() => {
            this.store
                .select(notificationsLatestNotification)
                .pipe(first())
                .subscribe((res: Notification) => {
                    if (res)
                        this.store.dispatch(
                            NotificationsActions.fetchNotifications({
                                date: res.date
                            })
                        );
                });
        }, NOTIFICATIONS_FETCH_TIME);
        this.notifications$ = this.store.select(notificationsSelector);
        this.counter$ = this.store.select(notificationsCounterSelector);
        this.loading$ = this.store.select(notificationsLoadingSelector);
        this.store
            .select(notificationsTotalItemsSelector)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => (this.totalItems = res));
        this.store
            .select(instancesSelector)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                this.instances = res;
                if (res.salesForce.length) {
                    this.generalService
                        .getDashboards(res.salesForce[0].id)
                        .pipe(first())
                        .subscribe(response => {
                            this.hasSfAccess =
                                response.find(
                                    d =>
                                        d.url === 'executive' &&
                                        d['is-available-for-user']
                                ) !== undefined;
                        });
                }
                if (res.serviceNow.length) {
                    this.generalService
                        .getDashboards(res.serviceNow[0].id)
                        .pipe(first())
                        .subscribe(response => {
                            this.hasSnowAccess =
                                response.find(
                                    d =>
                                        d.url === 'executive' &&
                                        d['is-available-for-user']
                                ) !== undefined;
                        });
                }
            });
    }

    ngOnDestroy() {
        clearInterval(this.timer);
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    markAllNotificationsAsRead() {
        this.store.dispatch(NotificationsActions.markAllNotificationsAsRead());
    }

    markNotificationAsRead(notification: Notification, notificationId: string) {
        if (!notification.isRead) {
            this.store.dispatch(
                NotificationsActions.markNotificationAsRead({ notificationId })
            );
        }
    }

    checkAccess(instanceId) {
        let inst;
        inst = this.instances.salesForce.find(i => +i.id === +instanceId);
        if (!inst)
            inst = this.instances.serviceNow.find(i => +i.id === +instanceId);
        if (!inst) return false;
        if (+inst.attributes['service-id'] === 2) return this.hasSfAccess;
        if (+inst.attributes['service-id'] === 1) return this.hasSnowAccess;
        return false;
    }

    notificationClickedHandler(notification: Notification) {
        if (this.checkAccess(notification.instanceId))
            this.notificationClicked.emit(notification);
    }

    scrollHandler(event, lastItem: Notification) {
        if (
            event.target.offsetHeight + event.target.scrollTop >=
            event.target.scrollHeight - 1
        ) {
            this.store
                .select(notificationsLatestNotification)
                .pipe(first())
                .subscribe((res: Notification) => {
                    this.store.dispatch(
                        NotificationsActions.getNextPage({
                            lastNotificationDate: lastItem.date,
                            firstNotificationDate: res.date
                        })
                    );
                });
        }
    }

    stopPropagation(event) {
        event.stopPropagation();
    }
}
