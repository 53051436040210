import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetClassToggleDirective } from './widget-class-toggle.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [WidgetClassToggleDirective],
    exports: [WidgetClassToggleDirective]
})
export class WidgetClassToggleModule {}
