<h1 mat-dialog-title>Manage your Tags</h1>
<div mat-dialog-content>
  <table style="width:100%" *ngIf="tags$ | async as catalog;">
    <tr>
      <th>Tag</th>
      <th>Actions</th>
    </tr>
    <tr *ngFor="let item of catalog">
      <td class="p-1">
        <mat-chip-list aria-label="Tags">
          <mat-chip
            [ngStyle]="{
                    'background-color': item.bgColor,
                    'color': item.textColor,
                    'padding': '8px',
                    'border-radius': '5px'
                }"
            class="chip-tag"
          >
            {{ item.name }}
          </mat-chip>
        </mat-chip-list>
      </td>
      <td class="p-1">
        <button mat-icon-button matTooltip="Edit Tag" (click)="editTag(item)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Delete Label" (click)="deleteTag(item.id)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </tr>
  </table>

</div>
<div mat-dialog-actions class="flex justify-center">
  <button mat-button mat-dialog-close>Close</button>
</div>
