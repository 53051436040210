import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainFilterComponent } from './main-filter.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [CommonModule, MatSelectModule, MatTooltipModule],
    declarations: [MainFilterComponent],
    exports: [MainFilterComponent]
})
export class MainFilterModule {}
