import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';
import { Table, TablePageEvent } from '../../abstracts/table.component';
import { NotificationService } from '../../core/notifications/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import {
    dashboardsSelector,
    instanceSelector
} from '../../core/state/general/general.selectors';
import { first } from 'rxjs/operators';
import { GeneralActions } from '../../core/state/general/general.actions';
import { SortDirection } from '@angular/material/sort/sort-direction';
import { ColorService } from '../../core/services/color.service';

export type PagerOptions = {
    pageIndex: number;
    pageSize: number;
    from: number;
    to: number;
    total: number;
    lastPage: number;
};

export type SortOptions = {
    sort: string;
    direction: SortDirection;
};

@Component({
    selector: 'qcbi-server-table',
    templateUrl: './table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    // TODO: move this to the abstract class with an animation builder
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            )
        ])
    ]
})
export class ServerTableComponent extends Table {
    @Input() loading: boolean;
    @Output() handleChange = new EventEmitter<TablePageEvent>();
    @Output() openDialogEmitter = new EventEmitter();
    @Output() closeDialogEmitter = new EventEmitter();
    peerReviewDashboard;

    constructor(
        public store: Store<AppState>,
        public notificationService: NotificationService,
        public ref: ChangeDetectorRef,
        public colorService: ColorService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        super(store, notificationService, ref, colorService);
    }

    _filter: any;

    @Input()
    set filter(value: SortOptions & PagerOptions) {
        this._filter = value;
        this.setPaginationValues(value);
    }

    onInit(): void {
        if (this._filter === null) return;
        this.setPaginationValues(this._filter);
        if (this._filter.sort && this._filter.direction) {
            const columnEntries = Object.entries(this.columnNames);
            const entry = columnEntries.find(
                e => e[1] === this._filter.sort.replace(/_/g, '-')
            );
            if (entry) {
                this.defaultSortingColumn = entry[0].replace(/_/g, ' ');
                this.defaultSortingDirection = this._filter.direction;
            }
        }
    }

    pageHandler(): void {
        this.selection.clear();
        this.handleChange.emit(this.getTableParams());
    }

    setPaginationValues(value: SortOptions & PagerOptions) {
        if (!this.paginator) return;
        if (!value) return;
        this.paginator.length = value.total;
        this.paginator.pageSize = value.pageSize;
        this.paginator.pageIndex = value.pageIndex;
    }

    sortHandler(): void {
        this.selection.clear();
        this.handleChange.emit(this.getTableParams());
    }

    openDialog(element) {
        this.openDialogEmitter.emit({ element });
    }

    async navigatePeerReview(element) {
        this.store
            .select(dashboardsSelector)
            .pipe(first())
            .subscribe(dashboards => {
                this.peerReviewDashboard = dashboards.find(
                    d => d.url === 'peer-review' && d['is-available-for-user']
                );
            });
        const instance = await this.store
            .select(instanceSelector)
            .pipe(first())
            .toPromise();
        const services = ['servicenow', 'salesforce'];
        if (
            this.peerReviewDashboard === null ||
            this.peerReviewDashboard === undefined
        ) {
            console.error(
                'the user does not has peer-review dashboard assigned'
            );
            return;
        } else {
            this.store.dispatch(
                GeneralActions.setDashboard({
                    dashboard: this.peerReviewDashboard
                })
            );
            await this.router.navigate([
                '/',
                services[instance.serviceId - 1],
                instance.id,
                'peer-review'
            ]);

            const peerReviewId = element['peer-review-write-off-id'];
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: {
                    peerReviewId
                },
                queryParamsHandling: 'merge'
            });
        }
    }

    getSysIdFromLivecheck(element) {
        return (
            this.router.url.includes('livecheck') ||
            (this.router.url.includes('servicenow') &&
                element['sysId'] !== null &&
                element['sysId'] !== undefined) ||
            (this.router.url.includes('salesforce') &&
                element['CE Type'] !== null &&
                element['CE Type'] !== undefined &&
                element['CE Name'] !== null &&
                element['CE Name'] !== undefined) ||
            (element['Type'] !== null &&
                element['Type'] !== undefined &&
                element['Name'] !== null &&
                element['Name'] !== undefined)
        );
    }

    getPeerReviewWriteOffIdFromLivecheck(element) {
        return (
            this.router.url.includes('livecheck') &&
            element['peer-review-write-off-id'] !== null &&
            element['peer-review-write-off-id'] !== undefined
        );
    }

    getTooltip(element) {
        // TODO: we need to decouple that to be able to set tooltips from other columns
        return element['Quality Gate Result Message'];
    }

    getStatusTooltip(element) {
        // TODO: we need to decouple that to be able to set tooltips from other columns
        return `Quality Gate skipped. Reason: ${element['QgSkipReason']}`;
    }

    getPeerReviewTooltip(element) {
        // TODO: we need to decouple that to be able to set tooltips from other columns
        return (
            (element['peer-review-approver'] != null
                ? 'Approver: ' + element['peer-review-approver']
                : '') +
            '\n' +
            (element['peer-review-requester'] != null
                ? 'Requester: ' + element['peer-review-requester']
                : '') +
            '\n' +
            (element['peer-review-request-description'] != null
                ? 'Request Description: ' +
                  element['peer-review-request-description']
                : '')
        );
    }
}
