<div class="container">
	<mat-form-field appearance="outline" class="w-full">
		<mat-label>Expiration Date</mat-label>
		<qcbi-expiration [formControl]="expirationControl"></qcbi-expiration>
	</mat-form-field>
</div>
<div class="actions flex justify-center gap-8">
	<button
		mat-raised-button
		color="primary"
		class="w-full"
		(click)="submitChanges()"
		cdkFocusInitial
	>
		Change date
	</button>
	<button
		mat-button
		class="w-full"
		(click)="close()"
	>
		Cancel
	</button>
</div>
