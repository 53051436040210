import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { parseList } from '../../../utils/model';

@Injectable({
    providedIn: 'root'
})
export class ScanIssuesService {
    constructor(private http: HttpClient) {}

    load(): Observable<any> {
        return forkJoin([
            this.http.get(
                environment.apiEndPoint + 'widgets/v1/issues-by-scan/4374'
            ),
            this.http
                .get(
                    environment.apiDevEndPoint +
                        'api/v2/issue?filter[scan]=edfd1c9d-f921-49d2-b24d-7b0dfe9261de&ce-type?' +
                        'filter[service_id]=2&page[size]=5000&page[number]=1'
                )
                .pipe(map((data: any) => parseList(data.data)))
        ]).pipe(
            map((data: any) => {
                return data[0].data.reduce(
                    (result, value, key) => {
                        return {
                            ...result,
                            ['chart' + key]: [value]
                        };
                    },
                    {
                        table: data[1]
                    }
                );
            })
        );
    }
}
