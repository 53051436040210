import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ColourBubbleComponent } from './colour-bubble.component';

@NgModule({
    declarations: [ColourBubbleComponent],
    imports: [CommonModule],
    exports: [ColourBubbleComponent]
})
export class ColourBubbleModule {}
