import { JiraIntegrationState } from './jira-integration.models';
import { Action, createReducer, on } from '@ngrx/store';
import { JiraIntegrationActions } from './jira-integration.actions';

export const jiraIntegrationInitialState: JiraIntegrationState = {
    batchProcess: '',
    error: '',
    projects: [],
    users: [],
    transitions: [],
    applications: [],
    integrationEnabled: false,
    activityLog: {
        data: [],
        meta: {}
    },
    loading: {
        config: false,
        transitions: false,
        users: false,
        project: false,
        applications: false
    },
    config: {
        id: undefined,
        email: null,
        token: null,
        subdomain: null,
        project: null,
        severity: null,
        impactArea: null,
        application: null,
        selfClose: null,
        enabled: null,
        initialState: null,
        finalState: null,
        initialStatus: null,
        finalStatus: null,
        issueType: null,
        assignee: null
    }
};

const reducer = createReducer(
    jiraIntegrationInitialState,
    on(JiraIntegrationActions.setJiraConfig, (state, { config }) => {
        return {
            ...state,
            config: {
                ...config
            },
            integrationEnabled: config.id !== null
        };
    }),
    on(JiraIntegrationActions.enableJiraIntegration, state => ({
        ...state,
        integrationEnabled: true
    })),
    on(JiraIntegrationActions.disableJiraIntegration, state => ({
        ...state,
        integrationEnabled: false,
        config: {
            ...jiraIntegrationInitialState.config
        }
    })),
    on(JiraIntegrationActions.connect, state => {
        return {
            ...state,
            batchProcess: 'connecting'
        };
    }),
    on(JiraIntegrationActions.connectionSuccess, (state, { config }) => {
        return {
            ...state,
            batchProcess: '',
            config: { ...config },
            integrationEnabled: config.id !== null
        };
    }),
    on(JiraIntegrationActions.connectionError, (state, { error }) => {
        return {
            ...state,
            batchProcess: '',
            error
        };
    }),
    on(JiraIntegrationActions.disconnect, state => ({
        ...state,
        batchProcess: 'disconnecting'
    })),
    on(JiraIntegrationActions.disconnectionSuccess, (state, { config }) => {
        return {
            ...state,
            config: { ...config },
            batchProcess: ''
        };
    }),
    on(JiraIntegrationActions.fetchProjects, state => {
        return {
            ...state,
            loading: {
                ...state.loading,
                project: true
            }
        };
    }),
    on(JiraIntegrationActions.fetchProjectsSuccess, (state, { projects }) => {
        return {
            ...state,
            loading: {
                ...state.loading,
                project: false
            },
            projects: [...projects]
        };
    }),
    on(
        JiraIntegrationActions.saveProjectAndStates,
        (state, { project, initialState, finalState, issueType, assignee }) => {
            return {
                ...state,
                batchProcess: 'saving_project_states',
                config: {
                    ...state.config,
                    project,
                    initialState,
                    finalState,
                    issueType,
                    assignee
                }
            };
        }
    ),
    on(JiraIntegrationActions.saveProjectAndStatesSuccess, state => {
        return {
            ...state,
            batchProcess: ''
        };
    }),
    on(JiraIntegrationActions.saveProjectAndStatesError, (state, { error }) => {
        return {
            ...state,
            batchProcess: '',
            error
        };
    }),
    on(
        JiraIntegrationActions.saveConfig,
        (state, { impactArea, severity, application }) => {
            return {
                ...state,
                batchProcess: 'saving_config',
                config: { ...state.config, impactArea, severity, application }
            };
        }
    ),
    on(JiraIntegrationActions.saveConfigSuccess, state => {
        return {
            ...state,
            batchProcess: ''
        };
    }),
    on(JiraIntegrationActions.saveConfigError, (state, { error }) => {
        return {
            ...state,
            batchProcess: '',
            error
        };
    }),
    on(JiraIntegrationActions.fetchApplications, state => {
        return {
            ...state,
            loading: {
                ...state.loading,
                applications: true
            }
        };
    }),
    on(JiraIntegrationActions.setApplications, (state, { applications }) => {
        return {
            ...state,
            applications: [...applications],
            loading: {
                ...state.loading,
                applications: false
            }
        };
    }),
    on(JiraIntegrationActions.fetchTransitions, state => {
        return {
            ...state,
            transitions: [],
            loading: {
                ...state.loading,
                transitions: true
            }
        };
    }),
    on(JiraIntegrationActions.setTransitions, (state, { transitions }) => {
        return {
            ...state,
            transitions: [...transitions],
            loading: {
                ...state.loading,
                transitions: false
            }
        };
    }),
    on(JiraIntegrationActions.fetchUsers, state => {
        return {
            ...state,
            users: [],
            loading: {
                ...state.loading,
                users: true
            }
        };
    }),
    on(JiraIntegrationActions.fetchUsersSuccess, (state, { users }) => {
        return {
            ...state,
            users: [...users],
            loading: {
                ...state.loading,
                users: false
            }
        };
    }),
    on(JiraIntegrationActions.fetchUsersError, state => {
        return {
            ...state,
            loading: {
                ...state.loading,
                users: false
            }
        };
    }),
    on(JiraIntegrationActions.setActivityLog, (state, { data, meta }) => {
        return {
            ...state,
            activityLog: {
                data,
                meta
            }
        };
    })
);

export function jiraIntegrationReducer(
    state: JiraIntegrationState,
    action: Action
): JiraIntegrationState {
    return reducer(state, action);
}
