import {
    ActionReducerMap,
    createFeatureSelector,
    MetaReducer
} from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { RouterStateUrl } from './router/router.state';
import { settingsReducer } from './settings/settings.reducer';
import { SettingsState } from './settings/settings.model';
import { generalReducer } from './state/general/general.reducer';
import { GeneralState } from './state/general/general.models';

// Livecheck overview widget reducers
import { stateReducer as ov_sw_usage_reducer } from './state/widgets/small/livecheck-overview/usage.state';
import { stateReducer as ov_lw_usage_reducer } from './state/widgets/large/livecheck-overview/usage.state';
import { stateReducer as ov_sw_ed_reducer } from './state/widgets/small/livecheck-overview/early-detection.state';
import { stateReducer as ov_lw_ed_reducer } from './state/widgets/large/livecheck-overview/early-detection.state';
import { stateReducer as ov_sw_rm_app_reducer } from './state/widgets/small/livecheck-overview/rm-app.state';
import { stateReducer as ov_sw_rm_hist_reducer } from './state/widgets/small/livecheck-overview/rm-hist.state';
import { stateReducer as ov_sw_fb_reducer } from './state/widgets/small/livecheck-overview/fb.state';

// usage widgets reducers
import { stateReducer as u_sw_ua_reducer } from './state/widgets/small/usage/ua.state';
import { stateReducer as u_sw_li_reducer } from './state/widgets/small/usage/li.state';
import { stateReducer as u_sw_gls_reducer } from './state/widgets/small/usage/gls.state';
import { stateReducer as u_sw_glac_reducer } from './state/widgets/small/usage/glac.state';
import { stateReducer as u_lw_ua_reducer } from './state/widgets/large/usage/ua.state';
import { stateReducer as u_lw_li_reducer } from './state/widgets/large/usage/li.state';
import { stateReducer as u_lw_gls_reducer } from './state/widgets/large/usage/gls.state';
import { stateReducer as u_lw_glac_reducer } from './state/widgets/large/usage/glac.state';

// upgradeability widgets reducers
import { stateReducer as up_sw_ceu_reducer } from './state/widgets/small/upgradeability/ceu.state';
import { stateReducer as up_sw_ootb_reducer } from './state/widgets/small/upgradeability/ootb.state';
import { stateReducer as up_sw_ootb_inv_reducer } from './state/widgets/small/upgradeability/ootb-inventory.state';
import { stateReducer as up_lw_ceu_reducer } from './state/widgets/large/upgradeability/ceu.state';
import { stateReducer as up_lw_ootb_reducer } from './state/widgets/large/upgradeability/ootb.state';
import { stateReducer as up_lw_ootb_inv_reducer } from './state/widgets/large/upgradeability/ootb-inventory.state';

// governance widgets reducers
import { stateReducer as ov_sw_sf_reducer } from './state/widgets/small/governance-overview/salesforce.state';
import { stateReducer as ov_sw_sn_reducer } from './state/widgets/small/governance-overview/service-now.state';
import { stateReducer as ov_sw_al_reducer } from './state/widgets/small/governance-overview/alerts.state';
import { stateReducer as ov_u_sn_reducer } from './state/widgets/small/governance-overview/usage-service-now.state';
import { stateReducer as ov_u_sf_reducer } from './state/widgets/small/governance-overview/usage-salesforce.state';

import { stateReducer as gov_lw_salesforce_reducer } from './state/widgets/large/governance-overview/salesforce.state';
import { stateReducer as gov_lw_servicenow_reducer } from './state/widgets/large/governance-overview/service-now.state';

// asset manager
import { stateReducer as am_lw_am_reducer } from './state/widgets/large/asset-manager/asset-manager.state';

// profiling
import { stateReducer as p_sw_ct_reducer } from './state/widgets/small/profiling/ct.state';
import { stateReducer as p_sw_nlocs_reducer } from './state/widgets/small/profiling/nlocs.state';
import { stateReducer as p_sw_nce_reducer } from './state/widgets/small/profiling/nce.state';
import { stateReducer as p_sw_warn_reducer } from './state/widgets/small/profiling/warn.state';
import { stateReducer as p_sw_apps_reducer } from './state/widgets/small/profiling/apps.state';
import { stateReducer as p_sw_tc_reducer } from './state/widgets/small/profiling/tc.state';
import { stateReducer as p_sw_cctir_reducer } from './state/widgets/small/profiling/cctir.state';
import { stateReducer as p_lw_apps_reducer } from './state/widgets/large/profiling/apps.state';
import { stateReducer as p_lw_ct_reducer } from './state/widgets/large/profiling/ct.state';
import { stateReducer as p_lw_nce_reducer } from './state/widgets/large/profiling/nce.state';
import { stateReducer as p_lw_issues_reducer } from './state/widgets/large/profiling/iss.state';
import { stateReducer as p_lw_nlocs_reducer } from './state/widgets/large/profiling/nlocs.state';
import { stateReducer as p_lw_locs_reducer } from './state/widgets/large/profiling/locs.state';
import { stateReducer as p_lw_warn_reducer } from './state/widgets/large/profiling/warn.state';
import { stateReducer as p_lw_cctir_reducer } from './state/widgets/large/profiling/cctir.state';

// compare multiple instances
import { stateReducer as cmi_lw_reducer } from './state/widgets/large/compare-multiple-instances/cmi-large.state';
import { stateReducer as int_sw_reducer } from './state/widgets/small/compare-multiple-instances/int-small.state';

// most configurable areas
import { stateReducer as mca_lw_reducer } from './state/widgets/large/most-configurable-areas/mca.state';

// user adoption
import { stateReducer as ua_sw_au_reducer } from './state/widgets/small/user-adoption/au.state';
import { stateReducer as ua_sw_tl_reducer } from './state/widgets/small/user-adoption/tl.state';
import { stateReducer as ua_lw_au_reducer } from './state/widgets/large/user-adoption/au.state';
import { stateReducer as ua_lw_tl_reducer } from './state/widgets/large/user-adoption/tl.state';
import { stateReducer as uasf_lw_au_reducer } from './state/widgets/large/user-adoption-sf/au.state';
import { stateReducer as uasf_lw_tl_reducer } from './state/widgets/large/user-adoption-sf/tl.state';

// catalog
import { stateReducer as c_sw_noc_reducer } from './state/widgets/small/catalog/noc.state';
import { stateReducer as c_sw_cc_reducer } from './state/widgets/small/catalog/cc.state';
import { stateReducer as c_sw_cv_reducer } from './state/widgets/small/catalog/cv.state';
import { stateReducer as c_sw_ci_reducer } from './state/widgets/small/catalog/ci.state';
import { stateReducer as c_sw_issues_reducer } from './state/widgets/small/catalog/issues.state';
import { stateReducer as c_lw_noc_reducer } from './state/widgets/large/catalog/noc.state';
import { stateReducer as c_lw_cc_reducer } from './state/widgets/large/catalog/cc.state';
import { stateReducer as c_lw_ci_reducer } from './state/widgets/large/catalog/ci.state';
import { stateReducer as c_lw_cv_reducer } from './state/widgets/large/catalog/cv.state';
import { stateReducer as c_lw_issues_reducer } from './state/widgets/large/catalog/issues.state';

// licensing
import { stateReducer as li_sw_li_reducer } from './state/widgets/small/licensing/licenses.state';
import { stateReducer as li_sw_gls_reducer } from './state/widgets/small/licensing/gls.state';
import { stateReducer as li_sw_glac_reducer } from './state/widgets/small/licensing/glac.state';
import { stateReducer as li_lw_li_reducer } from './state/widgets/large/licensing/licenses.state';
import { stateReducer as li_lw_gls_reducer } from './state/widgets/large/licensing/gls.state';
import { stateReducer as li_lw_glac_reducer } from './state/widgets/large/licensing/glac.state';

// cross org licensing
import { stateReducer as col_sw_tl_reducer } from './state/widgets/small/cross-org-licensing/tl.state';
import { stateReducer as col_lw_tl_reducer } from './state/widgets/large/cross-org-licensing/tl.state';

// performance
import { stateReducer as pf_sw_cst_reducer } from './state/widgets/small/performance/cst.state';
import { stateReducer as pf_sw_sst_reducer } from './state/widgets/small/performance/sst.state';
import { stateReducer as pf_sw_pi_reducer } from './state/widgets/small/performance/pi.state';
import { stateReducer as pf_lw_cst_reducer } from './state/widgets/large/performance/cst.state';
import { stateReducer as pf_lw_sst_reducer } from './state/widgets/large/performance/sst.state';
import { stateReducer as pf_lw_pi_reducer } from './state/widgets/large/performance/pi.state';

// customization vs configuration
import { stateReducer as cvsc_sw_cr_reducer } from './state/widgets/small/customization-vs-configuration/cr.state';
import { stateReducer as cvsc_lw_cr_reducer } from './state/widgets/large/customization-vs-configuration/cr.state';

// Integrations
import { stateReducer as int_lw_reducer } from './state/widgets/large/integrations/int.state';

// alerts
import { stateReducer as al_sw_ai_reducer } from './state/widgets/small/alerts/ai.state';
import { stateReducer as al_sw_at_reducer } from './state/widgets/small/alerts/at.state';
import { stateReducer as al_lw_ai_reducer } from './state/widgets/large/alerts/ai.state';
import { stateReducer as al_lw_at_reducer } from './state/widgets/large/alerts/at.state';

// duplicated code
import { stateReducer as dc_sw_dc_reducer } from './state/widgets/small/duplicated-code/dc.state';
import { stateReducer as dc_lw_dc_reducer } from './state/widgets/large/duplicated-code/dc.state';

// release management
import { stateReducer as rm_sw_rm_reducer } from './state/widgets/small/release-management/rm.state';
import { stateReducer as rm_lw_rm_reducer } from './state/widgets/large/release-management/rm.state';
import { stateReducer as rm_lw_appl_reducer } from './state/widgets/large/release-management/appl.state';

// impact analysis
import { stateReducer as ia_lw_ia_reducer } from './state/widgets/large/impact-analysis/ia/ia.state';

// feature branch
import { stateReducer as fb_lw_fb_reducer } from './state/widgets/large/feature-branch/fb.state';

// writeoffs
import { stateReducer as wo_lw_wo_reducer } from './state/widgets/large/write-offs/wo.state';

// debt manager
import { stateReducer as dm_lw_dm_reducer } from './state/widgets/large/debt-manager/dm.state';
import { stateReducer as dm_lw_dmcwo_reducer } from './state/widgets/large/debt-manager/dm-closed-wo.state';
import { stateReducer as dm_lw_dmcf_reducer } from './state/widgets/large/debt-manager/dm-closed-fixed.state';

// models
import { Widgets } from './state/models/widgets';
import { resetWidgets } from './meta-reducers/reset-views.reducer';
import { authLibReducer, AuthLibState, debug } from './auth-lib';
import { notificationsReducer } from './state/notifications/notifications.reducer';
import { NotificationsState } from './state/notifications/notifications.models';
import { DownloadXlsState } from './state/download-formatted-file/download-formatted-file.models';
import { downloadFormattedFileReducer } from './state/download-formatted-file/download-formatted-file.reducer';
import { tagsReducer } from './state/tags/tags.reducer';
import { TagsState } from './state/tags/tags.models';
import { jiraIntegrationReducer } from './state/jira-integration/jira-integration.reducer';
import { JiraIntegrationState } from './state/jira-integration/jira-integration.models';
import { twoFactorAuthenticationReducer } from '../shared/two-factor-authentication/two-factor-authentication.reducer';
import { TwoFactorAuthenticationState } from '../shared/two-factor-authentication/two-factor-authentication.models';

export const reducers: ActionReducerMap<AppState> = {
    general: generalReducer,
    notifications: notificationsReducer,
    twoFactorAuthentication: twoFactorAuthenticationReducer,
    jiraIntegrations: jiraIntegrationReducer,
    tags: tagsReducer,
    downloadXls: downloadFormattedFileReducer,
    auth: authLibReducer,
    settings: settingsReducer,
    router: routerReducer,
    // small livecheck overview widgets
    ov_sw_usage: ov_sw_usage_reducer,
    ov_lw_usage: ov_lw_usage_reducer,
    ov_sw_ed: ov_sw_ed_reducer,
    ov_lw_ed: ov_lw_ed_reducer,
    ov_sw_rm_app: ov_sw_rm_app_reducer,
    ov_sw_rm_hist: ov_sw_rm_hist_reducer,
    ov_sw_fb: ov_sw_fb_reducer,

    // small usage widgets
    us_sw_ua: u_sw_ua_reducer,
    us_sw_li: u_sw_li_reducer,
    us_sw_gls: u_sw_gls_reducer,
    us_sw_glac: u_sw_glac_reducer,
    // large usage widgets
    us_lw_ua: u_lw_ua_reducer,
    us_lw_li: u_lw_li_reducer,
    us_lw_gls: u_lw_gls_reducer,
    us_lw_glac: u_lw_glac_reducer,
    // small upgradeability widgets
    up_sw_ceu: up_sw_ceu_reducer,
    up_sw_ootb: up_sw_ootb_reducer,
    up_sw_ootb_inv: up_sw_ootb_inv_reducer,
    // large upgradeability widgets
    up_lw_ceu: up_lw_ceu_reducer,
    up_lw_ootb: up_lw_ootb_reducer,
    up_lw_ootb_inv: up_lw_ootb_inv_reducer,
    // governance
    ov_sw_sf: ov_sw_sf_reducer,
    ov_sw_sn: ov_sw_sn_reducer,
    ov_sw_al: ov_sw_al_reducer,
    ov_u_sf: ov_u_sf_reducer,
    ov_u_sn: ov_u_sn_reducer,
    gov_lw_salesforce: gov_lw_salesforce_reducer,
    gov_lw_servicenow: gov_lw_servicenow_reducer,
    // asset manager
    am_lw_am: am_lw_am_reducer,
    // profiling
    p_sw_ct: p_sw_ct_reducer,
    p_sw_nlocs: p_sw_nlocs_reducer,
    p_sw_nce: p_sw_nce_reducer,
    p_sw_warn: p_sw_warn_reducer,
    p_sw_tc: p_sw_tc_reducer,
    p_sw_cctir: p_sw_cctir_reducer,
    p_lw_apps: p_lw_apps_reducer,
    p_lw_ct: p_lw_ct_reducer,
    p_lw_issues: p_lw_issues_reducer,
    p_lw_nlocs: p_lw_nlocs_reducer,
    p_lw_locs: p_lw_locs_reducer,
    p_sw_apps: p_sw_apps_reducer,
    p_lw_nce: p_lw_nce_reducer,
    p_lw_warn: p_lw_warn_reducer,
    p_lw_cctir: p_lw_cctir_reducer,
    // compare multiple instances
    cmi_lw: cmi_lw_reducer,
    cmi_int_sw: int_sw_reducer,
    // most configurable areas
    mca_lw: mca_lw_reducer,
    // user adoption
    ua_sw_au: ua_sw_au_reducer,
    ua_sw_tl: ua_sw_tl_reducer,
    ua_lw_au: ua_lw_au_reducer,
    ua_lw_tl: ua_lw_tl_reducer,
    uasf_lw_au: uasf_lw_au_reducer,
    uasf_lw_tl: uasf_lw_tl_reducer,
    // catalog
    c_sw_noc: c_sw_noc_reducer,
    c_sw_cc: c_sw_cc_reducer,
    c_sw_cv: c_sw_cv_reducer,
    c_sw_ci: c_sw_ci_reducer,
    c_sw_issues: c_sw_issues_reducer,
    c_lw_noc: c_lw_noc_reducer,
    c_lw_cc: c_lw_cc_reducer,
    c_lw_ci: c_lw_ci_reducer,
    c_lw_cv: c_lw_cv_reducer,
    c_lw_issues: c_lw_issues_reducer,
    // licensing
    li_sw_li: li_sw_li_reducer,
    li_sw_gls: li_sw_gls_reducer,
    li_sw_glac: li_sw_glac_reducer,
    li_lw_li: li_lw_li_reducer,
    li_lw_gls: li_lw_gls_reducer,
    li_lw_glac: li_lw_glac_reducer,
    // cross org licensing
    col_sw_tl: col_sw_tl_reducer,
    col_lw_tl: col_lw_tl_reducer,
    // performance
    pf_sw_cst: pf_sw_cst_reducer,
    pf_sw_sst: pf_sw_sst_reducer,
    pf_sw_pi: pf_sw_pi_reducer,
    pf_lw_cst: pf_lw_cst_reducer,
    pf_lw_sst: pf_lw_sst_reducer,
    pf_lw_pi: pf_lw_pi_reducer,
    // customization vs configuration
    cvsc_sw_cr: cvsc_sw_cr_reducer,
    cvsc_lw_cr: cvsc_lw_cr_reducer,
    // integrations
    int_lw: int_lw_reducer,
    // alerts
    al_sw_ai: al_sw_ai_reducer,
    al_sw_at: al_sw_at_reducer,
    al_lw_ai: al_lw_ai_reducer,
    al_lw_at: al_lw_at_reducer,
    // duplicated code
    dc_sw_dc: dc_sw_dc_reducer,
    dc_lw_dc: dc_lw_dc_reducer,
    // release management
    rm_sw_rm: rm_sw_rm_reducer,
    rm_lw_rm: rm_lw_rm_reducer,
    rm_lw_appl: rm_lw_appl_reducer,
    // impact analysis
    ia_lw_ia: ia_lw_ia_reducer,
    // feature branch
    fb_lw_fb: fb_lw_fb_reducer,
    // write-offs
    wo_lw_wo: wo_lw_wo_reducer,
    // debt manager
    dm_lw_dm: dm_lw_dm_reducer,
    dm_lw_dmcwo: dm_lw_dmcwo_reducer,
    dm_lw_dmcf: dm_lw_dmcf_reducer
};

export interface AppState {
    general: GeneralState;
    notifications: NotificationsState;
    twoFactorAuthentication: TwoFactorAuthenticationState;
    jiraIntegrations: JiraIntegrationState;
    tags: TagsState;
    downloadXls: DownloadXlsState;
    auth: AuthLibState;
    settings: SettingsState;
    router: RouterReducerState<RouterStateUrl>;

    // QUALITY IN USE VIEW

    // small usage widgets
    us_sw_ua: typeof Widgets.Small.Usage.UA;
    us_sw_li: typeof Widgets.Small.Usage.LI;
    us_sw_gls: typeof Widgets.Small.Usage.GLS;
    us_sw_glac: typeof Widgets.Small.Usage.GLAC;
    // large usage widgets
    us_lw_ua: typeof Widgets.Large.Usage.UA;
    us_lw_li: typeof Widgets.Large.Usage.LI;
    us_lw_gls: typeof Widgets.Large.Usage.GLS;
    us_lw_glac: typeof Widgets.Large.Usage.GLAC;

    // LIVECHECK VIEW
    ov_sw_usage: typeof Widgets.Small.Overview.USAGE;
    ov_lw_usage: typeof Widgets.Large.Overview.USAGE;
    ov_sw_ed: typeof Widgets.Small.Overview.ED;
    ov_lw_ed: typeof Widgets.Large.Overview.ED;
    ov_sw_rm_app: typeof Widgets.Small.Overview.RMAPP;
    ov_sw_rm_hist: typeof Widgets.Small.Overview.RMHIST;
    ov_sw_fb: typeof Widgets.Small.Overview.FB;

    // UPGRADEABILITY
    up_sw_ceu: typeof Widgets.Small.Upgradeability.CEU;
    up_sw_ootb: typeof Widgets.Small.Upgradeability.OOTB;
    up_sw_ootb_inv: typeof Widgets.Small.Upgradeability.OOTBINVENTORY;
    up_lw_ceu: typeof Widgets.Large.Upgradeability.CEU;
    up_lw_ootb: typeof Widgets.Large.Upgradeability.OOTB;
    up_lw_ootb_inv: typeof Widgets.Large.Upgradeability.OOTBINVENTORY;

    // GOVERNANCE
    ov_sw_sf: typeof Widgets.Small.Overview.SALESFORCE;
    ov_sw_sn: typeof Widgets.Small.Overview.SERVICENOW;
    ov_sw_al: typeof Widgets.Small.Overview.AL;
    ov_u_sn: typeof Widgets.Small.Governance.USNOW;
    ov_u_sf: typeof Widgets.Small.Governance.USF;
    gov_lw_salesforce: typeof Widgets.Large.Governance.SALESFORCE;
    gov_lw_servicenow: typeof Widgets.Large.Governance.SERVICENOW;

    // ASSET MANAGER
    am_lw_am: typeof Widgets.Large.AssetManager.AM;

    // PROFILING
    p_sw_ct: typeof Widgets.Small.Profiling.CT;
    p_sw_nlocs: typeof Widgets.Small.Profiling.NLOCS;
    p_sw_nce: typeof Widgets.Small.Profiling.NCE;
    p_sw_warn: typeof Widgets.Small.Profiling.WARN;
    p_sw_apps: typeof Widgets.Small.Profiling.APPS;
    p_sw_tc: typeof Widgets.Small.Profiling.TC;
    p_sw_cctir: typeof Widgets.Small.Profiling.CCTIR;
    p_lw_apps: typeof Widgets.Large.Profiling.APPS;
    p_lw_ct: typeof Widgets.Large.Profiling.CT;
    p_lw_nce: typeof Widgets.Large.Profiling.NCE;
    p_lw_issues: typeof Widgets.Large.Profiling.ISS;
    p_lw_nlocs: typeof Widgets.Large.Profiling.NLOCS;
    p_lw_locs: typeof Widgets.Large.Profiling.LOCS;
    p_lw_warn: typeof Widgets.Large.Profiling.WARN;
    p_lw_cctir: typeof Widgets.Large.Profiling.CCTIR;

    // COMPARE MULTIPLE INSTANCES
    cmi_lw: typeof Widgets.Large.CompareMultipleInstances.CMI;
    cmi_int_sw: typeof Widgets.Small.CompareMultipleInstances.INT;
    // MOST CONFIGURABLE AREAS
    mca_lw: typeof Widgets.Large.MostConfigurableAreas.MCA;

    // USER ADOPTION
    ua_sw_au: typeof Widgets.Small.UserAdoption.AU;
    ua_sw_tl: typeof Widgets.Small.UserAdoption.TL;
    ua_lw_au: typeof Widgets.Large.UserAdoption.AU;
    ua_lw_tl: typeof Widgets.Large.UserAdoption.TL;
    uasf_lw_au: typeof Widgets.Large.UserAdoption.AUSF;
    uasf_lw_tl: typeof Widgets.Large.UserAdoption.TLSF;

    // CATALOG
    c_sw_noc: typeof Widgets.Small.Catalog.NOC;
    c_sw_cc: typeof Widgets.Small.Catalog.CC;
    c_sw_cv: typeof Widgets.Small.Catalog.CV;
    c_sw_ci: typeof Widgets.Small.Catalog.CI;
    c_sw_issues: typeof Widgets.Small.Catalog.ISSUES;
    c_lw_noc: typeof Widgets.Large.Catalog.NOC;
    c_lw_cc: typeof Widgets.Large.Catalog.CC;
    c_lw_ci: typeof Widgets.Large.Catalog.CI;
    c_lw_cv: typeof Widgets.Large.Catalog.CV;
    c_lw_issues: typeof Widgets.Large.Catalog.ISSUES;

    // LICENSING
    li_sw_li: typeof Widgets.Small.Licensing.LI;
    li_sw_gls: typeof Widgets.Small.Licensing.GLS;
    li_sw_glac: typeof Widgets.Small.Licensing.GLAC;
    li_lw_li: typeof Widgets.Large.Licensing.LI;
    li_lw_gls: typeof Widgets.Large.Licensing.GLS;
    li_lw_glac: typeof Widgets.Large.Licensing.GLAC;

    // CROSS ORG LICENSING
    col_sw_tl: typeof Widgets.Small.CrossOrgLicensing.TL;
    col_lw_tl: typeof Widgets.Large.CrossOrgLicensing.TL;

    // PERFORMANCE
    pf_sw_cst: typeof Widgets.Small.Performance.CST;
    pf_sw_sst: typeof Widgets.Small.Performance.SST;
    pf_sw_pi: typeof Widgets.Small.Performance.PI;
    pf_lw_cst: typeof Widgets.Large.Performance.CST;
    pf_lw_sst: typeof Widgets.Large.Performance.SST;
    pf_lw_pi: typeof Widgets.Large.Performance.PI;

    // CUSTOMIZATION VS CONFIGURATION
    cvsc_sw_cr: typeof Widgets.Small.CustomizationVsConfiguration.CR;
    cvsc_lw_cr: typeof Widgets.Large.CustomizationVsConfiguration.CR;
    // ITNEGRATIONS
    int_lw: typeof Widgets.Large.Integrations.INT;

    // ALERTS
    al_sw_ai: typeof Widgets.Small.Alerts.AI;
    al_sw_at: typeof Widgets.Small.Alerts.AT;
    al_lw_ai: typeof Widgets.Large.Alerts.AI;
    al_lw_at: typeof Widgets.Large.Alerts.AT;

    // DUPLICATED CODE
    dc_sw_dc: typeof Widgets.Small.DuplicatedCode.DC;
    dc_lw_dc: typeof Widgets.Large.DuplicatedCode.DC;

    // RELEASE MANAGEMENT
    rm_sw_rm: typeof Widgets.Small.ReleaseManagement.RM;
    rm_lw_rm: typeof Widgets.Large.ReleaseManagement.RM;
    rm_lw_appl: typeof Widgets.Large.ReleaseManagement.RM;

    // IMPACT ANALYSIS
    ia_lw_ia: typeof Widgets.Large.ImpactAnalysis.IA;

    // FEATURE BRANCH
    fb_lw_fb: typeof Widgets.Large.FeatureBranch.FB;

    // WRITE OFFS
    wo_lw_wo: typeof Widgets.Large.WriteOffs.WO;

    // DEBT MANAGER
    dm_lw_dm: typeof Widgets.Large.DebtManager.DM;
    dm_lw_dmcwo: typeof Widgets.Large.DebtManager.DMCWO;
    dm_lw_dmcf: typeof Widgets.Large.DebtManager.DMCF;
}

export const metaReducers: MetaReducer<AppState>[] = [
    initStateFromLocalStorage,
    resetWidgets
];

// if (!environment.production) {
//     if (!environment.test) {
metaReducers.unshift(debug);
//     }
// }

export const selectSettingsState = createFeatureSelector<
    AppState,
    SettingsState
>('settings');

export const selectRouterState = createFeatureSelector<
    AppState,
    RouterReducerState<RouterStateUrl>
>('router');
