const packageJson = require('../../../../package.json');

export const environment = {
    appName: 'Quality Clouds - Portal',
    envName: 'Custom',
    production: true,
    apiEndPoint: 'API_URL/',
    apiDevEndPoint: 'SERVICES_API_URL/',
    test: false,
    i18nPrefix: '',
    apiUrl: 'SERVICES_API_URL/api',
    portalUrl: 'PORTAL_APP_URL/',
    loginUrl: 'AUTH_APP_URL/',
    adminUrl: 'ADMIN_APP_URL/',
    cookieDomain: '.qualityclouds.com',
    cookiePrefix: 'qcAPP_ENV.',
    versions: {
        app: packageJson.version,
        angular: packageJson.dependencies['@angular/core'],
        ngrx: packageJson.dependencies['@ngrx/store'],
        material: packageJson.dependencies['@angular/material'],
        bootstrap: packageJson.dependencies.bootstrap,
        rxjs: packageJson.dependencies.rxjs,
        ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
        fontAwesome:
            packageJson.dependencies['@fortawesome/fontawesome-free-webfonts'],
        angularCli: packageJson.devDependencies['@angular/cli'],
        typescript: packageJson.devDependencies['typescript'],
        cypress: packageJson.devDependencies['cypress']
    },
    token:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjRiMDlmYjkyOWQwZTQ0MWVkOTcxYjc4YjMwMWQ0YjczNTZiZDVmN2Y0ZGRhNmEwNDY0ZjFiYjQ5OTRiNjg5NGI3YzhhYTJhNDBjMjBhNjkzIn0.eyJhdWQiOiIxMyIsImp0aSI6IjRiMDlmYjkyOWQwZTQ0MWVkOTcxYjc4YjMwMWQ0YjczNTZiZDVmN2Y0ZGRhNmEwNDY0ZjFiYjQ5OTRiNjg5NGI3YzhhYTJhNDBjMjBhNjkzIiwiaWF0IjoxNTc1NTYxMDA1LCJuYmYiOjE1NzU1NjEwMDUsImV4cCI6MTYwNzE4MzQwNSwic3ViIjoiOTkiLCJzY29wZXMiOlsic2NhbiJdfQ.PwKinFSV-6I_g8vi4v1UspDbvoWSXhSJlqdkoMIJCnjj1iKMBhoaqCU5ys--I7ruJ3OYS3AoH18UVzsEPXlU6wayqb7MQiC4QyrXjVfQxkfm9JVx0RghUuizW4hbcGocWn6cY9lQFU_FWBvuNGZfZls3GYM9KQMJnqO8O1DAs-gNm8vMD1-EU4uPzuHmQMkNDjqpsGdxDvH5ePjwj8woWacdbJdzPi11QzjO1_9NE7tKcw9Gxl1-UB48MhwwvEyWVicFNcUSkTcjWRuowzh8VVJPny8wIw8pxJnurdJK0Yw74G-O-LQcP0d-gY7JwgEgZmPAVwJFMaFTGHVcI5xj3GOGbZm92eCuONTephRul9FYgiGd05yaUlBYLEFaUBkZGUcfNlMUSC7ZXHELpYT0G5TeKwAn8olZCDspuQIy2ObxE62F3_37igh3Atsm8c2iGX_enIfkfqTb3B4S8Sr5EPSc-AqXlRfbFZbwgoen0drpYynweOMJF59J3IwCzdntxhZx5gYeCgD_tgtSaNwUtFUSsSAp-mxCKbDJGMSYhTO1t5MHmKifiZKzZb8z21JMpA90TMsNSDUEptALN6MH-BP-3czR7PcK5Lq5SJuqAX0tE6kRLOiXAkg1-xGn_F4BNSKzyZu37wclcN9xfDEeMuecBRo6PXxyUqi1BiYYq1Q'
};
