import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QgBannerComponent } from './qg-banner.component';
import { BannerModule } from '../banner/banner.module';

@NgModule({
    imports: [CommonModule, BannerModule],
    declarations: [QgBannerComponent],
    exports: [QgBannerComponent]
})
export class QgBannerModule {}
