export interface AuthLibState {
    onWindow: boolean;
    windowId: string;
    status: AuthLibStatus;
    isAuthenticated: boolean;
    onEmulation: boolean;
    user: any;
    emulatedUser: any;
    logoutOnFocus: boolean;
}

export enum AuthLibStatus {
    OFF = 'OFF',
    ON = 'ON'
}

export interface InitSuccess {
    isAuthenticated: boolean;
    onEmulation: boolean;
    user: any;
    emulatedUser: any;
}

export interface ApiUser {
    type: string;
    id: string;
    attributes: {
        username: string;
        'first-name': string;
        'last-name': string;
        'personal-company-email': string;
        'last-logged': string;
        'customer-id': number;
        'customer-name': string;
        'terms-accepted': boolean;
        active: boolean;
        'can-scan': boolean;
        'launch-update-set': boolean;
        role: string;
        'instance-ids': string[];
        'dashboard-ids': number[];
        'profile-id': number;
        'can-download': boolean;
        'valid-until': string;
        expires: number | null;
        'force-change': false;
        'pwd-expiration-days': number;
        'show-pwd-warning': boolean;
        'pwd-changed': string;
        role_name: string;
        num_dashboards: number;
        'logged-in': boolean;
        'can-be-emulated': boolean;
        'show-new-portal-popup': boolean;
        self: string;
        emulatedUser: any;
        'is-approver': boolean;
    };
}

export interface User {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    lastLogged: string;
    customerId: number;
    customerName: string;
    termsAccepted: boolean;
    active: boolean;
    canScan: boolean;
    launchUpdateSet: boolean;
    role: string;
    instanceIds: string[];
    dashboardIds: number[];
    profileId: number;
    canDownload: boolean;
    validUntil: string;
    expires: number | null;
    forceChange: false;
    pwdExpirationDays: number;
    showPwdWarning: boolean;
    pwdChanged: string;
    roleName: string;
    numDashboards: number;
    loggedIn: boolean;
    canBeEmulated: boolean;
    showNewPortalPopup: boolean;
    self: string;
    emulatedUser: any;
    timeFormat: string;
    customerHasMfa: boolean;
    forceMfa: boolean;
    hasSso: boolean;
    isApprover: boolean;
}

export interface Credentials {
    username: string;
    password: string;
}

export interface HeartbeatInfo {
    heartbeat: boolean;
    refreshed: boolean;
    emulationOutdated: boolean;
    reloadState: boolean;
    onWindow: boolean;
}
