<div class="">
  <h6 class="title">Tags</h6>
</div>
<mat-form-field class="searchField">
  <mat-label>Search</mat-label>
  <input #filter type="text" matInput placeholder="Write here">
  <mat-icon matSuffix>search</mat-icon>
</mat-form-field>
<mat-selection-list *ngIf="tags$ | async as catalog;">
  <mat-list-item *ngFor="let item of catalog | tagsFilter: filter.value">
    <div class="flex justify-between w-full">
      <div>{{item.name}}</div>
      <mat-checkbox
        [value]="item.id"
        [checked]="tagsAndItems[item.id] && tagsAndItems[item.id].length > 0"
        [indeterminate]="tagsAndItems[item.id] && tagsAndItems[item.id].length < items.length"
        (change)="checkboxChange($event)"
      >
      </mat-checkbox>
    </div>
  </mat-list-item>
</mat-selection-list>
<mat-list>
  <mat-list-item class="pointer" matRipple (click)="addTag()"> Create new </mat-list-item>
  <mat-list-item class="pointer" matRipple style="cursor: pointer" (click)="manage()"> Manage tags </mat-list-item>
</mat-list>
