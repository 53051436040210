<div class="px-4 py-4" *ngIf="currentViewHasConfig$ | async">
	<div class="flex justify-between pb-1 mb-4 border-b-1 border-gray-400">
		<qcbi-kpis [kpis]="kpis$ | async"></qcbi-kpis>
	</div>
	<div class="flex w-full justify-between">
		<div class="flex gap-16 w-full">
			<div class="w-full">
				<qcbi-selectable-filters-component
					[availableFilters]="availableFilters$"
					[selectedOptions]="selectedOptions$"
					[filterOptions]="filterOptions$"
					[canShowStatus]="canShowStatusFilter$ | async"
					(filtersReset)="resetFiltersHandler()"
					(filterChanged)="handleNewSelectedOptions($event)"
					(addFilterToDisplay)="addFilterToDisplayHandler($event)"
					(removeFilterFromDisplay)="removeFilterFromDisplayHandler($event)"
				></qcbi-selectable-filters-component>
			</div>
			<div class="create-view" *ngIf="canCreateViews$ | async">
				<button
					mat-raised-button
					color="primary"
					[disabled]="((filterUnchanged$ | async) && (columnsConfigUnchanged$ | async) && (pageSizeUnchanged$ | async) && (sortUnchanged$ | async)) || (maxViewLimitReached$ | async)"
					[matTooltip]="(maxViewLimitReached$ | async) ? 'You have reached your view limit.' : undefined"
					(click)="openCreateViewModal()"
				>
					Create View
				</button>
			</div>
			<div class="save-filters" *ngIf="canShowSaveFilters$ | async">
				<button
					mat-raised-button
					color="primary"
					[disabled]="(filterUnchanged$ | async) && (columnsConfigUnchanged$ | async) && (pageSizeUnchanged$ | async) && (sortUnchanged$ | async)"
					(click)="updateViewClick()"
					[loading]="updatingFilters$ | async"
				>
					Save Changes
				</button>
			</div>
		</div>
	</div>
	<div class="table-container" *ngIf="columnNames">
		<qcbi-server-table
			id="home-table"
			class="body-border clickable-rows"
			[actions]="actions"
			[loading]="loading$ | async"
			[isExpandable]="false"
			[columnNames]="columnNames"
			[data]="data$ | async"
			[filter]="meta$ | async"
			downloadType="open-issue"
			[downloadFilters]='downloadFilter$ | async'
			(handleChange)="handleChange($event)"
			(actionDispatched)="actionsService.executeAction($event)"
			[disabledSortingColumns]='disabledSortingColumns'
			(rowClick)="openDrawer($event)"
			[configuration]="configColumnsName$ | async"
            [initialConfigValues]="initialConfigValues$ | async"
            (configChanged)="configChangedHandler($event)"
			[hideColumnsByDefault]="hideColumnsByDefault"
		>
		</qcbi-server-table>
	</div>
</div>
<div *ngIf="!(currentViewHasConfig$ | async)">loading...</div>
<qcbi-tag-menu-component></qcbi-tag-menu-component>
