import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ColoredDotComponent } from './colored-dot.component';

@NgModule({
    imports: [CommonModule],
    exports: [ColoredDotComponent],
    declarations: [ColoredDotComponent]
})
export class ColoredDotModule {}
