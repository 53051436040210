import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandedWidgetDirective } from './expanded-widget.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [ExpandedWidgetDirective],
    exports: [ExpandedWidgetDirective]
})
export class ExpandedWidgetModule {}
