export const ACTIONS = {
    OPEN: [
        { id: 'write-off', label: 'write-off selection' },
        { id: 'add-tag', label: 'manage tags' }
    ],
    REQUESTED: [
        { id: 'un-write-off', label: 'Bulk edit selected issues' },
        { id: 'add-tag', label: 'manage tags' }
    ],
    WRITTENOFF: [
        { id: 'un-write-off-only-decline', label: 'Bulk edit selected issues' },
        { id: 'add-tag', label: 'manage tags' },
        { id: 'modify-expiration-date', label: 'modify expiration date' }
    ],
    CLOSED: [{ id: 'add-tag', label: 'manage tags' }]
};

export const COLUMN_NAMES_BY_STATUS = {
    OPEN: {
        Issue_Open_Date: 'issue-open-date',
        Issue_type: 'issue-type',
        Impact_Area: 'area',
        Application: 'application',
        Package_Name: 'application',
        CE_Name: 'ce-name',
        CE_Type: 'ce-type',
        tags: 'tags',
        Line: 'line-number',
        'Time To Fix (minutes)': 'time-to-fix',
        hyperlink: 'sys-id',
        doc: 'doc-link',
        Severity: 'severity',
        Update_set_name: 'update-set-name',
        write_off_status: 'write-off-status',
        Issue_Id: 'delta-issues-long-id',
        OpenedAfterQgActivation: 'opened-after-qg-activation',
        CE_created_by: 'created-by',
        CE_created_on: 'created-on',
        CE_updated_by: 'updated-by',
        CE_updated_on: 'updated-on'
    },
    REQUESTED: {
        Issue_Open_Date: 'issue-open-date',
        Issue_type: 'issue-type',
        Impact_Area: 'area',
        Application: 'application',
        Package_Name: 'application',
        CE_Name: 'ce-name',
        CE_Type: 'ce-type',
        tags: 'tags',
        Line: 'line-number',
        'Time To Fix (minutes)': 'time-to-fix',
        hyperlink: 'sys-id',
        doc: 'doc-link',
        Severity: 'severity',
        Update_set_name: 'update-set-name',
        CE_Created_By: 'created-by',
        CE_created_on: 'created-on',
        CE_Updated_By: 'updated-by',
        CE_updated_on: 'updated-on',
        request_description: 'request-description',
        Write_Off_requestor: 'requester',
        request_date: 'request-date',
        Write_Off_reason: 'reason',
        write_off_status: 'write-off-status',
        write_off_expiration_date: 'write-off-expiration-date-time',
        Issue_Id: 'delta-issues-long-id'
    },
    WRITTENOFF: {
        Issue_Open_Date: 'issue-open-date',
        Issue_type: 'issue-type',
        Impact_Area: 'area',
        Application: 'application',
        Package_Name: 'application',
        CE_Name: 'ce-name',
        CE_Type: 'ce-type',
        tags: 'tags',
        Line: 'line-number',
        'Time To Fix (minutes)': 'time-to-fix',
        // Owner: 'issue-created-by',
        hyperlink: 'sys-id',
        doc: 'doc-link',
        Severity: 'severity',
        Update_set_name: 'update-set-name',
        CE_Created_By: 'created-by',
        CE_created_on: 'created-on',
        CE_Updated_By: 'updated-by',
        CE_updated_on: 'updated-on',
        request_description: 'request-description',
        approve_description: 'approver-description',
        Write_Off_requestor: 'requester',
        approve_date: 'approve-date',
        request_date: 'request-date',
        Write_Off_reason: 'reason',
        write_off_status: 'write-off-status',
        write_off_expiration_date: 'write-off-expiration-date-time',
        Issue_Id: 'delta-issues-long-id'
    },
    CLOSED: {
        Issue_Closed_Date: 'issue-closed-date',
        'Age (days)': 'age',
        Issue_type: 'issue-type',
        Impact_Area: 'area',
        Application: 'application',
        Package_Name: 'application',
        CE_Name: 'ce-name',
        CE_Type: 'ce-type',
        tags: 'tags',
        Line: 'line-number',
        'Time To Fix (minutes)': 'time-to-fix',
        hyperlink: 'sys-id',
        doc: 'doc-link',
        Severity: 'severity',
        Issue_Id: 'delta-issues-long-id',
        Update_set_name: 'update-set-name',
        write_off_expiration_date: 'write-off-expiration-date-time',
        CE_created_by: 'created-by',
        CE_created_on: 'created-on',
        CE_updated_by: 'updated-by',
        CE_updated_on: 'updated-on'
    }
};
