export interface Filter {
    label: string;
    value: string | number;
    disabled?: boolean;
}

export interface BaseFilter {
    context: string;
    filterValues: Filter[];
}

export interface BaseFilters {
    [key: string]: BaseFilter;
}

export interface FilterOption {
    value: string | number;
    label: string | number;
    disabled?: boolean;
}

export interface Filters {
    areas: FilterOption[];
    tagsByScan: FilterOption[];
    bestPractices: FilterOption[];
    cetypes: FilterOption[];
    cetypesnames: FilterOption[];
    licensesCustomer: FilterOption[];
    orgs: FilterOption[];
    cetypesnamesisootb?: FilterOption[];
    severities: FilterOption[];
    createdBy: FilterOption[];
    updatedBy: FilterOption[];
    auditUpdatedBy: FilterOption[];
    namespaces: FilterOption[];
    cmNamespaces: FilterOption[];
    CInamespaces: FilterOption[];
    sources: FilterOption[];
    auditElement: FilterOption[];
    auditApplication: FilterOption[];
    dateCeTypes?: FilterOption[];
    dateUsers?: FilterOption[];
    dateTeams?: FilterOption[];
    dateCeNames?: FilterOption[];
    dateIssuesCeTypes?: FilterOption[];
    dateIssuesDevelopers?: FilterOption[];
    dateIssuesTeams?: FilterOption[];
    scansFilter?: FilterOption[];
    typeOfChange?: FilterOption[];
    ootbModifications?: FilterOption[];
    updatedByUgr?: FilterOption[];
    cetypesnamesUgr?: FilterOption[];
    dateUpdateSetName?: FilterOption[];
    dateUpdateSetQgStatus?: FilterOption[];
    dateAppInventoryQgStatus?: FilterOption[];
    dateUpdateSetStatus?: FilterOption[];
    dateAppInventoryStatus?: FilterOption[];
    dateUpdateSetReleaseType?: FilterOption[];
    dateFeatureBranchQgStatus?: FilterOption[];
    dateFeatureBranchStatus?: FilterOption[];
    dateFeatureBranchReleaseType?: FilterOption[];
    dateFeatureBranchName?: FilterOption[];
    dateScansCeTypes?: FilterOption[];
    dateScansDevelopers?: FilterOption[];
    dateScansQualityGatesResults?: FilterOption[];
}

export interface GeneralState {
    sideMenuExpanded: boolean;
    largeWidgetExpanded: boolean;
    isLoaded: boolean;
    loading: boolean;
    error: string;
    view: ViewType;
    baseFilters: BaseFilters;
    dashboardFilters: Filters;
    generalFiltersLoaded: boolean;
    settings: DefaultSettings;
    onAllClouds: boolean;
    initialLoading: boolean;
    mainFilterSet: boolean; //
    instanceLoading: boolean;
    scanLoading: boolean;
    user: any;
    instances: any;
    instancesOnlyFullScans: any;
    instance: any | null;
    scans: any[];
    profilingScans: any[];
    scan: any;
    dashboards: any[];
    allCloudsDashboards: any[];
    dashboardContext: any;
    dashboard: any;
    emptyView: boolean;
    selectedDateRange: any;
    lastScans: any;
    addons: Addon[];
    mainFilter: 'opened' | 'closed' | 'all';
    includeBaseline: boolean;
    includeTablesAndDictionaries: boolean;
    userPortalConfiguration: { [key: string]: any };
}

export enum ViewType {
    Executive = 'Executive'
}

export interface DefaultSettings {
    serviceId: any;
    dashboardId: number;
    instanceId?: number;
}

export interface SimpleDefaultSettings {
    serviceId: any;
    instanceId: number;
}

export interface RawDefaultSettings {
    'dashboard-context': any;
    'dashboard-id': number;
    'instance-id'?: number;
}

export interface InitialLoadSuccessData {
    user: any;
    instances: any;
    instancesOnlyFullScans: any;
    allCloudsDashboards: any[];
    settings: any;
    dashboardContext: any;
}

export interface InstanceLoadSuccessData {
    instance: any;
    scans: any;
    profilingScans: any;
    dashboards: any;
    dashboard: any;
    scan?: any;
    filters?: any;
}

export interface SimpleInstanceLoadSuccessData {
    instance: any;
    scans: any;
    scan: any;
    filters?: any;
}

export interface ScanLoadSuccessData {
    scan: any;
    filters: any;
}

export interface Addon {
    id: string;
    name: string;
    nameId: string;
    description: string;
    serviceId: number;
}
