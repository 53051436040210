<div [formGroup]="formGroup">
    <mat-form-field appearance="outline" class="w-full">
        <input
            matInput
            type="text"
            formControlName="search"
            [matAutocomplete]="auto"
            placeholder="search..."
            aria-label="search"
        >
        <mat-autocomplete
            #auto
            (optionSelected)="addElement($event.option.value)"
        >
            <mat-option
                *ngFor="let option of (filteredOptions$ | async)"
                [value]="option"
                [attr.aria-label]="option[displayAttribute]"
            >
                <ng-container
                    [ngTemplateOutlet]="getOption()"
                    [ngTemplateOutletContext]="{
                        option: option
                    }"
                ></ng-container>
            </mat-option>
        </mat-autocomplete>
        <mat-spinner
            matSuffix
            *ngIf="loading"
            aria-label="loading results"
            [diameter]="24"
        ></mat-spinner>
    </mat-form-field>
</div>
<mat-chip-list aria-label="items-list" *ngIf="selectedItems?.length > 0; else noItems">
    <mat-chip
        *ngFor="let element of selectedItems"
        [removable]="true"
        (removed)="removeElement(element)"
    >
        <ng-container
            [ngTemplateOutlet]="getChipTemplate()"
            [ngTemplateOutletContext]="{
                element: element,
                displayAttribute: displayAttribute
            }"
        ></ng-container>
        <mat-icon matChipRemove aria-label="remove item">cancel</mat-icon>
    </mat-chip>
</mat-chip-list>

<ng-template #noItems>
    <span class="no-items">{{ noItemsText }}</span>
</ng-template>

<ng-template
    #defaultChipContent
    let-element="element"
    let-displayAttribute="displayAttribute"
>
    {{ element[displayAttribute] }}
</ng-template>

<ng-template
    #defaultOptionContent
    let-option="option"
>
    {{ option.name }}
</ng-template>